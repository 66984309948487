import React from 'react'
import { Grid, Typography } from '@mui/material'
import SongContent from '../song/SongContent'
import SongSetting from '../song/SongSetting'
import i18n from '../../i18n'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  preview: {
    paddingLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px'
    }
  }
}))

const PhonePreview = ({ value, settings, setSettings, autoSaveField }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='baseline'
      className={classes.preview}
    >
      <Typography color='textSecondary' sx={{ paddingBottom: 1 }}>
        {i18n.t('songs:preview')}
      </Typography>
      <SongSetting
        settings={settings}
        setSettings={setSettings}
        autoSaveField={autoSaveField}
        disableTwoColumns
      />
      <SongContent content={[...value]} fullSize={true} settings={settings} />
    </Grid>
  )
}

PhonePreview.propTypes = {
  /** song content */
  value: PropTypes.array,
  /** song settings */
  settings: PropTypes.object,
  setSettings: PropTypes.func,
  /** setting store field */
  autoSaveField: PropTypes.string
}

export default PhonePreview
