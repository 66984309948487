import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TableContainer
} from '@mui/material'
import i18n from '../../../i18n'
import apiConsumer from '../../../service/api/apiConsumer'
import { DataGrid } from '@mui/x-data-grid'
import AppDialog from '../../../components/unclassified/AppDialog'
import LogsGraphsDisplay from '../../../components/unclassified/LogsGraphsDisplay'
import ReactJson from 'react-json-view'
import frFR from '../../../assets/jss/datagridLocaleFr'
import NavBar from '../../../core/NavBar'
import { makeStyles } from '@mui/styles'
import { formatDate } from './../../../service/functions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  header: {
    overflowX: 'auto',
    flexWrap: 'nowrap!important',
    padding: theme.spacing(0, 1)
  },
  formControl: {
    minWidth: 150
  },
  datatable: {
    height: 'calc(100vh - 102px)',
    margin: 0,
    width: '100%',
    minWidth: 'auto!important',
    backgroundColor: 'transparent!important',
    backgroundImage: 'none!important',
    boxShadow: 'none!important',
    overflow: 'hidden',
    '& .MuiDataGrid-root': {
      border: '0'
    },
    '& .MuiDataGrid-viewport': {
      minWidth: 'auto!important'
    },
    '& .MuiDataGrid-root .MuiDataGrid-colCell:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus,.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none!important'
    },
    '& .MuiDataGrid-root .MuiDataGrid-colCellMoving, .MuiDataGrid-columnHeader': {
      backgroundColor: 'transparent!important'
    },

    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 110px)'
    }
  }
}))

const ContentCell = params => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <AppDialog
        title={`${i18n.t('logs:log details')} : ${params.row.date} - ${params.row.time}`}
        open={open}
        width='md'
        onClose={() => setOpen(false)}
      >
        <List>
          <Item primary={i18n.t('level') + ' : ' + params.row.level} />
          <Item primary={i18n.t('logger') + ' : ' + params.row.logger} />
          <Item
            primary={i18n.t('message') + ' :'}
            secondary={params.row.message}
          />
          <Item primary={params.row.context ? i18n.t('context') : null} />
          {params.row.context ? (
            <ReactJson src={params.row.context} theme='monokai' />
          ) : null}
        </List>
      </AppDialog>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setOpen(true)
        }}
      >
        {params.value}
      </div>
    </>
  )
}

const Item = ({ primary, secondary }) =>
  primary ? (
    <ListItem alignItems='flex-start'>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  ) : null

const favoriteLogList = 'dev-request'

const levels = {
  ALL: 'Tous',
  NOTICE: 'Notice',
  INFO: 'Info',
  DEBUG: 'Debug',
  ERROR: 'Error',
  CRITICAL: 'Critical'
}

const defaultColumns = [
  { field: 'id', headerName: 'id', width: 70, hide: true },
  { field: 'level', headerName: i18n.t('logs:level'), width: 100 },
  { field: 'date', headerName: i18n.t('logs:date'), width: 80 },
  { field: 'time', headerName: i18n.t('logs:time'), width: 80 },
  {
    field: 'message',
    headerName: i18n.t('logs:message'),
    width: 800,
    renderCell: ContentCell
  }
]

export default function Logs () {
  const classes = useStyles()
  const [channelList, setChannelList] = useState()
  const [channel, setChannel] = useState('')
  const [tabValue, setValue] = useState(0)
  const [level, setLevel] = useState('ALL')
  const [logs, setLogs] = useState(null)
  const [columns, setColumns] = useState(defaultColumns)
  const ref = useRef(null)

  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
  }

  const datatableRef = useCallback(node => {
    if (node) {
      const w = node.clientWidth - 300
      setColumns(c => {
        c[3].width = w > 200 ? w : 200
        return c
      })
    }
    ref.current = node
  }, [])

  const getLogs = useCallback(
    async (channel, level) => {
      if (channel) {
        const rep = await apiConsumer.get(`logs/${channel}/${level}`)
        if (rep.status === 200) {
          setLogs(rep.body)
        }
      }
    },
    [setLogs]
  )

  const selectChannel = useCallback(
    channel => {
      if (channel) {
        setChannel(channel)
        getLogs(channel, level)
      }
    },
    [level, setChannel, getLogs]
  )

  const selectLevel = useCallback(
    level => {
      setLevel(level)
      getLogs(channel, level)
    },
    [channel, getLogs]
  )

  useEffect(() => {
    if (!logs) {
      apiConsumer.get(`logs`).then(rep => {
        if (rep.status === 200) {
          setChannelList(rep.body || [])
          if (rep.body?.length) {
            selectChannel(
              rep.body.includes(favoriteLogList) ? 'dev-request' : rep.body[0]
            )
          }
        }
      })
    }
    return () => {
      selectChannel()
    }
  }, [setChannelList, selectChannel, logs])

  return (
    <>
      <NavBar title={i18n.t(`menu:logs`)} />
      <Grid container className={classes.root}>
        <Grid
          container
          alignItems='center'
          spacing={3}
          justifyContent='space-between'
          className={classes.header}
        >
          <Grid item>
            <Select
              variant='standard'
              className={classes.formControl}
              value={channel}
              onChange={e => selectChannel(e.target.value)}
            >
              {!!channelList &&
                channelList.map(e => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label={i18n.t('logs:list')} />
              <Tab label={i18n.t('logs:graphs')} />
            </Tabs>
          </Grid>
          <Grid item>
            <ButtonGroup variant='text'>
              {Object.keys(levels).map(e => (
                <Button
                  key={e}
                  onClick={() => selectLevel(e)}
                  variant={level === e ? 'outlined' : null}
                >
                  {levels[e]}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        </Grid>
        {!tabValue ? (
          <TableContainer className={classes.datatable}>
            <DataGrid
              ref={datatableRef}
              rows={
                logs === null
                  ? []
                  : logs.map(e => ({ ...e, date: formatDate(e.date) }))
              }
              columns={columns}
              loading={logs === null}
              pageSize={100}
              rowsPerPageOptions={[20, 50, 100, 500]}
              pagination
              rowHeight={28}
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }}
              localeText={frFR}
            />
          </TableContainer>
        ) : (
          <LogsGraphsDisplay datas={logs || []} />
        )}
      </Grid>
    </>
  )
}
