import React, { useCallback, useMemo, useState } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import PhonePreview from '../preview/SongPreview'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
  Edit,
  MoreHoriz,
  FormatBold
} from '@mui/icons-material'
import ProperMenu from '../menu/ProperMenu'
import SimpleTextDialog from './SimpleTextDialog'
import i18n from '../../i18n'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import SongTextField from './SongTextField'
import { defaultAdminSongSettings } from '../../config/constants'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  inputBold: {
    width: '100%',
    marginBottom: theme.spacing(1),
    '& *': {
      fontWeight: '800'
    }
  },
  NewButton: {
    transform: 'translateY(12px)'
  },
  songGrid: {
    position: 'relative'
  },
  menu: {
    float: 'right',
    height: '10px',
    marginTop: '8px!important'
  },
  addButton: {
    marginTop: theme.spacing(2)
  },
  songChordMenu: {
    width: '100%',
    overflow: 'auto',
    '& *': {
      textTransform: 'initial'
    }
  },
  switch: {
    margin: '0 0 10px 70px'
  }
}))

const settingsMenu = [
  {
    title: i18n.t('main:edit'),
    action: 'EDIT_PREPARE',
    icon: <Edit />
  },
  {
    title: i18n.t('main:delete'),
    action: 'DELETE',
    icon: <Delete />
  },
  {
    title: i18n.t('main:bold'),
    action: 'BOLD',
    icon: <FormatBold />
  },
  {
    title: i18n.t('main:move up'),
    action: 'MOVE_UP',
    icon: <KeyboardArrowUp />
  },
  {
    title: i18n.t('main:move down'),
    action: 'MOVE_DOWN',
    icon: <KeyboardArrowDown />
  }
]

const textEditParams = {
  title: i18n.t('songs:editor:verse name'),
  description: null,
  label: i18n.t('songs:editor:name'),
  yes: i18n.t('main:valid'),
  no: i18n.t('main:back'),
  autocomplete: [
    'Refrain',
    'Soliste',
    'Pont',
    'Coda',
    'Refrain final',
    'Fin',
    'Doxologie',
    'Contre-chant'
  ]
}

const SongMenu = ({ index, last, className, handleAction }) => {
  return (
    <>
      <ProperMenu icon={<MoreHoriz />} className={className}>
        <List>
          {settingsMenu.map((element, i) => {
            if (
              (index === 0 && element.action === 'MOVE_UP') ||
              (last && element.action === 'MOVE_DOWN')
            ) {
              return null
            } else {
              return (
                <ListItem
                  button
                  key={`sMenu-${index}-${i}`}
                  onClick={() => {
                    handleAction(element.action, index)
                  }}
                >
                  <ListItemIcon>{element.icon}</ListItemIcon>
                  <ListItemText primary={element.name} />
                </ListItem>
              )
            }
          })}
        </List>
      </ProperMenu>
    </>
  )
}

const SongEditor = ({
  field,
  editable,
  value,
  element,
  tones,
  setStoreValue
}) => {
  const classes = useStyles()
  const [editValue, setEditValue] = useState({ value: '', index: null })
  const [editOpen, setEditOpen] = useState(false)
  const [focus, setFocus] = useState([])
  const [settings, setSettings] = useState({
    ...defaultAdminSongSettings,
    ...(localStorage.getItem('AdminSongSettings')
      ? JSON.parse(localStorage.getItem('AdminSongSettings'))
      : {})
  })

  useMemo(() => {
    setSettings(s => ({ ...s, toneName: tones }))
  }, [setSettings, tones])

  const handleTextChange = useCallback(
    (index, val) => {
      value.text[index].text = val
      setStoreValue({ ...value }, null, field)
    },
    [value, setStoreValue, field]
  )

  const handleAction = useCallback(
    (action, index) => {
      switch (action) {
        case 'ADD_EDIT':
          if (editValue.index !== null) {
            value.text[editValue.index].name = editValue.value
            setStoreValue({ ...value }, null, field)
          } else {
            value.text = [...value.text, { name: editValue.value, text: '' }]
            setStoreValue({ ...value }, null, field)
          }

          setEditOpen(false)

          break
        case 'ADD_PREPARE':
          setEditValue({ value: '', index: null })
          setEditOpen(true)
          break
        case 'EDIT_PREPARE':
          setEditValue({ value: value.text[index].name, index })
          setEditOpen(true)
          break
        case 'DELETE':
          value.text.splice(index, 1)
          setStoreValue({ ...value }, null, field)
          break
        case 'BOLD':
          value.text[index] = {
            ...value.text[index],
            bold: value.text[index].bold ? false : true
          }
          setStoreValue({ ...value }, null, field)
          break
        case 'MOVE_UP':
          if (index > 0) {
            const tmp = value.text[index]
            value.text[index] = value.text[index - 1]
            value.text[index - 1] = tmp
            setStoreValue({ ...value }, null, field)
          }
          break
        case 'MOVE_DOWN':
          if (index < value.text.length - 1) {
            const tmp = value.text[index]
            value.text[index] = value.text[index + 1]
            value.text[index + 1] = tmp
            setStoreValue({ ...value }, null, field)
          }
          break

        default:
          break
      }
    },
    [editValue, setStoreValue, field, value]
  )

  const handleTextDialog = useCallback(
    value => {
      setEditValue(ev => ({ ...ev, value }))
    },
    [setEditValue]
  )

  return (
    <>
      <Grid item md={7} xs={12} className={classes.songGrid}>
        {value.text.map((verse, index) => (
          <React.Fragment key={`verse-block${index}`}>
            {editable ? (
              <SongMenu
                index={index}
                last={value.text.length - 1 === index}
                className={classes.menu}
                handleAction={handleAction}
              />
            ) : (
              <br />
            )}
            <SongTextField
              classes={classes}
              className={verse.bold ? classes.inputBold : classes.input}
              id='standard-basic'
              multiline={true}
              key={verse.name}
              label={verse.name}
              value={verse.text}
              onChange={value => handleTextChange(index, value)}
              onFocus={() => {
                setFocus(index)
              }}
              focus={focus === index && settings.displayChords}
              InputProps={{
                readOnly: editable === false
              }}
            />
          </React.Fragment>
        ))}

        {editable ? (
          <Grid container justifyContent='center' className={classes.addButton}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                handleAction('ADD_PREPARE')
              }}
            >
              {i18n.t('songs:editor:add')}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid item md={5} xs={12}>
        <PhonePreview
          value={value.text}
          settings={settings}
          setSettings={setSettings}
          autoSaveField='AdminSongSettings'
        />
      </Grid>
      <SimpleTextDialog
        params={textEditParams}
        values={editValue}
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        onChange={value => handleTextDialog(value)}
        onValid={() => {
          handleAction('ADD_EDIT')
        }}
      />
    </>
  )
}

SongEditor.propTypes = {
  field: PropTypes.string,
  editable: PropTypes.bool,
  value: PropTypes.object.isRequired,
  element: PropTypes.object,
  tones: PropTypes.string,
  setStoreValue: PropTypes.func
}

export default SongEditor

export const SongEditorStore = connect(
  (state, ownProps) => ({
    field: ownProps.element.field,
    value: state.form.values[ownProps.element.field],
    valid: state.form.valid[ownProps.element.field],
    tones: state.form.values.tones,
    editable: state.form.editable
  }),
  dispach => ({
    setStoreValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(SongEditor)
