const settings = {
  type: 'bar',
  options: {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }
}

export default settings
