import C from './c4.mp3'
import C_ from './c_4.mp3'
import D from './d4.mp3'
import D_ from './d_4.mp3'
import E from './e4.mp3'
import F from './f4.mp3'
import F_ from './f_4.mp3'
import G from './g4.mp3'
import G_ from './g_4.mp3'
import A from './a4.mp3'
import A_ from './a_4.mp3'
import B from './b4.mp3'

const getSound = (note = '') => {
  note = note.toLowerCase()
  if (note === 'c') {
    return C
  } else if (note === 'c#' || note === 'db') {
    return C_
  } else if (note === 'd') {
    return D
  } else if (note === 'd#' || note === 'eb') {
    return D_
  } else if (note === 'e') {
    return E
  } else if (note === 'f') {
    return F
  } else if (note === 'f#' || note === 'gb') {
    return F_
  } else if (note === 'g') {
    return G
  } else if (note === 'g#' || note === 'ab') {
    return G_
  } else if (note === 'a') {
    return A
  } else if (note === 'a#' || note === 'bb') {
    return A_
  } else if (note === 'b') {
    return B
  }
}

export default getSound
