import React from "react";
import { ListItem, ListItemText, List, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import RichTextPreview from "../preview/RichTextPreview";
import SongContent from "../song/SongContent";
import Loading from "../../core/Loading";
import {
  checkIsNotEmptyRichText,
  selectVerseCount,
} from "../../service/massSheetService";
import { displayDate } from "../../service/functions";
import { makeStyles } from "@mui/styles";
import {
  checkOrdinaryDisplaying,
  dontDisplayHeaderName,
} from "../../config/massSheetConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  richText: {
    margin: theme.spacing(2, 3),
  },
  list: {
    width: "100%",
    marginBottom: 100,
  },
  songList: {
    margin: theme.spacing(3, 0),
  },
  number: {
    minWidth: "auto",
    padding: theme.spacing(0, 1),
  },
  songHeader: {
    margin: theme.spacing(1, 0, 2, 0) + "!important",
  },
  dateBlock: {
    textAlign: "right",
    float: "right",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
      float: "none",
    },
  },
}));

const DisplayMassSheet = ({
  massSheet,
  displayName = true,
  AllowLinks = true,
  targetBlank = false,
  settings,
}) => {
  const classes = useStyles();

  return massSheet.name === undefined ? (
    <Loading />
  ) : (
    <>
      <List subheader={<li />} className={classes.list}>
        <Details
          primary={
            <>
              <Typography display="inline" variant="h6">
                {displayName ? massSheet.name : ""}
              </Typography>
              <div className={classes.dateBlock}>
                <Typography color="textSecondary">
                  {displayDate(massSheet.date)}
                </Typography>
                {!!massSheet.parish?.name && (
                  <Typography color="textSecondary">
                    {massSheet.parish.name}
                  </Typography>
                )}
                {!!massSheet.code && (
                  <Typography color="textSecondary">
                    {`Code : ${massSheet.code}`}
                  </Typography>
                )}
              </div>
            </>
          }
          secondary={massSheet.subname}
        />

        {checkIsNotEmptyRichText(massSheet.introduction) && (
          <div className={classes.richText}>
            <RichTextPreview value={massSheet.introduction} />
          </div>
        )}

        <div className={classes.songList}>
          {(massSheet.massSheetSong ?? []).map((mss, i) =>
            mss.song &&
              checkOrdinaryDisplaying(
                settings.displayOrdinary,
                mss.header.name
              ) ? (
              <Grid key={i}>
                <MyLink
                  to={
                    AllowLinks
                      ? `/app/song-${mss.song.id}-${mss.song.name}`
                      : null
                  }
                  target={targetBlank ? "blank" : "_self"}
                >
                  <ListItem button={AllowLinks} className={classes.songHeader}>
                    <Typography color="textSecondary">
                      {mss.header.name + "\u00a0:\u00a0"}
                    </Typography>
                    {dontDisplayHeaderName(mss.header.name) &&
                      !settings.compactMode ? null : (
                      <ListItemText
                        primary={
                          <Typography noWrap>{mss.song.name}</Typography>
                        }
                      />
                    )}
                  </ListItem>
                </MyLink>
                {!settings.compactMode ? (
                  <Grid container>
                    {mss.song?.content?.text ? (
                      <SongContent
                        content={selectVerseCount(
                          mss.song.content.text,
                          mss.verses
                        )}
                        settings={settings}
                      />
                    ) : null}
                  </Grid>
                ) : null}
              </Grid>
            ) : null
          )}
        </div>

        {checkIsNotEmptyRichText(massSheet.informations) && (
          <div className={classes.richText}>
            <RichTextPreview value={massSheet.informations} />
          </div>
        )}
      </List>
    </>
  );
};

DisplayMassSheet.propTypes = {
  /** massSheets */
  massSheet: PropTypes.object,
  displayName: PropTypes.bool,
  /** redirect when click on song */
  AllowLinks: PropTypes.bool,
  /** display settings */
  settings: PropTypes.object,
  targetBlank: PropTypes.bool,
};

export default DisplayMassSheet;

const Details = ({ primary, secondary, className }) =>
  primary ? (
    <ListItem key={primary} className={className}>
      <ListItemText
        primary={primary}
        secondary={<Typography color="textSecondary">{secondary}</Typography>}
      />
    </ListItem>
  ) : null;

const MyLink = ({ to, target, children }) =>
  to ? (
    <>
      <Link to={to} target={target}>
        {children}
      </Link>
    </>
  ) : (
    children
  );
