import AuthService from './authService'
import authStore from './authStore'

// roles order by importance decrease
export const USERS_ROLES = [
  'ROLE_SUPER_ADMIN',
  'ROLE_ADMIN',
  'ROLE_SUBSCRIBER',
  'ROLE_USER'
]

export const getUserRight = (roles = []) => {
  for (let k of USERS_ROLES) {
    if (roles.includes(k)) return k
  }
}

const ADMIN_USERS_ROLES = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']

const checkLogin = redirect =>
  rightValidator(USERS_ROLES, redirect ? 'login' : null)

const checkAdmin = redirect =>
  rightValidator(ADMIN_USERS_ROLES, redirect ? 'login' : null)

const checkIsAdmin = role =>
  role ? ADMIN_USERS_ROLES.includes(role) : rightValidator(ADMIN_USERS_ROLES)

const checkIsSubscriber = role =>
  role
    ? role !== 'ROLE_USER'
    : authStore.getConnectionDatas()?.role !== 'ROLE_USER'

const rightValidator = (rights = [], redirect) => {
  const role = authStore.getConnectionDatas()?.role
  if (role) {
    const flag = rights.includes(role)
    if (!flag && redirect) {
      window.location = redirect
    }
    return flag
  } else if (window.location.pathname !== '/login') {
    AuthService.goToAuthPage()
  }
}

export {
  rightValidator,
  checkLogin,
  checkAdmin,
  checkIsSubscriber,
  checkIsAdmin
}
