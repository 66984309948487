import React, { useCallback } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { setSnackbarAction } from '../store/setting/settingStoreAction'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// global snackbar to display messages to users

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
))

export function _Snackbar ({ snackbar, setSnackbar }) {
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setSnackbar('', snackbar?.type, 0)
    },
    [setSnackbar, snackbar]
  )

  return !snackbar ? null : (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={snackbar.message !== ''}
        autoHideDuration={
          snackbar?.time === 0
            ? null
            : !snackbar.time
            ? 3000
            : snackbar.time * 1000
        }
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar.type !== '' ? snackbar.type : 'info'}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

_Snackbar.propTypes = {
  snackbar: PropTypes.object,
  setSnackbar: PropTypes.func
}

export default connect(
  state => ({
    snackbar: state.setting.snackbar
  }),
  dispach => ({
    setSnackbar: (message, type, time) =>
      dispach(setSnackbarAction(message, type, time))
  })
)(_Snackbar)
