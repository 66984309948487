import React from 'react'
import { List, ListItem, ListItemText, Typography } from '@mui/material'
import { displayDate } from './../../service/functions'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  float: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      float: 'none'
    }
  }
}))

const ListMassSheet = ({ massSheets = [] }) => {
  const classes = useStyles()
  const history = useHistory()

  return massSheets?.length ? (
    <>
      <List>
        {massSheets.map((e, i) => (
          <ListItem
            key={i}
            button
            divider={i !== massSheets.length - 1}
            onClick={() => history.push(`/app/masssheet-${e.id}`)}
          >
            <ListItemText
              primary={
                <>
                  <Typography>{e.name}</Typography>
                  <Typography color='textSecondary' className={classes.float}>
                    {displayDate(e.date)}
                  </Typography>
                </>
              }
              secondary={e.parish?.name || ''}
            />
          </ListItem>
        ))}
      </List>
    </>
  ) : null
}

export default ListMassSheet
