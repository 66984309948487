import React, { useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import AppCardContainer from '../../../components/containers/AppCardContainer'
import { Doughnut, Line } from 'react-chartjs-2'
import AppStats from '../../../components/stats/Stats'
import LineGraphSettings from '../../../components/stats/lineChart'
import { makeStyles } from '@mui/styles'
import apiConsumer from '../../../service/api/apiConsumer'
import i18n from '../../../i18n'
import NavBar from '../../../core/NavBar'

const statCards = [
  {
    title: i18n.t('dashboard:songs'),
    value: '-',
    increase: 12,
    graph: {
      labels: ['1', '2', '3', '4', '5', '6'],
      datasets: [
        {
          data: [12, 19, 4, 5, 12, 25],
          backgroundColor: 'rgba(36, 142, 255,0.1)',
          borderColor: 'rgba(36, 142, 255, 0.3)',
          borderWidth: 2,
          fill: true
        }
      ]
    }
  },
  {
    title: i18n.t('dashboard:users'),
    value: '-',
    increase: 6,
    graph: {
      labels: ['1', '2', '3', '4', '5', '6'],
      datasets: [
        {
          data: [3, 15, 26, 22, 4, 9],
          backgroundColor: 'rgba(255, 99, 132,0.1)',
          borderColor: 'rgba(255, 99, 132, 0.3)',
          borderWidth: 2,
          fill: true
        }
      ]
    }
  },
  {
    title: i18n.t('dashboard:subscribers'),
    value: '-',
    increase: 12,
    graph: {
      labels: ['1', '2', '3', '4', '5', '6', '7'],
      datasets: [
        {
          data: [5, 12, 15, 8, 14, 12, 25],
          backgroundColor: 'rgba(0, 179, 21,0.1)',
          borderColor: 'rgba(0, 179, 21, 0.3)',
          borderWidth: 2,
          fill: true
        }
      ]
    }
  },
  {
    title: i18n.t('dashboard:messages'),
    value: '-',
    increase: 7,
    graph: {
      labels: ['1', '2', '3', '4', '5', '6'],
      datasets: [
        {
          data: [15, 10, 20, 22, 56, 10],
          backgroundColor: 'rgba(255, 162, 0,0.1)',
          borderColor: 'rgba(255, 162, 0, 0.3)',
          borderWidth: 2,
          fill: true
        }
      ]
    }
  }
]

const userChartData = {
  title: i18n.t("dashboard:user's view"),
  labels: [
    3,
    2,
    2,
    5,
    3,
    7,
    3,
    1,
    2,
    3,
    3,
    2,
    2,
    2,
    3,
    4,
    2,
    6,
    2,
    2,
    3,
    3,
    3,
    1
  ],
  datasets: [
    {
      label: i18n.t('dashboard:new users'),
      data: [
        3,
        2,
        2,
        3,
        3,
        4,
        3,
        1,
        2,
        1,
        3,
        2,
        1,
        2,
        3,
        4,
        1,
        6,
        2,
        2,
        1,
        3,
        3,
        1
      ],
      fill: true,
      backgroundColor: 'rgb(255, 99, 132,0.2)',
      borderColor: 'rgba(255, 99, 132, 0.7)'
    }
  ]
}

const donutChartData = {
  title: i18n.t("dashboard:songs's distribution "),
  labels: ['', '', '', ''],
  datasets: [
    {
      label: '',
      data: [12, 19, 3, 5],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(156, 39, 176, 0.2)',
        'rgba(255, 152, 0, 0.2)',
        'rgba(217, 20, 20, 0.2)',
        'rgba(0, 70, 201, 0.2)',
        'rgba(255, 87, 34, 0.2)',
        'rgba(201, 8, 130, 0.2)',
        'rgba(121, 85, 72, 0.2)',
        'rgba(8, 172, 201, 0.2)',
        'rgba(0, 150, 136, 0.2)',
        'rgba(200, 230, 9, 0.2)',
        'rgba(255, 56, 192, 0.2)',
        'rgba(235, 130, 78, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(156, 39, 176, 1)',
        'rgba(255, 152, 0, 1)',
        'rgba(217, 20, 20, 1)',
        'rgba(0, 70, 201, 0.1)',
        'rgba(255, 87, 34, 1)',
        'rgba(201, 8, 130, 1)',
        'rgba(121, 85, 72, 1)',
        'rgba(8, 172, 201, 1)',
        'rgba(0, 150, 136, 1)',
        'rgba(200, 230, 9, 1)',
        'rgba(255, 56, 192, 1)',
        'rgba(235, 130, 78, 1)'
      ],
      borderWidth: 1
    }
  ]
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}))

const Dashboard = () => {
  const classes = useStyles()
  const [_donutChartData, setDonutData] = useState({ ...donutChartData })
  const [_statCards, setStatCards] = useState([...statCards])
  const [_userChartData, setUserChartData] = useState({ ...userChartData })

  useMemo(async () => {
    const rep = await apiConsumer.get(`stats/dashboard`)
    if (rep.status === 200) {
      if ('songsCount' in rep.body && 'usersCount' in rep.body) {
        let data = statCards
        data[0].value = rep.body.songsCount
        data[1].value = rep.body.usersCount
        data[2].value = rep.body.subscriberCount
        data[3].value = rep.body.messagesCount
        setStatCards(data)
      }
      if ('songRepartition' in rep.body) {
        let data = donutChartData
        data.labels = data.datasets[0].data = []
        rep.body.songRepartition.forEach(e => {
          data.labels = [...data.labels, e.name]
          data.datasets[0].data = [...data.datasets[0].data, e.value]
        })
        setDonutData(data)
      }
      if ('userStats' in rep.body) {
        let data = userChartData
        data.labels = data.datasets[0].data = []
        rep.body.userStats.forEach(e => {
          data.labels = [...data.labels, e.date]
          data.datasets[0].data = [...data.datasets[0].data, e.value]
        })
        setUserChartData(data)
      }
    }
  }, [setDonutData, setUserChartData, setStatCards])

  return (
    <>
      <NavBar title={i18n.t(`menu:dashboard`)} />
      <Grid container spacing={3} className={classes.root}>
        {_statCards.map((element, index) => (
          <Grid item key={index} xs={12} sm={6} md={3}>
            <AppStats data={element}></AppStats>
          </Grid>
        ))}

        <Grid item sm={12} md={6} style={{ width: '100%' }}>
          <AppCardContainer title={userChartData.title}>
            <Line data={_userChartData} options={LineGraphSettings} />
          </AppCardContainer>
        </Grid>

        <Grid item sm={12} md={6} style={{ width: '100%' }}>
          <AppCardContainer title={donutChartData.title}>
            <Doughnut data={_donutChartData} />
          </AppCardContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
