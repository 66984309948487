import React from 'react'
import { FormControlLabel, Grid, Switch, Hidden, Button } from '@mui/material'
import { connect } from 'react-redux'
import i18n from '../../i18n'
import { toggleFieldAction } from '../../store/setting/settingStoreAction'
import NavBar from '../../core/NavBar'
import { makeStyles } from '@mui/styles'
import Cgu from '../connection/Cgu'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}))

export function Setting ({
  darkTheme,
  toggleDarkTheme,
  drawerReduced,
  toggleHideDrawer
}) {
  const classes = useStyles()

  return (
    <>
      <NavBar title={i18n.t('settings:settings')} />
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <MySwitch
            checked={darkTheme}
            onChange={toggleDarkTheme}
            label={i18n.t('settings:dark mode')}
          />
        </Grid>
        <Grid item xs={12}>
          <Hidden mdDown implementation='css'>
            <MySwitch
              checked={drawerReduced}
              onChange={toggleHideDrawer}
              label={i18n.t('settings:reduce drawer')}
            />
          </Hidden>
        </Grid>
        <Grid item xs={12}>
          <Cgu />
        </Grid>
        <Grid item xs={12}>
          <Link to='/app/create-parish'>
            <Button>{i18n.t(`app:parishes:add parish`)}</Button>
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

const SettingStore = connect(
  state => ({
    darkTheme: state.setting.darkTheme,
    drawerReduced: state.setting.drawerReduced
  }),
  dispach => ({
    toggleDarkTheme: () => dispach(toggleFieldAction('darkTheme')),
    toggleHideDrawer: () => dispach(toggleFieldAction('drawerReduced'))
  })
)(Setting)

export default SettingStore

const MySwitch = ({ checked, onChange, label }) => (
  <Grid item md={6}>
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          color='primary'
          name='toggle theme'
        />
      }
      label={label || ''}
    />
  </Grid>
)
