import React, { useCallback, useEffect } from 'react'
import {
  Card,
  CardMedia,
  Button,
  FormControlLabel,
  CardContent,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import i18n from '../../i18n'
import PropTypes from 'prop-types'
import apiConsumer from '../../service/api/apiConsumer'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  button: {
    textAlign: 'center',
    marginTop: theme.spacing(1)
  },
  card: {
    width: '100%'
  },
  media: {
    height: 240
  }
}))

const FormImageInput = ({ editable, element, value, valid, id, setValue }) => {
  const classes = useStyles()

  useEffect(() => {
    if (value === true) {
      apiConsumer.download(`${element.imageUrl}/${id}`).then(rep => {
        if (rep.status) {
          // error
          setValue('', '', element.field)
        } else {
          const reader = new FileReader()
          reader.onloadend = function () {
            setValue(reader.result, '', element.field)
          }
          try {
            reader.readAsDataURL(rep)
          } catch (error) {
            alert(error)
          }
        }
      })
    }
  }, [value, setValue, element, id])

  const handleChange = useCallback(
    event => {
      const reader = new FileReader()
      reader.onloadend = function () {
        setValue(reader.result, '', element.field)
      }
      try {
        reader.readAsDataURL(event.target.files[0])
      } catch (error) {
        alert(error)
      }
    },
    [setValue, element.field]
  )

  return (
    <Grid
      item
      xs={element.xs || 12}
      md={element.md}
      key={`grid-${element.label}`}
    >
      <Card className={classes.card}>
        {value && value !== true ? (
          <CardMedia
            className={classes.media}
            image={value}
            title={element.label}
          />
        ) : null}
        <CardContent className={classes.content}>
          <Typography variant='caption' color='primary'>
            {element.label}
          </Typography>
          <div className={classes.button}>
            <input
              accept='.png,.jpg,.jif,image/*'
              className={classes.input}
              type='file'
              id={`input-${element.label}`}
              onChange={editable ? e => handleChange(e) : null}
            />
            <FormControlLabel
              htmlFor={`input-${element.label}`}
              control={
                <Button component='span' variant='outlined' color='primary'>
                  {i18n.t('main:choose a file')}
                </Button>
              }
              label=''
            />
          </div>
          {valid !== '' ? (
            <Typography variant='caption' color='error'>
              {valid}
            </Typography>
          ) : null}
        </CardContent>
      </Card>
    </Grid>
  )
}

FormImageInput.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.object,
  value: PropTypes.any,
  valid: PropTypes.string,
  setValue: PropTypes.func
}

export default FormImageInput

export const AppImageInputStore = connect(
  (state, ownProps) => {
    return {
      value:
        'values' in state.form ? state.form.values[ownProps.element.field] : '',
      valid:
        'values' in state.form ? state.form.valid[ownProps.element.field] : '',
      editable: state.form.editable,
      id: 'values' in state.form ? state.form.values.id : ''
    }
  },
  dispach => ({
    setValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(FormImageInput)
