import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Grid
} from '@mui/material'
import http from '../../config/httpConstantes'
import { Link } from 'react-router-dom'
import AudioPlayer from '../preview/AudioPlayer'
import { MusicNote, PictureAsPdf, Headphones } from '@mui/icons-material'
import i18n from './../../i18n'

const useStyles = makeStyles(theme => ({
  typo: {
    fontSize: '14px',
    paddingVertical: theme.spacing(1)
  },
  filesList: {
    marginTop: theme.spacing(4),
    width: '100%'
  },
  accordion: {
    background: 'transparent!important',
    backgroundImage: 'none!important',
    boxShadow: 'none!important',
    width: '100%',
    padding: '0',
    '&::before': {
      background: 'transparent!important'
    }
  },
  accordionSummary: {
    margin: '-8px -8px 0 -8px!important'
  }
}))

const SongFilesList = ({ list = [] }) => {
  const classes = useStyles()
  const fileListeBis = list.filter(e => e.type !== 'audio')
  return list?.length ? (
    <>
      <List className={classes.filesList}>
        <MusicFilesAccordion
          list={list.filter(e => e.type === 'audio')}
          listInitialLength={list.length}
        />
        {fileListeBis.map((e, i) => {
          return (
            <Link
              key={i}
              to={`/app/music-${e.type === 'pdf' ? 'pdf' : 'sheet'}-${e.id}`}
            >
              <ListItem divider={i !== fileListeBis.length - 1} button>
                <ListItemAvatar>
                  {e.type === 'pdf' ? <PictureAsPdf /> : <MusicNote />}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography color='textPrimary' className={classes.typo}>
                      {e.name.name}
                    </Typography>
                  }
                />
              </ListItem>
            </Link>
          )
        })}
      </List>
    </>
  ) : null
}

export default SongFilesList

const MusicFilesAccordion = ({ list = [], listInitialLength }) => {
  const classes = useStyles()
  const [enable, setEnable] = useState(false)

  return (
    !!list.length && (
      <Accordion
        classes={{
          root: classes.accordion
        }}
        onChange={() => setEnable(true)}
      >
        <AccordionSummary
          classes={{
            root: classes.accordionSummary,
            content: classes.accordionSummary
          }}
          // expandIcon={<ExpandMoreIcon />}
        >
          <ListItem divider={listInitialLength > list.length} button>
            <ListItemAvatar sx={{ opacity: 0.7 }}>
              <Headphones />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' className={classes.typo}>
                  {i18n.t(`app:song:listen audio`)}
                </Typography>
              }
            />
          </ListItem>
        </AccordionSummary>
        <AccordionDetails>
          <Grid spacing={2} container>
            {enable && <MusicFilesList list={list} />}
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  )
}

const MusicFilesList = ({ list = [] }) => (
  <>
    {list.map((e, i) => {
      return e.type === 'audio' ? (
        <Grid item key={i} xs={12} md={6}>
          <Typography>{e.name.name}</Typography>
          <AudioPlayer key={i} src={`${http.apiUrl}/song_files/${e.id}`} />
        </Grid>
      ) : null
    })}
  </>
)

export { MusicFilesList, MusicFilesAccordion }
