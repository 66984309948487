import Img_2 from './img/undraw_compose_music_ovo2.svg'
import Img_3 from './img/undraw_time_management_re_tk5w.svg'
import Img_4 from './img/undraw_certification_aif8.svg'
import Img_5 from './img/undraw_social_distancing_2g0u.svg'
import Img_6 from './img/undraw_progressive_app_m-9-ms.svg'
import Cgu from '../connection/Cgu'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import ReactPlayer from 'react-player'

const datas = {
  header: {
    title: 'Carnet de chants',
    describe: `Une application pour avancer vers Dieu, plus de 1000 chants pour entourer notre liturgie.`
  },
  biSection_1: [
    {
      title: 'Chantez en tout moment',
      describe: `De nombreuses applications sont apparues dans le monde ecclésial : pourquoi pas une application de chant ? 
      
      Votre application de carnet de chant vous accompagne partout. Pas besoin de connexion internet, c'est "offline".`,
      html: (
        <Link to='/login' style={{ marginTop: 30 }}>
          <Button
            variant='contained'
            color='primary'
            aria-label='application access'
          >
            Tester l'application, c'est 'open'
          </Button>
        </Link>
      )
    },
    {
      html: (
        <ReactPlayer
          url='https://www.youtube.com/watch?v=gWhduiXtizM'
          width='100%'
          height={window.innerWidth < 600 ? 'auto' : undefined}
          controls
          style={{ marginTop: window.innerWidth < 600 ? 50 : 'auto' }}
        />
      )
    }
  ],
  biSection_2: [
    {
      alt: '',
      image: Img_2
    },
    {
      title: 'La fibre musicienne ?',
      describe: `Afficher les accords, accéder aux partitions, transposer, écouter les différentes voix, ... C'est possible `
    }
  ],
  tripleSection: [
    {
      image: Img_5,
      title: `Votre paroisse`,
      describe: `Connectez vous à votre paroisse, si elle n'existe pas, ajoutez la.`
    },
    {
      image: Img_4,
      title: `La feuille de messe`,
      describe: `La feuille de messe généré sur l'application est disponible.`
    },
    {
      image: Img_3,
      title: `Les horaires de messe`,
      describe: `Retrouvez les horaires de la paroisse (messes, adorations, confessions, laudes, ...)`
    }
  ],
  biSection_3: [
    {
      title: 'Créez vos feuilles de messe',
      describe: `L'application vous permet de générer simplement vos feuilles de messes pour votre paroisse ou votre aumônerie. Des propositions de chants vous sont égalements faites. Finalement, vous pouvez les Imprimmez.`
    },
    {
      alt: '',
      image: Img_6
    }
  ],
  biSection_4: [
    {
      alt: '',
      image: Img_2
    },
    {
      title: `Le projet, réalisons-le ensemble !`,
      describe: `Développée avec une association et suite à des problèmes internes, étranger au projet, notre collaboration a été stoppée. Ce travail représente plus de 33 000 lignes de code et 800h pour une application web et Android/IOS. 
      
      Pour continuer, j’ai besoin d’une équipe pour m’entourer (UI/UX, commercial, ...) et d’une légitimité envers le SECLI pour obtenir les droits d’auteur des chants. Contactez-moi si vous souhaitez que ce projet aboutisse.`
    }
  ],
  pricing: {
    title: 'Nos tarifs',
    describe: `En achetant une licence de un an, vous participez à la rémunération des auteurs, aux oeuvres de soutient à l'Église et aux prêtres mais également à la réalisation et au développement de l'application.`,
    cards: [
      {
        title: 'Abonné',
        price: 'X,XX',
        describe: [
          'Accès à tous les chants',
          '15 jour pour essayer',
          'Accès aux partitions'
        ],
        buttonText: 'Commencer',
        buttonVariant: 'outlined'
      },
      {
        title: 'À partager',
        subheader: 'Le plus populaire',
        price: 'XX',
        describe: ['3 licences', 'Un cadeau pour vos proches'],
        buttonText: 'Commencer',
        buttonVariant: 'contained'
      },
      {
        title: 'Paroisse',
        price: 'XXX',
        describe: ['100 licences', 'pour tous vos paroissiens'],
        buttonText: 'Commencer',
        buttonVariant: 'outlined'
      }
    ]
  },
  footer: [
    {
      title: `À propos`,
      content: `Je suis développeur informatique travaillant chez Youpray. J'ai à coeur de développer la mission dans le domaine de l'informatique.`
    },
    {
      title: `L'associations`,
      describe: [
        { title: 'Qui sommes nous', link: '#' },
        { title: 'Nos oeuvres', link: '#' }
      ]
    },
    {
      title: '',
      describe: [
        { title: 'Nous contacter', link: '#' },
        {
          component: <Cgu button='Mentions légales' />
        }
      ]
    }
  ]
}

export default datas
