import React from 'react'
import { Grid, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  box: {
    position: 'absolute',
    zIndex: 100,
    bottom: 20,
    right: 20
  },
  button: {
    background: theme.palette.primary.main,
    borderRadius: 100,
    width: 56,
    height: 56
  }
}))

const FloationActionButton = ({ icon, ...props }) => {
  const classes = useStyles()
  return (
    <Grid className={classes.box}>
      <div className={classes.button}>
        <IconButton
          {...props}
          aria-label='go back to song'
          className={classes.button}
        >
          {icon}
        </IconButton>
      </div>
    </Grid>
  )
}

FloationActionButton.propTypes = {
  icon: PropTypes.node
}

export default FloationActionButton
