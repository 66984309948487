import React, { useCallback, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { FormContentStore } from './FormContent'
import { makeStyles } from '@mui/styles'
import { Alert } from '@mui/material'
import { FormContentTabsStore } from './FormContentTabs'
import Loading from '../../core/Loading'
import {
  setStoreEditableAction,
  setStoreValidAction
} from '../../store/form/formStoreAction'
import { connect } from 'react-redux'
import i18n from '../../i18n'
import { checkCompleteFormValidation } from '../../service/form'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiGrid-root': {
        padding: theme.spacing(1)
      },
      '& .MuiDialog-paper': {
        margin: '0',
        width: '80%'
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiDialog-paper': {
          margin: '0',
          width: '96%'
        }
      }
    }
  },
  fullHeight: {
    '& .MuiDialog-paper': {
      height: '90%'
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiGrid-root': {
        padding: theme.spacing(1)
      },
      '& .MuiDialog-paper': {
        margin: '0',
        width: '96%',
        height: '98%'
      }
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  action: {
    margin: 0,
    padding: theme.spacing(1)
  },
  error: {
    margin: '0% 5%',
    width: '90%',
    position: 'relative',
    top: '-0px'
  },
  title: {
    paddingRight: '40px'
  }
}))

const DialogTitle = ({ children, onClose }) => {
  const classes = useStyles()
  return (
    <MuiDialogTitle>
      <Typography className={classes.title} noWrap>
        {children}
      </Typography>
      {onClose ? (
        <div className={classes.closeButton}>
          <IconButton aria-label='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      ) : null}
    </MuiDialogTitle>
  )
}

const FormValidationDialog = ({
  formParams,
  open,
  onValid,
  size,
  formType,
  stateForm,
  editable,
  canEdit,
  setStoreValid,
  setStoreEditable
}) => {
  const classes = useStyles()
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [mainButton, setMainButton] = useState()

  const setEditable = useCallback(() => {
    setStoreEditable(true)
    setMainButton(i18n.t('main:edit'))
  }, [setMainButton, setStoreEditable])

  const onExit = useCallback(() => {
    setMainButton(null)
    onValid(null)
  }, [onValid])

  const allFormValidation = useCallback(
    stateForm => checkCompleteFormValidation(stateForm, setStoreValid),
    [setStoreValid]
  )

  const handleSubmit = useCallback(async () => {
    if (!editable) {
      onValid(null)
      return
    }
    allFormValidation(stateForm)

    // check if there are validation's errors
    if (Object.values(stateForm.valid).filter(val => val.length).length) {
      setErrorMsg(i18n.t('error:default'))
    } else {
      // create / update
      setErrorMsg('')
      setLoading(true)
      setMainButton(null)
      const error = await onValid(stateForm.values)
      if (error) {
        setErrorMsg(error)
      }
      setLoading(false)
    }
  }, [stateForm, editable, onValid, allFormValidation])

  useMemo(() => {
    // reset the error message
    if (!open) {
      setErrorMsg('')
    }
  }, [open])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={size?.width || 'xs'}
      className={size?.fullHeight ? classes.fullHeight : classes.dialog}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') onExit()
      }}
      open={open}
      scroll={'paper'}
    >
      <DialogTitle onClose={() => onExit()} children={formParams.title} />
      <DialogContent dividers className={classes.content}>
        {formType === 'tabs' ? (
          <FormContentTabsStore inDialog />
        ) : (
          <FormContentStore />
        )}
        {errorMsg ? (
          <Alert severity='error' variant='outlined' className={classes.error}>
            {errorMsg}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button type='submit' autoFocus onClick={handleSubmit} color='primary'>
          {mainButton || formParams.button}
        </Button>
        {!editable && canEdit ? (
          <Button type='button' onClick={() => setEditable()} color='primary'>
            {i18n.t('main:edition')}
          </Button>
        ) : null}
      </DialogActions>
      {loading ? <Loading absolute={true} /> : null}
    </Dialog>
  )
}

FormValidationDialog.propTypes = {
  formParams: PropTypes.object,
  ready: PropTypes.bool,
  onValid: PropTypes.func,
  formType: PropTypes.string,
  stateForm: PropTypes.object,
  editable: PropTypes.bool,
  setStoreValid: PropTypes.func,
  setStoreEditable: PropTypes.func,
  open: PropTypes.bool,
  size: PropTypes.object,
  canEdit: PropTypes.bool
}

export default FormValidationDialog

export const FormValidationDialogStore = connect(
  state => ({
    stateForm: state.form,
    editable: state.form.editable
  }),
  dispach => ({
    setStoreValid: (valid, field) => dispach(setStoreValidAction(valid, field)),
    setStoreEditable: editable => dispach(setStoreEditableAction(editable))
  })
)(FormValidationDialog)
