import React, { useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem
} from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import ProperMenu from '../../menu/ProperMenu'
import AppSlider from '../../menu/AppSlider'
import i18n from '../../../i18n'
import { setSettingStoreValueAction } from '../../../store/setting/settingStoreAction'
import { connect } from 'react-redux'
import AppSwitch from '../../menu/AppSwitch'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    zIndex: 1300,
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  list: {
    width: '250px',
    maxHeight: '95vh'
  },
  checkbox: {
    paddingLeft: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0
  },
  formControl: {
    width: '100%'
  }
}))

const Component = ({
  instruments = [],
  disableTranspose = false,
  osmdSettings,
  setOsmdSettings,
  onClose
}) => {
  const classes = useStyles()

  const set = useCallback(
    (element, v) => setOsmdSettings({ ...osmdSettings, [element]: v }),
    [setOsmdSettings, osmdSettings]
  )

  const setInstruments = useCallback(
    (idx, value) => {
      const tmp = { ...osmdSettings.instruments }
      tmp[instruments[idx].name.toLowerCase()] = value
      setOsmdSettings({ ...osmdSettings, instruments: tmp })
    },
    [setOsmdSettings, osmdSettings, instruments]
  )

  return (
    <div className={classes.root}>
      <ProperMenu autoHide={false} icon={<MoreVert />} onClose={onClose}>
        <List className={classes.list}>
          <ListItem button>
            <AppSlider
              value={parseFloat(osmdSettings.zoom)}
              onChange={(_, v) => set('zoom', v.toFixed(2))}
              min={0.4}
              max={1.5}
              step={0.05}
              label={i18n.t('app:musicSheet:zoom', {
                value: Math.round(osmdSettings.zoom * 100)
              })}
            />
          </ListItem>
          <Divider />
          {!disableTranspose ? (
            <>
              <ListItem button>
                <AppSlider
                  value={osmdSettings.transpose}
                  onChange={(_, v) => set('transpose', v)}
                  min={-10}
                  max={10}
                  step={1}
                  label={i18n.t('app:musicSheet:transposition', {
                    value: osmdSettings.transpose
                  })}
                />
              </ListItem>
              <Divider />
            </>
          ) : null}
          {instruments.map((e, idx) => (
            <ListItem key={idx} className={classes.checkbox}>
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    disabled={idx === 0}
                    checked={
                      !(
                        osmdSettings.instruments[e.name.toLowerCase()] === false
                      )
                    }
                    color='primary'
                    onClick={e => setInstruments(idx, e.target.checked)}
                  />
                }
                label={e.name || ''}
              />
            </ListItem>
          ))}
          <Divider />
          <ListItem button>
            <AppSwitch
              value={osmdSettings.mode !== 'default'}
              onChange={() =>
                set(
                  'mode',
                  osmdSettings.mode === 'default' ? 'compacttight' : 'default'
                )
              }
              label={i18n.t('app:musicSheet:mode')}
            />
          </ListItem>
        </List>
      </ProperMenu>
    </div>
  )
}

Component.propTypes = {
  /** array of sheet instruments */
  instruments: PropTypes.array.isRequired,
  /** hide transpose setting */
  disableTranspose: PropTypes.bool,
  /** osmd stored settings */
  osmdSettings: PropTypes.object,
  /** set in store */
  setOsmdSettings: PropTypes.func,
  onClose: PropTypes.func
}

export default connect(
  state => ({
    osmdSettings: state.setting.osmdSettings
  }),
  dispach => ({
    setOsmdSettings: value =>
      dispach(setSettingStoreValueAction('osmdSettings', value))
  })
)(Component)
