import React from 'react'
import { useHistory } from 'react-router'
import NavBar from '../../../core/NavBar'
import { ParishEditorStore } from '../../../components/parishes/ParishEditor'
import i18n from '../../../i18n'

const Component = () => {
  const history = useHistory()

  return (
    <>
      <NavBar backArrow title={i18n.t('app:parishes:add parish')} />
      <ParishEditorStore create onEndEdit={() => history.push('/app/parishes')} />
    </>
  )
}

export default Component
