import React, { Component } from 'react'
import { Doughnut, Line, Bar } from 'react-chartjs-2'
import LinechartSettings from '../stats/lineChart'
import BarchartSettings from '../stats/barChart'
import { Grid, Typography } from '@mui/material'
import FormDatePicker from '../form/FormDatePicker'
import i18n from './../../i18n'
import { formatDate } from '../../service/functions'

const levels = ['NOTICE', 'INFO', 'DEBUG', 'ERROR', 'CRITICAL']

const mainDataset = [
  {
    label: 'Tous',
    data: [],
    fill: true,
    backgroundColor: 'rgb(255, 99, 132,0.2)',
    borderColor: 'rgba(255, 99, 132, 0.7)'
  },
  {
    label: 'Notice',
    data: [],
    fill: true,
    backgroundColor: 'rgba(54, 162, 235, 0.2)',
    borderColor: 'rgba(54, 162, 235, 1)'
  },
  {
    label: 'Info',
    data: [],
    fill: true,
    backgroundColor: 'rgba(255, 206, 86, 0.2)',
    borderColor: 'rgba(255, 206, 86, 1)'
  },
  {
    label: 'Debug',
    data: [],
    fill: true,
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
    borderColor: 'rgba(75, 192, 192, 1)'
  },
  {
    label: 'Error',
    data: [],
    fill: true,
    backgroundColor: 'rgba(156, 39, 176, 0.2)',
    borderColor: 'rgba(156, 39, 176, 1)'
  },
  {
    label: 'Critical',
    data: [],
    fill: true,
    backgroundColor: 'rgba(255, 152, 0, 0.2)',
    borderColor: 'rgba(255, 152, 0, 1)'
  }
]

const chartData = {
  base: {
    title: i18n.t('logs:baseTitle'),
    labels: [],
    datasets: [...mainDataset]
  },
  time: {
    title: i18n.t('logs:timeChartTitle'),
    labels: [...Array(24).keys()],
    datasets: [...mainDataset]
  },
  request: {
    title: i18n.t('logs:requestChartTitle'),
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        fill: true,
        backgroundColor: 'rgb(255, 99, 132,0.2)',
        borderColor: 'rgba(255, 99, 132, 0.7)'
      }
    ]
  },
  donut: {
    title: i18n.t('logs:donutTitle'),
    labels: ['Notice', 'Info', 'Debug', 'Error', 'Critical'],
    datasets: [
      {
        label: '',
        data: [0, 0, 0, 0, 0],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(156, 39, 176, 0.2)',
          'rgba(255, 152, 0, 0.2)'
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(156, 39, 176, 1)',
          'rgba(255, 152, 0, 1)'
        ],
        borderWidth: 1
      }
    ]
  }
}

class LogsGraphsDisplay extends Component {
  constructor (props) {
    super(props)

    this.chart1Ref = React.createRef()
    this.chart2Ref = React.createRef()
    this.chart3Ref = React.createRef()
    this.chart4Ref = React.createRef()
    const now = new Date()
    this.state = {
      ...chartData,
      end: formatDate(now, true),
      begin: formatDate(now.setDate(now.getDate() - 15), true)
    }
    this.handleDate = this.handleDate.bind(this)
  }

  componentDidMount () {
    this.setState(n => ({
      ...n,
      ...this.updateGraphs(this.props.datas)
    }))
  }

  componentDidUpdate (prevProps) {
    if (prevProps.datas !== this.props.datas) {
      this.setState(n => ({ ...n, ...this.updateGraphs(this.props.datas) }))
      this.update()
    }
  }

  update () {
    this.chart1Ref.current.chartInstance.update()
    this.chart2Ref.current.chartInstance.update()
    this.chart3Ref.current.chartInstance.update()
    this.chart4Ref.current.chartInstance.update()
  }

  updateGraphs (datas, range) {
    const begin = new Date(range?.begin || this.state.begin)
    const end = new Date(range?.end || this.state.end)

    let _chartData = { ...chartData }
    let date = null,
      j = -1
    // reset object
    _chartData.base.labels = []
    for (let k of _chartData.base.datasets) {
      k.data = []
    }
    for (let k in _chartData.time.datasets) {
      _chartData.time.datasets[k] = {
        ..._chartData.time.datasets[k],
        data: Array.from({ length: 24 }, (_, i) => 0)
      }
    }

    _chartData.donut.datasets[0].data = [0, 0, 0, 0, 0]
    _chartData.request.labels = []
    _chartData.request.datasets[0].data = []

    for (let i = datas.length - 1; i >= 0; i--) {
      // complete main lines
      const e = datas[i]
      const d = new Date(datas[i]?.date.substring(0, 10))
      if (d >= begin && d <= end) {
        const _date = e.date.substring(8) + '/' + e.date.substring(5, 7)
        // add date label
        if (date !== _date) {
          date = _date
          j++
          for (let k of _chartData.base.datasets) {
            k.data[j] = 0
          }
          _chartData.base.labels[j] = _date
        }
        // add 1 to level chart
        const idx = levels.indexOf(e.level)
        _chartData.base.datasets[idx + 1].data[j]++
        _chartData.base.datasets[0].data[j]++

        // complete time chart
        const time = parseInt(e.time.substring(0, 2))
        _chartData.time.datasets[idx + 1].data[time]++
        _chartData.time.datasets[0].data[time]++

        // complete donut chart
        const idx2 = levels.indexOf(e.level)
        _chartData.donut.datasets[0].data[idx2]++

        // complete request chart
        if (e.message.substring(0, 7) === 'Matched') {
          const requestName = e.message.match(/"(.*)"/).pop()
          const idx3 = _chartData.request.labels.indexOf(requestName)
          if (idx3 === -1) {
            _chartData.request.labels = [
              ..._chartData.request.labels,
              requestName
            ]
            _chartData.request.datasets[0].data[
              _chartData.request.labels.length - 1
            ] = 1
          } else {
            _chartData.request.datasets[0].data[idx3]++
          }
        }
      }
    }
    return _chartData
  }

  handleDate (value, valid, field) {
    let begin, end
    const date = new Date(value)
    if (field === 'begin' && new Date(this.state.end) <= date) {
      end = formatDate(date.setDate(date.getDate() + 2), true)
    } else if (field === 'end' && new Date(this.state.begin) >= date) {
      begin = formatDate(date.setDate(date.getDate() - 2), true)
    }
    this.setState(n => ({
      ...n,
      end: end || n.end,
      begin: begin || n.begin,
      [field]: value,
      ...this.updateGraphs(this.props.datas, {
        end: end || n.end,
        begin: begin || n.begin,
        [field]: value
      })
    }))
    setTimeout(() => this.update(), 100)
  }

  render () {
    return (
      <Grid container spacing={2} style={{ marginTop: 2 }}>
        <Grid item sm={12} md={6}>
          <Typography
            variant='h6'
            gutterBottom
            style={{ marginLeft: 20 }}
            children={this.state.donut.title}
          />
          <Doughnut ref={this.chart2Ref} data={this.state?.donut} />
        </Grid>
        <Grid item sm={12} md={6} style={{ paddingLeft: 32, paddingRight: 24 }}>
          <Typography
            variant='h6'
            gutterBottom
            style={{ marginLeft: 20 }}
            children={i18n.t('logs:filterTitle')}
          />
          <Grid container spacing={2}>
            <FormDatePicker
              editable
              element={{
                field: 'begin',
                label: i18n.t('logs:begin'),
                md: 7
              }}
              value={this.state.begin}
              setValue={this.handleDate}
            />
            <FormDatePicker
              editable
              element={{
                field: 'end',
                label: i18n.t('logs:end'),
                md: 7
              }}
              value={this.state.end}
              setValue={this.handleDate}
            />
            <Grid item xs={12} style={{ marginTop: 24 }}>
              <Typography
                color='textSecondary'
                children={`Erreurs : ${this.state.donut.datasets[0]?.data[3]}`}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color='textSecondary'
                children={`Erreurs critiques : ${this.state.donut.datasets[0]?.data[4]}`}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ width: '100%', marginTop: 20 }}>
          <Typography
            variant='h6'
            gutterBottom
            style={{ marginLeft: 20 }}
            children={this.state.base.title}
          />
          <Line
            ref={this.chart1Ref}
            data={this.state.base}
            options={LinechartSettings}
          />
        </Grid>
        <Grid item sm={12} xl={6} style={{ width: '100%', marginTop: 20 }}>
          <Typography
            variant='h6'
            gutterBottom
            style={{ marginLeft: 20 }}
            children={this.state.time.title}
          />
          <Bar
            ref={this.chart3Ref}
            data={this.state.time}
            options={BarchartSettings}
          />
        </Grid>
        <Grid
          item
          md={12}
          xl={6}
          style={{
            width: '100%',
            marginTop: 20,
            display: this.state.request.labels.length ? 'block' : 'none'
          }}
        >
          <Typography
            variant='h6'
            gutterBottom
            style={{ marginLeft: 20 }}
            children={this.state.request.title}
          />
          <Bar
            ref={this.chart4Ref}
            data={this.state?.request}
            options={{
              legend: {
                display: false
              }
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

export default LogsGraphsDisplay
