import { grey } from '@mui/material/colors'

const theme = {
  typography: {
    useNextVariants: true
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2'
    },
    secondary: {
      main: '#b80707'
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
    chords: {
      main: grey[500]
    }
  }
}

export default theme
