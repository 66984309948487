import http from '../../config/httpConstantes'
import authService from './authService'
import authStore from './authStore'

// service to get, put, post, delete datas from server

const isEnvMode = () => true
// !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const apiConsumer = {
  authHeader (redirect = true, token = null) {
    const _token = !token ? authStore.getToken() : token
    if (_token) {
      return { Authorization: 'Bearer ' + _token }
    } else if (redirect) {
      authService.goToAuthPage()
    }
    return {}
  },

  async checkResponse (r) {
    if (r.status === 401) {
      authService.goToAuthPage()
    } else if (r.status === 403) {
      return { status: r.status, statusText: r.statusText, body: null }
    } else if (r.status === 204) {
      return { status: r.status }
    } else {
      const body = await r.json()
      if (isEnvMode()) {
        console.info({ status: r?.status, statusText: r?.statusText, body })
      }
      return { status: r?.status, statusText: r?.statusText, body }
    }
  },

  request (method = 'POST', target, json = {}, noToken = false) {
    console.info(method, target, json)
    return fetch(`${http.apiUrl}/${target}`, {
      headers: { ...http.headers, ...(!noToken ? this.authHeader() : null) },
      method: method,
      body: JSON.stringify(json)
    })
      .then(r => this.checkResponse(r))
      .catch(() => ({ status: null, statustext: null, body: null }))
  },

  get (target, redirect = true, useToken = true) {
    return fetch(`${http.apiUrl}/${target}`, {
      headers: useToken
        ? { ...http.headers, ...this.authHeader(redirect) }
        : http.headers,
      method: 'GET'
    })
      .then(r => this.checkResponse(r))
      .catch(() => ({ status: null, statustext: null, body: null }))
  },

  getOne (target, id, noToken = false) {
    return fetch(`${http.apiUrl}/${target}/${id || ''}`, {
      headers: { ...http.headers, ...(!noToken ? this.authHeader() : null) },
      method: 'GET'
    })
      .then(r => this.checkResponse(r))
      .catch(() => ({ status: null, statustext: null, body: null }))
  },

  create (target, json) {
    if (json.hasOwnProperty('id')) {
      delete json.id
    }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.info(json)
    }

    return fetch(`${http.apiUrl}/${target}`, {
      headers: { ...http.headers, ...this.authHeader() },
      method: 'Post',
      body: JSON.stringify(json)
    })
      .then(r => this.checkResponse(r))
      .catch(() => ({ status: null, statustext: null, body: null }))
  },

  update (target, json) {
    return fetch(`${http.apiUrl}/${target}/${json.id}`, {
      headers: { ...http.headers, ...this.authHeader() },
      method: 'Put',
      body: JSON.stringify(json)
    })
      .then(r => this.checkResponse(r))
      .catch(() => ({ status: null, statustext: null, body: null }))
  },

  delete (target, id) {
    return fetch(`${http.apiUrl}/${target}/${id}`, {
      headers: { ...http.headers, ...this.authHeader() },
      method: 'Delete'
    })
      .then(r => this.checkResponse(r))
      .catch(() => ({ status: null, statustext: null, body: null }))
  },

  upload (target, data) {
    return fetch(`${http.apiUrl}/${target}`, {
      headers: {
        ...this.authHeader()
      },
      method: 'Post',
      body: data
    })
      .then(r => this.checkResponse(r))
      .catch(() => ({ status: null, statustext: null, body: null }))
  },

  download (target, redirect = true, token = null) {
    return fetch(`${http.apiUrl}/${target}`, {
      headers: { ...this.authHeader(redirect, token) },
      method: 'GET'
    })
      .then(r =>
        r.status === 200
          ? r.blob()
          : {
              status: r.status,
              statusText: r.statusText,
              body: r.blob()
            }
      )
      .catch(() => ({ status: null, statustext: null, body: null }))
  },

  getBadResponseMessage (rep) {
    console.info(rep?.body)
    if (rep?.body?.violations[0]) {
      return {
        type: 'field',
        name: rep.body.violations[0]?.title
          ? rep.body.violations[0].title
          : rep.body.violations[0].message,
        field: rep.body.violations[0].propertyPath,
        value: null
      }
    }
    return null
  }
}

export default apiConsumer
