import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const DialogPrompt = ({ params, open, value, onValid }) => {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={open}
        onClose={() => onValid(false)}
      >
        <DialogTitle>{params.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {params.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onValid(false)} color='primary'>
            {params.noButton}
          </Button>
          <Button onClick={() => onValid(value)} color='primary' autoFocus>
            {params.yesButton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DialogPrompt.propTypes = {
  params: PropTypes.object,
  open: PropTypes.bool,
  value: PropTypes.any,
  onValid: PropTypes.func
}

export default DialogPrompt

export const DialogPromptStore = connect(state => ({
  value: state.form.values
}))(DialogPrompt)
