import React, { useState } from 'react'
import SwipableGuideTour from './core/SwipableGuideTour'
import img1 from '../../assets/images/guide/1.png'
import img2 from '../../assets/images/guide/2.png'
import img3 from '../../assets/images/guide/3.png'
import img4 from '../../assets/images/guide/4.png'
import img5 from '../../assets/images/guide/5.png'
import img6 from '../../assets/images/guide/6.png'

const guidedTourSteps = [
  {
    title: 'Bienvenu',
    image: img1,
    text: `Voici votre nouvelle application de carnet de chants.`
  },
  {
    title: 'Chantez en tout temps',
    image: img2,
    text: `Ici, vous allez retrouver tous vos chants de messe, de louange, ... Pour pouvoir prier en toute circonstance.`
  },
  {
    title: 'La fibre musicienne ?',
    image: img3,
    text: `Retrouvez des audios des différentes voix et des partitions.`
  },
  {
    title: 'Créez vos feuilles de messe',
    image: img4,
    text: `Créez simplement et rapidement vos feuilles de messes.`
  },
  {
    title: 'Restez connectés',
    image: img5,
    text: `Abonnez-vous à votre paroisse pour garder les feuilles de messe et infos à portée de main.`
  },
  {
    title: `Toujours à l'heure`,
    image: img6,
    text: `Votre paroisse vous propose ses horaires de messes. Si un horaire de messe est erroné, d'autres utilisateurs vous préviendront.`
  }
]

const Component = () => {
  const [enable, setEnable] = useState(true)

  return !enable ? null : (
    <SwipableGuideTour
      type='initial'
      steps={guidedTourSteps}
      onDisable={() => setEnable(false)}
    />
  )
}

export default Component
