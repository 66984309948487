import React, { useCallback } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import i18n from '../../i18n'
import apiConsumer from '../../service/api/apiConsumer'
import PropTypes from 'prop-types'

const DeleteChat = ({ chat, onValid }) => {
  const closeChat = useCallback(
    chat =>
      apiConsumer
        .request(
          'POST',
          `chats/${chat.id}/open/${chat.isOpened ? 'false' : 'true'}`
        )
        .then(rep => onValid(rep.status === 200)),
    [onValid]
  )
  return (
    <>
      <Dialog fullWidth={true} maxWidth={'xs'} open onClose={() => onValid()}>
        <DialogTitle id='alert-dialog-title'>
          {i18n.t(chat.isOpened ? 'chat:close chat' : 'chat:reopen chat')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {i18n.t(
              chat.isOpened
                ? 'chat:are you sure to close chat'
                : 'chat:are you sure to reopen chat'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onValid()} color='primary'>
            {i18n.t('main:no')}
          </Button>
          <Button onClick={() => closeChat(chat)} color='primary' autoFocus>
            {i18n.t('main:yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DeleteChat.propTypes = {
  chat: PropTypes.object,
  onValid: PropTypes.func
}

export default DeleteChat
