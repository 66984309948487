import React from 'react'
import { useParams } from 'react-router'
import httpConstantes from '../../../config/httpConstantes'
import PdfViewer from '../../../components/preview/PdfViewer'
import apiConsumer from './../../../service/api/apiConsumer'

const MusicPdf = () => {
  const { id } = useParams()
  return (
    <div style={{ height: '100vh', overflow: 'auto' }}>
      <PdfViewer
        src={{
          url: `${httpConstantes.apiUrl}/song_files/${id}`,
          httpHeaders: { ...apiConsumer.authHeader() }
        }}
      />
    </div>
  )
}

export default MusicPdf
