import React, { useState } from 'react'
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import i18n from '../../i18n'
import httpConstantes from '../../config/httpConstantes'
import DisplayMassTimes from './DisplayMassTimes'
import { Code, Edit } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import AppDialog from '../unclassified/AppDialog'
import DisplayParishBlogs from './DisplayParishBlogs'
import DisplayParishInfos from './DisplayParishInfos'
import ListMassSheet from '../massSheet/ListMassSheet'

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(3)
  },
  linkButton: {
    margin: '32px!important'
  }
}))

const DisplayParish = ({ parish }) =>
  !!parish.name && (
    <>
      <DisplayParishInfos parish={parish} />
      <Grid item xs={12}>
        <Header
          title={i18n.t(`app:massTimes:massTimes`)}
          hasContent={!!parish.massTimes?.length}
          isResponsible={parish.isResponsible}
          editLink={`masstime-${parish.id}`}
          addName={i18n.t(`app:massTimes:add massTimes`)}
          integrateTitle={i18n.t('app:massTimes:integrate')}
          IntegrateContent={`<iframe\n   src="${httpConstantes.appUrl}widgets/massTimes-${parish.id}"\n   style="border:0; width:100%;"\n   height="700" \n/>`}
        />
        <DisplayMassTimes massTimes={parish.massTimes} />
      </Grid>
      {!!parish?.massSheet?.length && (
        <Grid item xs={12}>
          <Header title={i18n.t(`app:parishes:next massSheet`)} hasContent />
          <div style={{ marginTop: 16 }}>
            <ListMassSheet massSheets={parish.massSheet} />
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <Header
          title={i18n.t(`app:parishBlogs:parishBlogs`)}
          hasContent={!!parish.blogs?.length}
          isResponsible={parish.isResponsible}
          editLink={`parishblog-${parish.id}`}
          addName={i18n.t(`app:parishBlogs:add parishBlogs`)}
          integrateTitle={i18n.t('app:parishBlogs:integrate')}
          IntegrateContent={`<iframe\n   src="${httpConstantes.appUrl}widgets/parishblog-${parish.id}"\n   style="border:0; width:100%;"\n   height="700" \n/>`}
        />
        <DisplayParishBlogs articles={parish.blogs} />
      </Grid>
    </>
  )

export default DisplayParish

const Header = ({
  title,
  hasContent,
  isResponsible,
  editLink,
  addName,
  integrateTitle,
  IntegrateContent
}) => {
  const classes = useStyles()
  return (
    (isResponsible || hasContent) && (
      <>
        <Grid
          container
          justifyContent='space-between'
          className={classes.title}
        >
          <Grid item>
            <Typography variant='h6'>{title}</Typography>
          </Grid>
          {isResponsible && hasContent && !!integrateTitle && (
            <Grid item>
              <Link to={editLink}>
                <IconButton aria-label='edit'>
                  <Edit />
                </IconButton>
              </Link>
              <WebIntegrateButton
                title={integrateTitle}
                content={IntegrateContent}
              />
            </Grid>
          )}
        </Grid>
        {isResponsible && !hasContent && (
          <Link to={editLink} className={classes.Button}>
            <Button
              variant='outlined'
              color='primary'
              className={classes.linkButton}
            >
              {addName}
            </Button>
          </Link>
        )}
      </>
    )
  )
}

const WebIntegrateButton = ({ title, content }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Tooltip title={title}>
        <IconButton aria-label='integrate' onClick={() => setOpen(true)}>
          <Code />
        </IconButton>
      </Tooltip>

      <AppDialog
        title={title}
        open={open}
        onClose={() => setOpen(false)}
        width='sm'
      >
        <Grid sx={{ minWidth: 400 }}>
          <Typography style={{ whiteSpace: 'pre-wrap' }}>{content}</Typography>
        </Grid>
      </AppDialog>
    </>
  )
}
