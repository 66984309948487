import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DisplayCelebration from '../celebration/DisplayCelebration'
import { Grid, Typography } from '@mui/material'
import i18n from './../../i18n'
import { displayDate } from '../../service/functions'
import Loading from '../../core/Loading'

const CelebrationPreview = ({ date, celebration }) => {
  return (
    <>
      {!celebration || celebration?.loading ? (
        <Loading />
      ) : celebration?.name ? (
        <>
          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            style={{ height: 50 }}
          >
            <Grid item>
              <Typography color='text.secondary'>
                {displayDate(date)}
              </Typography>
            </Grid>
          </Grid>
          <DisplayCelebration celebration={celebration} newPageLoading />
        </>
      ) : (
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={{ height: 200 }}
        >
          <Grid item>
            <Typography>
              {i18n.t('celebrations:no celebration found')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

CelebrationPreview.propTypes = {
  date: PropTypes.any,
  celebration: PropTypes.object
}

export default CelebrationPreview

export const CelebrationPreviewStore = connect(state => ({
  date: state.form?.values?.date,
  celebration: state.setting?.celebration
}))(CelebrationPreview)
