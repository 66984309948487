import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Typography, Stack } from '@mui/material'
import PhonePreview from './PhonePreview'
import video from '../screenshots/recorder.mp4'
import BgImage from '../img/header.jpg'

const useStyles = makeStyles(theme => ({
  backImage: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '80vh',
    minHeight: 500,
    backgroundImage: `url(${BgImage})!important`,
    backgroundSize: 'cover',
    filter: 'brightness(0.5)'
  },
  headerContent: {
    width: '100%',
    position: 'relative',
    alignItems: 'center',
    top: 0,
    marginTop: 60,
    paddingBottom: 100,
    paddingTop: 200,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  },
  headerBox: {
    maxWidth: 700,
    paddingLeft: '25%',
    paddingTop: 100,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50,
      paddingLeft: 0,
      margin: 30,
      textAlign: 'center'
    },
    '& *': {
      color: '#eee!important',
      textShadow: '#000 0px 0 2px'
    }
  }
}))

const Header = ({ data }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.backImage} />
      <Grid
        container
        className={classes.headerContent}
        alignItems='flex-start'
        justifyContent='center'
      >
        <Grid item xs={12} sm={6}>
          <Stack
            direction='column'
            justifyContent='center'
            className={classes.headerBox}
          >
            <Typography variant='h2' component='h1' gutterBottom>
              {data.title}
            </Typography>
            <Typography variant='h5' component='h2'>
              {data.describe}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhonePreview video={video} />
        </Grid>
      </Grid>
    </>
  )
}

export default Header
