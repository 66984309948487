import React, { useMemo, useCallback } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import { Grid, Typography } from '@mui/material'
import { Element, Leaf } from '../unclassified/RichTextStyle'

const RichTextPreview = ({ value, label, md }) => {
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  return (
    <Grid item md={md || 12} xs={12}>
      <Typography variant='caption' color='textSecondary'>
        {label}
      </Typography>
      <Slate
        editor={editor}
        value={
          ['object', 'array'].includes((typeof value).toString())
            ? value
            : [
                {
                  type: 'paragraph',
                  children: [{ text: '' }]
                }
              ]
        }
      >
        <Editable
          readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    </Grid>
  )
}

export default RichTextPreview
