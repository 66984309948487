import React from 'react'
import { IconButton, Popover } from '@mui/material'
import PropTypes from 'prop-types'

const ProperMenu = ({
  icon,
  children,
  className,
  onClose,
  color = 'inherit',
  autoHide = true,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <IconButton
        className={className}
        aria-describedby={id}
        variant='contained'
        color={color}
        onClick={handleClick}
        {...props}
      >
        {icon}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClick={autoHide ? handleClose : null}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {children}
      </Popover>
    </>
  )
}

ProperMenu.propTypes = {
  /** menu button icon */
  icon: PropTypes.node,
  /** icon color */
  color: PropTypes.string,
  children: PropTypes.node,
  /** button style */
  className: PropTypes.any,
  onClose: PropTypes.func,
  /** auto hide when click on */
  autoHide: PropTypes.bool
}

export default ProperMenu
