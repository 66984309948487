import React, { useMemo } from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import { formatDate, sortFrenchDates } from '../../../service/functions'
import NavBar from '../../../core/NavBar'
import { useParams } from 'react-router'
import { regex } from '../../../service/regex'

const params = {
  entityName: 'parishBlogs',
  targetUrlGet: '',
  targetUrl: 'blogs',
  mainField: 'name',
  defaultEntity: {
    name: '',
    subname: '',
    content: null,
    informations: '',
    linkUrl: '',
    isPublished: true,
    publishedAt: new Date(Date.now()),
    hasEndDate: false,
    endAt: new Date(Date.now()),
    picture: null,
    parish: null
  },
  datagridOptions: {
    rights: {
      show: [],
      edit: [],
      delete: [],
      move_up: [],
      move_down: []
    },
    columnsOptions: {
      sortable: true
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('parishBlogs:field:id'),
      hide: true
    },
    {
      field: 'name',
      headerName: i18n.t('parishBlogs:field:name'),
      width: 300,
      main: true
    },
    {
      field: 'publishedAt',
      headerName: i18n.t('parishBlogs:field:publishedAt min'),
      width: 110,
      sortComparator: (v1, v2, p1, p2) => sortFrenchDates(v1, v2)
    },
    {
      field: 'endAt',
      headerName: i18n.t('parishBlogs:field:endAt'),
      width: 110,
      sortComparator: (v1, v2, p1, p2) => sortFrenchDates(v1, v2)
    },
    {
      field: 'isPublished',
      type: 'boolean',
      headerName: i18n.t('parishBlogs:field:isPublished')
    }
  ],
  formStruct: {
    size: {
      width: 'md',
      fullHeight: false
    },
    elements: [
      {
        type: 'text',
        required: true,
        field: 'name',
        label: i18n.t('parishBlogs:field:name'),
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'text',
        field: 'subname',
        label: i18n.t('parishBlogs:field:subname'),
        rules: {
          min: 3,
          max: 255,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'richText',
        field: 'content',
        required: true,
        label: i18n.t('parishBlogs:field:content')
      },
      {
        type: 'text',
        field: 'informations',
        label: i18n.t('parishBlogs:field:informations'),
        rules: {
          min: 3,
          max: 255,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'text',
        field: 'linkUrl',
        regex: regex.website,
        regexExample: 'https://exemple.fr',
        label: i18n.t('parishBlogs:field:linkUrl'),
        rules: {
          min: 3,
          max: 255,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'switch',
        field: 'isPublished',
        label: i18n.t('parishBlogs:field:isPublished'),
        md: 6,
        action: {
          display: ['publishedAt']
        }
      },
      {
        type: 'date',
        field: 'publishedAt',
        label: i18n.t('parishBlogs:field:publishedAt'),
        md: 6
      },
      {
        type: 'switch',
        field: 'hasEndDate',
        onlyEditor: true,
        label: i18n.t('parishBlogs:field:hasEndDate'),
        md: 6,
        action: {
          display: ['endAt']
        }
      },
      {
        type: 'date',
        field: 'endAt',
        label: i18n.t('parishBlogs:field:endAt'),
        md: 6,
        hidden: true
      },
      { type: 'spacer' },
      {
        type: 'image',
        field: 'picture',
        imageUrl: 'parishes-blog-picture',
        required: false,
        label: i18n.t('parishBlogs:field:picture'),
        md: 6
      }
    ]
  }
}

const formatEntities = entities => {
  return (
    entities.map(entity => {
      return formatEntity(entity)
    }) || null
  )
}

const formatEntity = entity => {
  entity.isPublished = !!entity.publishedAt
  entity.publishedAt = formatDate(entity.publishedAt)
  entity.endAt = entity.endAt && formatDate(entity.endAt)
  return entity
}

const formatCRUDEntity = entity => {
  entity.isPublished = !!entity.publishedAt
  entity.publishedAt = entity.publishedAt
    ? entity.publishedAt
    : new Date(Date.now())
  entity.parish = entity.parish?.id
  entity.hasEndDate = !!entity.endAt
  return entity
}

const Component = () => {
  const { id } = useParams()

  useMemo(async () => {
    params.targetUrlGet = `${params.targetUrl}/parish/${id}`
    params.defaultEntity.parish = id
  }, [id])

  return (
    <>
      <NavBar title={i18n.t('parishBlogs:parishBlogs')} backArrow />
      <CrudPage
        params={params}
        formatEntities={formatEntities}
        formatEntity={formatEntity}
        formatCRUDEntity={formatCRUDEntity}
      />
    </>
  )
}

export default Component
