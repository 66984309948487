import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@mui/material'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import PdfViewer from '../preview/PdfViewer'
import {
  generateMassSheetDocx,
  pdfMassSheetGenerator
} from '../../service/massSheetService'
import PrintMassSheetSettings from './PrintMassSheetSettings'
import { connect } from 'react-redux'
import i18n from '../../i18n'
import QRCode from 'qrcode'
import { Close, ExpandMore } from '@mui/icons-material'
import httpConstantes from './../../config/httpConstantes'
import { ORDINARY_SONGS } from '../../config/massSheetConstants'
import AppDialog from './../unclassified/AppDialog'
pdfMake.vfs = pdfFonts.pdfMake.vfs

const PrintMassSheet = ({ massSheet, compact, settings, disabled }) => {
  const [pdfData, setPdfData] = useState()
  const [qrCode, setQRCode] = useState()
  const [hidePrinSongs, setHidePrintSongs] = useState({})
  const [action, setAction] = useState()

  useEffect(() => {
    if (settings.qrCode && massSheet.id) {
      QRCode.toDataURL(
        httpConstantes.massSheetPublicUrl.replace(':id', massSheet.id),
        { type: 'terminal' },
        (err, url) => setQRCode(url)
      )
    } else {
      setQRCode()
    }
    return () => setQRCode()
  }, [massSheet.id, settings.qrCode])

  const printDocument = format => {
    const docDefinition = pdfMassSheetGenerator(
      massSheet,
      compact,
      hidePrinSongs,
      settings,
      qrCode
    )

    if (format === 'generate') {
      pdfMake.createPdf(docDefinition).getDataUrl(function (dataURL) {
        setPdfData(dataURL)
      })
    } else {
      pdfMake
        .createPdf(docDefinition)
        .download(`${massSheet.name || 'messe'}.pdf`)
    }
  }

  const selectPrint = useMemo(
    () =>
      massSheet.massSheetSong ? (
        <Grid container>
          <Grid item xs={12}>
            <Button
              onClick={() =>
                setHidePrintSongs(n => {
                  let rep = { ...n }
                  ORDINARY_SONGS.forEach(e => {
                    rep[e] = true
                  })
                  return rep
                })
              }
            >
              {i18n.t('massSheets:print:hide ordinary')}
            </Button>
          </Grid>
          {massSheet.massSheetSong.map(e => (
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!hidePrinSongs[e.header.name]}
                    onChange={(event, check) =>
                      setHidePrintSongs(n => ({
                        ...n,
                        [e.header.name]: !check
                      }))
                    }
                  />
                }
                label={e.header.name}
              />
            </Grid>
          ))}
        </Grid>
      ) : null,
    [massSheet, hidePrinSongs, setHidePrintSongs]
  )

  return (
    <>
      <Grid container direction='row-reverse'>
        <ButtonGroup
          variant='text'
          color='primary'
          aria-label='print mass sheet'
          disabled={disabled}
        >
          {pdfData ? (
            <IconButton key='close' onClick={() => setPdfData()}>
              <Close />
            </IconButton>
          ) : null}
          <Button key={0} onClick={() => printDocument('generate')}>
            {i18n.t('massSheets:print:generate pdf')}
          </Button>
          <Button key={1} onClick={() => setAction('pdf')}>
            {i18n.t('massSheets:print:save pdf')}
          </Button>
          {!compact && (
            <Button onClick={() => setAction('docx')}>
              {i18n.t('massSheets:print:save docx')}
            </Button>
          )}
          <PrintMassSheetSettings
            onClose={() => (pdfData ? printDocument('generate') : null)}
          />
        </ButtonGroup>
      </Grid>

      {pdfData ? (
        <>
          <Accordion
            sx={{
              boxShadow: 'none',
              width: '100%',
              '&:before': {
                display: 'none'
              }
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography align='right' sx={{ width: '98%' }}>
                {i18n.t('massSheets:print:display songs')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{selectPrint}</AccordionDetails>
          </Accordion>
          <div id='pdf' style={{ position: 'relative', width: '100%' }}>
            <Paper
              style={{
                width: '100%',
                padding: 16,
                background: 'grey',
                height: '500px',
                overflow: 'auto',
                marginLeft: 0
              }}
            >
              <PdfViewer src={pdfData} />
            </Paper>
          </div>
        </>
      ) : null}

      <AppDialog
        title={i18n.t('massSheets:print:display songs')}
        open={action}
        onClose={() => setAction()}
        action={
          <Button
            onClick={() => {
              if (action === 'pdf') {
                printDocument()
              } else {
                generateMassSheetDocx(
                  { ...massSheet },
                  hidePrinSongs,
                  settings.qrCode ? qrCode : null
                )
              }
              setAction()
            }}
          >
            {i18n.t('main:valid')}
          </Button>
        }
      >
        {!massSheet.id && (
          <Typography color='text.secondary'>
            {i18n.t("massSheets:print:need creation to add link's elements")}
          </Typography>
        )}
        {selectPrint}
      </AppDialog>
    </>
  )
}

PrintMassSheet.propTypes = {
  /** massSheet */
  value: PropTypes.object,
  compact: PropTypes.bool,
  disabled: PropTypes.bool
}

export default connect(state => ({
  settings: state.setting.printMassSheetSettings
}))(PrintMassSheet)
