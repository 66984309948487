import React from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import NavBar from '../../../core/NavBar'

const params = {
  entityName: 'categories',
  targetUrl: 'categories',
  mainField: 'name',
  defaultEntity: {
    id: 0,
    inPrimaryList: false,
    name: '',
    ordering: 0
  },
  datagridOptions: {
    rights: {
      show: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      edit: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      delete: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      move_up: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      move_down: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    columnsOptions: {
      sortable: false
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('categories:field:id'),
      width: 30,
      hide: true
    },
    {
      field: 'name',
      headerName: i18n.t('categories:field:name'),
      width: 200,
      main: true
    },
    {
      field: 'inPrimaryList',
      headerName: i18n.t('categories:field:inPrimaryList'),
      width: 100,
      type: 'boolean'
    }
  ],
  formStruct: {
    size: {
      width: 'xs',
      fullHeight: false
    },
    elements: [
      {
        type: 'text',
        required: true,
        field: 'name',
        label: i18n.t('categories:field:name'),
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'switch',
        field: 'inPrimaryList',
        label: i18n.t('categories:field:inPrimaryList explain')
      }
    ]
  }
}

export default function DataTable () {
  return (
    <>
      <NavBar title={i18n.t(`menu:${params.entityName}`)} />
      <CrudPage params={params} />
    </>
  )
}
