// form store reducer accordig to actions

const initialState = {
  values: {}
}

export default function FormReducer (state = initialState, action) {
  switch (action.type) {
    case 'INIT_STORE':
      return { ...action.payload }
    case 'SET_STORE_VALUES':
      return { ...state, ...action.payload }
    case 'SET_STORE_VALUE':
      state.values[action.payload.field] = action.payload.value
      if (action.payload.valid !== null) {
        state.valid[action.payload.field] = action.payload.valid
      }
      return { ...state }
    case 'SET_STORE_VALID':
      if (action.payload.valid !== null) {
        state.valid[action.payload.field] = action.payload.valid
      }
      return { ...state }
    case 'SET_EDITABLE':
      state.editable = action.payload.editable
      return { ...state }
    case 'SET_ELEMENT_VISIBILITY':
      const idx = state.elements.findIndex(
        e => e.field === action.payload.field
      )
      if (idx !== -1) {
        state.elements[idx].hidden = !action.payload.visible
      }
      return { ...state, elements: [...state.elements] }
    default:
      return state
  }
}
