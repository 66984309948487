import { Typography } from '@mui/material'
import React, { useState } from 'react'
import i18n from '../../i18n'
import DialogGuideTour from './core/DialogGuideTour'

const content = {
  title: `Bienvenu`,
  text: (
    <Typography>
      C'est une joie de vous compter parmi nos testeurs.
      <br />
      Il y a deux choses à savoir :
      <Typography style={{ fontWeight: 'bold' }}>
        <ul>
          <li>Toutes les données sont factices.</li>
          <li>
            Le chant n°0 "Acclamons le roi du ciel" est le seul chant à être complet avec les audios et partitions XML.
          </li>
        </ul>
      </Typography>
      <br /> Bonne visite !
    </Typography>
  ),
  button: i18n.t('main:understood')
}

const Component = () => {
  const [enable, setEnable] = useState(true)

  return !enable ? null : (
    <DialogGuideTour
      type='test'
      content={content}
      onDisable={() => setEnable(false)}
    />
  )
}

export default Component
