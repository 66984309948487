import React, { useCallback, useEffect } from 'react'
import { Grid, FormControlLabel, Switch, FormHelperText } from '@mui/material'
import { connect } from 'react-redux'
import {
  setStoreElementVisibilityAction,
  setStoreValueAction
} from '../../store/form/formStoreAction'
import PropTypes from 'prop-types'

const AppSwitch = ({
  editable,
  element,
  value,
  valid,
  setStoreValue,
  setElementVisibility
}) => {
  const handleChange = useCallback(
    newValue => {
      setStoreValue(newValue, null, element.field)
    },
    [element, setStoreValue]
  )

  useEffect(() => {
    if (element?.action?.display) {
      element?.action?.display.forEach(e => setElementVisibility(e, value))
    }
    if (element?.action?.hide) {
      element?.action?.hide.forEach(e => setElementVisibility(e, !value))
    }
  }, [value, element, setElementVisibility])

  return (
    <Grid
      item
      xs={element.xs || 12}
      md={element.md}
      key={`grid-${element.label}`}
      style={{ alignSelf: 'center', paddingLeft: 40 }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={value ? true : false}
            onChange={editable ? e => handleChange(e.target.checked) : null}
            color='primary'
          />
        }
        key={element.label}
        label={element.label || ''}
      />
      <FormHelperText error>{valid}</FormHelperText>
    </Grid>
  )
}

AppSwitch.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.object,
  value: PropTypes.bool,
  valid: PropTypes.string,
  setStoreValue: PropTypes.func,
  setElementVisibility: PropTypes.func
}

export default AppSwitch

export const AppSwitchStore = connect(
  (state, ownProps) => ({
    value: state.form?.values[ownProps.element.field] || false,
    valid: state.form?.valid[ownProps.element.field] || '',
    editable: state.form.editable
  }),
  dispach => ({
    setStoreValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field)),
    setElementVisibility: (field, visible) =>
      dispach(setStoreElementVisibilityAction(field, visible))
  })
)(AppSwitch)
