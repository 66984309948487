import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { setSettingStoreValueAction } from '../../store/setting/settingStoreAction'
import OpenSheetMusicDisplay from '../../components/preview/musicSheet/OpenSheetMusicDisplay'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '48px!important'
  }
}))

const Component = ({ storeToken, setStoreToken, setStoreDarkTheme }) => {
  const classes = useStyles()
  const { id, token, theme } = useParams()

  useEffect(() => setStoreToken(token), [setStoreToken, token])
  useEffect(() => setStoreDarkTheme(theme), [setStoreDarkTheme, theme])

  return (
    !!storeToken && (
      <div className={classes.root}>
        <OpenSheetMusicDisplay src={`song_files/${id}`} />
      </div>
    )
  )
}

export default connect(
  state => ({
    storeToken: state.setting?.user?.token
  }),
  dispach => ({
    setStoreToken: token =>
      dispach(setSettingStoreValueAction('user', { token })),
    setStoreDarkTheme: theme =>
      dispach(setSettingStoreValueAction('darkTheme', theme === 'dark'))
  })
)(Component)
