import { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { makeStyles } from '@mui/styles'
import Loading from './../../core/Loading'
import { ButtonGroup, Button } from '@mui/material'
import { Add, Remove, Search } from '@mui/icons-material'
import { useCallback } from 'react'
import CustomError from '../../pages/error/CustomError'
import i18n from '../../i18n'
import { connect } from 'react-redux'
import { setSettingStoreValueAction } from '../../store/setting/settingStoreAction'
import NavBar from 'core/NavBar'
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const notFoundParams = {
  icon: <Search fontSize={'large'} />,
  message: i18n.t('app:error:file not found'),
  button: i18n.t('app:error:go back home'),
  link: '/app'
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& canvas': {
      margin: 'auto'
    }
  },
  toolbox: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  page: {
    margin: '8px auto',
    backgroundColor: 'transparent!important'
  }
}))

const PDFViewer = ({ src, pdfScale, setPdfScale }) => {
  const classes = useStyles()
  const [numPages, setNumPages] = useState(null)
  const [width, setWidth] = useState(1)
  const ref = useRef(null)

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth < 600 ? ref?.current?.offsetWidth : 600)
    return () => setWidth()
  }, [ref, setWidth])

  const onDocumentLoadSuccess = useCallback(
    ({ numPages: nextNumPages }) => {
      setNumPages(nextNumPages)
    },
    [setNumPages]
  )

  const setZoom = useCallback(
    newScale => {
      if (newScale < 2.5 && newScale > 0.5) setPdfScale(newScale)
    },
    [setPdfScale]
  )

  return (
    <div ref={ref} className={classes.root}>
      <NavBar backArrow />
      <Document
        file={src}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode='canvas'
        loading={() => <Loading />}
        noData={() => <CustomError params={notFoundParams} />}
        error={() => <CustomError params={notFoundParams} />}
      >
        {Array.from({ length: numPages }, (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            scale={pdfScale}
            width={width}
            className={classes.page}
          />
        ))}
      </Document>
      {!!numPages && (
        <ButtonGroup variant='contained' className={classes.toolbox}>
          <Button
            variant='contained'
            aria-label='zoom out'
            onClick={() => setZoom(pdfScale - 0.1)}
          >
            <Remove />
          </Button>
          <Button
            variant='contained'
            aria-label='zoom in'
            onClick={() => setZoom(pdfScale + 0.1)}
          >
            <Add />
          </Button>
          <Button variant='contained' aria-label='page count'>
            {numPages}
          </Button>
        </ButtonGroup>
      )}
    </div>
  )
}

export default connect(
  state => ({
    pdfScale: state.setting?.pdfScale
  }),
  dispach => ({
    setPdfScale: value => dispach(setSettingStoreValueAction('pdfScale', value))
  })
)(PDFViewer)
