import React, { useEffect, useMemo, useState } from 'react'
import i18n from '../../../i18n'
import NavBar from '../../../core/NavBar'
import FormDatePicker from '../../../components/form/FormDatePicker'
import {
  Grid,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import apiConsumer from '../../../service/api/apiConsumer'
import { useCallback } from 'react'
import { formatDate } from '../../../service/functions'
import DisplayCelebration from '../../../components/celebration/DisplayCelebration'
import { connect } from 'react-redux'
import { setSettingStoreValueAction } from '../../../store/setting/settingStoreAction'
import Loading from './../../../core/Loading'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2) + '!important',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1) + '!important'
    }
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper
  }
}))

const getNextDate = arr => {
  const date = new Date().setHours(0, 0, 0, 0)
  for (const k of arr) {
    if (new Date(k.date) >= date) return k
  }
}

const validateDateBetween = (inDate, date = new Date()) => {
  const inRealDate = new Date(inDate)
  return inRealDate >= date && inRealDate <= date.setDate(date.getDate() + 20)
}

const Celebration = ({
  celebration,
  setCelebration,
  celebrationList,
  setCelebrationList
}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState()
  const [date, setDate] = useState(
    celebration ? celebration.date : new Date(Date.now())
  )

  const getCelebration = useCallback(
    celeb => {
      const d = formatDate(celeb?.date, true)
      if (!celebration?.loading && d !== celebration?.date) {
        setSelected(celeb.id)
        setDate(celeb.date)
        setCelebration({ date: d, loading: true })
        apiConsumer.getOne(`celebrations`, celeb.id).then(rep => {
          if (rep.status === 200) {
            setCelebration(rep.body)
          }
        })
      }
    },
    [setSelected, setDate, setCelebration, celebration]
  )

  const onSelect = useCallback(
    id => {
      const celeb = celebrationList.find(e => e.id === id)
      if (celeb) {
        getCelebration(celeb)
      }
    },
    [celebrationList, getCelebration]
  )

  const onSelectDate = useCallback(
    date => {
      const celeb = celebrationList.find(
        e => formatDate(e.date) === formatDate(date)
      )
      if (celeb) {
        getCelebration(celeb)
      }
    },
    [celebrationList, getCelebration]
  )

  const masseSelect = useMemo(
    () => (
      <MasseSelect
        list={celebrationList}
        onSelect={onSelect}
        value={selected}
      />
    ),
    [celebrationList, onSelect, selected]
  )

  const datePicker = useMemo(() => {
    const dates = celebrationList.map(e => formatDate(e.date))
    return (
      <FormDatePicker
        editable={true}
        element={{
          label: i18n.t('app:celebrations:date'),
          md: 6
        }}
        value={date}
        setValue={v => onSelectDate(v)}
        shouldDisableDate={(date, e) =>
          dates.length && !dates.includes(formatDate(date))
        }
      />
    )
  }, [celebrationList, onSelectDate, date])

  useEffect(() => {
    if (!celebrationList?.length) {
      let yesterday = new Date();
      yesterday.setDate((new Date()).getDate() - 1);
      var formattedDate = yesterday.toISOString().slice(0, 10);

      apiConsumer.get(`celebrations?date[after]=${formattedDate}`).then(rep => {
        if (rep.status === 200) {
          setCelebrationList(rep.body)
          getCelebration(getNextDate(rep.body))
        } else {
          setCelebrationList([])
        }
      })
      return () => setCelebrationList([])
    } else if (
      celebrationList.length &&
      !celebration?.name &&
      !celebrationList?.loading
    ) {
      getCelebration(getNextDate(celebrationList))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NavBar title={i18n.t('menu:celebrations')} backArrow />
      {celebrationList==null?
      <Loading />
      :celebrationList.length>0?
        <Grid container spacing={3} className={classes.root}>
        {datePicker}
        <Grid item md={6} xs={12}>
          {masseSelect}
        </Grid>
        {celebration ? (
          <Grid item xs={12}>
            <DisplayCelebration celebration={celebration} />
          </Grid>
        ) : (
         <></>
        )}  </Grid>
   
    :
    <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  sx={{ minHeight: '100vh' }}
>
  <Grid item xs={3}>
  <Typography>
    {i18n.t('app:celebrations:noCelebrations')}</Typography>
  </Grid>
</Grid>
   
    }
   
    
    </>
  )
}

const MasseSelect = ({ list, onSelect, value }) => {
  const classes = useStyles()

  return (
    <FormControl fullWidth={true}>
      <InputLabel htmlFor='grouped-select'>
        {i18n.t('app:celebrations:masses')}
      </InputLabel>
      <Select
        variant='standard'
        onChange={e => onSelect(e.target.value)}
        value={value}
      >
        <ListSubheader className={classes.subHeader}>
          {i18n.t('app:celebrations:next masses')}
        </ListSubheader>
        {list.map(e =>
          validateDateBetween(e.date) ? (
            <MenuItem key={e.id} value={e.id}>
              {e.name}
            </MenuItem>
          ) : null
        )}
        <ListSubheader className={classes.subHeader}>
          {i18n.t('app:celebrations:all masses')}
        </ListSubheader>
        {list.map(e => (
          <MenuItem key={e.id} value={e.id}>
            {e.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const CelebrationStore = connect(
  state => ({
    celebration: state.setting.celebration,
    celebrationList: state.setting.celebrationList || []
  }),
  dispach => ({
    setCelebration: value =>
      dispach(setSettingStoreValueAction('celebration', value)),
    setCelebrationList: value =>
      dispach(setSettingStoreValueAction('celebrationList', value))
  })
)(Celebration)

export default CelebrationStore
