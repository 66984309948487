import React from 'react'
import { Add, ChevronLeft, ChevronRight, Remove } from '@mui/icons-material'
import { Grid, Slider, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const AppSlider = ({
  value,
  onChange,
  field,
  label,
  max,
  min,
  step = 1,
  chevronMode = false
}) => {
  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid
        item
        onClick={() => onChange(field, value > min ? value - step : value)}
      >
        {chevronMode ? <ChevronLeft /> : <Remove />}
      </Grid>
      <Grid item xs>
        <Slider
          size='small'
          min={min}
          step={step}
          max={max}
          track={false}
          value={value}
          onChange={(e, newValue) => onChange(field, newValue)}
        />
      </Grid>
      <Grid
        item
        onClick={() => onChange(field, value < max ? value + step : value)}
      >
        {chevronMode ? <ChevronRight /> : <Add />}
      </Grid>
    </Grid>
  )
}

AppSlider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  field: PropTypes.string,
  label: PropTypes.node,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  /** - + or < > */
  chevronMode: PropTypes.bool
}

export default AppSlider
