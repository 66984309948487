import i18n from '../i18n'
import moment from 'moment'
import 'moment/locale/fr'
moment.locale('fr')

// global app functions and utils

const accentsMap = {
  a: 'á|à|ã|â|À|Á|Ã|Â',
  e: 'é|è|ê|É|È|Ê',
  i: 'í|ì|î|Í|Ì|Î',
  o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
  u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
  c: 'ç|Ç',
  n: 'ñ|Ñ'
}

const slugify = (text = '') =>
  Object.keys(accentsMap).reduce(
    (acc, cur) => acc.replace(new RegExp(accentsMap[cur], 'g'), cur),
    text.replace(/\.|,|\?|!|;|:|\s*/g, '')
  )

export const searchIn = (text = '', value = '') =>
  slugify(text).search(new RegExp(slugify(value), 'gi')) !== -1

export const searchInArray = (object, field, value) =>
  object.filter(o => searchIn(o[field], value))

export const searchNumberIn = (number = 0, value = 0) => {
  return searchIn(number.toString(), value.toString())
}

export const ValidatePassword = (password, bis) => {
  if (password !== bis) {
    return i18n.t('error:passwords.different')
  } else if (!/[a-z]+/.test(password)) {
    return i18n.t('error:should contain at least one lower case')
  } else if (!/[A-Z]+/.test(password)) {
    return i18n.t('error:should contain at least one upper case')
  } else if (!/\d+/.test(password)) {
    return i18n.t('error:should contain at least one digit')
  } else if (password.length < 5) {
    return i18n.t('error:should contain at least 6 characters')
  }
  return ''
}

const screenCutValue = [
  {
    width: 1000,
    fontSize: 30
  },
  {
    width: 800,
    fontSize: 24
  }
]

export const isPossibleTwoColums = (fontSize = 0) => {
  for (let k of screenCutValue) {
    if (window.innerWidth > k.width && fontSize < k.fontSize) {
      return true
    }
  }
  return false
}

export const formatDateTime = datetime => {
  const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ]
  const d = new Date(datetime)
  const time = d.getMinutes()
  return {
    day: d.getDate(),
    date: months[d.getMonth()],
    time: d.getHours() + ':' + (time.length < 2 ? `0${time}` : time)
  }
}

export const formatDate = (date, en = false) => {
  if (!date) return

  const d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate()

  return en
    ? [
        d.getFullYear(),
        month.length < 2 ? `0${month}` : month,
        day.length < 2 ? `0${day}` : day
      ].join('-')
    : [
        day.length < 2 ? `0${day}` : day,
        month.length < 2 ? `0${month}` : month,
        d
          .getFullYear()
          .toString()
          .substr(2)
      ].join('/')
}

export const formatTime = datetime => {
  const d = new Date(datetime),
    mins = d.getMinutes()
  return `${d.getHours()}:${mins < 10 ? `0${mins}` : mins}`
}

export const addDaysToDate = (date, days) =>
  new Date(new Date(date).getTime() + days * 24 * 60 * 60 * 1000)

export const compareDate = (a, b) => {
  const c = new Date(a)
  const d = new Date(b)
  return c > d ? 1 : c < d ? -1 : 0
}

export const capitalize = input => {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

export const displayDate = date =>
  capitalize(moment(date).format('dddd D MMMM'))

export const sortFrenchDates = (a, b) => {
  const aa = a
      .split('/')
      .reverse()
      .join(),
    bb = b
      .split('/')
      .reverse()
      .join()
  return aa < bb ? -1 : aa > bb ? 1 : 0
}

export const sortTimes = (a, b) => a.replace(/:/g, '') - b.replace(/:/g, '')
