import { combineReducers, createStore } from 'redux'
import SettingReducer from './setting/settingReducer'
import formReducer from './form/formReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// store initialisation

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'memorisedUser',
    'darkTheme',
    'notificationsRead',
    'osmdSettings',
    'printMassSheetSettings',
    'massSheetsCompactMode',
    'drawerReduced',
    'pdfScale',
    'guidedTour',
    'appDatas'
  ]
}

const persistedReducer = persistReducer(persistConfig, SettingReducer)

export const store = createStore(
  combineReducers({
    setting: persistedReducer,
    form: formReducer
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const persistor = persistStore(store)
