import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import apiConsumer from '../../service/api/apiConsumer'
import { FormValidationStore } from '../crud/FormValidation'
import {
  initStoreStateAction,
  setStoreEditableAction,
  setStoreValidAction,
  setStoreValuesAction
} from '../../store/form/formStoreAction'
import i18n from '../../i18n'
import {
  setSettingStoreValueAction,
  setSnackbarAction
} from '../../store/setting/settingStoreAction'
import { PropTypes } from 'prop-types'
import { regex } from '../../service/regex'

const params = {
  entityName: 'parishes',
  targetUrl: 'parishes',
  defaultEntity: {
    id: 0,
    name: '',
    comment: '',
    email: '',
    phone: '',
    website: '',
    adress: '',
    city: '',
    postcode: '',
    country: '',
    picture2: null,
    picture: null,
    enable: false,
    priests: '',
    responsibles: []
  },
  formStruct: {
    type: 'tabs',
    elements: [
      {
        label: i18n.t('parishes:tabs:main'),
        data: [
          {
            type: 'text',
            required: true,
            field: 'name',
            label: i18n.t('parishes:field:name'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'textarea',
            required: true,
            field: 'comment',
            label: i18n.t('parishes:field:comment'),
            md: 6,
            rules: {
              min: 3,
              max: 1000,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'email',
            label: i18n.t('parishes:field:email'),
            md: 6,
            regex: regex.email,
            regexExample: 'exemple@sjtm.fr',
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'phone',
            label: i18n.t('parishes:field:phone'),
            md: 6,
            regex: regex.phone,
            regexExample: '0243568745, +33681459678',
            rules: {
              min: 3,
              max: 15,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'priests',
            label: i18n.t('parishes:field:priests'),
            md: 6,
            rules: {
              min: 3,
              max: 200,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'website',
            regex: regex.website,
            regexExample: 'https://exemple.fr',
            label: i18n.t('parishes:field:website'),
            md: 6,
            rules: {
              min: 3,
              max: 255,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'spacer'
          },
          {
            type: 'image',
            field: 'picture',
            imageUrl: 'parishes-picture',
            required: true,
            label: i18n.t('parishes:field:picture'),
            md: 6
          },
          {
            type: 'image',
            field: 'picture2',
            imageUrl: 'parishes-picture2',
            required: true,
            label: i18n.t('parishes:field:picture2'),
            md: 6
          }
        ]
      },
      {
        label: i18n.t('parishes:tabs:adress'),
        data: [
          {
            type: 'text',
            required: true,
            field: 'adress',
            label: i18n.t('parishes:field:adress'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'city',
            label: i18n.t('parishes:field:city'),
            md: 6,
            rules: {
              min: 3,
              max: 50,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'number',
            required: true,
            field: 'postcode',
            label: i18n.t('parishes:field:postcode'),
            md: 6,
            regex: regex.postcode,
            regexExample: '75000',
            rules: {
              min: 3,
              max: 8,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'country',
            label: i18n.t('parishes:field:country'),
            md: 6,
            rules: {
              min: 3,
              max: 50,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          }
        ]
      },
      {
        label: i18n.t('parishes:tabs:responsible'),
        data: [
          {
            type: 'ParishResponsiblesEditor',
            field: 'responsibles'
          }
        ]
      }
    ]
  }
}

const formatCRUDEntity = entity => {
  entity.postcode = entity.city.postcode
  entity.country = entity.city.country
  entity.city = entity.city.name
  return entity
}

const ParishEditor = ({
  create = false,
  parish,
  initStoreState,
  setStoreValues,
  setStoreEditable,
  setStoreValid,
  setSnackbar,
  onEndEdit,
  setParishes
}) => {
  useEffect(() => {
    initStoreState({ ...params.formStruct })
    setStoreValues(
      formatCRUDEntity(parish || { ...params.defaultEntity }),
      false
    )
    setStoreEditable(true)
  }, [initStoreState, setStoreValues, setStoreEditable, parish])

  const onValid = useCallback(
    async element => {
      const getBadResponse = rep => {
        const err = rep?.body?.violations
          ? apiConsumer.getBadResponseMessage(rep)
          : rep?.body[0]

        if (err?.field) {
          const message = i18n.t(`error:${err.name}`, {
            name: i18n.t(`${params.entityName}:field:${err.field}`)
          })
          setStoreValid(message, err.field)
          return message
        } else if (err?.name) {
          return i18n.t(`error:${err.name}`)
        } else if (err?.status === 500) {
          return i18n.t('error:default')
        }
        return i18n.t('error:check the form')
      }

      const rep = create
        ? await apiConsumer.create(params.targetUrl, element)
        : await apiConsumer.update(params.targetUrl, element)
      if (rep.status === 201) {
        setSnackbar(i18n.t('app:parishes:parish created'), 'success', 10)
        onEndEdit()
      } else if (rep.status === 200) {
        setSnackbar(i18n.t('app:parishes:parish updated'), 'success', 3)
        // reset parishes saved
        setParishes()
        onEndEdit()
      } else {
        return getBadResponse(rep)
      }
    },
    [setSnackbar, setStoreValid, onEndEdit, create, setParishes]
  )

  return (
    <FormValidationStore
      formType={params.formStruct.type || null}
      onValid={v => onValid(v)}
      formParams={{ create }}
    />
  )
}

ParishEditor.propTypes = {
  create: PropTypes.bool,
  parish: PropTypes.object,
  initStoreState: PropTypes.func,
  setStoreValues: PropTypes.func,
  setStoreEditable: PropTypes.func,
  setStoreValid: PropTypes.func,
  setSnackbar: PropTypes.func,
  onEndEdit: PropTypes.func,
  setParishes: PropTypes.func,
  isSubscriber: PropTypes.bool
}

export default ParishEditor

export const ParishEditorStore = connect(
  state => ({}),
  dispach => ({
    setStoreValues: (values, editable) => {
      const valid = { ...values }
      Object.keys(valid).map(key => (valid[key] = ''))
      dispach(
        setStoreValuesAction({ values, valid, displayError: false, editable })
      )
    },
    setStoreValid: (valid, field) => dispach(setStoreValidAction(valid, field)),
    initStoreState: init => dispach(initStoreStateAction(init)),
    setSnackbar: (message, type, time) =>
      dispach(setSnackbarAction(message, type, time)),
    setStoreEditable: editable => dispach(setStoreEditableAction(editable)),
    setParishes: value => dispach(setSettingStoreValueAction('parishes', value))
  })
)(ParishEditor)
