import React, { useCallback } from 'react'
import { Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import { compareDate, formatDate } from '../../service/functions'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs';
import { frFR } from '@mui/x-date-pickers/locales'

const FormDatePicker = ({
  editable = true,
  element,
  value,
  valid = '',
  setValue,
  shouldDisableDate,
  ruleMinVal
}) => {
  const handleChange = useCallback(
    (a, b) => {
      if (ruleMinVal && compareDate(a, ruleMinVal) === -1) {
        setValue(
          formatDate(a, true),
          element?.rules?.minFieldMessage,
          element.field
        )
      } else {
        setValue(formatDate(a, true), '', element.field)
      }
    },
    [element, setValue, ruleMinVal]
  )

  return (
    <Grid
      item
      xs={element.xs || 12}
      md={element.md}
      key={`grid-${element.label}`}
    >
    <LocalizationProvider
  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText} 
  dateAdapter={AdapterDayjs}
>
        <Stack spacing={3}>
          <DatePicker
            format="DD/MM/YYYY"
            minDate={dayjs(element.minDate || '01-01-1500')}
            shouldDisableDate={shouldDisableDate}
            label={element.label}
            value={dayjs(value)}
            onChange={handleChange}
            // rend={params => (
            //   <TextField
            //     variant='standard'
            //     error={valid !== ''}
            //     helperText={valid}
            //     required={element.required || false}
            //     {...params}
            //   />
            // )}
            readOnly={editable === false}
          />
        </Stack>
      </LocalizationProvider>
    </Grid>
  )
}

FormDatePicker.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.object,
  value: PropTypes.any,
  valid: PropTypes.string,
  setValue: PropTypes.func,
  shouldDisableDate: PropTypes.any,
  ruleMinVal: PropTypes.any
}

export default FormDatePicker

export const FormDatePickerStore = connect(
  (state, ownProps) => ({
    value: state.form?.values[ownProps.element.field],
    valid: state.form?.valid[ownProps.element.field],
    editable: state.form.editable,
    ruleMinVal: state.form?.values[ownProps.element?.rules?.minField]
  }),
  dispach => ({
    setValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(FormDatePicker)
