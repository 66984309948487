import { drawerWidth } from './globalAppVariables'

const appStyle = theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.6em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '0px'
    },
    '.MuiPaper-root:not(#drawer *)': {
      backgroundImage: 'none!important'
    },
    '.PrivateSwipeArea-root': {
      width: '10px!important'
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.secondary
    }
  },
  root: {
    display: 'flex',
    overflow: 'hidden'
  },
  content: {
    width: '100%',
    paddingLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },
  fullContent: {
    width: '100%'
  }
})

export default appStyle
