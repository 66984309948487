import React, { useEffect, useState } from 'react'
import MobileStepper from '@mui/material/MobileStepper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import { CardMedia, Dialog, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import i18n from '../../../i18n'
import { connect } from 'react-redux'
import { setSettingStoreGuidedTourAction } from '../../../store/setting/settingStoreAction'

const useStyles = makeStyles(theme => ({
  root: {
    '& *': {
      userSelect: 'none'
    }
  },
  media: {
    backgroundPosition: 'initial!important',
    maxWidth: 600,
    height: '300px',
    [theme.breakpoints.down('sm')]: {
      height: '200px'
    }
  },
  title: {
    padding: theme.spacing(4, 4, 0, 4),
    textAlign: 'center',
    fontSize: '20px!important'
  },
  divider: {
    width: '50%',
    margin: 'auto!important'
  },
  textBox: {
    padding: theme.spacing(3, 5),
    textAlign: 'center'
  },
  text: {
    fontSize: '16px!important'
  },
  stepper: {
    background: 'transparent!important'
  }
}))

const Component = ({ onDisable, enable, setGuidedTour, type, steps }) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    if (!enable) onDisable()
  }, [onDisable, enable])

  return (
    enable && (
      <Dialog onClose={() => {}} open className={classes.root}>
        <SwipeableViews
          enableMouseEvents
          index={activeStep}
          onChangeIndex={s => setActiveStep(s)}
        >
          {steps.map((e, i) => (
            <Panel key={i} title={e.title} image={e.image} text={e.text} />
          ))}
        </SwipeableViews>
        <MobileStepper
          variant='dots'
          steps={steps.length}
          position='static'
          activeStep={activeStep}
          className={classes.stepper}
          nextButton={
            activeStep === steps.length - 1 ? (
              <Button onClick={() => setGuidedTour(type, true)}>
                {i18n.t('main:finish')}
              </Button>
            ) : (
              <Button onClick={() => setActiveStep(s => s + 1)}>
                {i18n.t('main:next')}
                <KeyboardArrowRight />
              </Button>
            )
          }
          backButton={
            <Button
              onClick={() => setActiveStep(s => s - 1)}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              {i18n.t('main:previous')}
            </Button>
          }
        />
      </Dialog>
    )
  )
}

export default connect(
  (state, ownProps) => ({
    enable: !state.setting?.guidedTour[ownProps.type]
  }),
  dispach => ({
    setGuidedTour: (type, value) =>
      dispach(setSettingStoreGuidedTourAction(type, value))
  })
)(Component)

const Panel = ({ title, image, text }) => {
  const classes = useStyles()
  return (
    <>
      <CardMedia className={classes.media} image={image} title={title} />
      <Typography className={classes.title} variant='h5'>
        {title}
      </Typography>
      <Stack
        className={classes.textBox}
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Typography className={classes.text}>{text}</Typography>
      </Stack>
    </>
  )
}
