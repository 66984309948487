import React from 'react'
import { Grid, Typography, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '60px!important',
    paddingBottom: '100px!important'
  },
  image: {
    width: '100%',
    maxWidth: 400
  }
}))

const BiSection = ({ data = [], html }) => {
  const classes = useStyles()
  return (
    <Grid
      className={classes.root}
      container
      justifyContent='center'
      alignItems='center'
      spacing={3}
    >
      {data.map((d, i) => (
        <Item key={i} data={d} />
      ))}
    </Grid>
  )
}

export default BiSection

const Item = ({ data }) => {
  const classes = useStyles()
  return (
    <Grid item xs={8} sm={6}>
      {data.title ? (
        <Stack alignItems='center' spacing={1}>
          <Typography component='h2' variant='h4' align='center' sx={{ pb: 2 }}>
            {data.title}
          </Typography>
          <Typography
            component='p'
            variant='h6'
            color='text.secondary'
            align='center'
            style={{ whiteSpace: 'pre-line' }}
          >
            {data.describe}
          </Typography>
          {data?.html}
        </Stack>
      ) : data?.html ? (
        data.html
      ) : (
        <Stack alignItems='center'>
          <img
            alt={data.alt}
            className={classes.image}
            style={data.styles}
            src={data.image}
          />
        </Stack>
      )}
    </Grid>
  )
}
