import * as React from 'react'
import Grid from '@mui/material/Grid'
import { Typography, Divider } from '@mui/material'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'

function Copyright (props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'© '}
      <Link color='inherit' href='https://www.aiderpretres.fr'>
        Oeuvre de Soutien aux Eglises de France et aux Prêtres
      </Link>
      {` ${new Date().getFullYear()}.`}
    </Typography>
  )
}

const Footer = ({ data }) => {
  return (
    <>
      <Container maxWidth='lg' component='footer' sx={{ mb: 3 }}>
        <Divider variant='middle' sx={{ mt: 8, mb: 8 }} />
        <Grid container spacing={4} justifyContent='space-evenly'>
          {data.map(footer => (
            <Grid item xs={10} sm={footer.describe ? 3 : 5} key={footer.title}>
              <Typography
                variant='h6'
                color='text.primary'
                sx={{ height: 32 }}
                gutterBottom
              >
                {footer.title}
              </Typography>

              {footer.describe ? (
                <ul>
                  {footer.describe.map((item, i) => (
                    <li key={i}>
                      {item.link ? (
                        <Link
                          href={item.link}
                          variant='subtitle1'
                          color='text.secondary'
                        >
                          {item.title}
                        </Link>
                      ) : item.component ? (
                        <Typography variant='subtitle1' color='text.secondary'>
                          {item.component}
                        </Typography>
                      ) : null}
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography variant='p' color='text.secondary'>
                  {footer.content}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </>
  )
}

export default Footer
