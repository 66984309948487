import React, { useCallback, useEffect, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import { useHistory } from 'react-router'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Skeleton } from '@mui/material'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  list: {
    marginLeft: theme.spacing(1) + '!important'
  },
  number: {
    width: '50px'
  },
  noItems: {
    marginTop: '80px',
    textAlign: 'center'
  }
}))

const SongList = ({ list, scrollable = true, emptyMessage = '', scrollOffset, onScroll }) => {
  const history = useHistory()
  const classes = useStyles()
  const calendarRef = useRef(null);

  const loadSong = useCallback(
    song => {
      history.push(`/app/song-${song.id}-${song.name}`)
    },
    [history]
  )

  const Row = useCallback(
    ({ index, style }) => (
      <div key={index} style={style}>
        <ListItem button divider={true} onClick={() => loadSong(list[index])}>
          <ListItemIcon className={classes.number}>
            <Typography>{list[index].number}</Typography>
          </ListItemIcon>
          <ListItemText
            primary={<Typography noWrap>{list[index].name}</Typography>}
          />
        </ListItem>
      </div>
    ),
    [classes, loadSong, list]
  )

  useEffect(() => {
    if (calendarRef.current) {
      const currentScrollPosition = calendarRef.current.scrollTop;

      if (currentScrollPosition !== (scrollOffset ?? 0)) {
        calendarRef.current.scrollTo(scrollOffset ?? 0, 0);
      }
    }
  }, [calendarRef, scrollOffset]);

  return list && list.length ? (
    <div
      style={{ height: scrollable ? 'calc(100vh - 66px)' : list.length * 50 }}
      className={classes.list}
    >
      <AutoSizer>
        {({ height, width }) => {
          return (
            <FixedSizeList
              width={width - 1}
              height={height}
              itemCount={list.length}
              itemSize={49}
              onScroll={onScroll}
              ref={calendarRef}
            >
              {Row}
            </FixedSizeList>
          )
        }}
      </AutoSizer>
    </div>
  ) : list ? (
    <NoItems emptyMessage={emptyMessage} />
  ) : (
    <div style={{ padding: '16px' }}>
      {[...Array(parseInt(window.innerHeight / 40))].map((x, i) => (
        <Skeleton key={i} height={40} />
      ))}
    </div>
  )
}

SongList.propTypes = {
  /** songs list */
  list: PropTypes.array,
  /** list is inside scrollable box */
  scrollable: PropTypes.bool,
  /** no content response */
  emptyMessage: PropTypes.string
}

export default SongList

const NoItems = ({ emptyMessage }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      className={classes.noItems}
    >
      <Typography>{emptyMessage}</Typography>
    </Grid>
  )
}
