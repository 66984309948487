import React, { useCallback, useMemo, useRef, useState } from 'react'
import AppDialog from '../unclassified/AppDialog'
import i18n from '../../i18n'
import { Skeleton, Alert } from '@mui/material'
import { Typography, Grid, IconButton, TextField } from '@mui/material'
import apiConsumer from '../../service/api/apiConsumer'
import { formatDateTime } from '../../service/functions'
import { Delete, Send } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start'
  },
  messageRight: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  paper: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'left',
    background: theme.palette.primary.main,
    color: theme.palette.grey[200],
    position: 'relative',
    borderRadius: '30px',
    maxWidth: '80%',
    '& p': {
      paddingTop: '5px'
    }
  },
  mdTitle: {
    paddingRight: '40px',
    color: theme.palette.grey[300]
  },
  mdIcon: {
    position: 'absolute!important',
    right: '15px',
    top: '2px'
  },
  typo: {
    whiteSpace: 'pre-wrap'
  },
  input: {
    width: '100%',
    margin: theme.spacing(1) + '!important'
  },
  inputButton: {
    padding: 0,
    paddingRight: 4
  },
  alert: {
    marginTop: 10,
    width: '90%'
  }
}))

const ShowChat = ({ open, chat, onClose, resetIsReplied, userId }) => {
  const classes = useStyles()
  const contentRef = useRef(null)
  const [message, setMessage] = useState({ value: '', valid: '' })
  const [errorMsg, setErrorMsg] = useState('')
  const [messageList, setMessageList] = useState([])
  const [sending, setSending] = useState(false)

  useMemo(async () => {
    let isMounted = true
    setMessageList([])
    apiConsumer.getOne(`chats`, chat.id).then(async rep => {
      if (isMounted && rep.status === 200) {
        if (rep.status === 200) {
          await setMessageList(rep.body.messages, false)
          contentRef.current?.scrollIntoView({ behavior: 'smooth' })
        } else {
          setErrorMsg(i18n.t('error:An error has occurred'))
        }
      }
    })
    return () => (isMounted = false)
  }, [setMessageList, chat.id, setErrorMsg])

  const sendMessage = useCallback(async () => {
    if (message.value.length < 4 || message.value.length > 800) {
      setMessage(e => ({
        value: e.value,
        valid: i18n.t(
          message.value.length < 4
            ? 'error:the text is too short'
            : 'error:the text is too long'
        )
      }))
    } else {
      if (!sending) {
        setSending(true)
        apiConsumer
          .create(`chats/${chat.id}/messages`, {
            content: message.value
          })
          .then(rep => {
            if (rep.status === 201) {
              setMessage({ value: '', valid: '' })
              setMessageList(e => [...e, rep.body])
              setErrorMsg('')
              resetIsReplied(chat.id)
              contentRef.current?.scrollIntoView({ behavior: 'smooth' })
            } else if ('message' in rep) {
              setErrorMsg(i18n.t(`error:${rep.message}`))
            } else {
              setErrorMsg(i18n.t('error:default'))
            }
            setSending(false)
          })
      }
    }
  }, [
    message,
    setMessage,
    setErrorMsg,
    setMessageList,
    chat.id,
    contentRef,
    resetIsReplied,
    sending
  ])

  const deleteMessage = useCallback(
    async id => {
      const rep = await apiConsumer.delete('chats/messages', id)
      if (rep.status === 204) {
        setMessageList(e => e.filter(el => id !== el.id))
      }
    },
    [setMessageList]
  )

  const messages = useMemo(
    () =>
      messageList.map((message, idx) => (
        <Message
          message={message}
          key={idx}
          own={userId === message.owner.id}
          allowDelete={idx === messageList.length - 1 && idx && chat.isOpened}
          onDelete={() => deleteMessage(message.id)}
        />
      )),
    [messageList, userId, deleteMessage, chat.isOpened]
  )

  return (
    <>
      <AppDialog
        title={
          chat.subject + (chat.parish?.id ? ` - ${chat.parish?.name}` : '')
        }
        maxHeight={500}
        action={
          <Grid container justifyContent='center'>
            {errorMsg ? (
              <Alert
                className={classes.alert}
                severity='error'
                variant='outlined'
              >
                {errorMsg}
              </Alert>
            ) : null}
            {chat.isOpened ? (
              <TextField
                variant='standard'
                className={classes.input}
                label={i18n.t('chat:crud:message')}
                multiline
                value={message.value}
                onChange={e => setMessage({ value: e.target.value, valid: '' })}
                onKeyDown={e => {
                  if (e.key === 'Enter' && e.ctrlKey) sendMessage()
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      className={classes.inputButton}
                      onClick={() => sendMessage()}
                      color='primary'
                    >
                      <Send />
                    </IconButton>
                  )
                }}
              />
            ) : (
              i18n.t('chat:crud:chat closed')
            )}
          </Grid>
        }
        open={open}
        width='xs'
        onClose={onClose}
      >
        {messageList.length ? (
          <>
            {messages}
            <div ref={contentRef} />
          </>
        ) : (
          [...Array(5)].map((x, i) => <Skeleton key={i} height={40} />)
        )}
      </AppDialog>
    </>
  )
}

ShowChat.propTypes = {
  /** chat */
  chat: PropTypes.object,
  /** open */
  open: PropTypes.bool,
  onClose: PropTypes.func,
  /** function to reset chat count */
  resetIsReplied: PropTypes.func,
  userId: PropTypes.number
}

export default connect(state => ({
  userId: state.setting?.user?.id
}))(ShowChat)

const Message = ({ message, own, allowDelete, onDelete }) => {
  const classes = useStyles()
  return (
    <div className={own ? classes.messageRight : classes.message}>
      <div elevation={2} className={classes.paper}>
        <MessageDetails onDelete={own && allowDelete ? onDelete : null}>
          {i18n.t('chat:datetime', formatDateTime(message.createdAt)) +
            ('owner' in message && !own ? ' - ' + message.owner.firstname : '')}
        </MessageDetails>
        <Typography className={classes.typo} variant='body1'>
          {message.content}
        </Typography>
      </div>
    </div>
  )
}

const MessageDetails = ({ children, onDelete }) => {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.mdTitle} variant='caption'>
        {children}
      </Typography>
      {onDelete ? (
        <IconButton
          size='small'
          className={classes.mdIcon}
          color='inherit'
          onClick={() => {
            onDelete()
          }}
        >
          <Delete />
        </IconButton>
      ) : null}
    </>
  )
}
