import React, { useMemo } from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import apiConsumer from '../../../service/api/apiConsumer'
import { formatDate, formatTime, sortTimes } from '../../../service/functions'
import NavBar from '../../../core/NavBar'
import { useParams } from 'react-router'

const params = {
  entityName: 'massTimes',
  targetUrlGet: '',
  targetUrl: 'mass_times',
  mainField: 'name',
  defaultEntity: {
    name: '',
    category: null,
    isRecurrent: true,
    day: null,
    date: new Date(Date.now()),
    beginTime: new Date(1970, 1, 1, 10, 0, 0),
    hasEndTime: false,
    endTime: new Date(1970, 1, 1, 11, 0, 0),
    comment: '',
    parish: null,
    isPublished: true
  },
  datagridOptions: {
    rights: {
      show: [],
      edit: [],
      delete: []
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('massTimes:field:id'),
      hide: true
    },
    {
      field: 'name',
      headerName: i18n.t('massTimes:field:name'),
      width: 300,
      main: true
    },
    {
      field: 'date',
      headerName: i18n.t('massTimes:field:date'),
      width: 200
    },
    {
      field: 'beginTime',
      headerName: i18n.t('massTimes:field:beginTime'),
      width: 100,
      sortComparator: (v1, v2, p1, p2) => sortTimes(v1, v2)
    },
    {
      field: 'endTime',
      headerName: i18n.t('massTimes:field:endTime'),
      width: 80
    },
    {
      field: 'isPublished',
      type: 'boolean',
      headerName: i18n.t('massTimes:field:isPublished'),
      width: 100
    },
    {
      field: 'wrongVotes',
      type: 'error',
      headerName: i18n.t('massTimes:field:wrongVotes'),
      width: 100
    }
  ],
  formStruct: {
    size: {
      width: 'md',
      fullHeight: false
    },
    elements: [
      {
        type: 'text',
        required: false,
        field: 'name',
        label: i18n.t('massTimes:field:optional name'),
        md: 6,
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'select',
        field: 'category',
        label: i18n.t('massTimes:field:category'),
        md: 6,
        options: []
      },
      {
        type: 'switch',
        field: 'isRecurrent',
        onlyEditor: true,
        label: i18n.t('massTimes:field:isRecurrent'),
        md: 6,
        action: {
          display: ['day'],
          hide: ['date']
        }
      },
      {
        type: 'select',
        field: 'day',
        label: i18n.t('massTimes:field:day'),
        md: 6,
        options: []
      },
      {
        type: 'date',
        field: 'date',
        label: i18n.t('massTimes:field:date'),
        md: 6,
        hidden: true
      },
      {
        type: 'time',
        required: true,
        field: 'beginTime',
        label: i18n.t('massTimes:field:beginTime'),
        md: 6
      },
      {
        type: 'switch',
        field: 'hasEndTime',
        onlyEditor: true,
        label: i18n.t('massTimes:field:hasEndTime'),
        md: 6,
        action: {
          display: ['endTime']
        }
      },
      {
        type: 'time',
        required: false,
        field: 'endTime',
        label: i18n.t('massTimes:field:endTime'),
        md: 6,
        hidden: true
      },
      {
        type: 'textarea',
        field: 'comment',
        label: i18n.t('massTimes:field:comment'),
        md: 12,
        rules: {
          max: 255,
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'switch',
        field: 'isPublished',
        label: i18n.t('massTimes:field:isPublished'),
        md: 12
      }
    ]
  }
}

const formatEntities = entities => {
  return (
    entities.map(entity => {
      return formatEntity(entity)
    }) || null
  )
}

const formatEntity = entity => {
  entity.name = `${entity.name}${entity.name ? ' - ' : ''}${
    entity.category.name
  }`
  entity.date = entity.day ? entity.day.name : formatDate(entity.date)
  entity.beginTime = formatTime(entity.beginTime)
  entity.endTime = entity.endTime && formatTime(entity.endTime)
  return entity
}

const formatCRUDEntity = entity => {
  entity.category = entity.category?.id
  entity.day = entity.day?.id
  entity.parish = entity.parish?.id
  entity.hasEndTime = !!entity.endTime
  entity.isRecurrent = !!entity.day
  return entity
}

const Component = () => {
  const { id } = useParams()

  useMemo(async () => {
    params.targetUrlGet = `${params.targetUrl}/parish/${id}`
    params.defaultEntity.parish = id

    const rep = await apiConsumer.get(`mass_times-form-content`, false)
    if (rep.status === 200 && rep?.body) {
      Object.keys(rep.body).forEach(e => {
        const idx = params.formStruct.elements.findIndex(el => el.field === e)
        if (idx !== -1) {
          params.formStruct.elements[idx].options = rep.body[e]
          if (e === 'parish') {
            params.defaultEntity[e] = id
            params.formStruct.elements[idx].readOnly = true
          } else {
            params.defaultEntity[e] = rep.body[e][0]?.id
          }
        }
      })
    }
  }, [id])

  return (
    <>
      <NavBar title={i18n.t('massTimes:massTimes')} backArrow />
      <CrudPage
        params={params}
        formatEntities={formatEntities}
        formatEntity={formatEntity}
        formatCRUDEntity={formatCRUDEntity}
      />
    </>
  )
}

export default Component
