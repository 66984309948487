// settigs store action

export const initSettingStoreStateAction = state => ({
  type: 'INIT_SETTING_STORE',
  payload: { ...state }
})

export const setSettingStoreValueAction = (field, value, subfield = null) => ({
  type: 'SET_SETTING_STORE_VALUE',
  payload: { field, subfield, value }
})

export const toggleFieldAction = (field, value = null) => ({
  type: 'TOGGLE_FIELD',
  payload: { field, value }
})

export const setSnackbarAction = (message, type, time) => ({
  type: 'SET_SNACKBAR',
  payload: { message, type, time }
})

export const setSettingStoreGuidedTourAction = (field, value = null) => ({
  type: 'SET_GUIDED_TOUR',
  payload: { field, value }
})
