import React, { useCallback } from 'react'
import { Grid, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import TimePicker from '@mui/lab/TimePicker'

const FormTimePicker = ({
  editable = true,
  element,
  value,
  valid = '',
  setValue
}) => {
  const handleChange = useCallback(
    value => {
      setValue(value, '', element.field)
    },
    [element, setValue]
  )

  return (
    <Grid
      item
      xs={element.xs || 12}
      md={element.md}
      key={`grid-${element.label}`}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          ampm={false}
          label={element.label}
          value={value}
          onChange={handleChange}
          renderInput={params => (
            <TextField
              variant='standard'
              sx={{ width: '100%' }}
              error={valid !== ''}
              required={element.required || false}
              {...params}
            />
          )}
          readOnly={editable === false}
        />
      </LocalizationProvider>
    </Grid>
  )
}

FormTimePicker.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.object,
  value: PropTypes.any,
  valid: PropTypes.string,
  setValue: PropTypes.func
}

export default FormTimePicker

export const FormTimePickerStore = connect(
  (state, ownProps) => ({
    value: state.form?.values[ownProps.element.field],
    valid: state.form?.valid[ownProps.element.field],
    editable: state.form.editable,
    ruleMinVal: state.form?.values[ownProps.element?.rules?.minField]
  }),
  dispach => ({
    setValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(FormTimePicker)
