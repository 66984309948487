import React from 'react'
import ReactDOM from 'react-dom'
import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import App from './App'
import { store, persistor } from './store'
import {PersistGate} from 'redux-persist/integration/react'

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
)
