import React from 'react'
import {
  Typography,
  Card,
  CardContent,
  Grid,
  CardActions
} from '@mui/material'
import { Divider } from '@mui/material'
import Loading from '../../core/Loading'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  rootSpacing: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  fullHeight: {
    height: 'calc(100vh - 80px)',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 65px)',
      '& .MuiGrid-root': {
        padding: theme.spacing(1),
        borderRadius: 0
      },
      '& .MuiCard-paper': {
        margin: '0',
        width: '98%'
      }
    }
  },
  header: {
    padding: theme.spacing(2),
    paddingBottom: 0
  },
  content: {
    padding: 0,
    height: 'calc(100% - 60px)',
    overflowY: 'auto'
  },
  action: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}))

const AppCard = ({
  children,
  title,
  action,
  loading = false,
  fullHeight = false,
  spacing = false
}) => {
  const classes = useStyles()

  return (
    <Grid className={spacing ? classes.rootSpacing : classes.root}>
      <Card classes={fullHeight ? { root: classes.fullHeight } : null}>
        <div className={classes.header}>
          <Typography noWrap gutterBottom variant='h6'>
            {title}
          </Typography>
          <Divider />
        </div>
        <CardContent className={classes.content}>{children}</CardContent>
        {action ? (
          <CardActions className={classes.action}>{action}</CardActions>
        ) : null}
        {loading ? <Loading absolute={true} /> : null}
      </Card>
    </Grid>
  )
}

AppCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  action: PropTypes.node,
  loading: PropTypes.bool,
  fullHeight: PropTypes.bool,
  spacing: PropTypes.bool
}

export default AppCard
