import React from 'react'
import { AppTextFieldStore } from '../form/FormTextField'
import { AppAutocompleteStore } from '../form/FormAutocomplete'
import { AppSwitchStore } from '../form/FormSwitch'
import { SongEditorStore } from '../form/SongEditor'
import { SongFilesEditorStore } from '../form/SongFilesEditor'
import { AppFileInputStore } from '../form/FormFileInput'
import { CelebrationEditorStore } from '../form/CelebrationEditor'
import { MassSheetEditorStore } from '../form/MassSheetEditor'
import { RichTextInputStore } from '../form/RichTextInput'
import { YoutubePreviewStore } from '../preview/YoutubePreview'
import { CelebrationPreviewStore } from '../preview/CelebrationPreview'
import { MassSheetPreviewStore } from '../preview/MassSheetPreview'
import { FormDatePickerStore } from '../form/FormDatePicker'
import { Grid, Divider } from '@mui/material'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ParishResponsiblesEditorStore } from '../form/ParishResponsiblesEditor'
import { AppImageInputStore } from '../form/FormImageInput'
import { FormTimePickerStore } from '../form/FormTimePicker'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(5) + '!important'
  },
  spacer: {
    marginTop: theme.spacing(4) + '!important',
    marginBottom: theme.spacing(3) + '!important',
    width: '100%'
  }
}))

const FormContent = ({ elements = [], editable }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.root}>
      {elements.map((element, index) => {
        // display only if onlyEditor === true in element
        if (
          (!element?.onlyEditor || (editable && element?.onlyEditor)) &&
          !element?.hidden &&
          element
        ) {
          if (element.type === 'spacer') {
            return (
              <Grid
                item
                key={`spacer-${index}`}
                xs={12}
                className={classes.spacer}
              >
                <Divider variant='middle' />
              </Grid>
            )
          } else if (
            ['text', 'password', 'textarea', 'number', 'select'].includes(
              element.type
            )
          ) {
            return (
              <AppTextFieldStore
                key={`fieldt-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'autocomplete') {
            return (
              <AppAutocompleteStore
                key={`fielda-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'date') {
            return (
              <FormDatePickerStore
                key={`fieldd-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'time') {
            return (
              <FormTimePickerStore
                key={`fieldd-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'switch') {
            return (
              <AppSwitchStore
                key={`fields-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'file') {
            return (
              <AppFileInputStore
                key={`fieldf-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'image') {
            return (
              <AppImageInputStore
                key={`fieldI-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'song') {
            return <SongEditorStore key={`songEditor`} element={element} />
          } else if (element.type === 'songfiles') {
            return (
              <SongFilesEditorStore key={`songFilesEditor`} element={element} />
            )
          } else if (element.type === 'celebration') {
            return (
              <CelebrationEditorStore
                key={`fieldc-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'massSheet') {
            return (
              <MassSheetEditorStore
                key={`fieldcz-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'massSheetPreview') {
            return (
              <MassSheetPreviewStore
                key={`fieldcs-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'celebrationPreview') {
            return (
              <CelebrationPreviewStore
                key={`fieldcd-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'richText') {
            return (
              <RichTextInputStore
                key={`fieldaz-${element.label}`}
                element={element}
              />
            )
          } else if (element.type === 'youtubePreview') {
            return (
              <YoutubePreviewStore
                key={`fieldyoutubePreview-${element.field}`}
                element={element}
              />
            )
          } else if (element.type === 'ParishResponsiblesEditor') {
            return (
              <ParishResponsiblesEditorStore
                key={`ParishResponsiblesEditor-${element.field}`}
                element={element}
              />
            )
          }
        }
        return ''
      })}
    </Grid>
  )
}

FormContent.propTypes = {
  elements: PropTypes.array,
  editable: PropTypes.bool
}

export default FormContent

export const FormContentStore = connect(state => ({
  elements: state.form?.elements,
  editable: state.form?.editable
}))(FormContent)
