import React, { useEffect, useState } from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import apiConsumer from '../../../service/api/apiConsumer'
import { formatDate, sortFrenchDates } from '../../../service/functions'
import NavBar from '../../../core/NavBar'
import { setSettingStoreValueAction } from '../../../store/setting/settingStoreAction'
import { connect } from 'react-redux'
import { checkIsSubscriber } from '../../../service/api/authValidation'
import NeedSubscribe from '../../error/NeedSubscribe'

const params = {
  entityName: 'massSheets:create',
  targetUrl: 'mass_sheets',
  mainField: 'name',
  defaultEntity: {
    name: '',
    subname: '',
    date: new Date(Date.now()),
    code: null,
    parish: null,
    introduction: '',
    informations: '',
    massSheetSong: [
      { header: { name: 'Entrée' }, song: {}, verses: [] },
      { header: { name: 'Psaume' }, song: {}, verses: [] },
      { header: { name: 'Prière universelle' }, song: {}, verses: [] },
      { header: { name: 'Offertoire' }, song: {}, verses: [] },
      { header: { name: 'Communion' }, song: {}, verses: [] },
      { header: { name: 'Envoi' }, song: {}, verses: [] }
    ]
  },
  datagridOptions: {
    rights: {
      show: [],
      edit: [],
      delete: []
    },
    columnsOptions: {
      sortable: true,
      disableColumnMenu: true
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('massSheets:create:field:id'),
      hide: true
    },
    {
      field: 'name',
      headerName: i18n.t('massSheets:create:field:name'),
      width: 300,
      main: true
    },
    {
      field: 'date',
      headerName: i18n.t('massSheets:create:field:date'),
      width: 100,
      sortComparator: (v1, v2, p1, p2) => sortFrenchDates(v1, v2)
    }
  ],
  formStruct: {
    size: {
      width: 'lg',
      fullHeight: true
    },
    type: 'tabs',
    elements: [
      {
        label: i18n.t('massSheets:create:tabs:create massSheet'),
        data: [
          {
            type: 'text',
            required: true,
            field: 'name',
            label: i18n.t('massSheets:create:field:name'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'date',
            required: true,
            field: 'date',
            label: i18n.t('massSheets:create:field:date'),
            md: 6
          },
          {
            type: 'text',
            field: 'subname',
            label: i18n.t('massSheets:create:field:subname'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'autocomplete',
            field: 'parish',
            label: i18n.t('massSheets:create:field:parish'),
            md: 6,
            options: []
          },
          {
            type: 'richText',
            field: 'introduction',
            label: i18n.t('massSheets:create:field:introduction')
          },
          {
            type: 'massSheet',
            field: 'massSheetSong',
            label: i18n.t('massSheets:create:field:massSong'),
            options: []
          },
          {
            type: 'richText',
            field: 'informations',
            label: i18n.t('massSheets:create:field:informations')
          }
        ]
      },
      {
        label: i18n.t('massSheets:create:tabs:celebrations'),
        data: [
          {
            type: 'celebrationPreview'
          }
        ]
      },
      {
        label: i18n.t('massSheets:create:tabs:compact massSheet'),
        data: [
          {
            type: 'massSheetPreview',
            compact: true
          }
        ]
      },
      {
        label: i18n.t('massSheets:create:tabs:complete massSheet'),
        data: [
          {
            type: 'massSheetPreview',
            compact: false
          }
        ]
      }
    ]
  }
}

const formatEntities = entities => {
  return (
    entities.map(entity => {
      return formatEntity(entity)
    }) || null
  )
}

const formatEntity = entity => {
  entity.date = formatDate(entity.date)
  return entity
}

const Component = ({
  setMassSheets,
  isSubscriber,
  songList,
  celebrationList,
  setCelebrationList
}) => {
  const [isLoading, setIsLoading] = useState()

  useEffect(() => {
    if (isSubscriber) {
      // reset massSheets saved
      setMassSheets()
      apiConsumer.get(`parishes/responsible`, false).then(rep => {
        if (rep.status === 200) {
          params.formStruct.elements.forEach(param => {
            let id = param.data.findIndex(el => el.field === 'parish') // id of element
            if (id !== -1) {
              param.data[id].options = [
                { id: 0, name: i18n.t('massSheets:create:no attached') },
                ...rep.body
              ]
            }
          })
        }
      })
    }
  }, [setMassSheets, isSubscriber, songList])

  useEffect(() => {
    if (!isLoading && !celebrationList?.length) {
      setIsLoading(true)
      apiConsumer.get(`celebrations`).then(rep => {
        setCelebrationList(rep.status === 200 ? rep.body : [])
      })
      return () => setCelebrationList([])
    }
  }, [setCelebrationList, celebrationList, isLoading])

  return !isSubscriber ? (
    <NeedSubscribe message={i18n.t('app:error:bad massSheet edit access')} />
  ) : (
    <>
      <NavBar title={i18n.t(`app:massSheets:my massSheets`)} backArrow />
      <CrudPage
        params={params}
        formatEntities={formatEntities}
        formatEntity={formatEntity}
      />
    </>
  )
}

export default connect(
  state => ({
    isSubscriber: checkIsSubscriber(state.setting?.user.role),
    songList: state.setting.appDatas?.song,
    celebrationList: state.setting.celebrationList || []
  }),
  dispach => ({
    setMassSheets: value =>
      dispach(setSettingStoreValueAction('massSheets', value)),
    setCelebrationList: value =>
      dispach(setSettingStoreValueAction('celebrationList', value))
  })
)(Component)
