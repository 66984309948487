import React from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import { formatDate, addDaysToDate } from '../../../service/functions'
import NavBar from '../../../core/NavBar'

const now = formatDate(new Date(), true)

const params = {
  entityName: 'appNotifications',
  targetUrl: 'app_notifications',
  mainField: 'name',
  defaultEntity: {
    id: 0,
    name: '',
    text: '',
    isEnable: true,
    beginAt: now,
    endAt: addDaysToDate(now, 15)
  },
  datagridOptions: {
    rights: {
      show: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      edit: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      delete: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('appNotifications:field:id'),
      width: 30,
      hide: true
    },
    {
      field: 'name',
      headerName: i18n.t('appNotifications:field:name'),
      width: 200,
      main: true
    },
    {
      field: 'isEnable',
      headerName: i18n.t('appNotifications:field:is enable'),
      width: 100,
      type: 'boolean'
    },
    {
      field: 'beginAt',
      headerName: i18n.t('appNotifications:field:begin at'),
      width: 100
    },
    {
      field: 'endAt',
      headerName: i18n.t('appNotifications:field:end at'),
      width: 100
    }
  ],
  formStruct: {
    size: {
      width: 'xs',
      fullHeight: false
    },
    elements: [
      {
        type: 'text',
        required: true,
        field: 'name',
        label: i18n.t('appNotifications:field:name'),
        rules: {
          min: 3,
          max: 255,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'textarea',
        required: true,
        field: 'text',
        label: i18n.t('appNotifications:field:text'),
        rules: {
          min: 3,
          max: 800,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'switch',
        field: 'isEnable',
        label: i18n.t('appNotifications:field:is enable')
      },
      {
        type: 'date',
        required: true,
        field: 'beginAt',
        label: i18n.t('appNotifications:field:begin at'),
        minDate: now,
        md: 6
      },
      {
        type: 'date',
        required: true,
        field: 'endAt',
        label: i18n.t('appNotifications:field:end at'),
        minDate: now,
        md: 6,
        rules: {
          minField: 'beginAt',
          minFieldMessage: i18n.t('error:date less than field', {
            name: i18n.t('appNotifications:field:begin at')
          })
        }
      }
    ]
  }
}

const formatEntities = entities => {
  return (
    entities.map(entity => {
      return formatEntity(entity)
    }) || null
  )
}

const formatEntity = entity => {
  entity.beginAt = formatDate(entity.beginAt)
  entity.endAt = formatDate(entity.endAt)
  return entity
}

export default function DataTable () {
  return (
    <>
      <NavBar title={i18n.t(`menu:${params.entityName}`)} />
      <CrudPage
        params={params}
        formatEntities={formatEntities}
        formatEntity={formatEntity}
      />
    </>
  )
}
