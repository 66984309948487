import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { checkLogin } from '../../service/api/authValidation'
import Drawer from '../../core/Drawer'
import { connect } from 'react-redux'
import appStyle from '../../assets/jss/globalAppStyle'
import InitialGuide from '../../components/guide/InitialGuide'

const useStyles = makeStyles(theme => appStyle(theme))

const Application = ({ children, drawerReduced }) => {
  const classes = useStyles()

  // check if user has right
  useEffect(() => {
    checkLogin(true)
  }, [])

  return (
    <div className={classes.root}>
      <Drawer />
      <div className={drawerReduced ? classes.fullContent : classes.content}>
        {children}
      </div>
      <InitialGuide />
    </div>
  )
}

Application.propTypes = {
  children: PropTypes.node,
  drawerReduced: PropTypes.bool
}

const ApplicationStore = connect(state => ({
  drawerReduced: state.setting.drawerReduced
}))(Application)

export default ApplicationStore
