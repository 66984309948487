import React, { useMemo } from 'react'
import { Drawer, Hidden, SwipeableDrawer } from '@mui/material'
import BgImage from '../assets/images/drawer-bg.jpg'
import { drawerWidth } from '../assets/jss/globalAppVariables'
import {
  setSettingStoreValueAction,
  toggleFieldAction
} from '../store/setting/settingStoreAction'
import { connect } from 'react-redux'
import DrawerMenuList from '../components/menu/DrawerMenuList'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

// global menu

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    backgroundImage: `url(${BgImage})!important`,
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    width: drawerWidth
  },
  drawerEffect: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: 'rgba(27,27,27,.60)',
    overflowY: 'hidden',
    paddingBottom: theme.spacing(10)
  }
}))

const container = window !== undefined ? () => window.document.body : undefined

const _Drawer = ({
  menus = [],
  setAppMenu,
  drawer,
  toggleDrawer,
  drawerReduced,
  pageType
}) => {
  const classes = useStyles()

  const DrawerList = useMemo(
    () => (
      <DrawerMenuList
        menus={menus}
        setAppMenu={pageType === 'admin' ? () => {} : setAppMenu}
      />
    ),
    [menus, setAppMenu, pageType]
  )

  return (
    <>
      <Hidden mdUp={!drawerReduced} implementation='css'>
        <SwipeableDrawer
          id='drawer'
          container={container}
          variant='temporary'
          anchor='left'
          aria-label='drawer'
          open={drawer}
          onClose={() => {} } //toggleDrawer(false)
          onOpen={() => toggleDrawer(true)}
          classes={{
            paper: classes?.drawerPaper
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          onClick={() => toggleDrawer(false)}
        >
          <div className={classes.drawerEffect}>{DrawerList}</div>
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown mdUp={drawerReduced} implementation='css'>
        <Drawer
          id='drawer'
          variant='permanent'
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerEffect}>{DrawerList}</div>
        </Drawer>
      </Hidden>
    </>
  )
}

_Drawer.propTypes = {
  menus: PropTypes.array,
  setAppMenu: PropTypes.func,
  drawer: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  drawerReduced: PropTypes.bool,
  pageType: PropTypes.string
}

export default connect(
  (state, ownProps) => ({
    drawer: state.setting.drawer,
    drawerReduced: state.setting.drawerReduced,
    menus: ownProps.menus || state.setting.appMenu
  }),
  dispach => ({
    toggleDrawer: value => dispach(toggleFieldAction('drawer', value)),
    setAppMenu: value => {
      if (value !== null) dispach(setSettingStoreValueAction('appMenu', value))
    }
  })
)(_Drawer)
