import React, { Component } from 'react'
import {
  OpenSheetMusicDisplay as OSMD,
  TransposeCalculator
} from 'opensheetmusicdisplay'
import Loading from '../../../core/Loading'
import { Typography } from '@mui/material'
import MusicSheetSettings from './MusicSheetSettings'
import { connect } from 'react-redux'
import { setSettingStoreValueAction } from '../../../store/setting/settingStoreAction'
import i18n from '../../../i18n'
import apiConsumer from '../../../service/api/apiConsumer'
import ErrorPage from '../../../pages/error/ErrorPage'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'

const styles = theme => ({
  root: {
    '& *': {
      userSelect: 'none'
    }
  }
})

class OpenSheetMusicDisplay extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: 0,
      dataReady: false,
      instruments: [],
      transposeError: false
    }
    this.osmd = undefined
    this.divRef = React.createRef()

    this.setZoom = this.setZoom.bind(this)
    this.setVisibilityInstrument = this.updateInstrumentsVisibility.bind(this)
    this.updateOSMD = this.updateOSMD.bind(this)

    this.osmdOptions = {
      autoResize: true,
      drawTitle: false,
      drawComposer: false,
      // drawSubtitle:false,
      // drawLyricist:false,
      drawingParameters: this.props.osmdSettings.mode,
      backend: 'svg',
      defaultFontFamily: 'Helvetica',
      colorStemsLikeNoteheads: true
    }
    this.setColorDarkTheme(this.props.darkTheme)
  }

  setupOsmd () {
    this.osmd = new OSMD(this.divRef.current, this.osmdOptions)
    this.osmd.TransposeCalculator = new TransposeCalculator()
    var reader = new FileReader()
    reader.onload = e => {
      this.osmd.load(e.target.result).then(() => {
        this.setZoom(this.props.osmdSettings.zoom, false)
        this.transpose(this.props.osmdSettings.transpose, false)
        this.osmd.render()
        const instruments = this.initInstruments()
        this.osmd.render()

        this.setState(n => ({
          ...n,
          dataReady: true,
          title: this.osmd.sheet?.title?.text,
          composer: this.osmd.sheet?.composer?.text,
          instruments
        }))
      })
    }
    apiConsumer.download(this.props.src, true).then(r => {
      if (r.status) {
        this.setState(n => ({
          ...n,
          error: r.status
        }))
      } else {
        reader.readAsBinaryString(r)
      }
    })
  }

  initInstruments () {
    let instruments = []
    if (this.osmd?.sheet?.instruments) {
      let count = 0
      instruments = this.osmd.sheet.instruments.map((e, idx) => {
        if (
          this.props.osmdSettings.instruments[
            e.nameLabel.text.toLowerCase()
          ] === false
        ) {
          this.osmd.sheet.instruments[idx].Visible = false
          count++
          return { name: e.nameLabel.text, visible: false }
        }
        return { name: e.nameLabel.text, visible: true }
      })
      if (count === instruments.length && count !== 0) {
        this.setInstrumentVisible(0)
      }
    }
    return instruments
  }

  setInstrumentVisible (idx) {
    const tmp = { ...this.props.osmdSettings.instruments }
    tmp[this.osmd.sheet.instruments[idx].nameLabel.text.toLowerCase()] = true
    this.props.setOsmdSettings({
      ...this.props.osmdSettings,
      instruments: tmp
    })
  }

  updateInstrumentsVisibility (instruments, render = true) {
    if (this.osmd?.sheet?.Instruments) {
      this.osmd.sheet.Instruments.forEach((e, idx) => {
        if (instruments[e.nameLabel.text.toLowerCase()] === false) {
          this.osmd.sheet.Instruments[idx].Visible = false
        } else {
          this.osmd.sheet.Instruments[idx].Visible = true
        }
      })
    }
    if (render) this.osmd.render()
  }

  setZoom (value, render = true) {
    this.osmd.zoom = value > 0.1 && value < 1.5 ? value : 0.75
    if (render) this.osmd.render()
  }

  setDrawingMode (value, render = true) {
    this.osmdOptions = { ...this.osmdOptions, drawingParameters: value }
    this.osmd.setOptions(this.osmdOptions)
    if (render) this.osmd.render()
  }

  setColorDarkTheme (darkTheme) {
    if (darkTheme) {
      const color = '#ffffff80'
      this.osmdOptions = {
        ...this.osmdOptions,
        defaultColorNotehead: color,
        defaultColorLabel: color,
        defaultColorStem: color,
        defaultColorRest: color
      }
    }
  }

  transpose (value = 0, render = true) {
    this.osmd.Sheet.Transpose = parseInt(value)
    try {
      this.osmd.updateGraphic()
    } catch (error) {
      console.error('transpose error:', error)
      this.setState(n => ({
        ...n,
        transposeError: true
      }))
    }
    if (render) this.osmd.render()
  }

  cursorShow () {
    this.osmd.cursor.show()
  }

  cursorHide () {
    this.osmd.cursor.hide()
  }

  cursorStart () {
    this.cursorInterval = setInterval(() => {
      this.osmd.cursor.next()
      if (!this.osmd.cursor?.Iterator?.CurrentVoiceEntries?.length) {
        this.cursorStop()
      } else {
        // console.info(this.osmd.cursor.Iterator.CurrentVoiceEntries[0].Notes[0].Pitch?.ToString())
      }
    }, this.osmd.sheet.defaultStartTempoInBpm - 500)
  }

  cursorStop () {
    if (this.cursorInterval) clearInterval(this.cursorInterval)
  }

  componentDidUpdate (prevProps) {
    if (this.props.osmdSettings.zoom !== prevProps.osmdSettings.zoom) {
      this.setZoom(this.props.osmdSettings.zoom)
    }
  }

  componentWillUnmount () {
    this.cursorStop()
  }

  componentDidMount () {
    this.setupOsmd()
  }

  updateOSMD () {
    this.transpose(this.props.osmdSettings.transpose, false)
    this.setDrawingMode(this.props.osmdSettings.mode, false)
    this.updateInstrumentsVisibility(this.props.osmdSettings.instruments)
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <MusicSheetSettings
          instruments={this.state.instruments}
          disableTranspose={this.state.transposeError}
          onClose={this.updateOSMD}
        />
        <Typography variant='h5' align='center'>
          {this.state.title}
        </Typography>
        <Typography variant='body1' align='center' color='textSecondary'>
          {this.state.composer}
        </Typography>

        <Typography
          variant='body2'
          style={{ marginLeft: 56, marginTop: 16, height: 20 }}
        >
          {this.props.osmdSettings.transpose &&
          this.state.instruments.length &&
          !this.state.transposeError
            ? i18n.t('app:musicSheet:transposition', {
                value: this.props.osmdSettings.transpose
              })
            : null}
        </Typography>

        {this.state.error ? (
          <ErrorPage
            status={
              this.state.error === 404
                ? i18n.t('app:error:music sheet not found')
                : i18n.t('app:error:default')
            }
          />
        ) : !this.state.dataReady ? (
          <Loading text />
        ) : null}
        <div
          ref={this.divRef}
          style={{ width: 'calc(100% - 8px)', margin: 0 }}
        />
      </div>
    )
  }
}

OpenSheetMusicDisplay.propTypes = {
  /** url */
  src: PropTypes.string.isRequired,
  /** stored settings */
  osmdSettings: PropTypes.object.isRequired,
  /** dark theme to have the best note color */
  darkTheme: PropTypes.bool
}

const OpenSheetMusicDisplayStyled = withStyles(styles)(OpenSheetMusicDisplay)

export default connect(
  state => ({
    darkTheme: state.setting.darkTheme,
    osmdSettings: state.setting.osmdSettings
  }),
  dispach => ({
    setOsmdSettings: value =>
      dispach(setSettingStoreValueAction('osmdSettings', value))
  })
)(OpenSheetMusicDisplayStyled)
