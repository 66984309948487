import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { Paper, IconButton, Typography, Stack, Slider } from '@mui/material'
import {
  PlayArrow,
  Pause,
  VolumeDown,
  VolumeUp,
  VolumeOff
} from '@mui/icons-material'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '8px 8px 8px 8px',
    margin: '8px 0 0px 0'
  },
  volumeSlider: {
    width: '100px!important',
    margin: '0px 16px 0px 8px!important',
    '& .MuiSlider-thumb': {
      width: 12,
      height: 12
    }
  },
  progressSlider: {
    margin: '0px 8px 0 16px!important',
    height: 4,
    '& .MuiSlider-thumb': {
      width: 8,
      height: 8,
      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
      '&:before': {
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)'
      },
      '&:hover, &.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 8px ${
          theme.palette.mode === 'dark'
            ? 'rgb(255 255 255 / 16%)'
            : 'rgb(0 0 0 / 16%)'
        }`
      },
      '&.Mui-active': {
        width: 20,
        height: 20
      }
    },
    '& .MuiSlider-rail': {
      opacity: 0.28
    }
  }
}))

const fmtMSS = s => (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s

const AudioPlayer = ({ src }) => {
  const classes = useStyles()
  const [isPlaying, setisPlaying] = useState(false)
  const [sound, setSound] = useState(1)
  const [time, setTime] = useState(0)
  const [inter, setInter] = useState()

  const audioRef = useRef(new Audio(src))

  const setPause = useCallback(() => {
    audioRef.current.pause()
    clearInterval(inter)
    setisPlaying(false)
  }, [setisPlaying, inter])

  const timer = useCallback(() => {
    setTime(audioRef.current.currentTime)
    if (audioRef.current.ended) {
      audioRef.current.currentTime = 0
      setPause()
    }
  }, [setTime, setPause])

  const setPlay = useCallback(() => {
    audioRef.current.play()
    setInter(setInterval(timer, 100))
    setisPlaying(true)
  }, [setisPlaying, setInter, timer])

  useEffect(() => {
    const i = setInterval(() => {
      if (audioRef.current.duration) {
        timer(0)
        clearInterval(i)
      }
    }, 900)
    return () => clearInterval(i)
  }, [src, timer])

  const setAudioTime = useCallback(
    value => {
      audioRef.current.currentTime = value
      timer(value)
    },
    [audioRef, timer]
  )

  const setAudioSound = useCallback(
    value => {
      audioRef.current.volume = value
      setSound(value)
    },
    [audioRef, setSound]
  )

  useEffect(() => {
    return () => (isPlaying ? setPause() : null)
  }, [isPlaying, setPause])

  return (
    <Paper className={classes.root} elevation={0}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <IconButton onClick={isPlaying ? setPause : setPlay} color='primary'>
          {isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>
        <SoundButton sound={sound} setSound={setAudioSound} />
        <Typography>{fmtMSS(parseInt(time))}</Typography>
        <Slider
          value={time}
          min={0}
          step={0.01}
          max={audioRef.current.duration || 1}
          onChange={(_, value) => setAudioTime(value)}
          className={classes.progressSlider}
        />
        <Typography>
          {fmtMSS(parseInt(audioRef.current.duration || 0))}
        </Typography>
      </Stack>
    </Paper>
  )
}

AudioPlayer.propTypes = {
  src: PropTypes.string
}

export default AudioPlayer

const SoundButton = ({ sound, setSound }) => {
  const classes = useStyles()
  const [soundOpen, setSoundOpen] = useState(false)

  return (
    <>
      <IconButton onClick={() => setSoundOpen(n => !n)} color='primary'>
        {sound === 0 ? (
          <VolumeOff />
        ) : sound < 0.4 ? (
          <VolumeDown />
        ) : (
          <VolumeUp />
        )}
      </IconButton>
      {soundOpen ? (
        <Slider
          className={classes.volumeSlider}
          min={0}
          step={0.01}
          max={1}
          value={sound}
          onChange={(_, v) => setSound(v)}
        />
      ) : null}
    </>
  )
}
