import React, { useMemo } from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import apiConsumer from '../../../service/api/apiConsumer'
import { formatDate, sortFrenchDates } from '../../../service/functions'
import NavBar from '../../../core/NavBar'

const params = {
  entityName: 'songs',
  targetUrl: 'songs',
  mainField: 'name',
  defaultEntity: {
    name: '',
    alias: '',
    number: 0,
    description: '',
    author: '',
    releasedAt: 0,
    tones: '',
    bpm: 0,
    music: false,
    mlxIntro: true,
    musicUrl: '',
    category: null,
    language: null,
    secliLicence: '',
    secliId: 0,
    licence: '',
    metaDescription: '',
    metaKeyword: '',
    published: false,
    content: {
      text: [
        {
          name: 'Refrain',
          text: ''
        }
      ]
    },
    tag: [],
    solemnity: [],
    liturgicalTime: [],
    liturgicalMoment: [],
    alpId: 0,
    introXML: null
  },
  datagridOptions: {
    rights: {
      show: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      edit: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      delete: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    columnsOptions: {
      disableColumnMenu: false
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('songs:field:id'),
      width: 60,
      hide: true
    },
    {
      field: 'number',
      headerName: i18n.t('songs:field:number'),
      width: 110
    },
    {
      field: 'name',
      headerName: i18n.t('songs:field:song'),
      width: 300,
      main: true
    },
    {
      field: 'category',
      headerName: i18n.t('songs:field:category'),
      width: 150
    },
    {
      field: 'publishedName',
      headerName: i18n.t('songs:field:published'),
      width: 80
    },
    {
      field: 'published',
      type: 'boolean',
      headerName: i18n.t('songs:field:published')
    },
    {
      field: 'publishedAt',
      headerName: i18n.t('songs:field:publishedAt'),
      width: 130,
      sortComparator: (v1, v2, p1, p2) => sortFrenchDates(v1, v2)
    }
  ],
  formStruct: {
    size: {
      width: 'lg',
      fullHeight: true
    },
    type: 'tabs',
    elements: [
      {
        label: i18n.t('songs:headers:header'),
        data: [
          {
            type: 'text',
            required: true,
            field: 'name',
            label: i18n.t('songs:field:name'),
            md: 12,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'number',
            required: true,
            field: 'number',
            label: i18n.t('songs:field:number'),
            md: 4,
            rules: {
              minVal: 0,
              minValMessage: i18n.t(
                'error:value must be greater than or equal to zero'
              )
            }
          },
          {
            type: 'text',
            field: 'tones',
            label: i18n.t('songs:field:tones'),
            md: 4,
            rules: {
              max: 15,
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'number',
            field: 'bpm',
            label: i18n.t('songs:field:bpm'),
            md: 4,
            rules: {
              minVal: 0,
              minValMessage: i18n.t(
                'error:value must be greater than or equal to zero'
              )
            }
          },
          {
            type: 'autocomplete',
            field: 'language',
            label: i18n.t('songs:field:language'),
            md: 6,
            options: []
          },
          {
            type: 'autocomplete',
            field: 'category',
            label: i18n.t('songs:field:category'),
            md: 6,
            options: []
          },
          {
            type: 'textarea',
            rowsMax: 5,
            required: false,
            field: 'description',
            label: i18n.t('songs:field:description'),
            md: 12,
            rules: {
              min: 3,
              minMessage: i18n.t('error:the text is too short'),
              max: 800,
              maxMessage: i18n.t('error:the text is too long')
            }
          }
        ]
      },
      {
        label: i18n.t('songs:headers:right'),
        data: [
          {
            type: 'text',
            required: false,
            field: 'author',
            label: i18n.t('songs:field:author'),
            md: 6,
            rules: {
              min: 3,
              max: 255,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'number',
            required: false,
            field: 'releasedAt',
            label: i18n.t('songs:field:releasedAt'),
            md: 6,
            rules: {
              minVal: 0,
              minValMessage: i18n.t(
                'error:value must be greater than or equal to zero'
              )
            }
          },
          {
            type: 'text',
            required: false,
            field: 'secliLicence',
            label: i18n.t('songs:field:secliLicence'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'number',
            required: false,
            field: 'secliId',
            label: i18n.t('songs:field:secliId'),
            md: 6,
            rules: {
              minVal: 0,
              minValMessage: i18n.t(
                'error:value must be greater than or equal to zero'
              )
            }
          },
          {
            type: 'text',
            required: false,
            field: 'licence',
            label: i18n.t('songs:field:licence'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          }
        ]
      },
      {
        label: i18n.t('songs:headers:details'),
        data: [
          {
            type: 'autocomplete',
            multiple: true,
            field: 'tag',
            label: i18n.t('songs:field:tag'),
            md: 12,
            options: []
          },
          {
            type: 'autocomplete',
            multiple: true,
            field: 'liturgicalMoment',
            label: i18n.t('songs:field:liturgicalMoment'),
            md: 12,
            options: []
          },
          {
            type: 'autocomplete',
            multiple: true,
            field: 'liturgicalTime',
            label: i18n.t('songs:field:liturgicalTime'),
            md: 12,
            options: []
          },
          {
            type: 'autocomplete',
            multiple: true,
            field: 'solemnity',
            label: i18n.t('songs:field:solemnity'),
            md: 12,
            options: []
          }
        ]
      },
      {
        label: i18n.t('songs:headers:song'),
        data: [
          {
            type: 'song',
            field: 'content'
          }
        ]
      },
      {
        label: i18n.t('songs:headers:files'),
        data: [
          {
            type: 'text',
            field: 'musicUrl',
            label: i18n.t('songs:field:musicUrl'),
            md: 6
          },
          {
            type: 'youtubePreview',
            field: 'musicUrl',
            preview: 'video',
            label: '',
            md: 6
          },
          { type: 'spacer' },
          {
            type: 'songfiles',
            field: 'files',
            uploadTarget: 'songs/{id}/file',
            target: 'song_files',
            options: [],
            md: 12
          }
        ]
      },
      {
        label: i18n.t('songs:headers:publication'),
        data: [
          {
            type: 'textarea',
            rowsMax: 5,
            required: false,
            field: 'metaKeyword',
            label: i18n.t('songs:field:metaKeyword'),
            md: 6,
            rules: {
              min: 3,
              minMessage: i18n.t('error:the text is too short')
            }
          },
          {
            type: 'textarea',
            rowsMax: 5,
            required: false,
            field: 'metaDescription',
            label: i18n.t('songs:field:metaDescription'),
            md: 6,
            rules: {
              min: 3,
              minMessage: i18n.t('error:the text is too short')
            }
          },
          // {
          //   type: 'number',
          //   field: 'alpId',
          //   label: i18n.t('songs:field:alpId'),
          //   md: 6,
          //   rules: {
          //     minVal: 0,
          //     minValMessage: i18n.t(
          //       'error:value must be greater than or equal to zero'
          //     )
          //   }
          // },
          {
            type: 'switch',
            field: 'published',
            label: i18n.t('songs:field:published'),
            md: 6
          }
        ]
      }
    ]
  }
}

const formatEntities = entities => {
  return (
    entities.map(entity => {
      return formatEntity(entity)
    }) || null
  )
}

const formatEntity = entity => {
  entity.category = entity.category ? entity.category.name : null
  entity.publishedName = entity.published
    ? i18n.t('main:yes')
    : i18n.t('main:no')
  entity.musicUrl = entity.content ? entity.content.musicUrl : ''
  entity.alpId = entity.content ? entity.content.alpId : ''
  entity.publishedAt = formatDate(entity.publishedAt)
  return entity
}

const formatCRUDEntity = entity => {
  entity.musicUrl = entity.content ? entity.content.musicUrl : ''
  entity.alpId = entity.content ? entity.content.alpId : ''
  entity.audioPath = entity.content ? entity.content.audioPath : ''
  entity.musicXmlPath = entity.content ? entity.content.musicXmlPath : ''
  return entity
}

export default function DataTable () {
  useMemo(async () => {
    const rep = await apiConsumer.get(`song-form-content`)
    if (rep.status === 200) {
      Object.keys(rep.body).forEach(entity => {
        params.formStruct.elements.forEach(subElements => {
          let id = subElements.data.findIndex(el => el.field === entity) // id of element
          if (id !== -1) {
            subElements.data[id].options = rep.body[entity]
          }
        })
      })
    }
  }, [])

  return (
    <>
      <NavBar title={i18n.t(`menu:${params.entityName}`)} />
      <CrudPage
        params={params}
        formatEntities={formatEntities}
        formatEntity={formatEntity}
        formatCRUDEntity={formatCRUDEntity}
      />
    </>
  )
}
