import React, { useState } from 'react'
import {
  Button,
  Card,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Stack
} from '@mui/material'
import i18n from '../../i18n'
import httpConstantes from '../../config/httpConstantes'
import { makeStyles } from '@mui/styles'
import CreateChat from '../chat/CreateChat'
import { setSnackbarAction } from '../../store/setting/settingStoreAction'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%!important',
    margin: '0!important',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-8px!important'
    }
  },
  card: {
    maxWidth: 600
  },
  card2: {
    maxWidth: 600,
    float: 'right',
    width: '50%',
    margin: theme.spacing(0, 0, 2, 2) + '!important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(1, 0, 2, 0) + '!important'
    }
  },
  media: {
    maxWidth: 600,
    height: '300px',
    [theme.breakpoints.down('sm')]: {
      height: '200px'
    }
  },
  chatButton: {
    marginTop: theme.spacing(2) + '!important'
  }
}))

const DisplayParishInfos = ({ parish, setSnackbar, userIsConnected }) => {
  const classes = useStyles()
  const [newChatOpen, setNewChatOpen] = useState(false)

  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        {parish.picture ? (
          <Grid item md={6} xs={12}>
            <Card key={parish.id} className={classes.card}>
              <CardMedia
                className={classes.media}
                image={`${httpConstantes.apiUrl}/parishes-picture/${parish.id}`}
                title={parish.name}
              />
            </Card>
          </Grid>
        ) : null}
        <Grid item md={6} xs={12}>
          <Typography variant='h6'>
            {i18n.t(`app:parishes:informations`)}
          </Typography>
          <List dense>
            {['email', 'phone'].map(e => (
              <Item
                key={e}
                primary={i18n.t(`app:parishes:${e}`)}
                secondary={parish[e]}
              />
            ))}
            <Item
              primary={i18n.t(`app:parishes:website`)}
              secondary={
                <a href={parish.website} target='blank' rel='noreferrer'>
                  {parish.website}
                </a>
              }
            />
            <Item
              primary={i18n.t(`app:parishes:adress`)}
              secondary={`${parish.adress}, ${parish.city.postcode} - ${parish.city.name}, ${parish.city.country}`}
            />
            <Item
              primary={
                i18n.t(`app:parishes:priests`) +
                ((parish.priests?.match(/,/g) || [])?.length > 0 ? 's' : '')
              }
              secondary={parish.priests}
            />
            <Item
              primary={
                i18n.t(`app:parishes:responsible`) +
                (parish.responsibles?.length > 1 ? 's' : '')
              }
              secondary={parish.responsibles
                .map(e => `${e.firstname} ${e.lastname}`)
                .join(', ')}
            />
          </List>
          {!parish.isResponsible && userIsConnected && (
            <Stack direction='row' justifyContent='center'>
              <Button
                variant='outlined'
                color='primary'
                className={classes.chatButton}
                onClick={() => setNewChatOpen(true)}
              >
                {i18n.t(`app:parishes:create chat`)}
              </Button>
              <CreateChat
                open={newChatOpen}
                onClose={() => setNewChatOpen(false)}
                onValid={() => {
                  setNewChatOpen(false)
                  setSnackbar(i18n.t('chat:message sended'), 'success')
                }}
                disableOptions
                parish={parish.id}
              />
            </Stack>
          )}
        </Grid>
        <Grid item md={parish.picture ? 12 : 12} xs={12}>
          {parish.picture ? (
            <Card key={parish.id} className={classes.card2}>
              <CardMedia
                className={classes.media}
                image={`${httpConstantes.apiUrl}/parishes-picture2/${parish.id}`}
                title={parish.name}
              />
            </Card>
          ) : null}
          <Typography
            color='text.secondary'
            style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}
          >
            {parish.comment}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default connect(
  state => ({
    userIsConnected: !!state.setting?.user
  }),
  dispach => ({
    setSnackbar: (message, type, time) =>
      dispach(setSnackbarAction(message, type, time))
  })
)(DisplayParishInfos)

const Item = ({ primary, secondary }) => (
  <ListItem>
    <ListItemText
      secondary={
        <>
          <Typography color='textPrimary' component='span'>
            {primary + '\u00a0: '}
          </Typography>
          {secondary}
        </>
      }
    />
  </ListItem>
)
