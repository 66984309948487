import React, { useState, useCallback, useEffect } from 'react'
import { Badge, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { displayDate, formatTime } from '../../service/functions'
import { Timeline, TimelineOppositeContent } from '@mui/lab'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { Check, Close } from '@mui/icons-material'
import clsx from 'clsx'
import i18n from '../../i18n'
import apiConsumer from '../../service/api/apiConsumer'
import { connect } from 'react-redux'
import { setSnackbarAction } from '../../store/setting/settingStoreAction'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  timeline: {
    margin: 0,
    padding: '0!important',
    paddingTop: '24px!important',
    width: '100%',
    '& *': {
      userSelect: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '60px!important',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '100px!important'
      }
    }
  },
  header: {
    maxWidth: 110,
    paddingTop: '14px!important'
  },
  item: {
    maxWidth: 400,
    padding: theme.spacing(1, 0),
    cursor: 'pointer'
  },
  itemSelected: {
    padding: theme.spacing(1, 1),
    cursor: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  },
  itemComment: {
    paddingLeft: theme.spacing(2)
  },
  itemBadge: {
    '& .MuiBadge-badge': {
      right: -10,
      top: 0
    }
  },
  itemCorrectBox: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}))

const formatMassTimes = massTimes => {
  if (massTimes) {
    let lastUpdate = new Date('1970-01-01')
    let obj = {}
    massTimes.forEach(mt => {
      if (lastUpdate.getTime() < new Date(mt.updatedAt).getTime()) {
        lastUpdate = new Date(mt.updatedAt)
      }
      if (obj[mt.category.name]) {
        obj[mt.category.name] = [...obj[mt.category.name], { ...mt }]
      } else {
        obj[mt.category.name] = [{ ...mt }]
      }
    })
    return Object.keys(obj).length ? [obj, lastUpdate] : null
  }
}

const DisplayMassTimes = ({ massTimes, userIsConnected, setSnackbar }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState()
  const [formatedMassTimes, setFormatedMassTimes] = useState()
  const [updatedDate, setUpdatedDate] = useState()

  useEffect(() => {
    const rep = formatMassTimes(massTimes)
    if (rep) {
      setFormatedMassTimes(rep[0])
      setUpdatedDate(rep[1])
    }
    return () => {
      setFormatedMassTimes()
      setUpdatedDate()
    }
  }, [massTimes, setFormatedMassTimes])

  const vote = useCallback(
    (key, idx, id, value) => {
      apiConsumer
        .request('POST', `mass_times/vote/${id}/${value ? 'true' : 'false'}`)
        .then(rep => {
          if (rep.status === 200) {
            setFormatedMassTimes(e => {
              e[key][idx].wrongVotes += value ? -1 : 1
              return { ...e }
            })
          } else if (rep.status === 400) {
            if (value) {
              setSnackbar(i18n.t('app:massTimes:vote no error'), 'success')
            } else {
              setSnackbar(i18n.t('app:massTimes:vote is error'), 'info')
            }
          } else {
            setSnackbar(i18n.t('error:default'), 'error')
          }
        })
    },
    [setFormatedMassTimes, setSnackbar]
  )

  return (
    !!formatedMassTimes &&
    !!updatedDate && (
      <Grid item xs={12}>
        <Typography color='textSecondary' variant='caption'>
          {i18n.t('app:massTimes:last update on', {
            date: displayDate(updatedDate)
          })}
        </Typography>
        <Timeline className={classes.timeline}>
          {Object.keys(formatedMassTimes).map((key, idx) => {
            return (
              <TimelineItem key={idx}>
                <TimelineOppositeContent className={classes.header}>
                  {key}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {formatedMassTimes[key]
                    .sort(
                      (a, b) =>
                        new Date(a.beginTime).getTime() -
                        new Date(b.beginTime).getTime()
                    )
                    .map((mt, i) => (
                      <Item
                        key={i}
                        selected={mt.id === selected}
                        onClick={() =>
                          userIsConnected
                            ? setSelected(mt.id !== selected ? mt.id : null)
                            : null
                        }
                        onVote={v => vote(key, i, mt.id, v)}
                        mt={mt}
                      />
                    ))}
                </TimelineContent>
              </TimelineItem>
            )
          })}
        </Timeline>
      </Grid>
    )
  )
}

export default connect(
  state => ({
    userIsConnected: !!state.setting?.user
  }),
  dispach => ({
    setSnackbar: (message, type, time) =>
      dispach(setSnackbarAction(message, type, time))
  })
)(DisplayMassTimes)

const Item = ({ selected, mt, onVote, ...props }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.item, selected ? classes.itemSelected : null)}
      {...props}
    >
      <Typography className={classes.itemName}>
        {`${mt.name}${mt.name ? '\u00a0-\u00a0' : ''}${mt.day?.name ||
          displayDate(mt.date)}\u00a0: `}
        <Badge
          invisible={!mt.wrongVotes}
          className={classes.itemBadge}
          color='error'
          badgeContent={
            <Tooltip title={i18n.t('app:massTimes:noted incorrect')}>
              <Typography variant='caption'>{`-${mt.wrongVotes}`}</Typography>
            </Tooltip>
          }
        >
          <Typography color='primary' component='span'>{`${formatTime(
            mt.beginTime
          )}${
            mt.endTime ? `\u00a0-\u00a0${formatTime(mt.endTime)}` : ''
          }`}</Typography>
        </Badge>
      </Typography>
      {mt.comment ? (
        <Typography
          color='textSecondary'
          className={classes.itemComment}
          variant='caption'
        >
          {mt.comment}
        </Typography>
      ) : null}
      {selected ? (
        <>
          <div className={classes.itemCorrectBox}>
            <Typography component='span'>
              {i18n.t('app:massTimes:correct time')}
            </Typography>
            <IconButton onClick={() => onVote(true)}>
              <Check />
            </IconButton>
            <IconButton onClick={() => onVote(false)}>
              <Close />
            </IconButton>
          </div>
        </>
      ) : null}
    </div>
  )
}
