// form store actions

export const initStoreStateAction = state => ({
  type: 'INIT_STORE',
  payload: { ...state }
})

export const setStoreValuesAction = values => ({
  type: 'SET_STORE_VALUES',
  payload: { ...values }
})

export const setStoreValueAction = (value, valid, field) => ({
  type: 'SET_STORE_VALUE',
  payload: { value, valid, field }
})

export const setStoreValidAction = (valid, field) => ({
  type: 'SET_STORE_VALID',
  payload: { valid, field }
})

export const setStoreEditableAction = editable => ({
  type: 'SET_EDITABLE',
  payload: { editable }
})

export const setStoreElementVisibilityAction = (field, visible) => ({
  type: 'SET_ELEMENT_VISIBILITY',
  payload: { field, visible }
})
