import React, { useCallback, useRef, useState } from 'react'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import ReactPlayer from 'react-player'
import i18n from './../../i18n'
import PropTypes from 'prop-types'
import SongFilesList from './SongsFilesList'

const useStyles = makeStyles(theme => ({
  accordion: {
    backgroundColor: 'transparent!important',
    backgroundImage: 'none!important',
    boxShadow: 'none!important',
    width: '100%',
    marginTop: theme.spacing(5),
    padding: '0',
    '&::before': {
      background: 'transparent!important'
    },
    '& *': {
      userSelect: 'none'
    }
  },
  container: {
    width: '100%',
    marginBottom: theme.spacing(5)
  },
  infoList: {
    width: '100%'
  },
  heading: {
    padding: '0 8px 0 0',
    width: '100%',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular
  },
  typo: {
    fontSize: '14px',
    paddingVertical: theme.spacing(1)
  },
  button: {
    textTransform: 'none',
    fontSize: '14px',
    marginTop: -2,
    marginBottom: -2,
    width: '100%',
    justifyContent: 'left'
  },
  ytAccordion: {
    background: 'transparent!important',
    backgroundImage: 'none!important',
    boxShadow: 'none!important',
    width: '100%',
    padding: '0',
    '&::before': {
      background: 'transparent!important'
    }
  },
  ytAccordionSummary: {
    margin: '-8px -16px 0 -16px!important',
    paddingLeft: '8px !important'
  },

  ytPlayer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  chipList: {
    '& > *': {
      margin: theme.spacing(0.3, 0.3)
    }
  },
  divider: {
    margin: `10px 0 10px 0`
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

const DisplayInfos = ({ song }) => {
  const classes = useStyles()
  const accordionRef = useRef(null)

  const executeScroll = useCallback(
    (e, expanded) => {
      if (expanded) {
        const interval = setInterval(
          () => accordionRef.current.scrollIntoView(),
          10
        )
        setTimeout(() => clearInterval(interval), 500)
      }
    },
    [accordionRef]
  )

  return (
    <Accordion
      aria-label='song info'
      onChange={executeScroll}
      classes={{
        root: classes.accordion
      }}
      ref={accordionRef}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant='subtitle2'
          style={{ whiteSpace: 'pre-wrap' }}
          align='right'
          className={classes.heading}
        >
          {song.author}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container className={classes.container}>
          <SongInfos key='infos' song={song} classes={classes} />
          <SongFilesList key='files' list={song.files} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

DisplayInfos.propTypes = {
  song: PropTypes.object.isRequired
}

export default DisplayInfos

const infosList = [
  'category',
  'description',
  'tag',
  'liturgicalMoment',
  'liturgicalTime',
  'solemnity',
  'releasedAt',
  'copyright',
  'musicUrl'
]

const SongInfos = ({ song, classes }) => {
  song.copyright = [song.secliLicence, song.secliId, song.licence]
    .filter(Boolean)
    .join(' - ')

  const componentList = infosList
    .map(element =>
      Array.isArray(song[element]) && song[element].length ? (
        <>
          <span>
            <Typography color='textSecondary' variant='span'>
              {i18n.t(`app:song:${element}`) + ' : '}
            </Typography>
            <ChipList list={song[element]} classes={classes} />
          </span>
        </>
      ) : Array.isArray(song[element]) && !song[element].length ? null : song[
          element
        ] && song[element].name ? (
        <Typography className={classes.typo}>
          <Typography color='textSecondary' variant='span'>
            {i18n.t(`app:song:${element}`)} :
          </Typography>{' '}
          {song[element].name}
        </Typography>
      ) : element === 'musicUrl' && song.content.musicUrl ? (
        <YTPlayer src={song.content.musicUrl} classes={classes} />
      ) : song[element] ? (
        <Typography className={classes.typo}>
          <Typography color='textSecondary' variant='span'>
            {i18n.t(`app:song:${element}`) + ' : '}
          </Typography>
          {song[element]}
        </Typography>
      ) : null
    )
    .filter(Boolean)

  return (
    <List className={classes.infoList}>
      {componentList.map((element, i) =>
        element ? (
          <ListItem key={`sl-${i}`} divider={i !== componentList.length - 1}>
            <ListItemText primary={element} />
          </ListItem>
        ) : null
      )}
    </List>
  )
}

const YTPlayer = ({ src, classes }) => {
  const [enable, setEnable] = useState()
  return (
    <Accordion
      classes={{
        root: classes.ytAccordion
      }}
      onChange={() => setEnable(true)}
    >
      <AccordionSummary
        classes={{
          root: classes.ytAccordionSummary,
          content: classes.ytAccordionSummary
        }}
        // expandIcon={<ExpandMoreIcon />}
      >
        <ListItem button>{i18n.t(`app:song:musicUrl`)}</ListItem>
      </AccordionSummary>
      <AccordionDetails>
        {enable && ReactPlayer.canPlay(src) ? (
          <div className={classes.ytPlayer}>
            <ReactPlayer url={src} width='426px' height='240px' controls />
          </div>
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}

const ChipList = ({ list, classes }) => (
  <span className={classes.chipList}>
    {list.map((element, index) => (
      <Chip size='small' label={element.name} key={`sle-${index}`} />
    ))}
  </span>
)
