import React from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import PropTypes from 'prop-types'

const AppSwitch = ({ value, onChange, label }) => (
  <FormControlLabel
    control={
      <Switch
        checked={value}
        onChange={onChange}
        name='checkedB'
        color='primary'
      />
    }
    label={label || ''}
  />
)

AppSwitch.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node
}

export default AppSwitch
