const chords = {
  fr: [
    'Do',
    'Do#',
    'Réb',
    'Ré',
    'Ré#',
    'Mib',
    'Mi',
    'Fa',
    'Fa#',
    'Solb',
    'Sol',
    'Sol#',
    'Lab',
    'La',
    'La#',
    'Sib',
    'Si'
  ],
  en: [
    'C',
    'C#',
    'Db',
    'D',
    'D#',
    'Eb',
    'E',
    'F',
    'F#',
    'Gb',
    'G',
    'G#',
    'Ab',
    'A',
    'A#',
    'Bb',
    'B'
  ],
  level: {
    fr: {
      Do: 0,
      'Do#': 1,
      Réb: 1,
      Ré: 2,
      'Ré#': 3,
      Mib: 3,
      Mi: 4,
      Fa: 5,
      'Fa#': 6,
      Solb: 6,
      Sol: 7,
      'Sol#': 8,
      Lab: 8,
      La: 9,
      'La#': 10,
      Sib: 10,
      Si: 11
    },
    en: {
      C: 0,
      'C#': 1,
      Db: 1,
      D: 2,
      'D#': 3,
      Eb: 3,
      E: 4,
      F: 5,
      'F#': 6,
      Gb: 6,
      G: 7,
      'G#': 8,
      Ab: 8,
      A: 9,
      'A#': 10,
      Bb: 10,
      B: 11
    }
  },
  transpose: {
    fr: [
      'Do',
      'Do#',
      'Ré',
      'Mib',
      'Mi',
      'Fa',
      'Fa#',
      'Sol',
      'Sol#',
      'La',
      'Sib',
      'Si'
    ],
    en: ['C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B']
  }
}

export default chords
