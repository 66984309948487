import React from 'react'
import {
  ListSubheader,
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import i18n from '../../i18n'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  },
  list: {
    marginBottom: '100px!important'
  },
  note: {
    marginTop: theme.spacing(2)
  }
}))

const DisplayCelebration = ({ celebration, newPageLoading }) => {
  const classes = useStyles()

  return (
    !!celebration?.name && (
      <div className={classes.root}>
        <List subheader={<li />} className={classes.list}>
          <Details element='name' primary={celebration.name} />
          <Details element='description' primary={celebration.description} />
          <Details
            element='alleluiaVerse'
            primary={celebration.alleluiaVerse}
            secondary={celebration.alleluiaRef || 'jn 2,12'}
          />

          {celebration.celebrationSong.map((cs, i) => (
            <li key={i} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader disableSticky>{cs.header}</ListSubheader>
                {cs.songs.map((song, index) => (
                  <Link
                    key={index}
                    to={`/app/song-${song.id}-${song.name}`}
                    target={newPageLoading && 'blank'}
                  >
                    <ListItem button divider={index !== cs.songs.length - 1}>
                      <ListItemIcon className={classes.number}>
                        <Typography>{song.number}</Typography>
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography noWrap>{song.name}</Typography>}
                      />
                    </ListItem>
                  </Link>
                ))}
              </ul>
            </li>
          ))}
          <Details
            element='note'
            primary={celebration.note}
            className={classes.note}
          />
        </List>
      </div>
    )
  )
}

DisplayCelebration.propTypes = {
  /** celebrations */
  celebration: PropTypes.object
}

export default DisplayCelebration

const Details = ({ primary, secondary, element, className }) =>
  primary ? (
    <>
      <ListItem key={element} className={className}>
        <ListItemText
          primary={
            <>
              <Typography color='textSecondary' display='inline'>
                {i18n.t(`app:celebrations:${element}`) + ' : '}
              </Typography>
              {primary}
            </>
          }
          secondary={
            <Typography color='textSecondary' align='right'>
              {secondary}
            </Typography>
          }
        />
      </ListItem>
    </>
  ) : null
