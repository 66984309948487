import React from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import NavBar from '../../../core/NavBar'

const params = {
  entityName: 'languages',
  targetUrl: 'languages',
  mainField: 'name',
  defaultEntity: {
    id: 0,
    name: '',
    ordering: 0
  },
  datagridOptions: {
    rights: {
      show: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      edit: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      delete: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    columnsOptions: {
      sortable: false
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('languages:field:id'),
      width: 30,
      hide: true
    },
    {
      field: 'name',
      headerName: i18n.t('languages:field:name'),
      width: 150,
      main: true
    }
  ],
  formStruct: {
    size: {
      width: 'xs',
      fullHeight: false
    },
    elements: [
      {
        type: 'text',
        required: true,
        field: 'name',
        label: i18n.t('languages:field:name'),
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      }
    ]
  }
}

export default function DataTable () {
  return (
    <>
      <NavBar title={i18n.t(`menu:${params.entityName}`)} />
      <CrudPage params={params} />
    </>
  )
}
