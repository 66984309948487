import { Grid, List, ListItem, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import NavBar from '../../../core/NavBar'
import i18n from '../../../i18n'
import apiConsumer from '../../../service/api/apiConsumer'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}))

const Component = () => {
  const classes = useStyles()
  const history = useHistory()
  const [list, setList] = useState([])

  useEffect(() => {
    apiConsumer.get(`trainings/app`).then(rep => {
      if (rep.status === 200) setList(rep.body)
    })
    return () => setList()
  }, [setList])

  return (
    <>
      <NavBar title={i18n.t('menu:trainings')} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <List>
            {list.map((e, i) => (
              <ListItem
                key={i}
                button
                divider={i !== list.length - 1}
                onClick={() => history.push(`/app/training-${e.id}-${e.name}`)}
              >
                <ListItemText primary={e.name} secondary={e.subname || ''} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  )
}

export default Component
