import React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Autocomplete } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(6),
    margin: 0
  }
}))

const SimpleTextDialog = ({
  params,
  values,
  open,
  handleClose,
  onChange,
  onValid
}) => {
  const classes = useStyles()
  const testKey = e => {
    if (e.key === 'Enter') {
      onValid()
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle>{params.title}</DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {params.description ? (
          <DialogContentText>{params.description}</DialogContentText>
        ) : null}
        {params.autocomplete ? (
          <Autocomplete
            freeSolo
            disableClearable
            options={params.autocomplete}
            // defaultValue={values?.value || ''}
            onInputChange={(event, newInputValue) => {
              onChange(newInputValue)
            }}
            renderInput={p => (
              <TextField
                {...p}
                variant='standard'
                autoFocus
                margin='dense'
                label={params.label || ''}
                value={values?.value}
                onKeyDown={testKey}
                fullWidth
                InputProps={{ ...p.InputProps, type: 'search' }}
              />
            )}
          />
        ) : (
          <TextField
            variant='standard'
            autoFocus
            margin='dense'
            label={params.label || ''}
            value={values?.value}
            onChange={e => onChange(e.target.value)}
            onKeyDown={testKey}
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        {params.no ? (
          <Button onClick={handleClose} color='primary'>
            {params.no}
          </Button>
        ) : null}
        {params.yes ? (
          <Button onClick={onValid} color='primary'>
            {params.yes}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

SimpleTextDialog.prototype = {
  params: PropTypes.object,
  value: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onChange: PropTypes.func,
  onValid: PropTypes.func
}

export default SimpleTextDialog
