import React from 'react'
import { MoreVert } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import ProperMenu from '../menu/ProperMenu'
import { List, ListItem } from '@mui/material'
import i18n from '../../i18n'
import AppSlider from '../menu/AppSlider'
import AppSwitch from '../menu/AppSwitch'
import { isPossibleTwoColums } from '../../service/functions'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  list: {
    width: '250px',
    maxHeight: '95vh'
  },
  toneButton: {
    position: 'absolute',
    right: '24px',
    top: '0px'
  }
}))

const MassSheetSetting = ({
  settings,
  setSettings,
  disableTwoColumns = false
}) => {
  const classes = useStyles()

  return (
    <ProperMenu aria-label='song settings' autoHide={false} icon={<MoreVert />}>
      <List className={classes.list}>
        <ListItem button>
          <AppSlider
            value={settings.fontSize}
            onChange={(f, v) => setSettings({ ...settings, fontSize: v })}
            min={10}
            max={40}
            label={i18n.t('app:song:settings:font size', {
              value: Math.round((settings.fontSize - 10) * 20)
            })}
          />
        </ListItem>
        <ListItem button>
          <AppSwitch
            value={settings.displayOrdinary}
            onChange={() =>
              setSettings({
                ...settings,
                displayOrdinary: !settings.displayOrdinary
              })
            }
            label={i18n.t('app:massSheets:settings:displayOrdinary')}
          />
        </ListItem>
        <ListItem button>
          <AppSwitch
            value={settings.compactMode}
            onChange={() =>
              setSettings({
                ...settings,
                compactMode: !settings.compactMode
              })
            }
            label={i18n.t('app:massSheets:settings:compactMode')}
          />
        </ListItem>
        <ListItem button>
          <AppSwitch
            value={settings.chorusEach}
            onChange={() =>
              setSettings({
                ...settings,
                chorusEach: !settings.chorusEach
              })
            }
            label={i18n.t('app:song:settings:chorus each')}
          />
        </ListItem>
        {!disableTwoColumns && isPossibleTwoColums() ? (
          <ListItem button>
            <AppSwitch
              value={settings.allowTwoColumns}
              onChange={() =>
                setSettings({
                  ...settings,
                  allowTwoColumns: !settings.allowTwoColumns
                })
              }
              label={i18n.t('app:song:settings:allow two columns')}
            />
          </ListItem>
        ) : null}
      </List>
    </ProperMenu>
  )
}

MassSheetSetting.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  /** hide two columns song display setting */
  disableTwoColumns: PropTypes.bool
}

export default MassSheetSetting
