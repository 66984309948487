import React, { useEffect, useState } from 'react'
import SongsList from '../../../components/song/SongsList'
import { connect } from 'react-redux'
import i18n from '../../../i18n'
import FormContent from '../../../components/crud/FormContent'
import { setStoreValuesAction } from '../../../store/form/formStoreAction'
import { searchIn, searchNumberIn } from '../../../service/functions'
import NavBar from '../../../core/NavBar'

const formStruct = {
  defaultEntity: {
    search: '',
    category: null,
    language: null,
    tag: [],
    solemnity: [],
    liturgicalTime: [],
    liturgicalMoment: []
  },
  elements: [
    {
      type: 'text',
      field: 'search',
      label: i18n.t('main:search'),
      md: 4
    },
    {
      type: 'autocomplete',
      field: 'category',
      label: i18n.t('songs:field:category'),
      md: 4,
      options: [],
      rules: {}
    },
    {
      type: 'autocomplete',
      field: 'language',
      label: i18n.t('songs:field:language'),
      md: 4,
      options: [],
      rules: {}
    },
    {
      type: 'autocomplete',
      multiple: true,
      field: 'tag',
      label: i18n.t('songs:field:tag'),
      md: 12,
      options: [],
      rules: {}
    },
    {
      type: 'autocomplete',
      multiple: true,
      field: 'liturgicalMoment',
      label: i18n.t('songs:field:liturgicalMoment'),
      md: 12,
      options: [],
      rules: {}
    },
    {
      type: 'autocomplete',
      multiple: true,
      field: 'liturgicalTime',
      label: i18n.t('songs:field:liturgicalTime'),
      md: 12,
      options: [],
      rules: {}
    },
    {
      type: 'autocomplete',
      multiple: true,
      field: 'solemnity',
      label: i18n.t('songs:field:solemnity'),
      md: 12,
      options: [],
      rules: {}
    }
  ]
}

const filterSongs = (list, values) => {
  if (!list || !values) return list
  list = list.filter(
    song =>
      searchIn(song.name, values.search) ||
      searchNumberIn(song.number, values.search)
  )
  list = filterManyToOne(list, 'category', values.category)
  list = filterManyToOne(list, 'language', values.language)
  list = filterManyToMany(list, 'tag', values.tag)
  list = filterManyToMany(list, 'liturgicalMoment', values.liturgicalMoment)
  list = filterManyToMany(list, 'liturgicalTime', values.liturgicalTime)
  list = filterManyToMany(list, 'solemnity', values.solemnity)
  return list
}

const filterManyToOne = (list, field, element) => {
  if (element && 'id' in element) {
    list = list.filter(song => song[field] && song[field].id === element.id)
  }
  return list
}

const filterManyToMany = (list, field, arr = []) => {
  if (arr?.length) {
    arr.forEach(element => {
      list = list.filter(song =>
        song[field].map(e => e.id).includes(element.id)
      )
    })
  }
  return list
}

const SongFilter = ({ setStoreValues, values, appDatas = {} }) => {
  const [list, setList] = useState(null)
  const [form, setForm] = useState([])

  useEffect(() => {
    setStoreValues(formStruct.defaultEntity, true)
    let data = formStruct.elements
    Object.keys(appDatas).forEach(entity => {
      let id = data.findIndex(el => el.field === entity) // id of element
      if (id !== -1) {
        data[id].options = appDatas[entity]
      }
    })
    setForm([...data])
    setList(appDatas.song)

    return () => {
      setList()
      setList()
    }
  }, [setList, setForm, setStoreValues, appDatas])

  return (
    <>
      <NavBar title={i18n.t('menu:filter')} />
      <FormContent elements={form} editable={true} />
      <SongsList
        list={filterSongs(list, values)}
        emptyMessage={i18n.t('main:emptySearch')}
      />
    </>
  )
}

export default connect(
  state => ({
    values: { ...state.form.values },
    appDatas: state.setting.appDatas
  }),
  dispach => ({
    setStoreValues: (values, editable) => {
      const valid = { ...values }
      Object.keys(valid).map(key => (valid[key] = ''))
      dispach(
        setStoreValuesAction({ values, valid, displayError: false, editable })
      )
    }
  })
)(SongFilter)
