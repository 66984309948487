import { ChordParser } from "../assets/AppChordPro";
import { defaultPrintMassSheetSettings } from "../config/constants";
import { displayDate } from "./functions";
// import PizZipUtils from "pizzip/utils/index.js";
// import templateFile from "../assets/docTemplates/template.docx"; // TODO: use
// import createReport from "docx-templates";
import { dontDisplayHeaderName } from "../config/massSheetConstants";
import i18n from "./../i18n";

// mass sheet service to generate pdf and word
// todo - repair word generator

export const selectVerseCount = (content, verses) => {
  return !verses?.length
    ? content
    : content.filter((e) =>
        ["r", "refrain", ...verses].map(String).includes(e.name.toLowerCase())
      );
};

export const pdfMassSheetGenerator = (
  massSheet,
  compact,
  hidePrinSongs = {},
  settings = defaultPrintMassSheetSettings,
  qrCode
) => {
  const chordParser = new ChordParser();

  var docDefinition = {
    pageOrientation: "portrait",
    pageSize: settings.a5 ? "A5" : "A4",
    content: [
      {
        columns: [
          {
            text: massSheet.name,
            style: "header",
          },
          {
            alignment: "right",
            text: displayDate(massSheet.date),
            width: 140,
            style: "date",
          },
        ],
      },
      {
        columns: [
          {
            text: massSheet.subname,
            style: "mainSubheader",
          },
          {
            alignment: "right",
            text:
              massSheet?.parish?.id && massSheet?.parish?.name && massSheet.code
                ? `${massSheet.parish.name}\nCode : ${massSheet.code}`
                : massSheet?.parish?.id && massSheet?.parish?.name
                ? massSheet.parish.name
                : massSheet.code
                ? `Code : ${massSheet.code}`
                : "",
            width: 140,
            style: "code",
          },
        ],
      },

      qrCode
        ? {
            alignment: "right",
            image: qrCode,
            width: 50,
          }
        : {
            text: "",
            style: "spacer",
          },
      checkIsNotEmptyRichText(massSheet.introduction)
        ? {
            stack: parseRichText(massSheet.introduction),
            unbreakable: true,
          }
        : null,

      {
        text: "",
        style: qrCode ? "spacer" : "bigSpacer",
      },
    ],
    styles: {
      header: {
        fontSize: settings.headerSize,
        bold: true,
      },
      mainSubheader: {
        fontSize: settings.subheaderSize,
      },
      date: {
        fontSize: settings.textSize,
        color: "grey",
      },
      code: {
        fontSize: settings.textSize,
        color: "grey",
      },
      "heading-one": {
        fontSize: settings.subheaderSize,
        marginBottom: 8,
      },
      "heading-two": {
        fontSize: settings.subheaderSize - 2,
        marginBottom: 8,
      },
      list: {
        marginLeft: 24,
        marginTop: 4,
        marginBottom: 4,
      },
      quote: {
        italics: true,
        fontSize: settings.textSize - 2,
      },
      spacer: {
        marginTop: 16,
      },
      bigSpacer: {
        marginTop: 32,
      },
      songHeader: {
        fontSize: settings.subheaderSize,
      },
      subheader: {
        color: "grey",
      },
      song: {
        marginTop: 8,
        marginBottom: 16,
        fontSize: settings.songSize,
      },
      songText: {
        fontSize: settings.songSize,
      },
      author: {
        alignment: "right",
        fontSize: settings.songSize - 2,
        marginBottom: 16,
        italics: true,
      },
      footer: {
        alignment: "right",
        color: "grey",
        italics: true,
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: settings.textSize,
    },
  };

  massSheet.massSheetSong.forEach((mss, i) => {
    if (mss.song?.id && hidePrinSongs[mss.header?.name] !== true) {
      let verses = [];
      if (!compact && mss.song?.content?.text) {
        const content = selectVerseCount(mss.song.content.text, mss.verses);
        content.forEach((e, i) => {
          if (!(i % 2)) {
            verses = [
              ...verses,
              {
                columns: [
                  {
                    text: `${
                      e.name ? e.name + ". " : ""
                    }${chordParser.removeAllChords(e.text)}`,
                    bold: e.bold,
                    style: "songText",
                  },
                  {
                    text:
                      content.length > i + 1
                        ? `${
                            content[i + 1].name
                              ? content[i + 1].name + ". "
                              : ""
                          }${chordParser.removeAllChords(content[i + 1].text)}`
                        : "",
                    bold: content.length > i + 1 ? content[i + 1].bold : null,
                    style: "songText",
                  },
                ],
                unbreakable: true,
                style: "song",
              },
            ];
          }
        });
      }

      docDefinition.content = [
        ...docDefinition.content,
        {
          stack: [
            {
              stack: [
                {
                  text: [
                    { text: `${mss.header.name} : `, style: "subheader" },
                    dontDisplayHeaderName(mss.header.name) && !compact
                      ? ""
                      : `${mss.song.number} - ${mss.song.name}`,
                  ],
                  style: "songHeader",
                },
                ...verses.slice(0, 1),
              ],
              unbreakable: true,
            },
            ...verses.slice(1),
            // mss.song.author
            //   ? {
            //       text: mss.song.author,
            //       style: 'author'
            //     }
            //   : null
          ],
          unbreakable: false,
        },
      ];
    }
  });

  docDefinition.content = [
    ...docDefinition.content,
    {
      text: "",
      style: "bigSpacer",
    },
    checkIsNotEmptyRichText(massSheet.informations)
      ? {
          stack: parseRichText(massSheet.informations),
          unbreakable: true,
        }
      : null,
    { text: i18n.t("massSheets:print:footer"), style: "footer" },
  ];

  return docDefinition;
};

export const checkIsNotEmptyRichText = (richText) => {
  if (!richText?.length) return false;
  let flag = false;
  for (let e of richText) {
    if (e?.children?.length > 0 && e?.children[0]?.text?.length > 0) {
      flag = true;
      break;
    }
  }
  return flag;
};

const parseRichText = (richText) => {
  return richText.map((e) => {
    if (e.type === "paragraph") {
      return {
        text: parseRichText(e.children),
      };
    } else if (e.type === "left") {
      return {
        text: parseRichText(e.children),
        alignment: "left",
      };
    } else if (e.type === "center") {
      return {
        text: parseRichText(e.children),
        alignment: "center",
      };
    } else if (e.type === "right") {
      return {
        text: parseRichText(e.children),
        alignment: "right",
      };
    } else if (e.type?.includes("heading")) {
      return {
        text: parseRichText(e.children),
        style: e.type,
      };
    } else if (e.type === "numbered-list") {
      return {
        ol: parseRichText(e.children),
        style: "list",
      };
    } else if (e.type === "bulleted-list") {
      return {
        ul: parseRichText(e.children),
        style: "list",
      };
    } else if (e.type === "block-quote") {
      return {
        text: parseRichText(e.children),
        style: "quote",
      };
    } else if (e.children) {
      return {
        text: parseRichText(e.children),
      };
    } else if (e.text) {
      return {
        text: e.text,
        bold: e.bold,
        italics: e.italic,
      };
    } else {
      return { text: "\n" };
    }
  });
};

/********************************************************************************* docx */

export const generateMassSheetDocx = (massSheet, hidePrinSongs, qrCode) => {
  if (massSheet) {
    // PizZipUtils.getBinaryContent(templateFile, (error, template) => {
    // createReport({
    //   template,
    //   data: templateMassSheetGenerator(massSheet, hidePrinSongs),
    //   additionalJsContext: {
    //     qrCode: () =>
    //       qrCode
    //         ? {
    //             width: 1.6,
    //             height: 1.6,
    //             data: qrCode.slice("data:image/png;base64,".length),
    //             extension: ".png",
    //           }
    //         : null,
    //   },
    // })
    //   .then((report) =>
    //     saveDataToFile(
    //       report,
    //       "report.docx",
    //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    //     )
    //   )
    //   .catch((e) => console.error(e));
    // });
  } else {
    console.error("no massSheet");
  }
};

// const saveDataToFile = (data, fileName, mimeType) => {
//   const blob = new Blob([data], { type: mimeType });
//   const url = window.URL.createObjectURL(blob);
//   downloadURL(url, fileName, mimeType);
//   setTimeout(() => {
//     window.URL.revokeObjectURL(url);
//   }, 1000);
// };

// const downloadURL = (data, fileName) => {
//   const a = document.createElement("a");
//   a.href = data;
//   a.download = fileName;
//   document.body.appendChild(a);
//   a.style = "display: none";
//   a.click();
//   a.remove();
// };

export const templateMassSheetGenerator = (massSheet, hidePrinSongs = {}) => {
  const chordParser = new ChordParser();
  massSheet.date = displayDate(massSheet.date);
  massSheet.parish =
    massSheet?.parish?.id && massSheet?.parish?.name
      ? massSheet.parish.name
      : null;
  massSheet.massSheetSong = massSheet.massSheetSong
    .map((mss, i) => {
      if (mss?.song?.id && hidePrinSongs[mss?.header?.name] !== true) {
        let songVerses = [];
        if (
          ["Psaume", "Prière universelle"].includes(mss?.header?.name) &&
          mss.song?.content?.text?.length === 1
        ) {
          return {
            ...mss,
            song: {
              name: mss.song?.content?.text[0].text || "",
            },
            songVerses: [],
          };
        } else {
          if (mss.song?.content?.text) {
            const content = selectVerseCount(mss.song.content.text, mss.verses);
            content.forEach((e, i) => {
              if (!(i % 2)) {
                songVerses = [
                  ...songVerses,
                  [
                    {
                      text: `${
                        e.name ? e.name + ". " : ""
                      }${chordParser.removeAllChords(e.text)}`,
                      bold: e.bold,
                    },
                    {
                      text:
                        content.length > i + 1
                          ? `${
                              content[i + 1].name
                                ? content[i + 1].name + ". "
                                : ""
                            }${chordParser.removeAllChords(
                              content[i + 1].text
                            )}`
                          : "",
                      bold: content.length > i + 1 ? content[i + 1].bold : null,
                    },
                  ],
                ];
              }
            });
            return {
              ...mss,
              song: {
                name: dontDisplayHeaderName(mss?.header?.name)
                  ? ""
                  : mss?.song?.name,
              },
              songVerses,
            };
          }
        }
      }
      return null;
    })
    .filter(Boolean);

  massSheet.introduction = checkIsNotEmptyRichText(massSheet.introduction)
    ? parseRichTextToHtml(massSheet.introduction)
    : null;

  massSheet.informations = checkIsNotEmptyRichText(massSheet.informations)
    ? parseRichTextToHtml(massSheet.informations)
    : null;

  massSheet.footer = i18n.t("massSheets:print:footer");
  return massSheet;
};

const parseRichTextToHtml = (richText) => {
  const style =
    "font-size:10pt; padding-bottom:1pt; margin:0pt; font-family:'Calibri',sans-serif;";
  return richText
    .map((e) => {
      if (e.type === "paragraph") {
        return `<p style="${style}">${parseRichTextToHtml(e.children)}</div>`;
      } else if (e.type === "left") {
        return `<p style="text-align:left; ${style}">${parseRichTextToHtml(
          e.children
        )}</p>`;
      } else if (e.type === "center") {
        return `<p style="text-align:center; ${style}">${parseRichTextToHtml(
          e.children
        )}</p>`;
      } else if (e.type === "right") {
        return `<p style="text-align:right; ${style}">${parseRichTextToHtml(
          e.children
        )}</p>`;
      } else if (e.type?.includes("heading")) {
        const a = e.type === "heading-one";
        return `<p style="${style} 
        font-size:${a ? "14" : "12"}pt; padding-top:4pt; padding-bottom:8pt">
        ${parseRichTextToHtml(e.children)}
        </p>`;
      } else if (e.type === "list-item") {
        return `<li style="${style} font-family: Symbol;">
        <span style="${style}">${parseRichTextToHtml(e.children)}</span>
        </li>`;
      } else if (e.type === "numbered-list") {
        return `<ol style="${style}">${parseRichTextToHtml(e.children)}</ol>`;
      } else if (e.type === "bulleted-list") {
        return `<ul style="${style}">${parseRichTextToHtml(e.children)}</ul>`;
      } else if (e.type === "block-quote") {
        return `<p style="${style} margin-left:30pt; margin-right:20pt; color:#707070; 
        border-left: 2pt solid #969696; padding-left:5pt;">
        <span style="${style}" >
        ${parseRichTextToHtml(e.children)}
        </span></p>`;
      } else if (e.children) {
        return parseRichTextToHtml(e.children);
      } else if (e.text) {
        return `<span style="
        font-weight:${e.bold ? "bold" : "normal"};
        font-style:${e.italic ? "italic" : "normal"};
        text-decoration:${e.underline ? "underline" : "none"}">${
          e.text
        }</span>`;
      } else {
        return `<p style="${style}">&nbsp;</p>`;
      }
    })
    .join("");
};
