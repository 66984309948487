import React from 'react'
import { Button } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3, 0, 3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  },
  icon: {
    paddingRight: 10,
    paddingTop: 10
  },
  search: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      with: '100%'
    }
  }
}))

const DynatableHeader = ({ addText, searchText, onAddAction, onSearch }) => {
  const classes = useStyles()
  return (
    <>
      <Grid container className={classes.root} justifyContent='center'>
        <Grid item md={6} xs={12}>
          {onAddAction ? (
            <Button
              aria-label='dynatable add button'
              startIcon={<AddIcon />}
              onClick={onAddAction}
            >
              {addText}
            </Button>
          ) : null}
        </Grid>
        {onSearch ? (
          <Grid aria-label='dynatable search' item md={6} xs={12}>
            <Grid container className={classes.search}>
              <Grid item className={classes.icon}>
                <SearchIcon fontSize='small' />
              </Grid>
              <Grid item>
                <TextField
                  variant='standard'
                  placeholder={searchText}
                  onChange={e => onSearch(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  )
}

DynatableHeader.propTypes = {
  addText: PropTypes.string,
  searchText: PropTypes.string,
  onAddAction: PropTypes.func,
  onSearch: PropTypes.func
}

export default DynatableHeader
