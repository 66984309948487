import React from 'react'
import AudioPlayer from './AudioPlayer'
import httpConstantes from '../../config/httpConstantes'
import AppDialog from '../unclassified/AppDialog'
import OpenSheetMusicDisplay from './musicSheet/OpenSheetMusicDisplay'
import PdfViewer from './PdfViewer'
import PropTypes from 'prop-types'
import apiConsumer from './../../service/api/apiConsumer'

const FilePreview = ({ target, entity, onClose }) => {
  return !entity ? null : (
    <AppDialog
      title={entity.name.name}
      open={!!entity}
      onClose={onClose}
      width={entity.type === 'audio' ? 'md' : 'xl'}
      fullSceen={entity.type !== 'audio'}
    >
      {entity.type === 'xmlSheet' ? (
        <div style={{ position: 'relative' }}>
          <OpenSheetMusicDisplay src={`${target}/${entity.id}`} />
        </div>
      ) : entity.type === 'audio' ? (
        <AudioPlayer src={`${httpConstantes.apiUrl}/${target}/${entity.id}`} />
      ) : entity.type === 'pdf' ? (
        <PdfViewer
        src={{
          url: `${httpConstantes.apiUrl}/${target}/${entity.id}`,
          httpHeaders: { ...apiConsumer.authHeader() }
        }}
         />
      ) : null}
    </AppDialog>
  )
}

FilePreview.propTypes = {
  target: PropTypes.string,
  entity: PropTypes.object,
  onClose: PropTypes.func
}

export default FilePreview
