import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { setSettingStoreGuidedTourAction } from '../../../store/setting/settingStoreAction'

const useStyles = makeStyles(theme => ({
  root: {
    '& *': {
      userSelect: 'none'
    }
  },
  media: {
    backgroundPosition: 'initial!important',
    maxWidth: 600,
    height: '300px',
    [theme.breakpoints.down('sm')]: {
      height: '200px'
    }
  },
  title: {
    padding: theme.spacing(4, 4, 0, 4),
    textAlign: 'center',
    fontSize: '20px!important'
  },
  divider: {
    width: '50%',
    margin: 'auto!important'
  },
  textBox: {
    padding: theme.spacing(3, 5),
    textAlign: 'center'
  },
  text: {
    fontSize: '16px!important'
  },
  stepper: {
    background: 'transparent!important'
  }
}))

const Component = ({ onDisable, enable, setGuidedTour, type, content }) => {
  const classes = useStyles()

  useEffect(() => {
    if (!enable) onDisable()
  }, [onDisable, enable])

  return (
    enable && (
      <Dialog open className={classes.root}>
        <DialogTitle id='alert-dialog-title'>{content.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {content.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGuidedTour(type, true)}>
            {content.button}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}

export default connect(
  (state, ownProps) => ({
    enable: !state.setting?.guidedTour[ownProps.type]
  }),
  dispach => ({
    setGuidedTour: (type, value) =>
      dispach(setSettingStoreGuidedTourAction(type, value))
  })
)(Component)
