import React from 'react'
import Avatar from '@mui/material/Avatar'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    //https://source.unsplash.com/random
    backgroundImage:
      'url(https://images.unsplash.com/photo-1632585312258-52f368909632?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzNDIxNTQxNQ&ixlib=rb-1.2.1&q=80&w=1080)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  title: {
    margin: theme.spacing(2, 0, 3)
  },
  links: {
    marginTop: theme.spacing(2) + '!important'
  }
}))

export default function SetPassword ({
  title,
  children,
  links,
  sm = 8,
  md = 5
}) {
  const classes = useStyles()
  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={12 - sm}
        md={12 - md}
        className={classes.image}
      />
      <Grid item xs={12} sm={sm} md={md} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5' className={classes.title}>
            {title}
          </Typography>
          {children}
          <Grid container justifyContent='flex-end' className={classes.links}>
            {links && links[1] ? (
              <Grid item xs>
                <Link href={links[1].url} variant='body2' underline='none'>
                  {links[1].label}
                </Link>
              </Grid>
            ) : null}
            {links && links[0] ? (
              <Grid item>
                <Link href={links[0].url} variant='body2' underline='none'>
                  {links[0].label}
                </Link>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}
