import i18n from '../i18n'
import { compareDate } from './functions'
import { checkIsNotEmptyRichText } from './massSheetService'

export const formValidation = (element, value, stateForm) => {
  // check rules
  if (element?.required || (value || '').length !== 0) {
    if (element?.rules) {
      if (['text', 'textarea'].includes(element.type)) {
        if (value?.length > element.rules.max) {
          return element.rules.maxMessage || 'error'
        } else if (value?.length < element.rules.min) {
          return element.rules.minMessage || 'error'
        }
      } else if (element.type === 'number') {
        if (value < element.rules.minVal) {
          return element.rules.minValMessage || 'error'
        } else if (value < element.rules.maxVal) {
          return element.rules.maxValMessage || 'error'
        }
      } else if (element.type === 'date') {
        if (
          element?.rules?.minField &&
          compareDate(value, stateForm.values[element.rules.minField]) === -1
        ) {
          return element.rules.minFieldMessage || 'error'
        }
      }
    } else if (element.type === 'image') {
      if (!value?.length || value?.length < 10) {
        return i18n.t('error:image require')
      }
    }
  }
  // check regex
  if (element?.regex && value?.length && !value.match(element.regex)?.length) {
    return element?.regexExample
      ? i18n.t('error:regex example', {
          example: element?.regexExample,
          interpolation: { escapeValue: false }
        })
      : i18n.t('error:the entry is not valid')
  }
  // check require
  if (element.required) {
    if (value?.length === 0 || value === '' || value === false) {
      return i18n.t('error:the entry is required')
    }
    // check checkbox and switch
    if (
      (element.type === 'checkbox' || element.type === 'switch') &&
      value !== true
    ) {
      return i18n.t('error:the checkbox must be checked')
    }
    // check rich text
    if (element.type === 'richText' && !checkIsNotEmptyRichText(value)) {
      return i18n.t('error:the entry is required')
    }
  }
  return ''
}

export const checkCompleteFormValidation = (stateForm, setStoreValid) => {
  if (stateForm?.type === 'tabs') {
    stateForm.elements.forEach(subElements => {
      subElements.data.forEach(element => {
        setStoreValid(
          formValidation(element, stateForm.values[element.field], stateForm),
          element.field
        )
      })
    })
  } else {
    stateForm.elements.forEach(element => {
      setStoreValid(
        formValidation(element, stateForm.values[element.field], stateForm),
        element.field
      )
    })
  }
}
