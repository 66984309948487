import apiConsumer from './apiConsumer'

// service to update app song list, categories

const fields = [
  'song',
  'category',
  'language',
  'tag',
  'solemnity',
  'liturgicalTime',
  'liturgicalMoment'
]

const updateElementList = (dataField, updateField) => {
  if (dataField?.length && updateField?.length) {
    updateField.forEach(e => {
      const idx = dataField.findIndex(x => x.id === e.id)
      if (idx !== -1) {
        dataField[idx] = { ...e }
      } else {
        dataField = [...dataField, e]
      }
    })
    dataField.sort((a, b) => a.ordering - b.ordering)
  }
  return [...dataField]
}

const deleteElementList = (dataField, id) => {
  if (dataField?.length && id) {
    const idx = dataField.findIndex(x => x.id === id)
    if (idx !== -1) dataField.splice(idx, 1)
  }
  return dataField?.length ? [...dataField] : []
}

const updateAppDatas = (data, update) => {
  fields.forEach(field => {
    if (field in data && field in update)
      data[field] = updateElementList(data[field], update[field])
  })

  update.deleted.forEach(e => {
    data[e.entity] = deleteElementList(data[e.entity], e.elementId)
  })

  return {
    ...data,
    song: data.song.sort((a, b) => a.number - b.number)
  }
}

export const appDataUpdate = (appDatas, setAppDatas) => {
  apiConsumer.get(`app-update-datas/${appDatas?.date}`).then(rep => {
    if (rep.status === 200 && rep.body?.date && rep.body?.date)
      if (rep.body?.update === true) {
        setAppDatas(updateAppDatas(appDatas, rep.body))
      } else {
        setAppDatas(rep.body)
      }
  })
}
