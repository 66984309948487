import React from 'react'
import i18n from '../../i18n'
import {
  Avatar,
  Badge,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material'
import { Check, Close, Delete, Drafts, Email } from '@mui/icons-material'
import { formatDateTime } from '../../service/functions'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  primary: {
    background: theme.palette.primary.main,
    color: theme.palette.grey[300]
  }
}))

const ChatList = ({ type = 'app', chatList, onAction, userId }) => {
  const classes = useStyles()

  return (
    <>
      {chatList.length ? (
        <List style={{ width: '100%' }}>
          {chatList.map((e, idx) => {
            const isOwner = e?.owner.id === userId
            const isReplied =
              e.isOpened &&
              ((type === 'admin' && !e.isReplied) ||
                (type === 'app' && e.isReplied && isOwner) ||
                (type === 'app' && !e.isReplied && !isOwner))

            return (
              <div key={idx}>
                <ListItem
                  button
                  title={
                    isReplied
                      ? i18n.t('chat:waiting')
                      : e.isOpened
                      ? i18n.t('chat:answered')
                      : i18n.t('chat:chat closed')
                  }
                  onClick={() => onAction('SHOW', e)}
                  style={type === 'admin' ? { paddingRight: '80px' } : null}
                >
                  <ListItemAvatar>
                    <Badge
                      color='secondary'
                      variant='dot'
                      overlap='circular'
                      invisible={!isReplied}
                    >
                      <Avatar className={classes.primary}>
                        {isReplied ? (
                          <Email />
                        ) : e.isOpened ? (
                          <Drafts />
                        ) : (
                          <Check />
                        )}
                      </Avatar>
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        {e.subject}
                        {!!e.parish?.id && ` - ${e.parish?.name}`}
                        {!e.isOpened && (
                          <Typography
                            variant='caption'
                            color='textSecondary'
                            children={` (${i18n.t('chat:closed')})`}
                          />
                        )}
                      </>
                    }
                    secondary={
                      i18n.t('chat:datetime', formatDateTime(e.createdAt)) +
                      ('owner' in e ? ' - ' + e.owner.firstname : '')
                    }
                  />
                  <ListItemSecondaryAction>
                    {(type === 'admin' || isOwner === false) && (
                      <Tooltip
                        placement='left'
                        title={i18n.t(
                          e.isOpened ? 'chat:close chat' : 'chat:reopen chat'
                        )}
                      >
                        <IconButton onClick={() => onAction('CLOSE', e)}>
                          {e.isOpened ? <Close /> : <Check />}
                        </IconButton>
                      </Tooltip>
                    )}
                    {isOwner && (
                      <Tooltip
                        placement='left'
                        title={i18n.t('chat:delete chat')}
                      >
                        <IconButton onClick={() => onAction('DELETE', e)}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                {chatList.length - 1 !== idx ? (
                  <Divider key={'-' + idx} variant='inset' component='li' />
                ) : null}
              </div>
            )
          })}
        </List>
      ) : (
        <Grid item xs={12} style={{ padding: '24px', textAlign: 'center' }}>
          {i18n.t('chat:no messages')}
        </Grid>
      )}
    </>
  )
}

ChatList.propTypes = {
  type: PropTypes.oneOf(['app', 'admin']),
  chatList: PropTypes.array,
  onAction: PropTypes.func,
  userId: PropTypes.number
}

export default connect(state => ({
  userId: state.setting?.user?.id
}))(ChatList)
