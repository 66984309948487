import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DisplayMassSheet from '../massSheet/DisplayMassSheet'
import apiConsumer from '../../service/api/apiConsumer'
import PrintMassSheet from '../massSheet/PrintMassSheet'
import { defaultMassSheetSettings } from './../../config/constants'

const MassSheetPreview = ({ element, value }) => {
  const [massSheet, setMassSheet] = useState({})
  const [readyCount, setReadyCount] = useState(0)

  useEffect(() => {
    setMassSheet({ ...value })
    if (!element?.compact) {
      setReadyCount(value.massSheetSong.length)
      value.massSheetSong.forEach((e, i) => {
        if (e.song?.id && !e.song?.content) {
          apiConsumer.getOne(`songs`, e.song.id).then(rep => {
            if (rep.status === 200) {
              value.massSheetSong[i].song['content'] = {
                text: rep.body.content.text
              }
              setMassSheet({ ...value })
            }
            setReadyCount(n => n - 1)
          })
        } else {
          setReadyCount(n => n - 1)
        }
      })
    }
    return () => {
      setReadyCount(0)
      setMassSheet({})
    }
  }, [value, setMassSheet, element])

  return (
    <>
      <PrintMassSheet
        massSheet={massSheet}
        compact={element?.compact}
        disabled={readyCount > 0}
      />
      <DisplayMassSheet
        massSheet={massSheet}
        AllowLinks={element?.compact}
        targetBlank={element?.compact}
        settings={{
          ...defaultMassSheetSettings,
          compactMode: element?.compact
        }}
      />
    </>
  )
}

MassSheetPreview.propTypes = {
  /** config - compact | bool */
  element: PropTypes.object,
  /** massSheet */
  value: PropTypes.object
}

export default MassSheetPreview

export const MassSheetPreviewStore = connect(state => ({
  value: state.form?.values
}))(MassSheetPreview)
