import React, { useCallback, useState } from 'react'
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton
} from '@mui/material'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import apiConsumer from '../../service/api/apiConsumer'
import {
  Delete,
  MusicNote,
  PictureAsPdf,
  PlayArrow,
  Visibility
} from '@mui/icons-material'
import FilePreview from '../preview/FilePreview'
import { Select } from '@mui/material'
import i18n from './../../i18n'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: 10
  },
  input: {
    display: 'none'
  },
  formControl: {
    width: '100%'
  },
  add: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  center: {
    textAlign: 'center'
  }
}))

const SongFilesEditor = ({
  editable,
  element,
  value = [],
  songId,
  setStoreValue
}) => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState()
  const [showEntity, setShowEntity] = useState()

  const uploadFile = useCallback(
    event => {
      if (name === '') {
        setError(i18n.t('songs:fileEditor:must select name'))
      } else if (songId) {
        const files = event.target.files
        const formData = new FormData()
        formData.append('file', files[0])
        formData.append('name', element.options[name].id)

        setError('')
        setLoading(true)

        const uploadLink = element.uploadTarget.replace('{id}', songId)
        apiConsumer.upload(uploadLink, formData).then(rep => {
          setLoading(false)

          if (rep.status === 201) {
            setStoreValue([rep.body, ...value], null, element.field)
            setError('')
          } else {
            const err = rep?.body?.violations
              ? apiConsumer.getBadResponseMessage(rep)
              : rep?.body[0]

            if (err?.name) {
              setError(i18n.t(`error:${err.name}`))
            } else {
              setError(i18n.t('error:default'))
            }
          }
        })
      }
    },
    [element, songId, name, setError, setLoading, setStoreValue, value]
  )

  const deleteFile = useCallback(
    id => {
      apiConsumer.delete(element.target, id).then(rep => {
        if (rep.status === 204) {
          setStoreValue(
            [...value].filter(e => e.id !== id),
            null,
            element.field
          )
        } else {
          setError(i18n.t('error:default'))
        }
      })
    },
    [element, setStoreValue, value]
  )

  return !songId ? (
    <Grid item xs={12}>
      <Typography color='textSecondary'>
        {i18n.t(
          'songs:fileEditor:you will be able to add files when the song is created'
        )}
      </Typography>
    </Grid>
  ) : (
    <Grid item xs={12} md={element.md}>
      {editable ? (
        <>
          <Typography color='textSecondary'>
            {i18n.t('songs:fileEditor:add')}
          </Typography>
          <Grid
            container
            className={classes.add}
            alignItems='flex-end'
            spacing={3}
          >
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel variant='standard' id='name-select-input'>
                  {i18n.t('songs:fileEditor:file name')}
                </InputLabel>
                <Select
                  variant='standard'
                  id='name-select-input'
                  value={name}
                  onChange={e => setName(e.target.value)}
                >
                  {Object.keys(element.options).map(e => (
                    <MenuItem key={e} value={e}>
                      {element.options[e].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className={classes.center}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant='outlined' color='primary' component='label' disabled={!name}>
                  {i18n.t('songs:fileEditor:select file')}
                  <input hidden type='file' onChange={e => uploadFile(e)} />
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography color='error' variant='caption'>
                {error ? error : ''}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : null}

      {value && value.length ? (
        <>
          <Typography color='textSecondary'>
            {i18n.t('songs:fileEditor:files')}
          </Typography>

          <List className={classes.root}>
            {value.map((e, i) => {
              return (
                <ListItem key={i} divider={i !== value.length - 1}>
                  <ListItemAvatar>
                    <Avatar>
                      {e.type === 'pdf' ? (
                        <PictureAsPdf />
                      ) : e.type === 'audio' ? (
                        <PlayArrow />
                      ) : (
                        <MusicNote />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={e.name.name}
                    secondary={e.filePath ? e.filePath.substr(14) : ''}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => setShowEntity(e)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => deleteFile(e.id)}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
          <FilePreview
            target={element.target}
            entity={showEntity}
            onClose={() => setShowEntity()}
          />
        </>
      ) : null}
    </Grid>
  )
}

SongFilesEditor.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.object,
  value: PropTypes.array,
  songId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setStoreValue: PropTypes.func
}

export default SongFilesEditor

export const SongFilesEditorStore = connect(
  (state, ownProps) => ({
    value:
      'values' in state.form ? state.form.values[ownProps.element.field] : [],
    editable: state.form.editable,
    songId: state.form.values?.id
  }),
  dispach => ({
    setStoreValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(SongFilesEditor)
