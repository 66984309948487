import React, { useCallback, useEffect, useState } from 'react'
import i18n from '../../i18n'
import apiConsumer from '../../service/api/apiConsumer'
import { Button, Grid } from '@mui/material'
import CreateChat from '../../components/chat/CreateChat'
import ShowChat from '../../components/chat/ShowChat'
import DeleteChat from '../../components/chat/DeleteChat'
import CloseChat from '../../components/chat/CloseChat'
import ChatList from '../../components/chat/ChatList'
import { Pagination } from '@mui/lab'
import NavBar from '../../core/NavBar'
import { makeStyles } from '@mui/styles'
import Loading from '../../core/Loading'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}))

const countPerPage = 20

const Chat = ({ type = 'app', widget }) => {
  const classes = useStyles()
  const [chatList, setChatList] = useState()
  const [jsonChat, setJsonChat] = useState(null)
  const [page, setPage] = useState(1)

  useEffect(() => {
    apiConsumer.get(`chats/${type}`).then(async rep => {
      if (rep.status === 200) {
        setChatList(rep.body, false)
      }
    })
    return () => setChatList()
  }, [setChatList, type])

  const onAction = useCallback(
    (action, chat, success) => {
      switch (action) {
        case 'CREATE_END':
          setChatList(e => [chat, ...e])
          setJsonChat(null)
          break
        case 'CLOSE_END':
          if (success)
            setChatList(e =>
              e.map(el =>
                el.id === chat.id
                  ? { ...el, isOpened: !el.isOpened }
                  : { ...el }
              )
            )
          setJsonChat(null)
          break
        case 'DELETE_END':
          if (success) setChatList(e => e.filter(el => el.id !== chat.id))
          setJsonChat(null)
          break
        case 'RESET_COUNT':
          setChatList(e =>
            e.map(el =>
              el.id === chat.id
                ? { ...el, isReplied: !chat.isReplied }
                : { ...el }
            )
          )
          break
        default:
          setJsonChat({ action, chat })
          break
      }
    },
    [setChatList, setJsonChat]
  )

  const pageBegin = (page - 1) * countPerPage
  return (
    <>
      {!widget && <NavBar title={i18n.t('chat:chat')} />}
      <Grid container className={classes.root} justifyContent='center'>
        {type === 'app' ? (
          <Button onClick={() => onAction('CREATE')}>
            {i18n.t('chat:New message')}
          </Button>
        ) : null}
        {chatList ? (
          <ChatList
            type={type}
            chatList={chatList.slice(pageBegin, pageBegin + countPerPage)}
            onAction={onAction}
          />
        ) : (
          <Loading />
        )}
        {chatList?.length > countPerPage ? (
          <Pagination
            count={Math.ceil(chatList.length / countPerPage)}
            color='primary'
            page={page}
            onChange={(e, value) => {
              setPage(value)
            }}
          />
        ) : null}
      </Grid>
      <CreateChat
        open={jsonChat?.action === 'CREATE'}
        onClose={() => setJsonChat(null)}
        onValid={chat => onAction('CREATE_END', chat)}
      />
      {jsonChat?.action === 'SHOW' && (
        <ShowChat
          open={true}
          onClose={() => setJsonChat(null)}
          chat={jsonChat?.chat}
          resetIsReplied={() => onAction('RESET_COUNT', jsonChat?.chat)}
        />
      )}
      {jsonChat?.action === 'DELETE' && (
        <DeleteChat
          chatId={jsonChat?.chat.id}
          onValid={success => onAction('DELETE_END', jsonChat?.chat, success)}
        />
      )}
      {jsonChat?.action === 'CLOSE' && (
        <CloseChat
          chat={jsonChat?.chat}
          onValid={success => onAction('CLOSE_END', jsonChat?.chat, success)}
        />
      )}
    </>
  )
}

export default Chat
