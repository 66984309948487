import React, { useCallback } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import i18n from '../../i18n'
import apiConsumer from '../../service/api/apiConsumer'
import PropTypes from 'prop-types'

const DeleteChat = ({ chatId, onValid }) => {
  const deleteChat = useCallback(
    id =>
      apiConsumer.delete('chats', id).then(rep => onValid(rep.status === 204)),
    [onValid]
  )
  return (
    <>
      <Dialog fullWidth={true} maxWidth={'xs'} open onClose={() => onValid()}>
        <DialogTitle>{i18n.t('chat:delete chat')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {i18n.t('chat:are you sure to delete chat')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onValid()} color='primary'>
            {i18n.t('main:no')}
          </Button>
          <Button onClick={() => deleteChat(chatId)} color='primary' autoFocus>
            {i18n.t('main:yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DeleteChat.propTypes = {
  /** chat id */
  chatId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValid: PropTypes.func
}

export default DeleteChat
