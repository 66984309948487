import React, { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import { FormContentStore } from './FormContent'
import { Grid, Alert } from '@mui/material'
import { FormContentTabsStore } from './FormContentTabs'
import {
  setStoreEditableAction,
  setStoreValidAction
} from '../../store/form/formStoreAction'
import { connect } from 'react-redux'
import i18n from '../../i18n'
import { checkCompleteFormValidation } from '../../service/form'
import PropTypes from 'prop-types'
import Loading from '../../core/Loading'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    width: '100%'
  },
  closeButton: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1)
  },
  error: {
    margin: '0% 5%',
    width: '90%'
  },
  button: {
    marginRight: '24px!important'
  },
  skeleton: {
    padding: theme.spacing(3)
  }
}))

const FormValidation = ({
  ready = true,
  formParams,
  onValid,
  formType,
  stateForm,
  editable,
  setStoreValid,
  setStoreEditable
}) => {
  const classes = useStyles()
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const allFormValidation = useCallback(
    stateForm => checkCompleteFormValidation(stateForm, setStoreValid),
    [setStoreValid]
  )

  const handleSubmit = useCallback(async () => {
    if (!editable) {
      onValid(null)
      return
    }
    allFormValidation(stateForm)

    // check if there are validation's errors
    if (Object.values(stateForm.valid).filter(val => val.length).length) {
      setErrorMsg(i18n.t('error:default'))
    } else {
      // create / update
      setErrorMsg('')
      setLoading(true)
      const error = await onValid(stateForm.values)
      if (error) {
        setErrorMsg(error)
      } else {
        setStoreEditable(false)
      }
      setLoading(false)
    }
  }, [stateForm, editable, setStoreEditable, onValid, allFormValidation])

  return !ready ? (
    <Loading />
  ) : (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        {formType === 'tabs' ? <FormContentTabsStore /> : <FormContentStore />}
      </Grid>
      {errorMsg ? (
        <Alert severity='error' variant='outlined' className={classes.error}>
          {errorMsg}
        </Alert>
      ) : null}
      {editable ? (
        <Grid container justifyContent='flex-end'>
          <Button
            type='submit'
            autoFocus
            onClick={handleSubmit}
            color='primary'
            className={classes.button}
          >
            {formParams?.create ? i18n.t('main:add') : i18n.t('main:edit')}
          </Button>
        </Grid>
      ) : null}
      {loading ? <Loading absolute /> : null}
    </Grid>
  )
}

FormValidation.propTypes = {
  formParams: PropTypes.object,
  ready: PropTypes.bool,
  onValid: PropTypes.func,
  formType: PropTypes.string,
  stateForm: PropTypes.object,
  editable: PropTypes.bool,
  setStoreValid: PropTypes.func,
  setStoreEditable: PropTypes.func
}

export default FormValidation

export const FormValidationStore = connect(
  state => ({
    stateForm: state.form,
    editable: state.form.editable
  }),
  dispach => ({
    setStoreValid: (valid, field) => dispach(setStoreValidAction(valid, field)),
    setStoreEditable: editable => dispach(setStoreEditableAction(editable))
  })
)(FormValidation)
