import React from 'react'
import { useParams } from 'react-router'
import SongShow from '../../../components/song/SongShow'
// import SongGuide from '../../../components/guide/SongGuide' // Todo enable song guide

const Song = () => {
  const { id } = useParams()

  return (
    <>
      <SongShow
        id={id}
      />
      {/* <SongGuide /> */}
    </>
  )
}

export default Song
