import React from 'react'
import {
  AccountCircle,
  ExitToApp,
  Settings,
  ChevronRight,
  FilterList,
  Email,
  EventAvailable,
  Home,
  Explore,
  Layers,
  Dashboard
} from '@mui/icons-material'
import i18n from '../../i18n'
import AuthService from '../../service/api/authService'
import { checkAdmin } from '../../service/api/authValidation'
import apiConsumer from '../../service/api/apiConsumer'

// service to define app left menu and notifications
// each menu element is defined by name, icon and link

let defaultMenu = [
  { divider: true },
  {
    name: i18n.t('menu:trainings'),
    icon: <Explore />,
    link: `/app/training`
  },
  {
    name: i18n.t('menu:filter'),
    icon: <FilterList />,
    link: `/app/filter`
  },
  {
    name: i18n.t('menu:massSheets'),
    icon: <EventAvailable />,
    link: `/app/masssheet`
  },
  {
    name: i18n.t('menu:parishes'),
    icon: <Home />,
    link: `/app/parishes`
  },
  { divider: true },
  { adminButton: null },
  {
    notification: 'userMessages',
    name: i18n.t('menu:chat'),
    icon: <Email />,
    link: `/app/chat`
  },
  {
    name: i18n.t('menu:profile'),
    icon: <AccountCircle />,
    link: `/app/profil`
  },
  {
    name: i18n.t('menu:settings'),
    icon: <Settings />,
    link: `/app/setting`
  },
  {
    name: i18n.t('menu:log out'),
    icon: <ExitToApp />,
    link: '#',
    func: () => {
      AuthService.logout()
    }
  }
]

export const generateMenu = categories => {
  if (checkAdmin()) {
    const id = defaultMenu.findIndex(e => 'adminButton' in e)
    if (id !== -1)
      defaultMenu[id] = {
        name: i18n.t('menu:admin'),
        icon: <Dashboard />,
        link: '/admin'
      }
  }

  return [
    {
      link: '/app',
      name: i18n.t('app:menu:all songs'),
      icon: <ChevronRight />
    },
    ...categories
      .filter(e => e.inPrimaryList)
      .map(category => ({
        link: `/app/category-${category.id}-${category.name}`,
        name: category.name,
        icon: <ChevronRight />
      })),
    {
      icon: <Layers />,
      name: i18n.t(`app:menu:others categories`),
      open: false,
      submenus: [
        ...categories
          .filter(e => !e.inPrimaryList)
          .map(category => ({
            link: `/app/category-${category.id}-${category.name}`,
            name: category.name,
            icon: <ChevronRight />
          }))
      ]
    },
    ...defaultMenu
  ]
}

export const getAppNotifications = setAppNotifications => {
  apiConsumer.get(`stats`).then(rep => {
    if (rep.status === 200) {
      setAppNotifications({
        appNotifications: rep.body?.appNotifications || [],
        userMessages: rep.body?.userMessagesCount || 0,
        adminMessages: rep.body?.adminMessagesCount || 0
      })
    }
  })
}
