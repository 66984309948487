import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import StarIcon from '@mui/icons-material/StarBorder'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

const Pricing = ({ data }) => {
  return (
    <>
      <Container disableGutters maxWidth='sm' component='main' sx={{ pb: 6 }}>
        <Typography
          component='h2'
          variant='h4'
          align='center'
          color='text.primary'
          gutterBottom
        >
          {data.title}
        </Typography>
        <Typography
          variant='h6'
          align='center'
          color='text.secondary'
          component='p'
          sx={{ pl: 3, pr: 3 }}
        >
          {data.describe}
        </Typography>
      </Container>
      <Container maxWidth='md' component='main'>
        <Grid container spacing={5} alignItems='flex-end'>
          {data.cards.map(card => (
            <Grid
              item
              key={card.title}
              xs={12}
              sm={card.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={card.title}
                  subheader={card.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={card.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center'
                  }}
                  sx={{
                    backgroundColor: theme =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700]
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2
                    }}
                  >
                    <Typography
                      component='h3'
                      variant='h4'
                      color='text.primary'
                    >
                      {card.price}€
                    </Typography>
                    <Typography variant='h6' color='text.secondary'>
                      /an
                    </Typography>
                  </Box>
                  <ul>
                    {card.describe.map(line => (
                      <Typography component='li' variant='subtitle1' key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={card.buttonVariant}>
                    {card.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default Pricing
