import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import admin from './locales/admin.json'
import app from './locales/application.json'

i18n.use(initReactI18next).init({
  resources: {
    // en
    fr: {
      app: { ...app },
      ...admin
    }
  },
  lng: 'fr',
  keySeparator: true // we do not use keys in form messages.welcome

  //   interpolation: {
  //     escapeValue: false // react already safes from xss
  //   }
})

export default i18n
