import React from 'react'
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material'
import { Close } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialogContent-root': {
        padding: theme.spacing(1)
      },
      '& .MuiDialog-paper': {
        margin: '0',
        width: '90%',
        maxWidth: '90%',
        maxHeight: '90%'
      }
    }
  },
  dialogFull: {
    '& .MuiDialog-paper': {
      height: '90%'
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialogContent-root': {
        padding: theme.spacing(1)
      },
      '& .MuiDialog-paper': {
        margin: '0',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%'
      }
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    paddingRight: '40px'
  }
}))

const DialogTitle = ({ children, onClose }) => {
  const classes = useStyles()

  return (
    <MuiDialogTitle>
      <Typography className={classes.title} noWrap>
        {children}
      </Typography>
      {onClose ? (
        <div className={classes.closeButton}>
          <IconButton aria-label='close' onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      ) : null}
    </MuiDialogTitle>
  )
}

const AppDialog = ({
  title,
  children,
  action,
  open,
  onClose,
  width = 'xs',
  fullSceen = false,
  maxHeight = 'auto'
}) => {
  const classes = useStyles()
  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { maxHeight: maxHeight } }}
        className={fullSceen ? classes.dialogFull : classes.dialog}
        fullWidth={true}
        maxWidth={width}
        open={open}
        onClose={() => (onClose ? onClose() : {})}
      >
        <DialogTitle onClose={() => (onClose ? onClose() : {})}>
          {title}
        </DialogTitle>
        <DialogContent style={{ paddingBottom: action ? 0 : '32px' }}>
          {children}
        </DialogContent>
        {action ? <DialogActions>{action}</DialogActions> : null}
      </Dialog>
    </>
  )
}

AppDialog.propTypes = {
  title: PropTypes.string,
  /** dialog content */
  children: PropTypes.node,
  /** dialog action */
  action: PropTypes.node,
  /** is opened */
  open: PropTypes.bool,
  /** close function */
  onClose: PropTypes.func,
  /** dialog size */
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  /** is full screen */
  fullSceen: PropTypes.bool,
  maxHeight: PropTypes.number
}

export default AppDialog
