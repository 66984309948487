import React, { useState, useEffect, useMemo } from 'react'
import { Pause, PlayArrow } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import getSound from './../../assets/notes/chords'
import PropTypes from 'prop-types'

const Player = ({ note, className }) => {
  const [audio, setAudio] = useState(new Audio())
  const [playing, setPlaying] = useState(false)

  const toggle = () => setPlaying(!playing)

  useMemo(() => {
    setAudio(new Audio(getSound(note)))
  }, [note, setAudio])

  useEffect(() => {
    playing ? audio.play() : audio.pause()
  }, [playing, audio])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [audio, setPlaying])

  return note ? (
    <span className={className}>
      <IconButton onClick={toggle} color='primary'>
        {playing ? <Pause /> : <PlayArrow />}
      </IconButton>
    </span>
  ) : null
}

Player.propTypes = {
  /** do re mi C D E */
  note: PropTypes.string,
  className: PropTypes.any
}

export default Player
