import React from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import NavBar from '../../../core/NavBar'
import { formatDate, sortFrenchDates } from '../../../service/functions'
import { regex } from '../../../service/regex'

const params = {
  entityName: 'parishes',
  targetUrl: 'parishes',
  mainField: 'name',
  defaultEntity: {
    id: 0,
    name: '',
    comment: '',
    email: '',
    phone: '',
    website: '',
    adress: '',
    city: '',
    postcode: '',
    country: '',
    picture2: null,
    picture: null,
    enable: false,
    priests: '',
    responsibles: []
  },
  datagridOptions: {
    rights: {
      show: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      edit: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      delete: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('parishes:field:id'),
      width: 60,
      hide: true
    },
    {
      field: 'name',
      headerName: i18n.t('parishes:field:name'),
      width: 200,
      main: true
    },
    {
      field: 'updatedAt',
      headerName: i18n.t('parishes:field:updatedAt'),
      width: 130,
      sortComparator: (v1, v2, p1, p2) => sortFrenchDates(v1, v2)
    },
    {
      field: 'isEnabled',
      headerName: i18n.t('parishes:field:enable'),
      width: 100
    },
    {
      field: 'enable',
      type: 'boolean',
      headerName: i18n.t('parishes:field:enable'),
      width: 100
    }
  ],
  formStruct: {
    size: {
      width: 'lg',
      fullHeight: true
    },
    type: 'tabs',
    elements: [
      {
        label: i18n.t('parishes:tabs:main'),
        data: [
          {
            type: 'text',
            required: true,
            field: 'name',
            label: i18n.t('parishes:field:name'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'textarea',
            required: true,
            field: 'comment',
            label: i18n.t('parishes:field:comment'),
            md: 6,
            rules: {
              min: 3,
              max: 1000,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'email',
            label: i18n.t('parishes:field:email'),
            md: 6,
            regex: regex.email,
            regexExample: 'exemple@sjtm.fr',
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'phone',
            label: i18n.t('parishes:field:phone'),
            md: 6,
            regex: regex.phone,
            regexExample: '0243568745, +33681459678',
            rules: {
              min: 3,
              max: 15,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'priests',
            label: i18n.t('parishes:field:priests'),
            md: 6,
            rules: {
              min: 3,
              max: 200,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'website',
            label: i18n.t('parishes:field:website'),
            md: 6,
            regex: regex.website,
            regexExample: 'https://exemple.fr',
            rules: {
              min: 3,
              max: 255,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'switch',
            field: 'enable',
            label: i18n.t('parishes:field:enable'),
            md: 12
          },
          {
            type: 'spacer'
          },
          {
            type: 'image',
            field: 'picture',
            imageUrl: 'parishes-picture',
            required: true,
            label: i18n.t('parishes:field:picture'),
            md: 6
          },
          {
            type: 'image',
            field: 'picture2',
            imageUrl: 'parishes-picture2',
            required: true,
            label: i18n.t('parishes:field:picture2'),
            md: 6
          }
        ]
      },
      {
        label: i18n.t('parishes:tabs:adress'),
        data: [
          {
            type: 'text',
            required: true,
            field: 'adress',
            label: i18n.t('parishes:field:adress'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'city',
            label: i18n.t('parishes:field:city'),
            md: 6,
            rules: {
              min: 3,
              max: 50,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'number',
            required: true,
            field: 'postcode',
            label: i18n.t('parishes:field:postcode'),
            md: 6,
            regex: regex.postcode,
            rules: {
              min: 3,
              max: 8,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            required: true,
            field: 'country',
            label: i18n.t('parishes:field:country'),
            md: 6,
            rules: {
              min: 3,
              max: 50,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          }
        ]
      },
      {
        label: i18n.t('parishes:tabs:responsible'),
        data: [
          {
            type: 'ParishResponsiblesEditor',
            field: 'responsibles'
          }
        ]
      }
    ]
  }
}

const formatEntities = entities =>
  entities.map(entity => {
    return formatEntity(entity)
  }) || null

const formatEntity = entity => {
  entity.updatedAt = formatDate(entity.updatedAt)
  entity.isEnabled = entity.enable ? i18n.t('main:yes') : i18n.t('main:no')
  return entity
}

const formatCRUDEntity = entity => {
  entity.postcode = entity.city.postcode
  entity.country = entity.city.country
  entity.city = entity.city.name
  return entity
}

export default function DataTable () {
  return (
    <>
      <NavBar title={i18n.t(`menu:${params.entityName}`)} />
      <CrudPage
        params={params}
        formatEntities={formatEntities}
        formatEntity={formatEntity}
        formatCRUDEntity={formatCRUDEntity}
      />
    </>
  )
}
