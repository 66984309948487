import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import apiConsumer from '../../service/api/apiConsumer'
import { FormValidationStore } from '../../components/crud/FormValidation'
import {
  initStoreStateAction,
  setStoreEditableAction,
  setStoreValidAction,
  setStoreValuesAction
} from '../../store/form/formStoreAction'
import i18n from '../../i18n'
import { setSnackbarAction } from './../../store/setting/settingStoreAction'
import { USERS_ROLES } from '../../service/api/authValidation'
import { ValidatePassword } from '../../service/functions'
import NavBar from '../../core/NavBar'
import authStore from '../../service/api/authStore'
import { Close, Edit } from '@mui/icons-material'
import { Button, Grid, IconButton } from '@mui/material'
import { regex } from '../../service/regex'

const params = {
  targetUrl: 'users',
  formStruct: {
    size: {
      width: 'md',
      fullHeight: true
    },
    elements: [
      {
        type: 'select',
        field: 'civility',
        required: true,
        label: i18n.t('users:field:civility'),
        md: 6,
        options: []
      },
      {
        type: 'text',
        field: 'email',
        required: true,
        readOnly: true,
        label: i18n.t('users:field:email'),
        md: 6,
        regex: regex.email
      },
      {
        type: 'text',
        field: 'firstname',
        required: true,
        label: i18n.t('users:field:firstname'),
        md: 6,
        regex: regex.name,
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'text',
        field: 'lastname',
        required: true,
        label: i18n.t('users:field:lastname'),
        md: 6,
        regex: regex.name,
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'text',
        field: 'phone',
        required: false,
        label: i18n.t('users:field:phone'),
        md: 6,
        regex: regex.phone
      },
      {
        type: 'spacer',
        onlyEditor: true
      },
      {
        type: 'password',
        field: 'oldPassword',
        onlyEditor: true,
        label: i18n.t('users:field:old password'),
        md: 6
      },
      {
        type: 'password',
        field: 'password',
        onlyEditor: true,
        label: i18n.t('users:field:password'),
        md: 6
      },
      {
        type: 'password',
        field: 'plainPassword',
        onlyEditor: true,
        label: i18n.t('users:field:plainPassword'),
        md: 6
      },
      {
        type: 'spacer'
      },
      {
        type: 'text',
        field: 'adress',
        required: true,
        label: i18n.t('users:field:adress'),
        md: 6,
        regex: regex.adress,
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'number',
        field: 'postcode',
        required: true,
        label: i18n.t('users:field:postcode'),
        md: 6,
        regex: regex.postcode
      },
      {
        type: 'text',
        field: 'city',
        required: true,
        label: i18n.t('users:field:city'),
        md: 6
      },
      {
        type: 'text',
        field: 'country',
        required: true,
        label: i18n.t('users:field:country'),
        md: 6
      },
      {
        type: 'switch',
        field: 'allowMailing',
        label: i18n.t('profil:allowMailing'),
        md: 12
      }
    ]
  }
}

const formatCRUDEntity = entity => {
  entity.sendPasswordRequest = false
  if ('infos' in entity) {
    entity.adress = entity.infos.adress
    entity.allowMailing = entity.infos.allowMailing
    entity.city = entity.infos.city
    entity.civility =
      entity.infos.civility !== null ? entity.infos.civility.id : ''
    entity.country = entity.infos.country
    entity.phone = entity.infos.phone
    entity.postcode = entity.infos.postcode
    entity.password = ''
    entity.plainPassword = ''
    entity.oldPassword = ''
    delete entity.infos
  }
  for (let k of USERS_ROLES) {
    if (entity.roles?.includes(k)) {
      entity.roles = k
      return entity
    }
  }

  entity.roles = ''
  return entity
}

export function Profil ({
  editable,
  initStoreState,
  setStoreValues,
  setStoreEditable,
  setStoreValid,
  setSnackbar,
  widget
}) {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    apiConsumer.get(`civilities`, false).then(rep => {
      if (rep.status === 200) {
        params.formStruct.elements.forEach((el, idx) => {
          if (el.field === 'civility') {
            params.formStruct.elements[idx].options = rep.body
          }
        })
      }
    })
    initStoreState({ ...params.formStruct })
  }, [initStoreState])

  useEffect(() => {
    if (authStore.getConnectionDatas()) {
      apiConsumer.get(`credentials`).then(async rep => {
        if (rep.status === 200) {
          if (rep.status === 200) {
            await setStoreValues(formatCRUDEntity(rep.body), false)
            setReady(true)
          }
        }
      })
    }
    return () => setReady()
  }, [setStoreValues])

  const onValid = useCallback(
    async element => {
      // check if user update his password
      if (element.password !== '') {
        let error = ValidatePassword(element.password, element.plainPassword)
        if (error) {
          setStoreValid(error, 'password')
          return error
        }
      }
      const getBadResponse = rep => {
        const err = rep?.body?.violations
          ? apiConsumer.getBadResponseMessage(rep)
          : rep?.body[0]

        if (err?.field) {
          const message = i18n.t(`error:${err.name}`, {
            name: i18n.t(`${params.entityName}:field:${err.field}`)
          })
          setStoreValid(message, err.field)
          return message
        } else if (err?.name) {
          return i18n.t(`error:${err.name}`)
        } else if (err?.status === 500) {
          return i18n.t('error:default')
        }
        return i18n.t('error:check the form')
      }

      const rep = await apiConsumer.update(params.targetUrl, element)
      if ([200, 201].includes(rep.status)) {
        setSnackbar(i18n.t('profil:profil updated'), 'success', 3)
        if (rep.body.email && !widget) {
          const user = authStore.getConnectionDatas()
          authStore.setConnectionDatas({
            ...rep.body,
            token: user.token
          })
        }
        return
      }
      return getBadResponse(rep)
    },
    [setSnackbar, setStoreValid, widget]
  )

  return (
    <>
      {widget ? (
        <Grid container justifyContent='center' sx={{ margin: 1 }}>
          <Button
            onClick={() => setStoreEditable(!editable)}
            startIcon={editable ? <Close /> : <Edit />}
          >
            {i18n.t('profil:edit my profil')}
          </Button>
        </Grid>
      ) : (
        <NavBar
          title={i18n.t('profil:my profil')}
          children={
            <IconButton
              aria-label='edit'
              onClick={() => setStoreEditable(!editable)}
            >
              {editable ? <Close /> : <Edit />}
            </IconButton>
          }
        />
      )}
      <FormValidationStore
        formType={params.formStruct.type || null}
        onValid={v => onValid(v)}
        ready={ready}
      />
    </>
  )
}

const ProfilStore = connect(
  state => ({
    editable: state.form?.editable
  }),
  dispach => ({
    setStoreValues: (values, editable) => {
      const valid = { ...values }
      Object.keys(valid).map(key => (valid[key] = ''))
      dispach(
        setStoreValuesAction({ values, valid, displayError: false, editable })
      )
    },
    setStoreValid: (valid, field) => dispach(setStoreValidAction(valid, field)),
    initStoreState: init => dispach(initStoreStateAction(init)),
    setSnackbar: (message, type, time) =>
      dispach(setSnackbarAction(message, type, time)),
    setStoreEditable: editable => dispach(setStoreEditableAction(editable))
  })
)(Profil)

export default ProfilStore
