const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

const appUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_URL_PROD
    : process.env.REACT_APP_URL_DEV;

const httpConstantes = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  apiUrl,
  appUrl,
  massSheetPublicUrl: `${appUrl}app/masssheet-:id`,
};

export default httpConstantes;
