import React from 'react'
import NavBar from '../../../core/NavBar'
import { useParams } from 'react-router'
import OpenSheetMusicDisplay from '../../../components/preview/musicSheet/OpenSheetMusicDisplay'

const SongSheet = () => {
  const { id } = useParams()
  return (
    <>
      <NavBar backArrow />
      <OpenSheetMusicDisplay src={`song_files/${id}`} />
    </>
  )
}

export default SongSheet
