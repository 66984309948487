import React, { useMemo, useState } from 'react'
import { Badge, Collapse } from '@mui/material'
import avatar from '../../assets/images/avatar.png'
import { Link } from 'react-router-dom'
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemAvatar,
  ListItemText
} from '@mui/material'
import AppNotification from './Notification'
import { connect } from 'react-redux'
import { Dehaze, ExpandLess, ExpandMore } from '@mui/icons-material'
import PropTypes from 'prop-types'
import {
  setSettingStoreValueAction,
  toggleFieldAction
} from '../../store/setting/settingStoreAction'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    '& ::-webkit-scrollbar': {
      width: 0
    }
  },
  drawerHeader: {
    color: '#fff',
    '& p': {
      color: '#fff',
      opacity: '0.6'
    }
  },
  drawerButtons: {
    overflowY: 'scroll',
    height: 'calc(100vh - 80px)',
    paddingBottom: '100px',
    paddingTop: '10px'
  },
  drawerItem: {
    '& *': {
      color: '#fff'
    }
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    whiteSpace: 'nowrap'
  },
  avatarDivider: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)'
  },
  menuDivider: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    marginTop: '10px',
    marginBottom: '10px'
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit'
  },
  submenus: {
    paddingLeft: theme.spacing(2)
  }
}))

const MenuList = ({ menus, setAppMenu }) => {
  const classes = useStyles()
  return (
    <List className={classes.root}>
      <AvatarItemStore />
      <div className={classes.drawerButtons}>
        <Menu menus={menus} setAppMenu={setAppMenu} />
      </div>
    </List>
  )
}

MenuList.propTypes = {
  menus: PropTypes.array,
  setAppMenu: PropTypes.func
}

export default MenuList

const CMenu = ({ menus, setAppMenu, toggleDrawer, drawer }) => {
  const classes = useStyles()
  const [open, setOpen] = useState({})
  useMemo(() => {
    menus.forEach((menu, index) => {
      if (menu?.open) {
        setOpen(n => ({ ...n, [index]: menu.open }))
      }
    })
  }, [setOpen, menus])

  return menus.map((menu, index) =>
    menu?.divider ? (
      <Divider
        key={index}
        className={classes.menuDivider}
        variant='middle'
        light={false}
      />
    ) : menu?.submenus ? (
      <div key={index}>
        <ListItem
          button
          className={classes.drawerItem}
          onClick={() => {
            menu.open = menu?.open ? false : true
            setOpen(n => ({ ...n, [index]: menu.open }))
            // do not auto close the drawer when clicking
            if (drawer) toggleDrawer(false)
          }}
        >
          <ListItemIcon>
            <Dehaze />
          </ListItemIcon>
          <ListItemText primary={menu.name} />
          {open[index] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={open[index]}
          timeout='auto'
          unmountOnExit
          className={classes.submenus}
        >
          <Menu menus={menu.submenus} setAppMenu={() => {}} />
        </Collapse>
      </div>
    ) : menu?.name ? (
      <MenuItem
        key={index}
        menu={menu}
        index={index}
        menus={menus}
        setAppMenu={setAppMenu}
      />
    ) : null
  )
}

const Menu = connect(
  state => ({
    drawer: state.setting.drawer
  }),
  dispach => ({
    toggleDrawer: value => dispach(toggleFieldAction('drawer', value))
  })
)(CMenu)

const MenuItem = ({ menu, index, menus, setAppMenu }) => {
  const classes = useStyles()
  return (
    <Link
      to={menu.link}
      key={index}
      className={classes.link}
      onClick={
        menu.notification
          ? () => {
              menus[index].notification = null
              setAppMenu(menus)
            }
          : null
      }
    >
      <ListItem
        onClick={() => {
          if (menu.func) menu.func()
        }}
        className={classes.drawerItem}
        button
        key={'link' in menu ? menu.link : ''}
        aria-label={`go to ${menu.name}`}
      >
        {menu.notification ? (
          <NotificationMenuIconStore menu={menu} />
        ) : (
          <>
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.name} />
          </>
        )}
      </ListItem>
    </Link>
  )
}

const AvatarItem = ({ user }) => {
  const classes = useStyles()
  const appNotification = useMemo(() => <AppNotification />, [])
  return (
    <>
      <ListItem className={classes.drawerHeader} alignItems='flex-start'>
        <ListItemAvatar aria-label='drawer avatar'>
          <Avatar alt='Fred' src={avatar} />
        </ListItemAvatar>
        <ListItemText
          classes={{
            primary: classes.ellipsis,
            secondary: classes.ellipsis
          }}
          primary={user.firstname}
          secondary={user.lastname}
        />
        {appNotification}
      </ListItem>
      <Divider
        className={classes.avatarDivider}
        variant='middle'
        light={false}
      />
    </>
  )
}

const AvatarItemStore = connect(state => ({
  user: state?.setting.user || {
    firstname: '',
    lastname: ''
  }
}))(AvatarItem)

const NotificationMenuIcon = ({ menu, notifCount, setRead }) => {
  return (
    <>
      <ListItemIcon onClick={() => setRead(menu.notification)}>
        <Badge badgeContent={notifCount} color='secondary'>
          {menu.icon}
        </Badge>
      </ListItemIcon>
      <ListItemText
        primary={menu.name}
        onClick={() => setRead(menu.notification)}
      />
    </>
  )
}

const NotificationMenuIconStore = connect(
  (state, props) => ({
    notifCount: state.setting.appNotifications[props.menu.notification] || 0
  }),
  dispach => ({
    setRead: notif =>
      dispach(setSettingStoreValueAction('appNotifications', 0, notif))
  })
)(NotificationMenuIcon)
