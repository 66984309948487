import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { setSettingStoreValueAction } from '../../store/setting/settingStoreAction'
import ProfilStore from '../globales/Profil'

const Component = ({ storeToken, setStoreToken, setStoreDarkTheme }) => {
  const { token, theme } = useParams()

  useEffect(() => setStoreToken(token), [setStoreToken, token])
  useEffect(() => setStoreDarkTheme(theme), [setStoreDarkTheme, theme])

  return !!storeToken && <ProfilStore widget />
}

export default connect(
  state => ({
    storeToken: state.setting?.user?.token
  }),
  dispach => ({
    setStoreToken: token =>
      dispach(setSettingStoreValueAction('user', { token })),
    setStoreDarkTheme: theme =>
      dispach(setSettingStoreValueAction('darkTheme', theme === 'dark'))
  })
)(Component)
