import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Grid } from '@mui/material'
import apiConsumer from '../../service/api/apiConsumer'
import { makeStyles } from '@mui/styles'
import DisplayParishBlogs from '../../components/parishes/DisplayParishBlogs'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
    '& *': {
      userSelect: 'none'
    }
  }
}))

const Component = () => {
  const classes = useStyles()
  const { id } = useParams()
  const [entities, setEntities] = useState([])

  useEffect(() => {
    document.body.style.background = 'transparent'
  })

  useEffect(() => {
    apiConsumer.getOne(`blogs/parish/widget`, id, true).then(rep => {
      if (rep.status === 200) {
        setEntities(rep.body)
      }
    })
    return () => setEntities()
  }, [setEntities, id])

  return entities ? (
    <Grid container className={classes.root}>
      <DisplayParishBlogs articles={entities} />
    </Grid>
  ) : null
}

export default Component
