import React from 'react'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import httpConstantes from '../../config/httpConstantes'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  media: {
    height: 180
  },
  content: {
    height: 160,
    '& p': {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 4,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}))

export default function MediaCard ({ parishes = [] }) {
  const classes = useStyles()

  return (
    !!parishes?.length &&
    parishes.map(e => (
      <Grid item key={e.id} lg={4} md={6} sm={6} xs={12}>
        <Link to={`/app/parish-${e.id}`}>
          <Card>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={`${httpConstantes.apiUrl}/parishes-picture/${e.id}`}
                title='Contemplative Reptile'
              />
              <CardContent className={classes.content}>
                <Typography gutterBottom variant='h5' component='h2'>
                  {e.name}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {e.comment}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      </Grid>
    ))
  )
}
