export const SONG_TYPES = [
  'Entrée',
  'Kyrie',
  'Gloria',
  'Psaume',
  'Séquence',
  'Acclamation',
  'Alléluia',
  'Prière universelle',
  'Offertoire',
  'Sanctus',
  'Anamnèse',
  'Doxologie',
  'Agnus',
  'Communion',
  'Action de grâce',
  'Envoi',
  "Invocation à l'Esprit Saint",
  'Chant à Marie'
]

export const ORDINARY_SONGS = [
  'Kyrie',
  'Gloria',
  'Alléluia',
  'Sanctus',
  'Anamnèse',
  'Doxologie',
  'Agnus'
]

const DONT_DISPLAY_HEADER_SONGS = [
  'Kyrie',
  'Gloria',
  'Alléluia',
  'Sanctus',
  'Anamnèse',
  'Doxologie',
  'Agnus',
  'Prière universelle',
  'Psaume'
]

export const dontDisplayHeaderName = header =>
  DONT_DISPLAY_HEADER_SONGS.includes(header)

export const checkOrdinaryDisplaying = (displayOrdinary, header) =>
  displayOrdinary || (!displayOrdinary && !ORDINARY_SONGS.includes(header))
