import React from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import NavBar from '../../../core/NavBar'
import { formatDate, sortFrenchDates } from '../../../service/functions'

const params = {
  entityName: 'trainings',
  targetUrl: 'trainings',
  mainField: 'name',
  defaultEntity: {
    id: 0,
    name: '',
    subname: '',
    content: '',
    ordering: 0,
    isPublished: false,
    updatedAt: null,
    createdAt: null
  },
  datagridOptions: {
    rights: {
      show: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      edit: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      delete: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('trainings:field:id'),
      width: 80,
      hide: true
    },
    {
      field: 'name',
      headerName: i18n.t('trainings:field:name'),
      width: 200,
      main: true
    },
    {
      field: 'isPublished',
      headerName: i18n.t('trainings:field:isPublished'),
      width: 100,
      type: 'boolean'
    },
    {
      field: 'updatedAt',
      headerName: i18n.t('trainings:field:updatedAt'),
      width: 120,
      sortComparator: (v1, v2, p1, p2) => sortFrenchDates(v1, v2)
    },
    {
      field: 'ordering',
      headerName: i18n.t('trainings:field:ordering'),
      width: 90
    }
  ],
  formStruct: {
    size: {
      width: 'lg',
      fullHeight: true
    },
    elements: [
      {
        type: 'text',
        required: true,
        field: 'name',
        label: i18n.t('trainings:field:name'),
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'text',
        required: false,
        field: 'subname',
        label: i18n.t('trainings:field:subname'),
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'number',
        required: false,
        field: 'ordering',
        label: i18n.t('trainings:field:ordering'),
        md: 6,
        rules: {
          minVal: 0,
          minValMessage: i18n.t(
            'error:value must be greater than or equal to zero'
          )
        }
      },
      {
        type: 'switch',
        field: 'isPublished',
        label: i18n.t('trainings:field:isPublished'),
        md: 6
      },
      {
        type: 'richText',
        field: 'content',
        required: true,
        label: i18n.t('trainings:field:content'),
        rules: {
          max: 255,
          maxMessage: i18n.t('error:the text is too long')
        }
      }
    ]
  }
}

const formatEntities = entities =>
  entities.map(entity => {
    return formatEntity(entity)
  }) || null

const formatEntity = entity => {
  entity.updatedAt = formatDate(entity.updatedAt)
  return entity
}

export default function DataTable () {
  return (
    <>
      <NavBar title={i18n.t(`menu:${params.entityName}`)} />
      <CrudPage
        params={params}
        formatEntities={formatEntities}
        formatEntity={formatEntity}
      />
    </>
  )
}
