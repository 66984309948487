import React from 'react'
import withStyles from '@mui/styles/withStyles'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle {...other}>
      <Typography noWrap>{children}</Typography>
      {onClose ? (
        <span className={classes.closeButton}>
          <IconButton aria-label='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </span>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

export default function CustomizedDialogs ({ button }) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      {button ? (
        <span style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
          {button}
        </span>
      ) : (
        <Button onClick={() => setOpen(true)}>Mentions légales</Button>
      )}
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={() => setOpen(false)}
        >
          Mentions légales
        </DialogTitle>
        <DialogContent dividers>
          <h2>Oeuvre de soutien aux eglises de france et aux prêtres</h2>
          <p>
            BP 12203
            <br />
            45012 Orléans Cedex 01
          </p>
          <h2>Responsabilité éditoriale :</h2>
          <p>Monsieur Philippe Lavaud, président de l'Oeuvre de Soutien.</p>
          <h2>Hébergement :</h2>
          <p>L'hébergement de ce site : OVH</p>
          <h2>Traitement automatisé d’informations nominatives :</h2>
          <p>
            L'Oeuvre de Soutien ne collecte sur les visiteurs du site aucune
            autre information nominative ou personnelle que celles qui lui sont
            ouvertement et volontairement fournies en particulier par
            l'intermédiaire des adresses électroniques de ses correspondants.
            <br />
            L'Oeuvre de Soutien est enregistré à la CNIL depuis le 22-09-2019
            sous la désignation N° DPO-70746.
            <br />
            Vous disposez d'un droit d'accès, de modification, de rectification
            et de suppression des données vous concernant (article 34 de la loi
            "Informatique et Libertés" du 6 janvier 1978). Pour exercer ce
            droit, adressez-vous à l'Oeuvre de Soutien.
          </p>
          <h2>Sécurité de vos paiements :</h2>
          <p>
            Pour la sécurité de vos paiements nous utilisons "Mercanet",
            solution de paiement par carte bancaire mis au point par la BNP.
            <br />
            Avec ce système, votre paiement par carte s'effectue directement sur
            le serveur sécurisé de notre banque. A aucun moment votre numéro de
            carte ne nous sera communiqué. Les échanges sont cryptés et
            sécurisés.
            <br />
            Pour votre sécurité, votre carte sera systématiquement vérifiée par
            le serveur bancaire, et le paiement sera autorisé par votre banque.
          </p>
          <h2>Contenu :</h2>
          <p>
            De nombreux articles de ce site ont paru dans la lettre de l'Oeuvre
            de Soutien.
            <br />
            Tout le contenu de ce site, textes, représentations iconographiques
            et photographiques relève de la législation française et
            internationale sur le droit d’auteur et la propriété intellectuelle.
            <br />
            Toute reproduction est formellement interdite sans l’accord écrit de
            l’Œuvre de Soutien.
          </p>
        </DialogContent>
      </Dialog>
    </>
  )
}
