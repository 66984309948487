import React, { useCallback, useState } from 'react'
import AppDialog from '../unclassified/AppDialog'
import i18n from '../../i18n'
import { Alert, Autocomplete } from '@mui/lab'
import { Button, TextField } from '@mui/material'
import apiConsumer from '../../service/api/apiConsumer'
import PropTypes from 'prop-types'

const subjectOptions = [
  i18n.t('chat:crud:bug'),
  i18n.t('chat:crud:increase function'),
  i18n.t('chat:crud:new function'),
  i18n.t('chat:crud:text')
]

const CreateChat = ({
  open,
  disableOptions,
  onValid,
  onClose,
  parish = null
}) => {
  const [subject, setSubject] = useState({ value: '', valid: '' })
  const [message, setMessage] = useState({ value: '', valid: '' })
  const [errorMsg, setErrorMsg] = useState('')
  const [sending, setSending] = useState(false)

  const sendMessage = useCallback(async () => {
    if (subject.value.length < 3 || subject.value.length > 255) {
      setSubject(e => ({
        value: e.value,
        valid: i18n.t(
          subject.value.length < 3
            ? 'error:the text is too short'
            : 'error:the text is too long'
        )
      }))
    } else if (message.value.length < 4 || message.value.length > 800) {
      setMessage(e => ({
        value: e.value,
        valid: i18n.t(
          message.value.length < 4
            ? 'error:the text is too short'
            : 'error:the text is too long'
        )
      }))
    } else {
      if (!sending) {
        setSending(true)
        apiConsumer
          .create('chats', {
            subject: subject.value,
            content: message.value,
            parish: parish
          })
          .then(rep => {
            if (rep.status === 201) {
              setSubject({ value: '', valid: '' })
              setMessage({ value: '', valid: '' })
              setErrorMsg('')
              onValid(rep.body)
            } else {
              setErrorMsg(i18n.t('error:default'))
            }
            setSending(false)
          })
      }
    }
  }, [
    subject,
    message,
    setSubject,
    setMessage,
    setErrorMsg,
    onValid,
    setSending,
    sending,
    parish
  ])

  return (
    <>
      <AppDialog
        title={i18n.t('chat:crud:new chat')}
        action={
          <Button onClick={() => sendMessage()} color='primary' autoFocus>
            {i18n.t('chat:crud:send')}
          </Button>
        }
        open={open}
        width='xs'
        onClose={onClose}
      >
        <Autocomplete
          id='standard'
          freeSolo
          value={subject.value}
          onChange={(e, value) => setSubject({ value, valid: '' })}
          options={disableOptions ? [] : subjectOptions}
          getOptionLabel={option => option || ''}
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              label={i18n.t('chat:crud:subject')}
              onChange={e => setSubject({ value: e.target.value, valid: '' })}
              helperText={subject.valid}
              error={subject.valid !== ''}
            />
          )}
        />
        <TextField
          style={{ margin: '8px 0 16px 0' }}
          variant='standard'
          label={i18n.t('chat:crud:message')}
          multiline
          fullWidth
          maxRows={8}
          value={message.value}
          onChange={e => setMessage({ value: e.target.value, valid: '' })}
          helperText={message.valid}
          error={message.valid !== ''}
        />
        {errorMsg ? (
          <Alert style={{ marginTop: 20 }} severity='error' variant='outlined'>
            {errorMsg}
          </Alert>
        ) : null}
      </AppDialog>
    </>
  )
}

CreateChat.propTypes = {
  open: PropTypes.bool,
  disableOptions: PropTypes.bool,
  onValid: PropTypes.func,
  onClose: PropTypes.func,
  parish: PropTypes.number
}

export default CreateChat
