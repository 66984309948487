import { Grid, Button, ButtonGroup, Typography } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import NavBar from '../../../core/NavBar'
import apiConsumer from '../../../service/api/apiConsumer'
import i18n from './../../../i18n'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import SearchBox, {
  defaultSearch
} from './../../../components/unclassified/SearchBox'
import Loading from '../../../core/Loading'
import ListMassSheet from './../../../components/massSheet/ListMassSheet'
import { setSettingStoreValueAction } from '../../../store/setting/settingStoreAction'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  buttons: {
    margin: theme.spacing(2, 0)
  },
  typo: {
    width: '90%',
    margin: '10% 5%!important',
    textAlign: 'center'
  }
}))

const Component = ({ search, setSearch, massSheets, setMassSheets }) => {
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const onSearch = useCallback(
    s => {
      setLoading(true)
      if (s?.value) {
        apiConsumer.get(`mass_sheets/search/${s.value}`).then(rep => {
          if (rep.status === 200) {
            setMassSheets(rep.body)
          }
          setLoading(false)
        })
      } else {
        apiConsumer.get(`mass_sheets/subscribed`).then(rep => {
          if (rep.status === 200) {
            setMassSheets(rep.body)
          }
          setLoading(false)
        })
      }
    },
    [setMassSheets, setLoading]
  )

  useMemo(() => {
    if (!massSheets) {
      onSearch()
      setSearch(defaultSearch)
    }
  }, [massSheets, onSearch, setSearch])

  return (
    <>
      <NavBar
        title={i18n.t('menu:massSheets')}
        children={
          <SearchBox
            label={i18n.t('app:massSheets:search')}
            search={search}
            setSearch={setSearch}
            onSearch={onSearch}
            searchButton
          />
        }
      />
      <Grid container className={classes.root} justifyContent='flex-end'>
        <Grid item className={classes.buttons}>
          <ButtonGroup variant='text'>
            <Button onClick={() => history.push('/app/celebrations')}>
              {i18n.t('app:massSheets:celebrations')}
            </Button>
            <Button onClick={() => history.push('/app/create-masssheet')}>
              {i18n.t('app:massSheets:my massSheets')}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          {massSheets?.length ? (
            <ListMassSheet massSheets={massSheets} />
          ) : search?.value ? (
            <Typography className={classes.typo}>
              {i18n.t('app:massSheets:search not found')}
            </Typography>
          ) : !loading ? (
            <Typography className={classes.typo}>
              {i18n.t('app:massSheets:no massSheet')}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      {loading ? <Loading absolute /> : null}
    </>
  )
}

export default connect(
  state => ({
    search: state.setting?.massSheetsSearch || defaultSearch,
    massSheets: state.setting?.massSheets
  }),
  dispach => ({
    setSearch: value =>
      dispach(setSettingStoreValueAction('massSheetsSearch', value)),
    setMassSheets: value =>
      dispach(setSettingStoreValueAction('massSheets', value))
  })
)(Component)
