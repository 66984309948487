import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { setSettingStoreValueAction } from '../../store/setting/settingStoreAction'
import apiConsumer from '../../service/api/apiConsumer'
import { MusicFilesList } from '../../components/song/SongsFilesList'
import { Grid } from '@mui/material'

const AudioWidget = ({ storeToken, setStoreToken, setStoreDarkTheme }) => {
  const { token, theme, id } = useParams()
  const [list, setList] = useState([])

  useEffect(() => setStoreToken(token), [setStoreToken, token])
  useEffect(() => setStoreDarkTheme(theme), [setStoreDarkTheme, theme])

  useEffect(() => {
    if (storeToken) {
      apiConsumer.getOne(`songs-file-list`, id).then(rep => {
        if (rep.status === 200)
          setList(rep.body.files.filter(e => e.type === 'audio'))
      })
    }
  }, [storeToken, id])

  return (
    !!list?.length && (
      <Grid sx={{ margin: 2 }}>
        <MusicFilesList list={list} />
      </Grid>
    )
  )
}

export default connect(
  state => ({
    storeToken: state.setting?.user?.token
  }),
  dispach => ({
    setStoreToken: token =>
      dispach(setSettingStoreValueAction('user', { token })),
    setStoreDarkTheme: theme =>
      dispach(setSettingStoreValueAction('darkTheme', theme === 'dark'))
  })
)(AudioWidget)
