import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Hidden, IconButton, Typography, ButtonGroup, Button } from "@mui/material";
import apiConsumer from "../../service/api/apiConsumer";
import Loading from "../../core/Loading";
import SongInfos from "./SongInfos";
import CustomError from "../../pages/error/CustomError";
import i18n from "../../i18n";
import { Close, MusicNote, Search } from "@mui/icons-material";
import SongContent from "./SongContent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setSettingStoreValueAction } from "../../store/setting/settingStoreAction";
import { checkIsSubscriber } from "../../service/api/authValidation";
import NeedSubscribe from "./../../pages/error/NeedSubscribe";
import SongSetting from "./SongSetting";
import NavBar from "../../core/NavBar";
import { defaultSongSettings } from "../../config/constants";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link, useHistory } from "react-router-dom";
import SongProjection from "./SongProjection";

const notFoundParams = {
  icon: <Search fontSize={"large"} />,
  message: i18n.t("app:error:song not found"),
  button: i18n.t("app:error:go back home"),
  link: "/app",
};

const errorParams = {
  icon: <Close fontSize={"large"} />,
  message: i18n.t("app:error:default"),
  button: i18n.t("app:error:go back home"),
  link: "/app",
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%!important",
    margin: "0px!important",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
    //disable select
    webkitUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  content: {
    width: "100%",
    minHeight: "calc(100vh - 180px)",
    marginTop: theme.spacing(0),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "200px",
    },
  },
}));

const SongShow = ({ id, setCurrentSong, isSubscriber }) => {
  const classes = useStyles();
  const [song, setSong] = useState(null);
  const history = useHistory();

  const [settings, setSettings] = useState({
    ...defaultSongSettings,
    ...(localStorage.getItem("songSettings") !== null
      ? {
        ...JSON.parse(localStorage.getItem("songSettings")),
        tone: 0, // set all time tone 0 when load song
      }
      : {}),
  });

  useEffect(() => {
    if (!song && isSubscriber) {
      apiConsumer.getOne(`songs`, id).then((rep) => {
        if (rep.status === 200) {
          setSong(rep.body || {});
          setSettings((s) => ({ ...s, toneName: rep.body.tones }));
          setCurrentSong(`${id}-${rep.body.name}`);
        } else if (rep.status === 404) {
          setSong({ notFound: true });
        } else {
          setSong({ error: rep.status });
        }
      });
    } else if (!isSubscriber) {
      setSong({ error: 403 });
    }
  }, [setSong, setSettings, id, song, setCurrentSong, isSubscriber]);

  useEffect(() => {
    // set saved tone
    if (song !== null) {
      setSettings((s) => ({
        ...s,
        tone: settings.savedTones[`${song.number}-${song.name}`] || 0,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [song]);

  const saveSettings = useCallback((settings) => {
    localStorage.setItem("songSettings", JSON.stringify({ ...settings }));
  }, []);

  const setTone = useCallback(
    (val) => {
      setSettings((s) => {
        s.savedTones[`${song.number}-${song.name}`] = val;
        saveSettings({ ...s, tone: val });
        return { ...s, tone: val };
      });
    },
    [setSettings, song, saveSettings]
  );

  if (song && song?.name) {
    const pdfFiles = song.files.filter((file) => file.type === "pdf");
    const xmlFiles = song.files.filter((file) => file.type === "xmlSheet");

    // auto redirect to pdf if only pdf file exist
    if (
      song.content.text.length === 0 &&
      song.files.length === 1 &&
      pdfFiles.length === 1
    ) {
      history.replace(`/app/music-pdf-${pdfFiles[0].id}`);
    }

    const isPhone = window.innerWidth < 800;

    return (
      <>
        <NavBar
          title={
            <Hidden smDown implementation="css">
              {`${song.number} - ${song.name}`}
            </Hidden>
          }
          backArrow
          children={
            <> {settings.displayChords ? (
              <Grid container justifyContent="flex-end" sx={{ mt: 0, width: 'auto' }}>
                {/* <Typography
                  sx={{ fontSize: "16px", mt: "6px", mr: "8px" }}
                  align="right"
                  aria-label="song tone"
                >
                  Capo
                </Typography> */}
                <ButtonGroup size="small" align="right">
                  {
                    (!isPhone ? [0, 1, 2, 3, 4, 5, 6] : - settings.tone < 3 ? [0, 1, 2, 3] : -settings.tone === 3 ? [0, 2, 3, 4] : [0, 3, 4, 5]).map(e =>
                      <Button
                        key={e}
                        onClick={() => setTone(-e)}
                        variant={e === -settings.tone ? 'contained' : null}
                        sx={{ borderRadius: '4px !important', minWidth: isPhone ? null : '25px !important', width: isPhone ? null : '25px' }}
                      >{e}</Button>
                    )
                  }
                </ButtonGroup>
              </Grid>
            ) : null}
              {pdfFiles.length ? (
                <Link to={`/app/music-pdf-${pdfFiles[0].id}`}>
                  <IconButton sx={{ m: 1 }}>
                    <PictureAsPdfIcon fontSize="small" />
                  </IconButton>
                </Link>
              ) : null}
              {xmlFiles.length ? (
                <Link to={`/app/music-sheet-${xmlFiles[0].id}`}>
                  <IconButton sx={{ m: 1 }}>
                    <MusicNote fontSize="small" />
                  </IconButton>
                </Link>
              ) : null}

              <SongSetting
                settings={settings}
                setSettings={setSettings}
                autoSaveField="songSettings"
                songTitle={`${song.number}-${song.name}`}
              />
            </>
          }
        />
        <Grid
          aria-label="song"
          container
          spacing={3}
          className={classes.container}
        >
          <Hidden smUp implementation="css">
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "20px" }} aria-label="song name">
                {song.number} - {song.name}
              </Typography>
            </Grid>
          </Hidden>
          {song.content && settings.projectorMode ? (
            <SongProjection
              content={[...song.content.text]}
              settings={settings}
            />
          ) : null}
          <div className={classes.content}>

            <Grid container spacing={2} sx={{ margin: "auto" }}>
              {song.content ? (
                <SongContent
                  content={[...song.content.text]}
                  settings={settings}
                />
              ) : null}
            </Grid>
          </div>
          <SongInfos song={song} />
        </Grid >
      </>
    );
  }

  return !song ? (
    <Loading />
  ) : song.notFound ? (
    <CustomError params={notFoundParams} />
  ) : song.error === 401 || song.error === 403 ? (
    <NeedSubscribe message={i18n.t("app:error:bad song access")} />
  ) : song.error ? (
    <CustomError params={errorParams} />
  ) : (
    <Loading />
  );
};

SongShow.propTypes = {
  /** song id */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setCurrentSong: PropTypes.func,
  isSubscriber: PropTypes.bool,
};

export default connect(
  (state) => ({
    isSubscriber: checkIsSubscriber(state.setting?.user.role),
  }),
  (dispach) => ({
    setCurrentSong: (value) => {
      dispach(setSettingStoreValueAction("currentSong", value));
    },
  })
)(SongShow);
