import React from 'react'
import { makeStyles } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'
import { Grid, Typography } from '@mui/material'
import i18n from '../i18n'
import PropTypes from 'prop-types'

// loading widget

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '80vh',
    flexDirection: 'column'
  },
  absolute: {
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: '9999',
    minHeight: '-webkit-fill-available',
    flexDirection: 'column'
  },
  text: {
    padding: theme.spacing(3)
  }
}))

const Loading = ({ absolute, text }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      className={absolute ? classes.absolute : classes.root}
    >
      <CircularProgress />
      {text ? (
        <Typography className={classes.text}>
          {i18n.t('main:loading')}
        </Typography>
      ) : null}
    </Grid>
  )
}

Loading.propTypes = {
  /** position absolute inside page */
  absolute: PropTypes.bool,
  /** display loading */
  text: PropTypes.bool
}

export default Loading
