import React from 'react'
import { makeStyles } from '@mui/styles'
import DemoPhone from '../screenshots/demoPhone.png'
import { Stack } from '@mui/material'

const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
    height: 610,
    position: 'relative',
    zIndex: 15
  },
  phone: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  image: {
    position: 'absolute',
    top: 15,
    left: 20,
    width: 260,
    borderRadius: 30
  }
}))

const PhonePreview = ({ image, video, children }) => {
  const classes = useStyles()
  return (
    <Stack alignItems='center'>
      <div className={classes.root}>
        {video ? (
          <video className={classes.image} autoPlay muted loop>
            <source src={video} type='video/mp4' />
          </video>
        ) : image ? (
          <img alt='phone preview' className={classes.image} src={image} />
        ) : (
          <div className={classes.image}>{children}</div>
        )}
        <img alt='phone' className={classes.phone} src={DemoPhone} />
      </div>
    </Stack>
  )
}

export default PhonePreview
