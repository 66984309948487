import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import RichTextPreview from '../../../components/preview/RichTextPreview'
import NavBar from '../../../core/NavBar'
import apiConsumer from '../../../service/api/apiConsumer'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  subname: {
    margin: theme.spacing(2) + '!important'
  },
  richText: {
    margin: theme.spacing(2) + '!important'
  }
}))

const Component = () => {
  const classes = useStyles()
  const { id } = useParams()
  const [training, setTraining] = useState({})

  useEffect(() => {
    apiConsumer.getOne(`trainings/app`, id).then(rep => {
      if (rep.status === 200) {
        setTraining(rep.body)
      }
    })
    return () => setTraining()
  }, [id, setTraining])

  return !training ? null : (
    <>
      <NavBar title={training?.name || ''} backArrow />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography color='textSecondary' className={classes.subname}>
            {training.subname || ''}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.richText}>
          <RichTextPreview value={training.content} />
        </Grid>
      </Grid>
    </>
  )
}

export default Component
