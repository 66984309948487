import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import SongsList from "../../../components/song/SongsList";
import { searchIn, searchNumberIn } from "../../../service/functions";
import i18n from "../../../i18n";
import NavBar from "../../../core/NavBar";
import SearchBox, {
  defaultSearch,
} from "../../../components/unclassified/SearchBox";
import { connect } from "react-redux";
import {
  setSettingStoreValueAction,
} from "../../../store/setting/settingStoreAction";
import { MusicNote } from "@mui/icons-material";
import FloationActionButton from "../../../components/unclassified/FloationActionButton";
import { useHistory } from "react-router-dom";
import { CircularProgress, Box } from '@mui/material';

const filterSongs = (list, category, search, searchNumber, primaryCategoriesList) => {
  if (category === -1 && search === "" && searchNumber === "") {
    // display all inPrimaryList
    return list.filter(
      (song) =>
        !song.category || primaryCategoriesList.includes(song.category?.id)
    );
  } else if (category === -1 && search !== "") {
    // display search
    return list.filter(
      (song) =>
        searchIn(song.name, search) || searchNumberIn(song.number, search)
    );
  } else if (category === -1 && searchNumber !== "") {
    // display search
    return list.filter(
      (song) =>
        searchNumberIn(song.number, searchNumber)
    );
  } else if (search === "" && searchNumber === "") {
    // display category
    return list.filter(
      (song) => song.category && category === song.category.id
    );
  } else if (searchNumber !== "") {
    // return category search
    return list.filter(
      (song) =>
      (song.category &&
        category === song.category.id &&
        searchNumberIn(song.number, searchNumber)
      )
    );
  } else {
    // return category search
    return list.filter(
      (song) =>
        (song.category &&
          category === song.category.id &&
          searchIn(song.name, search)) ||
        searchNumberIn(song.number, search) ||
        searchNumberIn(song.number, searchNumber)
    );
  }
};

const getNavTitle = (menu) => {
  const title = menu.filter(
    (m) => m.link === decodeURI(window.location.pathname)
  );
  return title.length && "name" in title[0] ? title[0].name : "";
};

const getCategoriesPrimaryList = (arr) =>
  (arr || []).map((e) => e.inPrimaryList && e.id).filter(Boolean);

const Category = ({
  menu,
  songList = [],
  currentSong,
  setSearch,
  search,
  setSearchNumber,
  searchNumber,
  primaryCategoriesList,
  songListScroll,
  setSongListScroll,
  categoryName,
  setCategoryName,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const [initScroll, setInitScroll] = useState(0);

  // auto reset search value
  useEffect(() => {
    const newTitle = getNavTitle(menu);
    if (categoryName !== newTitle) {
      setCategoryName(newTitle)
      setSearch({ ...defaultSearch, title: newTitle });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, menu, categoryName]);

  // useEffect(() => {
  //   const newTitle = getNavTitle(menu);
  //   if (searchNumber.title !== newTitle) {
  //     setSearchNumber({ ...defaultSearch });
  //   }
  // }, [searchNumber, setSearchNumber, menu, id, initScroll, setInitScroll]);


  // auto set scroll position when return to the page
  useEffect(() => {
    if (songListScroll?.categoryId === (id ?? 0) && !(search.value || searchNumber.value)) {
      setInitScroll(songListScroll?.position ?? 0);
      console.log(songListScroll?.position);

    } else if (songListScroll.position !== 0) {
      setSongListScroll({ categoryId: (id ?? 0), position: 0 })
      setInitScroll(initScroll === 0 ? 1 : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, songListScroll?.categoryId, initScroll, setSongListScroll, search.value, searchNumber.value]);

  const scrollHandler = useCallback(
    (event) =>
      setSongListScroll({ categoryId: (id ?? 0), position: event.scrollOffset }),
    [id, setSongListScroll]
  );

  return (
    <>
      <NavBar
        title={categoryName}
        children={
          <>
            <SearchBox
              search={search}
              setSearch={setSearch}
              isNumberMode={false}
            />
            <SearchBox
              search={searchNumber}
              setSearch={setSearchNumber}
              isNumberMode={true}
            />
          </>
        }
      />
      <SongsList
        onScroll={scrollHandler}
        scrollOffset={initScroll}
        list={
          songList
            ? filterSongs(
              songList,
              id ? parseInt(id) : -1,
              search.value,
              searchNumber.value,
              primaryCategoriesList
            )
            : null
        }
        emptyMessage={
          songList.length === 0 ?
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="60vh"
            >
              <CircularProgress />
            </Box>
            : search.value === ""
              ? i18n.t("app:song:no songs")
              : i18n.t("main:emptySearch")
        }
      />
      {currentSong ? (
        <FloationActionButton
          onClick={() => history.push(`/app/song-${currentSong}`)}
          icon={<MusicNote sx={{ color: "white" }} />}
        />
      ) : null}
    </>
  );
};

export default connect(
  (state) => ({
    menu: state.setting.appMenu,
    categoryName: state.setting.categoryName,
    currentSong: state.setting.currentSong,
    search: state.setting?.search || { ...defaultSearch },
    searchNumber: state.setting?.searchNumber || { ...defaultSearch },
    songList: state.setting.appDatas?.song,
    primaryCategoriesList: getCategoriesPrimaryList(
      state.setting.appDatas?.category
    ),
    songListScroll: state.setting.songListScroll,
  }),
  (dispach) => ({
    setSearch: (value) => {
      dispach(setSettingStoreValueAction("search", value))
      dispach(setSettingStoreValueAction("searchNumber", null))
    },
    setSearchNumber: (value) => {
      dispach(setSettingStoreValueAction("searchNumber", value))
      dispach(setSettingStoreValueAction("search", null))
    },
    setCategoryName: (value) => {
      dispach(setSettingStoreValueAction("categoryName", value))
    },
    setSongListScroll: (value) =>
      dispach(setSettingStoreValueAction("songListScroll", value)),
  })
)(Category);
