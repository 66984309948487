import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography
} from '@mui/material'
import { Delete, Send } from '@mui/icons-material'
import i18n from '../../i18n'
import apiConsumer from '../../service/api/apiConsumer'
import { setSnackbarAction } from '../../store/setting/settingStoreAction'
import DialogPrompt from '../crud/DialogPrompt'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import { makeStyles } from '@mui/styles'
import { regex } from '../../service/regex'
import { checkIsAdmin } from '../../service/api/authValidation'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0!important'
  },
  input: {
    width: '100%',
    maxWidth: 300
  },
  inputButton: {
    padding: 0,
    paddingRight: 4
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  }
}))

const ParishResponsiblesEditor = ({
  id,
  value,
  editable,
  setValue,
  setSnackbar,
  element,
  role
}) => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [deleteEmail, setDeleteEmail] = useState('')

  const isAdmin = useMemo(() => checkIsAdmin(role), [role])

  const addResponsible = useCallback(() => {
    if (email.match(regex.email)) {
      apiConsumer
        .request('POST', `parishes/${id}/responsible`, { email })
        .then(rep => {
          if (rep.status === 200) {
            setValue(
              [...value, rep.body].filter(
                (v, i, a) => a.findIndex(el => el.email === v.email) === i
              ),
              '',
              element.field
            )
            setEmail('')
          } else if (rep?.body[0].name) {
            setSnackbar(i18n.t(`error:${rep.body[0].name}`), 'error', 3)
          } else {
            setSnackbar(i18n.t(`error:default`), 'error', 3)
          }
        })
    } else {
      setSnackbar(i18n.t(`error:bad.email`), 'error', 3)
    }
  }, [id, email, setSnackbar, setEmail, setValue, value, element])

  const deleteResponsible = useCallback(
    email => {
      if (!email) return
      apiConsumer
        .request('DELETE', `parishes/${id}/responsible`, { email })
        .then(rep => {
          if (rep.status === 204) {
            setValue(
              value.filter(el => el.email !== email),
              '',
              element.field
            )
          } else if (rep?.body[0].name) {
            setSnackbar(i18n.t(`error:${rep.body[0].name}`), 'error', 3)
          } else {
            setSnackbar(i18n.t(`error:default`), 'error', 3)
          }
        })
    },
    [id, setSnackbar, setValue, value, element]
  )

  return !id ? (
    <Grid item xs={12}>
      <Typography>{i18n.t('parishes:members:need creation')}</Typography>
    </Grid>
  ) : (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item md={6} xs={12}>
          <Typography color='textSecondary'>
            {i18n.t('parishes:members:responsible')}
          </Typography>
          <List>
            {value.length ? (
              value.map((e, i) => (
                <ListItem key={i} divider={value.length - 1 !== i}>
                  <ListItemAvatar>
                    <Avatar>{e.firstname[0] + e.lastname[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${e.firstname} ${e.lastname}`}
                    secondary={e.email}
                  />
                  {editable && (value.length - 1 || isAdmin) ? (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => setDeleteEmail(e.email)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography>{i18n.t('parishes:members:no')}</Typography>
              </Grid>
            )}
          </List>
        </Grid>
        {editable ? (
          <Grid item md={6} xs={12}>
            <Typography color='textSecondary' className={classes.marginBottom}>
              {i18n.t('parishes:members:add responsible')}
            </Typography>
            <TextField
              variant='standard'
              className={classes.input}
              label={i18n.t('parishes:members:email')}
              value={email}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter' && e.ctrlKey) addResponsible()
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={classes.inputButton}
                    onClick={() => addResponsible()}
                    color='primary'
                  >
                    <Send />
                  </IconButton>
                )
              }}
            />
          </Grid>
        ) : null}
      </Grid>
      <DialogPrompt
        open={!!deleteEmail}
        params={{
          title: i18n.t(`parishes:members:delete`, {
            entity: deleteEmail
          }),
          msg: i18n.t(`parishes:members:are you sure`),
          yesButton: i18n.t('main:yes'),
          noButton: i18n.t('main:no')
        }}
        value={deleteEmail}
        onValid={v => {
          setDeleteEmail('')
          deleteResponsible(v)
        }}
      />
    </>
  )
}

ParishResponsiblesEditor.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.object,
  value: PropTypes.array,
  setValue: PropTypes.func,
  setSnackbar: PropTypes.func,
  role: PropTypes.string
}

export default ParishResponsiblesEditor

export const ParishResponsiblesEditorStore = connect(
  (state, ownProps) => ({
    id: state.form?.values.id,
    value: state.form?.values[ownProps.element.field] || [],
    editable: state.form.editable,
    role: state.setting.user.role
  }),
  dispach => ({
    setSnackbar: (message, type, time) =>
      dispach(setSnackbarAction(message, type, time)),
    setValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(ParishResponsiblesEditor)
