import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  header: {
    margin: theme.spacing(5, 0, 4, 0),
    fontSize: '1.3em',
    fontWeight: 100
  },
  subheader: {
    margin: theme.spacing(3, 2),
    fontSize: '1.15em',
    fontWeight: 100
  },
  list: {
    paddingTop: 6
  },
  blockquote: {
    borderLeft: '2px solid',
    borderLeftColor: theme.palette.divider,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 5),
    padding: theme.spacing(0.4, 0, 0.4, 2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1),
      padding: theme.spacing(0.2, 0, 0.2, 1)
    }
  }
}))

let elKey = 0

export const Element = ({ attributes, children, element }) => {
  const classes = useStyles()

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote
          key={elKey++}
          {...attributes}
          className={classes.blockquote}
        >
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul key={elKey++} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h2 key={elKey++} {...attributes} className={classes.header}>
          {children}
        </h2>
      )
    case 'heading-two':
      return (
        <h3 key={elKey++} {...attributes} className={classes.subheader}>
          {children}
        </h3>
      )
    case 'list-item':
      return (
        <li key={elKey++} {...attributes} className={classes.list}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol key={elKey++} {...attributes} className={classes.list}>
          {children}
        </ol>
      )
    case 'left':
      return (
        <div key={elKey++} style={{ textAlign: 'left' }} {...attributes}>
          {children}
        </div>
      )
    case 'center':
      return (
        <div key={elKey++} style={{ textAlign: 'center' }} {...attributes}>
          {children}
        </div>
      )
    case 'right':
      return (
        <div key={elKey++} style={{ textAlign: 'right' }} {...attributes}>
          {children}
        </div>
      )
    default:
      return (
        <div key={elKey++} {...attributes}>
          {children}
        </div>
      )
  }
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong key={elKey++}>{children}</strong>
  }

  if (leaf.italic) {
    children = <em key={elKey++}>{children}</em>
  }

  if (leaf.underline) {
    children = <u key={elKey++}>{children}</u>
  }

  return (
    <span key={elKey++} {...attributes}>
      {children}
    </span>
  )
}
