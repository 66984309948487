import React, { useCallback } from "react";
import { MoreVert } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import ProperMenu from "../menu/ProperMenu";
import { List, ListItem, Typography } from "@mui/material";
import i18n from "../../i18n";
import AppSlider from "../menu/AppSlider";
import AppSwitch from "../menu/AppSwitch";
import { ChordParser } from "../../assets/AppChordPro";
import NotePlayer from "./NotePlayer";
import { isPossibleTwoColums } from "./../../service/functions";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  list: {
    width: "250px",
    maxHeight: "95vh",
  },
  toneButton: {
    position: "absolute",
    right: "24px",
    top: "0px",
  },
}));

const SongSetting = ({
  settings,
  setSettings,
  autoSaveField = "songSettings",
  disableTwoColumns = false,
  songTitle,
}) => {
  const classes = useStyles();

  const set = useCallback(
    (field, val) =>
      setSettings((s) => {
        if (field === "tone") {
          s.savedTones[songTitle] = val;
        }
        return { ...s, [field]: val };
      }),
    [setSettings, songTitle]
  );

  const chordParser = new ChordParser();
  const toneName = settings.toneName
    ? chordParser.transposeChord(settings.toneName, settings.tone)
    : null;
  const toneValue = chordParser.getNote(toneName);

  const saveSettings = useCallback(() => {
    localStorage.setItem(autoSaveField, JSON.stringify(settings));
  }, [settings, autoSaveField]);

  return (
    <ProperMenu
      aria-label="song settings"
      autoHide={false}
      icon={<MoreVert />}
      onClose={() => saveSettings()}
    >
      <List className={classes.list}>
        <ListItem button>
          <AppSlider
            value={settings.fontSize}
            onChange={set}
            min={10}
            max={40}
            field={"fontSize"}
            label={i18n.t("app:song:settings:font size", {
              value: Math.round((settings.fontSize - 10) * 20),
            })}
          />
        </ListItem>
        <ListItem button>
          <AppSlider
            value={settings.tone}
            onChange={set}
            min={-6}
            max={6}
            field={"tone"}
            label={
              <>
                {i18n.t("app:song:settings:tone", {
                  value: settings.tone,
                })}
                {toneName ? (
                  <>
                    {` : ${toneName}`}
                    <NotePlayer
                      note={toneValue}
                      className={classes.toneButton}
                    />
                  </>
                ) : null}
                <br />
                <Typography variant="caption">
                  {i18n.t("app:song:settings:capo", {
                    value: -settings.tone,
                  })}
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem button>
          <AppSwitch
            value={settings.chorusEach}
            onChange={() => set("chorusEach", !settings.chorusEach)}
            field={"chorusEach"}
            label={i18n.t("app:song:settings:chorus each")}
          />
        </ListItem>
        {!disableTwoColumns && isPossibleTwoColums() ? (
          <ListItem button>
            <AppSwitch
              value={settings.allowTwoColumns}
              onChange={() => set("allowTwoColumns", !settings.allowTwoColumns)}
              field={"allowTwoColumns"}
              label={i18n.t("app:song:settings:allow two columns")}
            />
          </ListItem>
        ) : null}
        <ListItem button>
          <AppSwitch
            value={settings.displayChords}
            onChange={() => set("displayChords", !settings.displayChords)}
            field={"displayChords"}
            label={i18n.t("app:song:settings:display chords")}
          />
        </ListItem>
        {settings.displayChords ? (
          <>
            <ListItem button>
              <AppSwitch
                value={settings.chordFormat === "en"}
                onChange={() =>
                  set(
                    "chordFormat",
                    settings.chordFormat === "en" ? "fr" : "en"
                  )
                }
                field={"chordFormat"}
                label={i18n.t("app:song:settings:english chords")}
              />
            </ListItem>
            <ListItem button>
              <AppSlider
                chevronMode={true}
                value={settings.chordsPosition}
                onChange={set}
                min={-1}
                max={1}
                field={"chordsPosition"}
                label={i18n.t("app:song:settings:chords position")}
              />
            </ListItem>
          </>
        ) : null}
        <ListItem button>
          <AppSwitch
            value={settings.projectorMode}
            onChange={() => set("projectorMode", !settings.projectorMode)}
            field={"projectorMode"}
            label={i18n.t("app:song:settings:projector")}
          />
        </ListItem>
        {settings.projectorMode ? (
          <>
            <ListItem button>
              <AppSwitch
                value={settings.projectorIsDark}
                onChange={() =>
                  set("projectorIsDark", !settings.projectorIsDark)
                }
                field={"projectorIsDark"}
                label={i18n.t("app:song:settings:projectorIsDark")}
              />
            </ListItem>
            <ListItem button>
              <AppSlider
                value={settings.projectorFontSize}
                onChange={set}
                min={20}
                max={90}
                field={"projectorFontSize"}
                label={i18n.t("app:song:settings:projectorFontSize", {
                  value: Math.round((settings.projectorFontSize - 45) * 20),
                })}
              />
            </ListItem>
          </>
        ) : null}
      </List>
    </ProperMenu>
  );
};

SongSetting.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  /** autosave field */
  autoSaveField: PropTypes.string,
  /** hide two columns song display setting */
  disableTwoColumns: PropTypes.bool,
  songTitle: PropTypes.string,
};

export default SongSetting;
