import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import NavBar from '../../../core/NavBar'
import apiConsumer from '../../../service/api/apiConsumer'
import i18n from './../../../i18n'
import DisplayParish from '../../../components/parishes/DisplayParish'
import { Add, Check, Close, Edit } from '@mui/icons-material'
import { ParishEditorStore } from '../../../components/parishes/ParishEditor'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: 300,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(3),
      paddingBottom: 100
    }
  }
}))

const Component = () => {
  const classes = useStyles()
  const { id } = useParams()
  const [parish, setParish] = useState({})
  const [editMode, setEditMode] = useState()

  const getParish = useCallback(() => {
    apiConsumer.getOne(`parishes`, id).then(rep => {
      if (rep.status === 200) {
        setParish(rep.body)
      }
    })
    return () => setParish({})
  }, [id, setParish])

  useEffect(() => getParish(), [getParish])

  const subscribe = useCallback(
    subscribe => {
      apiConsumer
        .request(subscribe ? 'POST' : 'DELETE', `parishes/${id}/subscribe`)
        .then(rep => {
          if (rep.status === (subscribe ? 200 : 204)) {
            setParish(e => ({ ...e, isSubscriber: subscribe }))
          }
        })
    },
    [id, setParish]
  )

  return parish.name ? (
    <>
      <NavBar
        backArrow={!editMode}
        title={parish.name}
        children={
          parish.isResponsible ? (
            <IconButton aria-label='edit' onClick={() => setEditMode(e => !e)}>
              {editMode ? <Close /> : <Edit />}
            </IconButton>
          ) : parish.isSubscriber ? (
            <Button onClick={() => subscribe(false)} startIcon={<Check />}>
              {i18n.t(`app:parishes:unsubscribe`)}
            </Button>
          ) : (
            <Button
              startIcon={<Add />}
              variant='contained'
              color='primary'
              onClick={() => subscribe(true)}
            >
              {i18n.t(`app:parishes:subscribe`)}
            </Button>
          )
        }
      />
      {!editMode && parish.name ? (
        <Grid container spacing={2} className={classes.root}>
          <Grid container justifyContent='flex-end'>
            {parish?.subscriberCount > 10 && (
              <Typography color='text.secondary'>
                {i18n.t('app:parishes:n subscribers', {
                  n: parish.subscriberCount
                })}
              </Typography>
            )}
          </Grid>
          <DisplayParish parish={parish} />
        </Grid>
      ) : parish.name ? (
        <ParishEditorStore
          parish={{ ...parish }}
          onEndEdit={() => {
            setEditMode(false)
            getParish()
          }}
        />
      ) : null}
    </>
  ) : null
}

export default Component
