import React from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'

const YoutubePreview = ({ element, value }) => {
  return (
    <Grid
      item
      xs={element.xs || 12}
      md={element.md}
      key={`prev${element.field}`}
    >
      <Grid container justifyContent='center' alignItems='center'>
        {ReactPlayer.canPlay(value) ? (
          <ReactPlayer url={value} width='426px' height='240px' controls />
        ) : null}
      </Grid>
    </Grid>
  )
}

YoutubePreview.propTypes = {
  /** size [xs, md, ...], ?field */
  element: PropTypes.object,
  /** src */
  value: PropTypes.string
}

export default YoutubePreview

export const YoutubePreviewStore = connect((state, ownProps) => ({
  value: state.form?.values[ownProps.element.field]
}))(YoutubePreview)
