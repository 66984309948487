import React, { useCallback, useState } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
  Edit,
  MoreHoriz
} from '@mui/icons-material'
import ProperMenu from '../menu/ProperMenu'
import SimpleTextDialog from './SimpleTextDialog'
import i18n from '../../i18n'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import PropTypes from 'prop-types'
import FormBigAutocomplete from './FormBigAutocomplete'
import { SONG_TYPES } from '../../config/massSheetConstants'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  inputBold: {
    width: '100%',
    marginBottom: theme.spacing(1),
    '& *': {
      fontWeight: '800'
    }
  },
  NewButton: {
    transform: 'translateY(12px)'
  },
  songGrid: {
    position: 'relative'
  },
  menu: {
    float: 'right',
    height: '10px',
    marginTop: '8px!important'
  },

  addButton: {
    marginTop: theme.spacing(2)
  },
  songChordMenu: {
    width: '100%',
    overflow: 'auto',
    '& *': {
      textTransform: 'initial'
    }
  },
  switch: {
    margin: '0 0 10px 70px'
  }
}))

const settingsMenu = [
  {
    title: i18n.t('main:edit'),
    action: 'EDIT_PREPARE',
    icon: <Edit />
  },
  {
    title: i18n.t('main:delete'),
    action: 'DELETE',
    icon: <Delete />
  },
  {
    title: i18n.t('main:move up'),
    action: 'MOVE_UP',
    icon: <KeyboardArrowUp />
  },
  {
    title: i18n.t('main:move down'),
    action: 'MOVE_DOWN',
    icon: <KeyboardArrowDown />
  }
]

const songsCategories = {
  psaumes: 'Psaume',
  pu: 'Prière universelle'
}

const textEditParams = {
  title: i18n.t('celebrations:editor:category'),
  description: null,
  label: i18n.t('celebrations:editor:name'),
  yes: i18n.t('main:valid'),
  no: i18n.t('main:back'),
  autocomplete: SONG_TYPES
}

const SongMenu = ({ index, last, className, handleAction }) => {
  return (
    <>
      <ProperMenu icon={<MoreHoriz />} className={className}>
        <List key={`sMenu-${index}`}>
          {settingsMenu.map((element, i) => {
            if (
              (index === 0 && element.action === 'MOVE_UP') ||
              (last && element.action === 'MOVE_DOWN')
            ) {
              return null
            } else {
              return (
                <ListItem
                  button
                  key={`sMenu-${index}-${i}`}
                  onClick={() => {
                    handleAction(element.action, index)
                  }}
                >
                  <ListItemIcon>{element.icon}</ListItemIcon>
                  <ListItemText primary={element.title} />
                </ListItem>
              )
            }
          })}
        </List>
      </ProperMenu>
    </>
  )
}

const CelebrationEditor = ({
  field,
  editable,
  value,
  element,
  setStoreValue,
  songList,
  songCategories
}) => {
  const classes = useStyles()
  const [editValue, setEditValue] = useState({ value: '', index: null })
  const [editOpen, setEditOpen] = useState(false)

  const handleTextChange = useCallback(
    (index, val) => {
      value[index].songs = val
      setStoreValue([...value], null, field)
    },
    [value, setStoreValue, field]
  )

  const handleAction = useCallback(
    (action, index) => {
      switch (action) {
        case 'ADD_EDIT':
          if (editValue.index !== null) {
            value[editValue.index].header = editValue.value
            setStoreValue([...value], null, field)
          } else {
            setStoreValue(
              [...value, { header: editValue.value, songs: '' }],
              null,
              field
            )
          }
          setEditOpen(false)
          break
        case 'ADD_PREPARE':
          setEditValue({ value: '', index: null })
          setEditOpen(true)
          break
        case 'EDIT_PREPARE':
          setEditValue({ value: value[index].header, index })
          setEditOpen(true)
          break
        case 'DELETE':
          value.splice(index, 1)
          setStoreValue([...value], null, field)
          break
        case 'MOVE_UP':
          if (index > 0) {
            const tmp = value[index]
            value[index] = value[index - 1]
            value[index - 1] = tmp
            setStoreValue([...value], null, field)
          }
          break
        case 'MOVE_DOWN':
          if (index < value.length - 1) {
            const tmp = value[index]
            value[index] = value[index + 1]
            value[index + 1] = tmp
            setStoreValue([...value], null, field)
          }
          break
        default:
          break
      }
    },
    [editValue, setStoreValue, field, value]
  )

  const handleTextDialog = useCallback(
    value => {
      setEditValue(ev => ({ ...ev, value }))
    },
    [setEditValue]
  )

  return (
    <>
      <Grid item xs={12} className={classes.songGrid}>
        {value.map((verse, index) => {
          let options = !editable
            ? []
            : getMassPropositions(verse, songList, songCategories)

          return (
            <React.Fragment key={`verse-block${index}`}>
              {editable ? (
                <SongMenu
                  index={index}
                  last={value.length - 1 === index}
                  className={classes.menu}
                  handleAction={handleAction}
                />
              ) : (
                <br />
              )}
              {/* <Autocomplete
              multiple
              key={verse.name}
              id='standard'
              options={element.options}
              getOptionLabel={option => option.name}
              value={verse.songs || []}
              onChange={
                editable ? (e, value) => handleTextChange(index, value) : null
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant='standard'
                  label={verse.header}
                />
              )}
            /> */}

              <FormBigAutocomplete
                multiple
                key={verse.name}
                value={verse.songs || []}
                onChange={(e, value) => handleTextChange(index, value)}
                options={options}
                groupBy={options[0]?.type ? option => option.type : null}
                label={verse.header}
                readOnly={!editable}
              />
            </React.Fragment>
          )
        })}

        {editable ? (
          <Grid container justifyContent='center' className={classes.addButton}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                handleAction('ADD_PREPARE')
              }}
            >
              {i18n.t('celebrations:editor:add')}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <SimpleTextDialog
        params={textEditParams}
        values={editValue}
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        onChange={value => handleTextDialog(value)}
        onValid={() => {
          handleAction('ADD_EDIT')
        }}
      />
    </>
  )
}

CelebrationEditor.propTypes = {
  field: PropTypes.string,
  editable: PropTypes.bool,
  value: PropTypes.array,
  element: PropTypes.object,
  setStoreValue: PropTypes.func,
  songCategories: PropTypes.array
}

export default CelebrationEditor

export const CelebrationEditorStore = connect(
  (state, ownProps) => ({
    field: ownProps.element.field,
    value:
      'values' in state.form ? state.form.values[ownProps.element.field] : [],
    valid:
      'valid' in state.form ? state.form.valid[ownProps.element.field] : '',
    editable: state.form.editable,
    songList: state.setting.appDatas?.song.map(e => ({
      id: e.id,
      name: e.name,
      number: e.number,
      category: e.category?.id
    })),
    songCategories: state.setting.appDatas?.category
  }),
  dispach => ({
    setStoreValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(CelebrationEditor)

const getMassPropositions = (verse, songList, songCategories) => {
  // select only good propositions for psaumes and ordinary
  if (verse.header === songsCategories.psaumes) {
    const id = songCategories.find(e => e.name === songsCategories.psaumes)?.id
    if (id) songList = songList.filter(e => e.category === id)
  } else if (verse.header === songsCategories.pu) {
    const id = songCategories.find(e => e.name === songsCategories.pu)?.id
    if (id) songList = songList.filter(e => e.category === id)
  }

  return songList
}
