import { setSettingStoreValueAction } from '../../store/setting/settingStoreAction'
import { store } from '../../store/index'
import { getUserRight } from './authValidation'

// store user credentials

const authStore = {
  setMemorisedUserName (value) {
    store.dispatch(setSettingStoreValueAction('memorisedUser', value))
  },

  getMemorisedUserName () {
    return store.getState()?.setting.memorisedUser || ''
  },

  setConnectionDatas (data = {}) {
    store.dispatch(
      setSettingStoreValueAction('user', {
        ...data,
        role: getUserRight(data.roles)
      })
    )
  },

  resetConnectionDatas () {
    store.dispatch(setSettingStoreValueAction('user', {}))
  },

  getConnectionDatas () {
    return store.getState()?.setting?.user
  },

  getToken () {
    return this.getConnectionDatas()?.token
  }
}

export default authStore
