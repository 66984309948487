import http from "../../config/httpConstantes";
import apiConsumer from "./apiConsumer";
import authStore from "./authStore";

// autentification service to login, logout, signup, setPassword, forgotpassword

const AuthService = {
  login(username, password, remember) {
    return fetch(`${http.apiUrl}/login`, {
      headers: http.headers,
      method: "POST",
      body: JSON.stringify({ username, password }),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          authStore.setMemorisedUserName(remember ? username : "");
          authStore.setConnectionDatas(data);
          return { status: response.status, ...data };
        }
        return { status: response.status };
      })
      .catch(console.log);
  },

  async logout() {
    authStore.resetConnectionDatas();
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  },

  goToAuthPage() {
    authStore.resetConnectionDatas();
    setTimeout(() => {
      window.location = `/login?page=${window.location.pathname}`;
    }, 500);
  },
  signup(formValues) {
    return fetch(`${http.apiUrl}/signup`, {
      headers: http.headers,
      method: "POST",
      body: JSON.stringify(formValues),
    })
      .then((r) => apiConsumer.checkResponse(r))
      .catch(() => ({ status: null, statustext: null, body: null }));
  },

  setPassword(formValues) {
    return fetch(`${http.apiUrl}/setpassword`, {
      headers: http.headers,
      method: "POST",
      body: JSON.stringify(formValues),
    }).then(async (response) => {
      const data = await response.json();
      return { status: response.status, ...data };
    });
  },

  forgotpassword(email) {
    return fetch(`${http.apiUrl}/forgotpassword`, {
      headers: http.headers,
      method: "POST",
      body: JSON.stringify({ email }),
    }).then(async (response) => {
      const data = await response.json();
      return { status: response.status, ...data };
    });
  },
};

export default AuthService;
