import * as React from 'react'
import httpConstantes from './../../config/httpConstantes'
import { checkIsNotEmptyRichText } from '../../service/massSheetService'
import RichTextPreview from '../preview/RichTextPreview'
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  Divider,
  Stack,
  Button
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import i18n from '../../i18n'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '48px auto!important'
  },
  box: {
    width: '96%!important',
    margin: 'auto!important'
  },
  child: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0!important'
  },
  divider: {
    width: '100%',
    margin: '48px 0!important'
  },
  card: {
    width: '80%',
    height: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '200px',
      marginBottom: '24px!important'
    }
  },
  media: {
    minWidth: '100%',
    minHeight: '100%'
  },
  title: {
    fontSize: '18px!important'
  }
}))

const DisplayParishBlogs = ({ articles }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {!!articles?.length &&
        articles.map((e, i) => (
          <Grid
            key={i}
            container
            spacing={3}
            direction='row'
            justifyContent='center'
            alignItems='center'
            className={classes.box}
          >
            {e.picture && (
              <Grid item md={6} className={classes.child}>
                <Card className={classes.card}>
                  <CardMedia
                    component='img'
                    className={classes.media}
                    image={`${httpConstantes.apiUrl}/parishes-blog-picture/${e.id}`}
                  />
                </Card>
              </Grid>
            )}
            <Grid item md={e.picture ? 6 : 8} className={classes.child}>
              <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                <Typography
                  gutterBottom
                  variant='h6'
                  component='div'
                  className={classes.title}
                >
                  {e.name}
                  <Divider variant='middle' />
                </Typography>
                {e.subname && (
                  <Typography variant='body2' color='text.secondary'>
                    {e.subname}
                  </Typography>
                )}
                {checkIsNotEmptyRichText(e.content) && (
                  <div>
                    <RichTextPreview value={e.content} />
                  </div>
                )}
                {e.informations && (
                  <Typography variant='body2' color='text.secondary'>
                    {e.informations}
                  </Typography>
                )}
                {e.linkUrl && (
                  <a href={e.linkUrl} target='blank' rel='noreferrer'>
                    <Button variant='outlined' color='primary'>
                      {i18n.t(`app:parishBlogs:get more`)}
                    </Button>
                  </a>
                )}
              </Stack>
            </Grid>
            {i + 1 !== articles.length && (
              <Grid item xs={10} className={classes.child}>
                <Divider className={classes.divider} />
              </Grid>
            )}
          </Grid>
        ))}
    </div>
  )
}

export default DisplayParishBlogs
