import React from 'react'
import { Grid, Typography, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '60px!important',
    paddingBottom: '100px!important'
  },
  image: {
    maxWidth: 150,
    marginBottom: 20
  }
}))
const TripleSection = ({ data = [] }) => {
  const classes = useStyles()

  return (
    <Grid
      className={classes.root}
      container
      spacing={10}
      justifyContent='center'
    >
      {data.map((d, i) => (
        <Item key={i} data={d} />
      ))}
    </Grid>
  )
}

export default TripleSection

const Item = ({ data }) => {
  const classes = useStyles()
  return (
    <Grid item xs={8} sm={4}>
      <Stack alignItems='center' spacing={1}>
        <img
          alt={data.title}
          className={classes.image}
          style={data.styles}
          src={data.image}
        />
        <Typography component='h3' variant='h5' align='center'>
          {data.title}
        </Typography>
        <Typography component='p' align='center'>
          {data.describe}
        </Typography>
      </Stack>
    </Grid>
  )
}
