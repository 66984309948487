import React, { useCallback, useEffect, useState } from "react";
import { Grid, ListItemButton, ListItemText, Typography } from "@mui/material";
import { ChordParser } from "../../assets/AppChordPro";
import PropTypes from "prop-types";
import i18n from "../../i18n";

const betterDisplay = (text) =>
  text.replace(/\s*([!?:;]\n)|\s*([!?:;])$/g, "\u00A0$1$2");

const isChorus = (name) =>
  typeof name === "string" && ["refrain", "r"].includes(name.toLowerCase());

const isSpecificVerseBefore = (name = "") =>
  typeof name === "string" && ["pont", "coda"].includes(name.toLowerCase());

const isSpecificVerseAfter = (name = "") =>
  typeof name === "string" &&
  ["coda", "refrain final", "fin", "doxologie", "contre-chant"].includes(
    name.toLowerCase()
  );

const detectChorusPosition = (content) => {
  for (let k in content) {
    if (isChorus(content[k].name)) return k;
  }
  return -1;
};

const addChorusEach = (content) => {
  const pos = detectChorusPosition(content);
  if (pos !== -1) {
    let newContent = [];
    content.forEach((e, idx) => {
      if (
        idx > 0 &&
        Math.abs(idx - pos) > 1 &&
        !isSpecificVerseBefore(content[idx - 1].name) &&
        !isSpecificVerseAfter(content[idx].name)
      ) {
        newContent.push(content[pos]);
        newContent.push(content[idx]);
      } else {
        newContent.push(content[idx]);
      }
    });
    // add chorus at last position
    if (!isChorus(content.last.name)) {
      newContent.push(content[pos]);
    }
    // add black screen
    newContent.push({
      name: "Écran noire",
      text: "",
      bold: false,
    });
    return newContent;
  }
  return content;
};

const SongProjection = ({ content, settings, fullSize = false }) => {
  const chordParser = new ChordParser();
  if (content) content = addChorusEach(content);

  const [presentIndex, setPresentIndex] = useState(null);
  const [externalWindow, setExternalWindow] = useState(null);

  const projectSong = useCallback(
    (idx) => {
      setPresentIndex(idx);
      let _externalWindow;
      if (!externalWindow) {
        _externalWindow = window.open(
          "",
          "song-app",
          `popup=yes,width=${500},height=${500},left=200,top=200`
        );

        setExternalWindow(_externalWindow);
        if (_externalWindow) {
          _externalWindow.addEventListener("beforeunload", () => {
            console.log("popup is closed");
            setExternalWindow(null);
          });
        }
      } else {
        _externalWindow = externalWindow;
      }

      if (_externalWindow && content) {
        _externalWindow.document.body.innerHTML = getHtmlSongTypo(
          content[idx].text,
          isChorus(content[idx].name) ? "R" : content[idx].name,
          content[idx].bold,
          settings.projectorFontSize,
          settings.projectorIsDark
        );

        // setTimeout(() => {
        //   _externalWindow.document.activeElement.blur();
        //   const focusableElements = _externalWindow.document.querySelectorAll(
        //     "a, button, input, textarea, select, [tabindex]"
        //   );
        //   focusableElements.forEach((el) => el.blur());
        // }, 1000);
      }
    },
    [setPresentIndex, externalWindow, setExternalWindow, content, settings]
  );

  useEffect(() => {
    const scrollHandler = (e) => {
      e.preventDefault();
      if (e.key === "ArrowUp" && presentIndex > 0) {
        projectSong(presentIndex - 1);
      } else if (e.key === "ArrowDown" && presentIndex < content.length - 1) {
        projectSong(presentIndex + 1);
      }
    };

    window.addEventListener("keydown", scrollHandler);
    return () => window.removeEventListener("keydown", scrollHandler);
  }, [presentIndex, setPresentIndex, content, projectSong]);

  return (
    <Grid
      sx={{ margin: "auto", marginTop: 5, marginBottom: 5 }}
      container
      justifyContent="center"
      sm={8}
    >
      <Grid item sx={{ marginBottom: 2 }}>
        <Typography variant="h4">
          {i18n.t("app:song:projector mode")}
        </Typography>
      </Grid>
      {content.map((element, idx) => {
        return (
          <ListItemButton
            key={idx}
            component="a"
            onClick={() => projectSong(idx)}
            selected={idx === presentIndex}
            sx={{ width: "100%" }}
          >
            <ListItemText
              primary={`${
                isChorus(element.name) ? "R" : element.name
              }. ${chordParser.removeAllChords(betterDisplay(element.text))}`}
              primaryTypographyProps={{
                style: {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                  whiteSpace: "nowrap",
                },
              }}
            />
          </ListItemButton>
        );
      })}
      <Grid item sx={{ marginTop: 4 }}>
        <Typography>{i18n.t("app:song:projector mode explain")}</Typography>
      </Grid>
    </Grid>
  );
};

SongProjection.propTypes = {
  /** song content [{name: "", text: ""}] */
  content: PropTypes.array.isRequired,
  /** song settings */
  settings: PropTypes.object.isRequired,
  /** display in only one column */
  fullSize: PropTypes.bool,
};

export default SongProjection;

const getHtmlSongTypo = (text, chorusName, bold, fontSize, projectorIsDark) => {
  const chordParser = new ChordParser();
  text = chordParser
    .removeAllChords(betterDisplay(text))
    .replace(/\n/g, "<br>");

  const finalText = text.length === 0 ? "" : `${chorusName}. ${text}`;

  return `<!DOCTYPE html>
  <html>
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Carnet de chants</title>
      <style>
        * {
          font-family: arial;
          font-size: ${fontSize}px;
          color: ${projectorIsDark ? "white" : "black"};
          border:none;
        }
        html, body {
          background: ${projectorIsDark ? "black" : "white"};
          margin: 0;
        }
        .center {
          width:100%;
          height:100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .chorus {
          font-weight: bold !important;
        }
        .chorus, .verse {
          
        }
        .verse span, .chorus span {
          text-transform: capitalize;
        }
      </style>
    </head>
    <body>
      <div class="center">
        <p class="${bold ? "chorus" : "verse"}">${finalText}</p>
      </div>
    </body>
  </html>`;
};
