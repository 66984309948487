import React, { useCallback } from 'react'
import { MoreVert } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import ProperMenu from '../menu/ProperMenu'
import { List, ListItem, Typography } from '@mui/material'
import i18n from '../../i18n'
import AppSlider from '../menu/AppSlider'
import AppSwitch from '../menu/AppSwitch'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setSettingStoreValueAction } from '../../store/setting/settingStoreAction'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  list: {
    width: '250px',
    maxHeight: '95vh'
  }
}))

const PrintMassSheetSettings = ({ settings, setSettings, onClose }) => {
  const classes = useStyles()

  const set = useCallback(
    (field, val) => {
      setSettings({ ...settings, [field]: val })
    },
    [setSettings, settings]
  )

  return (
    <ProperMenu
      key='settings'
      aria-label='print settings'
      autoHide={false}
      icon={<MoreVert />}
      onClose={onClose}
    >
      <List className={classes.list}>
        <ListItem key='info'>
          <Typography variant='caption'>
            {i18n.t('massSheets:print:all settings')}
          </Typography>
        </ListItem>
        {['qrCode'].map((e, i) => (
          <ListItem button key={i + 10}>
            <AppSwitch
              value={settings[e]}
              onChange={() => set(e, !settings[e])}
              field={e}
              label={i18n.t(`massSheets:print:settings:${e}`)}
            />
          </ListItem>
        ))}
        <ListItem key='info2'>
          <Typography variant='caption'>
            {i18n.t('massSheets:print:pdf settings')}
          </Typography>
        </ListItem>
        {['headerSize', 'subheaderSize', 'textSize', 'songSize'].map((e, i) => (
          <ListItem button key={i}>
            <AppSlider
              value={settings[e]}
              onChange={set}
              min={5}
              max={30}
              field={e}
              label={i18n.t(`massSheets:print:settings:${e}`, {
                value: Math.round(settings[e])
              })}
            />
          </ListItem>
        ))}
        {['a5'].map((e, i) => (
          <ListItem button key={i + 10}>
            <AppSwitch
              value={settings[e]}
              onChange={() => set(e, !settings[e])}
              field={e}
              label={i18n.t(`massSheets:print:settings:${e}`)}
            />
          </ListItem>
        ))}
      </List>
    </ProperMenu>
  )
}

PrintMassSheetSettings.propTypes = {
  settings: PropTypes.object,
  setSettings: PropTypes.func,
  onClose: PropTypes.func
}

export default connect(
  state => ({
    settings: state.setting.printMassSheetSettings
  }),
  dispach => ({
    setSettings: value => {
      dispach(setSettingStoreValueAction('printMassSheetSettings', value))
    }
  })
)(PrintMassSheetSettings)
