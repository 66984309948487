import React, { useCallback } from 'react'
import { TextField, Grid } from '@mui/material'
import { Autocomplete } from '@mui/lab'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import PropTypes from 'prop-types'

const AppAutocomplete = ({ editable, element, value, setStoreValue }) => {
  const handleChange = useCallback(
    newValue => {
      setStoreValue(newValue, null, element.field)
    },
    [element, setStoreValue]
  )

  return (
    <Grid
      item
      xs={element.xs || 12}
      md={element.md}
      key={`grid-${element.label}`}
    >
      <Autocomplete
        multiple={element.multiple}
        id='standard'
        key={element.label}
        value={value || []}
        onChange={editable ? (e, value) => handleChange(value) : null}
        options={editable ? element.options : []}
        getOptionLabel={option => option.name || ''}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={
              editable ? { ...params.InputProps } : { readOnly: true }
            }
            variant='standard'
            label={element.label}
            placeholder={editable ? element.label : null}
          />
        )}
      />
    </Grid>
  )
}

AppAutocomplete.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.object,
  value: PropTypes.any,
  setStoreValue: PropTypes.func
}

export default AppAutocomplete

export const AppAutocompleteStore = connect(
  (state, ownProps) => ({
    value:
      'values' in state.form ? state.form.values[ownProps.element.field] : '',
    editable: state.form.editable
  }),
  dispach => ({
    setStoreValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(AppAutocomplete)
