import React from "react";

// Route Views
import Login from "./pages/connection/Login";
import SignUp from "./pages/connection/SignUp";
import SetPassword from "./pages/connection/SetPassword";
import ForgotPassword from "./pages/connection/ForgotPassword";
import Cgu from "./pages/connection/Cgu";

import ErrorPage from "./pages/error/ErrorPage";

import DefaultAdminLayout from "./pages/admin/admin";
import Users from "./pages/admin/tabs/Users";
import Songs from "./pages/admin/tabs/Songs";
import Categories from "./pages/admin/tabs/Categories";
import Civilities from "./pages/admin/tabs/Civilities";
import Languages from "./pages/admin/tabs/Languages";
import FileNames from "./pages/admin/tabs/FileNames";
import Tags from "./pages/admin/tabs/Tags";
import LiturgicalMoments from "./pages/admin/tabs/LiturgicalMoments";
import LiturgicalTimes from "./pages/admin/tabs/LiturgicalTimes";
import Solemnities from "./pages/admin/tabs/Solemnities";
import Trainings from "./pages/admin/tabs/Trainings";
import Celebrations from "./pages/admin/tabs/Celebrations";
import Parishes from "./pages/admin/tabs/Parishes";
import Dashboard from "./pages/admin/tabs/Dashboard";
import Logs from "./pages/admin/tabs/Logs";
import AppNotifications from "./pages/admin/tabs/AppNotifications";

import DefaultAppLayout from "./pages/application/Application";
import Category from "./pages/application/tabs/Category";
import Song from "./pages/application/tabs/Song";
import MusicSheet from "./pages/application/tabs/MusicSheet";
import Filter from "./pages/application/tabs/Filter";
import AppCelebrations from "./pages/application/tabs/Celebrations";
import MassSheet from "./pages/application/tabs/MassSheet";
import MassSheetCrud from "./pages/application/tabs/MassSheetCrud";
import MassSheetShow from "./pages/application/tabs/MassSheetShow";
import AppParishes from "./pages/application/tabs/Parishes";
import ParishShow from "./pages/application/tabs/ParishShow";
import ParishCreate from "./pages/application/tabs/ParishCreate";
import MassTimeCrud from "./pages/application/tabs/MassTimeCrud";
import parishBlogCrud from "./pages/application/tabs/ParishBlogCrud";
import AppTraining from "./pages/application/tabs/Training";
import TrainingShow from "./pages/application/tabs/TrainingShow";

import Chat from "./pages/globales/Chat";
import ProfilStore from "./pages/globales/Profil";
import SettingStore from "./pages/globales/Setting";
import MusicPdf from "./pages/application/tabs/MusicPdf";

import MassTimesWidget from "./pages/widgets/MassTimesWidget";
import ParishBlogWidget from "./pages/widgets/ParishBlogWidget";
import MusicSheetWidget from "./pages/widgets/MusicSheetWidget";
import ChatWidget from "./pages/widgets/ChatWidget";

import Showcase from "./pages/showcase/Showcase";
import ProfilWidget from "./pages/widgets/ProfilWidget";
import AudioWidget from "./pages/widgets/AudioWidget";

const routes = (isLogged) => [
  {
    path: "/",
    exact: true,
    layout: isLogged ? DefaultAppLayout : null,
    component: isLogged ? Category : Showcase,
  },
  {
    path: "/presentation",
    exact: true,
    component: Showcase,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/setpassword",
    component: SetPassword,
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
  },
  {
    path: "/cgu",
    component: Cgu,
  },
  // {
  //   path: '/',
  //   exact: true,
  //   layout: DefaultAppLayout,
  //   component: SongList
  // },
  {
    path: "/app",
    exact: true,
    layout: DefaultAppLayout,
    component: Category,
  },
  {
    path: "/app/chat",
    exact: true,
    layout: DefaultAppLayout,
    component: Chat,
    props: { type: "app" },
  },
  {
    path: "/app/profil",
    exact: true,
    layout: DefaultAppLayout,
    component: ProfilStore,
  },
  {
    path: "/app/setting",
    exact: true,
    layout: DefaultAppLayout,
    component: SettingStore,
  },
  {
    path: "/app/Filter",
    layout: DefaultAppLayout,
    component: Filter,
  },
  {
    path: "/app/celebrations",
    layout: DefaultAppLayout,
    component: AppCelebrations,
  },
  {
    path: "/app/masssheet",
    layout: DefaultAppLayout,
    component: MassSheet,
  },
  {
    path: "/app/masssheet-:id",
    layout: DefaultAppLayout,
    component: MassSheetShow,
  },
  {
    path: "/app/create-masssheet",
    layout: DefaultAppLayout,
    component: MassSheetCrud,
  },
  {
    path: "/app/parishes",
    layout: DefaultAppLayout,
    component: AppParishes,
  },
  {
    path: "/app/parish-:id",
    layout: DefaultAppLayout,
    component: ParishShow,
  },
  {
    path: "/app/create-parish",
    layout: DefaultAppLayout,
    component: ParishCreate,
  },
  {
    path: "/app/masstime-:id",
    layout: DefaultAppLayout,
    component: MassTimeCrud,
  },
  {
    path: "/app/parishblog-:id",
    layout: DefaultAppLayout,
    component: parishBlogCrud,
  },
  {
    path: "/app/training",
    layout: DefaultAppLayout,
    component: AppTraining,
  },
  {
    path: "/app/training-:id-:name",
    layout: DefaultAppLayout,
    component: TrainingShow,
  },
  {
    path: "/app/category-:id-:name",
    layout: DefaultAppLayout,
    component: Category,
  },
  {
    path: "/app/song-:id-:name",
    layout: DefaultAppLayout,
    component: Song,
  },
  {
    path: "/app/music-sheet-:id",
    layout: DefaultAppLayout,
    component: MusicSheet,
  },
  {
    path: "/app/music-pdf-:id",
    layout: DefaultAppLayout,
    component: MusicPdf,
  },
  {
    path: "/admin",
    exact: true,
    layout: DefaultAdminLayout,
    component: Dashboard,
  },
  {
    path: "/admin/chat",
    exact: true,
    layout: DefaultAdminLayout,
    component: Chat,
    props: { type: "admin" },
  },
  {
    path: "/admin/appnotifications",
    exact: true,
    layout: DefaultAdminLayout,
    component: AppNotifications,
  },
  {
    path: "/admin/profil",
    exact: true,
    layout: DefaultAdminLayout,
    component: ProfilStore,
  },
  {
    path: "/admin/setting",
    exact: true,
    layout: DefaultAdminLayout,
    component: SettingStore,
  },
  {
    path: "/admin/songs",
    layout: DefaultAdminLayout,
    component: Songs,
  },
  {
    path: "/admin/categories",
    layout: DefaultAdminLayout,
    component: Categories,
  },
  {
    path: "/admin/civilities",
    layout: DefaultAdminLayout,
    component: Civilities,
  },
  {
    path: "/admin/languages",
    layout: DefaultAdminLayout,
    component: Languages,
  },
  {
    path: "/admin/filenames",
    layout: DefaultAdminLayout,
    component: FileNames,
  },
  {
    path: "/admin/tags",
    layout: DefaultAdminLayout,
    component: Tags,
  },
  {
    path: "/admin/liturgicalMoments",
    layout: DefaultAdminLayout,
    component: LiturgicalMoments,
  },
  {
    path: "/admin/liturgicalTimes",
    layout: DefaultAdminLayout,
    component: LiturgicalTimes,
  },
  {
    path: "/admin/solemnities",
    layout: DefaultAdminLayout,
    component: Solemnities,
  },
  {
    path: "/admin/trainings",
    layout: DefaultAdminLayout,
    component: Trainings,
  },
  {
    path: "/admin/Celebrations",
    layout: DefaultAdminLayout,
    component: Celebrations,
  },
  {
    path: "/admin/parishes",
    layout: DefaultAdminLayout,
    component: Parishes,
  },
  {
    path: "/admin/users",
    layout: DefaultAdminLayout,
    component: Users,
  },
  {
    path: "/admin/logs",
    exact: true,
    layout: DefaultAdminLayout,
    component: Logs,
  },

  {
    path: "/widgets/music-sheet-:id-:theme-:token",
    component: () => <MusicSheetWidget />,
  },
  {
    path: "/widgets/massTimes-:id",
    component: () => <MassTimesWidget />,
  },
  {
    path: "/widgets/parishblog-:id",
    component: () => <ParishBlogWidget />,
  },
  {
    path: "/widgets/chat-:theme-:token",
    component: () => <ChatWidget />,
  },
  {
    path: "/widgets/profil-:theme-:token",
    component: () => <ProfilWidget />,
  },
  {
    path: "/widgets/audio-:id-:theme-:token",
    component: () => <AudioWidget />,
  },

  {
    path: "*",
    exact: true,
    component: () => <ErrorPage status="404" />,
  },
];

export default routes;
