import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { frFR } from '@mui/material/locale'
import light_theme from './assets/theme/light'
import dark_theme from './assets/theme/dark'
import Snackbar from './core/Snackbar'
import routes from './routes'
import { connect } from 'react-redux'
import { setSettingStoreValueAction } from './store/setting/settingStoreAction'
import { appDataUpdate } from './service/api/appDataUpdate'
import { generateMenu, getAppNotifications } from './pages/application/appMenuService'

function _App ({
  darkTheme,
  appDatas,
  setAppDatas,
  setAppMenu,
  setAppNotifications,
  user
}) {
  const theme = createTheme(darkTheme ? dark_theme : light_theme, frFR)

  // update app datas if user changed
  useEffect(() => {
    if (user?.token && user?.id) {
      appDataUpdate(appDatas, setAppDatas)
      getAppNotifications(setAppNotifications)
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  // générate app menu
  useEffect(() => {
    if (user?.token && user?.id && appDatas.category)
      setAppMenu(generateMenu(appDatas.category))
  }, [appDatas.category, setAppMenu, user])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          {routes(user?.token).map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={props =>
                route.layout ? (
                  <route.layout {...props}>
                    <route.component
                      {...props}
                      {...route.props}
                      routes={route.routes}
                    />
                  </route.layout>
                ) : (
                  <route.component
                    {...props}
                    {...route.props}
                    routes={route.routes}
                  />
                )
              }
            />
          ))}
        </Switch>
      </Router>
      <Snackbar />
    </ThemeProvider>
  )
}

const App = connect(
  state => ({
    darkTheme: state.setting.darkTheme,
    appDatas: state.setting.appDatas,
    user: state.setting.user,
    appMenu: state.setting.appMenu
  }),
  dispach => ({
    setAppDatas: value =>
      dispach(setSettingStoreValueAction('appDatas', value)),
    setAppMenu: value => dispach(setSettingStoreValueAction('appMenu', value)),
    setAppNotifications: value =>
      dispach(setSettingStoreValueAction('appNotifications', value))
  })
)(_App)

export default App
