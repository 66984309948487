import React from "react";
import { IconButton, AppBar, Toolbar, Typography, Hidden } from "@mui/material";
import { Menu, ArrowBack } from "@mui/icons-material";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { toggleFieldAction } from "../store/setting/settingStoreAction";
import PropTypes from "prop-types";

// global navigation bar

const NavBar = ({
  toggleDrawer,
  title = "",
  children,
  backArrow = false,
  drawerReduced,
}) => {
  const history = useHistory();

  return (
    <>
      <AppBar
        color="transparent"
        position="static"
        sx={{
          boxShadow: "none",
          //disable select
          webkitUserSelect: "none",
          mozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
        }}
      >
        <Toolbar>
          <Hidden mdUp={!drawerReduced} implementation="css">
            {!backArrow || drawerReduced ? (
              <IconButton
                edge="start"
                aria-label="open drawer"
                onClick={toggleDrawer}
              >
                <Menu />
              </IconButton>
            ) : null}
          </Hidden>
          {backArrow ? (
            <IconButton
              edge={!drawerReduced && "start"}
              aria-label="back"
              onClick={() => {
                backArrow === true ? history.goBack() : history.push(backArrow);
              }}
            >
              <ArrowBack />
            </IconButton>
          ) : null}
          <Typography
            component="h1"
            variant="h6"
            noWrap
            style={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
          {children}
        </Toolbar>
      </AppBar>
    </>
  );
};

NavBar.propTypes = {
  drawer: PropTypes.any,
  toggleDrawer: PropTypes.func,
  title: PropTypes.node,
  children: PropTypes.node,
  backArrow: PropTypes.bool,
  drawerReduced: PropTypes.bool,
};

export default connect(
  (state) => ({
    drawerReduced: state.setting.drawerReduced,
  }),
  (dispach) => ({
    toggleDrawer: () => dispach(toggleFieldAction("drawer")),
  })
)(NavBar);
