// defaults app settings

export const defaultSongSettings = {
  displayChords: false,
  chordFormat: "fr",
  chordsPosition: 0,
  fontSize: 15,
  tone: 0,
  toneName: "",
  savedTones: {},
  chorusEach: false,
  allowTwoColumns: false,
  projectorMode: false,
  projectorFontSize: 50,
  projectorIsDark: true,
};

export const defaultAdminSongSettings = {
  displayChords: false,
  chordFormat: "fr",
  chordsPosition: 0,
  fontSize: 13,
  tone: 0,
  toneName: "",
  chorusEach: false,
  allowTwoColumns: true,
};

export const defaulOsmdSettings = {
  zoom: 0.75,
  transpose: 0,
  mode: "compacttight",
  instruments: {},
};

export const defaultMassSheetSettings = {
  fontSize: 15,
  chorusEach: false,
  allowTwoColumns: true,
  compactMode: true,
  displayOrdinary: true,
};

export const defaultPrintMassSheetSettings = {
  headerSize: 12,
  subheaderSize: 9,
  textSize: 8,
  songSize: 8,
  qrCode: false,
  a5: true,
};
