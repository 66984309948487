import { People } from '@mui/icons-material'
import React from 'react'
import i18n from '../../i18n'
import CustomError from './CustomError'

const noAccessParams = {
  icon: <People fontSize={'large'} />,
  message: i18n.t('app:error:bad access'),
  button: i18n.t('app:error:bad song access sign'),
  link: '/'
}

const NeedSubscribe = ({ message }) => (
  <CustomError
    params={message ? { ...noAccessParams, message } : noAccessParams}
  />
)

export default NeedSubscribe
