import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  TextField,
  ListItemButton
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
  Edit,
  MoreHoriz
} from '@mui/icons-material'
import ProperMenu from '../menu/ProperMenu'
import SimpleTextDialog from './SimpleTextDialog'
import i18n from '../../i18n'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import PropTypes from 'prop-types'
import { formatDate } from '../../service/functions'
import apiConsumer from '../../service/api/apiConsumer'
import { setSettingStoreValueAction } from '../../store/setting/settingStoreAction'
import FormBigAutocomplete from './FormBigAutocomplete'
import AppDialog from '../unclassified/AppDialog'
import { ORDINARY_SONGS, SONG_TYPES } from '../../config/massSheetConstants'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  songGrid: {
    position: 'relative',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  menu: {
    float: 'right',
    height: '10px',
    marginTop: '8px!important'
  },
  addButton: {
    marginTop: theme.spacing(2) + '!important'
  },
  switch: {
    margin: '0 0 10px 70px'
  },
  inputBox: {
    padding: '0!important'
  },
  versesInput: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  dialogContent: {
    padding: theme.spacing(6),
    margin: 0
  }
}))

const settingsMenu = [
  {
    title: i18n.t('main:edit'),
    action: 'EDIT_PREPARE',
    icon: <Edit />
  },
  {
    title: i18n.t('main:delete'),
    action: 'DELETE',
    icon: <Delete />
  },
  {
    title: i18n.t('main:move up'),
    action: 'MOVE_UP',
    icon: <KeyboardArrowUp />
  },
  {
    title: i18n.t('main:move down'),
    action: 'MOVE_DOWN',
    icon: <KeyboardArrowDown />
  }
]

const songsCategories = {
  ordinaries: 'Messe',
  psaumes: 'Chanter la Messe',
  pu: 'Chanter la Messe'
}

const textEditParams = {
  title: i18n.t('massSheets:create:editor:category'),
  description: null,
  label: i18n.t('massSheets:create:editor:name'),
  yes: i18n.t('main:valid'),
  no: i18n.t('main:back'),
  autocomplete: SONG_TYPES
}

const searchIn = (a, b) => a.search(new RegExp(b, 'i')) !== -1

const SongMenu = ({ index, last, className, handleAction }) => {
  return (
    <>
      <ProperMenu icon={<MoreHoriz />} className={className}>
        <List key={`sMenu-${index}`}>
          {settingsMenu.map((element, i) => {
            if (
              (index === 0 && element.action === 'MOVE_UP') ||
              (last && element.action === 'MOVE_DOWN')
            ) {
              return null
            } else {
              return (
                <ListItem
                  button
                  key={`sMenu-${index}-${i}`}
                  onClick={() => {
                    handleAction(element.action, index)
                  }}
                >
                  <ListItemIcon>{element.icon}</ListItemIcon>
                  <ListItemText primary={element.title} />
                </ListItem>
              )
            }
          })}
        </List>
      </ProperMenu>
    </>
  )
}

const MassSheetEditor = ({
  field,
  editable,
  value,
  element,
  setStoreValue,
  celebration,
  celebrationList,
  setCelebration,
  date,
  songList,
  songCategories
}) => {
  const classes = useStyles()
  const [editValue, setEditValue] = useState({ value: '', index: null })
  const [editOpen, setEditOpen] = useState(false)
  const [editOrdinaryOpen, setEditOrdinaryOpen] = useState(false)

  const handleTextChange = useCallback(
    (index, val) => {
      value[index].song = { ...val }
      setStoreValue([...value], null, field)
    },
    [value, setStoreValue, field]
  )

  const handleNumberChange = useCallback(
    (index, val) => {
      value[index].verses = val
      setStoreValue([...value], null, field)
    },
    [value, setStoreValue, field]
  )

  const handleAction = useCallback(
    (action, index, param) => {
      switch (action) {
        case 'ADD_EDIT':
          if (editValue.index !== null) {
            value[editValue.index].header = { name: editValue.value }
            setStoreValue([...value], null, field)
          } else {
            setStoreValue(
              [
                ...value,
                {
                  header: { name: editValue.value },
                  song: {},
                  verses: []
                }
              ],
              null,
              field
            )
          }
          setEditOpen(false)
          break
        case 'ADD_PREPARE':
          setEditValue({ value: '', index: null })
          setEditOpen(true)
          break
        case 'EDIT_PREPARE':
          setEditValue({ value: value[index].header.name, index })
          setEditOpen(true)
          break
        case 'DELETE':
          value.splice(index, 1)
          setStoreValue([...value], null, field)
          break
        case 'MOVE_UP':
          if (index > 0) {
            const tmp = value[index]
            value[index] = value[index - 1]
            value[index - 1] = tmp
            setStoreValue([...value], null, field)
          }
          break
        case 'MOVE_DOWN':
          if (index < value.length - 1) {
            const tmp = value[index]
            value[index] = value[index + 1]
            value[index + 1] = tmp
            setStoreValue([...value], null, field)
          }
          break
        case 'ADD_ORDINARY_PREPARE':
          setEditOrdinaryOpen(true)
          break
        case 'ADD_ORDINARY':
          setEditOrdinaryOpen(false)
          // get ordinary songs
          const songs = songList.filter(e => searchIn(e.name, param))
          // add fields
          const ordinariesSongs = ORDINARY_SONGS.map(e => ({
            header: { name: e },
            song: songs.find(f => searchIn(f.name, e)),
            verses: []
          }))
          // prepare final value and
          const v = [
            ...value.filter(e => !ORDINARY_SONGS.includes(e.header.name)),
            ...ordinariesSongs
          ].sort(
            (a, b) =>
              SONG_TYPES.indexOf(a.header.name) -
              SONG_TYPES.indexOf(b.header.name)
          )
          setStoreValue(v, null, field)
          break
        default:
          break
      }
    },
    [editValue, setStoreValue, field, value, songList]
  )

  const handleTextDialog = useCallback(
    value => {
      setEditValue(ev => ({ ...ev, value }))
    },
    [setEditValue]
  )

  useEffect(() => {
    const d = formatDate(date, true)
    if (date && d !== celebration?.date) {
      if (celebrationList.findIndex(e => e.date.substr(0, 10) === d)) {
        setCelebration({ date: d, loading: true })
        apiConsumer.get(`celebrations/date/${d}`).then(rep => {
          setCelebration(
            rep.status === 200 ? { ...rep.body, date: d } : { date: d }
          )
        })
      } else {
        setCelebration({ date: d })
      }
    }
  }, [setCelebration, celebration, date, celebrationList, editable])

  const editDialog = useMemo(
    () => (
      <SimpleTextDialog
        params={textEditParams}
        values={editValue}
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        onChange={value => handleTextDialog(value)}
        onValid={() => {
          handleAction('ADD_EDIT')
        }}
      />
    ),
    [editValue, editOpen, setEditOpen, handleTextDialog, handleAction]
  )

  console.log(songList
    .filter(e => e.name.search(/agnus/i) !== -1)
    .filter((value, index, array) => array.indexOf(value) === index));

  const editOrdinaryDialog = useMemo(
    () => (
      <AppDialog
        title={i18n.t('massSheets:create:editor:add ordinary')}
        open={editOrdinaryOpen}
        width='xs'
        onClose={() => setEditOrdinaryOpen(false)}
      >
        <List component='div'>
          {songList
            .filter(e => e.name.search(/agnus/i) !== -1)
            .filter((value, index, array) => array.indexOf(value) === index) // unique
            .map(e => {
              const name = e.name.replace(/\s*-?\s*agnus/i, '')
              return (
                <ListItemButton
                  key={e.id}
                  onClick={() => handleAction('ADD_ORDINARY', null, name)}
                >
                  <ListItemText primary={name} />
                </ListItemButton>
              )
            })}
        </List>
      </AppDialog>
    ),
    [songList, editOrdinaryOpen, setEditOrdinaryOpen, handleAction]
  )

  return (
    <>
      <Grid item xs={12} className={classes.songGrid}>
        {value.map((verse, index) => {
          let options = !editable
            ? []
            : getMassPropositions(
                verse,
                celebration?.celebrationSong,
                songList,
                songCategories
              )

          return (
            <React.Fragment key={`verse-block${index}`}>
              {editable ? (
                <SongMenu
                  index={index}
                  last={value.length - 1 === index}
                  className={classes.menu}
                  handleAction={handleAction}
                />
              ) : (
                <br />
              )}
              <Grid container spacing={1} className={classes.inputBox}>
                <Grid item xs={12} md={10}>
                  <FormBigAutocomplete
                    key={verse.name}
                    value={verse.song || {}}
                    onChange={(e, value) => handleTextChange(index, value)}
                    options={options}
                    groupBy={options[0]?.type ? option => option.type : null}
                    label={verse.header.name}
                    readOnly={!editable}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    variant='standard'
                    label={i18n.t('massSheets:create:editor:number of verses')}
                    value={verse.verses ? verse.verses.join(',') : []}
                    className={classes.versesInput}
                    onChange={
                      editable
                        ? e =>
                            handleNumberChange(
                              index,
                              e.target.value.replace(/[^\w\s,]/g, '').split(',')
                            )
                        : null
                    }
                    onBlur={e =>
                      handleNumberChange(
                        index,
                        e.target.value
                          .replace(/,*$/g, '')
                          .split(',')
                          .map(e => e.trim())
                          .filter(e => e.length)
                      )
                    }
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })}

        {editable ? (
          <Grid
            container
            justifyContent='center'
            className={classes.addButton}
            spacing={2}
          >
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => {
                  handleAction('ADD_PREPARE')
                }}
              >
                {i18n.t('massSheets:create:editor:add')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => {
                  handleAction('ADD_ORDINARY_PREPARE')
                }}
              >
                {i18n.t('massSheets:create:editor:add ordinary')}
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {editDialog}
      {editOrdinaryDialog}
    </>
  )
}

MassSheetEditor.propTypes = {
  field: PropTypes.string,
  editable: PropTypes.bool,
  value: PropTypes.array,
  element: PropTypes.object,
  setStoreValue: PropTypes.func,
  celebration: PropTypes.object,
  celebrationList: PropTypes.array,
  date: PropTypes.any,
  songList: PropTypes.array,
  songCategories: PropTypes.array
}

export default MassSheetEditor

export const MassSheetEditorStore = connect(
  (state, ownProps) => ({
    field: ownProps.element.field,
    value: state.form?.values[ownProps.element.field] || [],
    editable: state.form.editable,
    celebration: state.setting.celebration,
    celebrationList: state.setting.celebrationList || [],
    date: state.form?.values?.date,
    songList: state.setting.appDatas?.song.map(e => ({
      id: e.id,
      name: e.name,
      number: e.number,
      category: e.category?.id
    })),
    songCategories: state.setting.appDatas?.category
  }),
  dispach => ({
    setStoreValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field)),
    setCelebration: value =>
      dispach(setSettingStoreValueAction('celebration', value))
  })
)(MassSheetEditor)

const getMassPropositions = (
  verse,
  celebrationSong,
  songList,
  songCategories
) => {
  // select only good propositions for psaumes and ordinary
  if (verse.header.name === 'Psaume') {
    const id = songCategories.find(e => e.name === songsCategories.psaumes)?.id
    if (id) songList = songList.filter(e => e.category === id)
  } else if (verse.header.name === 'Prière universelle') {
    const id = songCategories.find(e => e.name === songsCategories.pu)?.id
    if (id) songList = songList.filter(e => e.category === id)
  } else if (ORDINARY_SONGS.includes(verse.header.name)) {
    const id = songCategories.find(e => e.name === songsCategories.ordinaries)
      ?.id
    if (id)
      songList = songList.filter(
        e => e.category === id && searchIn(e.name, verse.header.name)
      )
  }

  if (celebrationSong) {
    const i = celebrationSong.findIndex(e => e.header === verse.header.name)
    if (i !== -1) {
      return [
        ...celebrationSong[i].songs.map(e => ({
          ...e,
          type: i18n.t('massSheets:create:editor:proposals')
        })),
        ...songList.map(e => ({
          ...e,
          type: i18n.t('massSheets:create:editor:songs')
        }))
      ]
    }
  }
  return songList
}
