import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import ListParishes from '../../../components/parishes/ListParishes'
import NavBar from '../../../core/NavBar'
import apiConsumer from '../../../service/api/apiConsumer'
import i18n from './../../../i18n'
import { connect } from 'react-redux'
import SearchBox, {
  defaultSearch
} from './../../../components/unclassified/SearchBox'
import Loading from '../../../core/Loading'
import { setSettingStoreValueAction } from '../../../store/setting/settingStoreAction'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  buttons: {
    margin: theme.spacing(2, 0, 4, 0)
  },
  typo: {
    width: '90%',
    margin: '10% 5%!important',
    textAlign: 'center'
  }
}))

const Component = ({ search, setSearch, parishes, setParishes }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const onSearch = useCallback(
    s => {
      setLoading(true)
      if (s?.value) {
        apiConsumer.get(`parishes/search/${s.value}`).then(rep => {
          if (rep.status === 200) {
            setParishes(rep.body)
          }
          setLoading(false)
        })
      } else {
        apiConsumer.get(`parishes/subscribed`).then(rep => {
          if (rep.status === 200) {
            setParishes(rep.body)
          }
          setLoading(false)
        })
      }
    },
    [setParishes, setLoading]
  )

  useEffect(() => {
    if (!parishes) {
      onSearch()
      setSearch(defaultSearch)
    }
  }, [parishes, onSearch, setSearch])

  return (
    <>
      <NavBar
        title={i18n.t(
          `app:parishes:${parishes?.length > 1 ? 'my parishes' : 'my parish'}`
        )}
        children={
          <SearchBox
            label={i18n.t('app:parishes:name, city')}
            search={search}
            setSearch={setSearch}
            onSearch={onSearch}
            searchButton
          />
        }
      />
      <Grid container className={classes.root} justifyContent='flex-end'>
        <Grid container spacing={2}>
          {parishes?.length ? (
            <ListParishes parishes={parishes} />
          ) : search?.value ? (
            <Typography className={classes.typo}>
              {i18n.t('app:parishes:search my parish')}
            </Typography>
          ) : !loading ? (
            <Typography className={classes.typo}>
              {i18n.t('app:parishes:no parish')}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      {loading ? <Loading absolute /> : null}
    </>
  )
}

export default connect(
  state => ({
    search: state.setting?.parishesSearch || defaultSearch,
    parishes: state.setting?.parishes
  }),
  dispach => ({
    setSearch: value =>
      dispach(setSettingStoreValueAction('parishesSearch', value)),
    setParishes: value => dispach(setSettingStoreValueAction('parishes', value))
  })
)(Component)
