import React, { useMemo } from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import apiConsumer from '../../../service/api/apiConsumer'
import { formatDate, sortFrenchDates } from '../../../service/functions'
import NavBar from '../../../core/NavBar'

const params = {
  entityName: 'celebrations',
  targetUrl: 'celebrations',
  mainField: 'name',
  defaultEntity: {
    name: '',
    date: new Date(Date.now()),
    description: '',
    note: '',
    alleluiaVerse: '',
    alleluiaRef: '',
    celebrationSong: [
      { header: 'Entrée', songs: [] },
      { header: 'Psaume', songs: [] },
      { header: 'Prière universelle', songs: [] },
      { header: 'Offertoire', songs: [] },
      { header: 'Communion', songs: [] },
      { header: 'Envoi', songs: [] }
    ]
  },
  datagridOptions: {
    rights: {
      show: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      edit: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      delete: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('celebrations:field:id'),
      width: 80,
      hide: true
    },
    {
      field: 'name',
      headerName: i18n.t('celebrations:field:name'),
      width: 300,
      main: true
    },
    {
      field: 'date',
      headerName: i18n.t('celebrations:field:date'),
      width: 100,
      sortComparator: (v1, v2, p1, p2) => sortFrenchDates(v1, v2)
    }
  ],
  formStruct: {
    size: {
      width: 'lg',
      fullHeight: false
    },
    elements: [
      {
        type: 'text',
        required: true,
        field: 'name',
        label: i18n.t('celebrations:field:name'),
        md: 6,
        rules: {
          min: 3,
          max: 255,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'date',
        required: true,
        field: 'date',
        label: i18n.t('celebrations:field:date'),
        md: 6
      },
      {
        type: 'textarea',
        field: 'description',
        label: i18n.t('celebrations:field:description'),
        rules: {
          max: 255,
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'textarea',
        required: true,
        field: 'alleluiaVerse',
        label: i18n.t('celebrations:field:alleluiaVerse'),
        md: 6,
        rules: {
          min: 3,
          max: 600,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'text',
        field: 'alleluiaRef',
        label: i18n.t('celebrations:field:alleluiaRef'),
        md: 6,
        rules: {
          max: 30,
          maxMessage: i18n.t('error:the text is too long')
        }
      },
      {
        type: 'celebration',
        field: 'celebrationSong',
        label: i18n.t('celebrations:field:celebrationSong'),
        options: []
      },
      {
        type: 'textarea',
        field: 'note',
        label: i18n.t('celebrations:field:note'),
        rules: {
          max: 800,
          maxMessage: i18n.t('error:the text is too long')
        }
      }
    ]
  }
}

const formatEntities = entities =>
  entities.map(entity => {
    return formatEntity(entity)
  }) || null

const formatEntity = entity => {
  entity.date = formatDate(entity.date)
  return entity
}

export default function DataTable () {
  useMemo(async () => {
    const rep = await apiConsumer.get(`song-form-list`, false)
    if (rep.status === 200) {
      params.formStruct.elements.forEach((param, index) => {
        if (param.type === 'celebration') {
          params.formStruct.elements[index].options = rep.body
        }
      })
    }
  }, [])

  return (
    <>
      <NavBar title={i18n.t(`menu:${params.entityName}`)} />
      <CrudPage
        params={params}
        formatEntities={formatEntities}
        formatEntity={formatEntity}
      />
    </>
  )
}
