import React, { useMemo, useState, useCallback } from 'react'
import {
  Badge,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import { connect } from 'react-redux'
import { Check, Notifications } from '@mui/icons-material'
import AppDialog from '../unclassified/AppDialog'
import i18n from '../../i18n'
import { formatDateTime } from '../../service/functions'
import { setSettingStoreValueAction } from '../../store/setting/settingStoreAction'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  notifButton: {
    marginRight: theme.spacing(1) + '!important'
  },
  date: {
    float: 'right'
  }
}))

const Component = ({
  appNotifications,
  notificationsRead,
  setStoreNotificationsRead
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [notifCount, setNotifCount] = useState(0)

  const knowed = useMemo(() => {
    const k = notificationsRead
    setNotifCount(appNotifications.filter(e => !k.includes(e.id)).length)
    return k
  }, [appNotifications, notificationsRead])

  const closeNotifications = useCallback(() => {
    setStoreNotificationsRead(appNotifications.map(e => e.id))
    setNotifCount(0)
    setOpen(false)
  }, [appNotifications, setNotifCount, setOpen, setStoreNotificationsRead])

  return (
    !!appNotifications.length && (
      <>
        <IconButton
          className={classes.notifButton}
          color='primary'
          edge='end'
          onClick={() => setOpen(true)}
        >
          <Badge badgeContent={notifCount} color='secondary'>
            <Notifications />
          </Badge>
        </IconButton>

        <AppDialog
          title={i18n.t('app:notification:notifications')}
          open={open}
          onClose={() => closeNotifications()}
          width='sm'
        >
          {appNotifications.length
            ? appNotifications
                .sort((a, b) => new Date(b.beginAt) - new Date(a.beginAt))
                .sort((a, b) => (knowed.includes(a.id) ? 1 : -1))
                .map((e, i) => (
                  <ListItem key={i} divider={i !== appNotifications.length - 1}>
                    {knowed.includes(e.id) ? (
                      <ListItemIcon>
                        <Check />
                      </ListItemIcon>
                    ) : null}
                    <ListItemText
                      primary={
                        <>
                          {e.name}
                          <Typography
                            className={classes.date}
                            color='textSecondary'
                            variant='caption'
                          >
                            {i18n.t(
                              'app:notification:datetime',
                              formatDateTime(e.beginAt)
                            )}
                          </Typography>
                        </>
                      }
                      secondary={e.text}
                    />
                  </ListItem>
                ))
            : i18n.t('app:notification:no notifications')}
        </AppDialog>
      </>
    )
  )
}

Component.propTypes = {
  /** all notif iside array */
  appNotifications: PropTypes.array,
  /** all id of read notif inside array */
  notificationsRead: PropTypes.array,
  /** set read notif in store */
  setStoreNotificationsRead: PropTypes.func
}

export default connect(
  state => ({
    appNotifications: state.setting.appNotifications.appNotifications || [],
    notificationsRead: state.setting.notificationsRead || []
  }),
  dispach => ({
    setStoreNotificationsRead: value =>
      dispach(setSettingStoreValueAction('notificationsRead', value))
  })
)(Component)
