import React, { useCallback, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import i18n from '../../i18n'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import AppTextField from '../../components/form/FormTextField'
import { FormHelperText } from '@mui/material'
import apiConsumer from '../../service/api/apiConsumer'
import AuthService from '../../service/api/authService'
import Core from './core/Core'
import { Alert } from '@mui/material'
import { ValidatePassword } from '../../service/functions'
import Cgu from './Cgu'
import { regex } from '../../service/regex'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2, 0, 5)
  },
  error: {
    margin: '15px 2%',
    width: '96%',
    position: 'relative',
    top: '-15px'
  },
  cguButton: {
    textAlign: 'right',
    cursor: 'pointer',
    marginBottom: '20px'
  }
}))

const params = [
  {
    label: i18n.t('signup:identity'),
    data: [
      {
        type: 'select',
        field: 'civility',
        required: true,
        label: i18n.t('signup:field:civility'),
        options: [{}]
      },
      {
        type: 'text',
        field: 'firstname',
        required: true,
        label: i18n.t('signup:field:firstname'),
        sm: 6,
        regex: regex.name,
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:error:the text is too long')
        }
      },
      {
        type: 'text',
        field: 'lastname',
        required: true,
        label: i18n.t('signup:field:lastname'),
        sm: 6,
        regex: regex.name,
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:error:the text is too long')
        }
      },
      {
        type: 'text',
        field: 'email',
        required: true,
        label: i18n.t('signup:field:email'),
        regex: regex.email
      },
      {
        type: 'text',
        field: 'phone',
        required: false,
        label: i18n.t('signup:field:phone'),
        sm: 12,
        regex: regex.phone
      }
      // {
      //   type: 'password',
      //   field: 'password',
      //   required: true,
      //   label: i18n.t('signup:field:password')
      // },
      // {
      //   type: 'password',
      //   field: 'plainPassword',
      //   required: true,
      //   label: i18n.t('signup:field:plainPassword')
      // }
    ]
  },
  {
    label: i18n.t('signup:adress'),
    data: [
      {
        type: 'text',
        field: 'adress',
        required: true,
        label: i18n.t('signup:field:adress'),
        regex: regex.adress,
        rules: {
          min: 3,
          max: 100,
          minMessage: i18n.t('error:the text is too short'),
          maxMessage: i18n.t('error:error:the text is too long')
        }
      },
      {
        type: 'text',
        field: 'postcode',
        required: true,
        label: i18n.t('signup:field:postcode'),
        sm: 6,
        regex: regex.postcode
      },
      {
        type: 'text',
        field: 'city',
        required: true,
        label: i18n.t('signup:field:city'),
        sm: 6
      },
      {
        type: 'text',
        field: 'country',
        required: true,
        label: i18n.t('signup:field:country')
      }
    ]
  },
  {
    label: i18n.t('signup:finished'),
    data: [
      {
        field: 'allowMailing',
        type: 'checkbox',
        label: i18n.t('signup:field:allowMailing'),
        sm: 12
      },
      {
        field: 'conditions',
        type: 'checkbox',
        required: true,
        label: i18n.t('signup:field:conditions'),
        sm: 12
      }
    ]
  }
]

const links = [
  {
    label: i18n.t('signup:sign in'),
    url: '/login'
  }
]

const validateRegex = (value, regex) => {
  return regex.test(String(value))
}

const checkValidationError = (array, _form) => {
  for (const k in array) {
    if (_form.valid[array[k].field] !== '') {
      return true
    }
  }
  return false
}

const formValidation = (array, _form, _setForm) => {
  const validation = array.map(param => {
    let flag = ''
    if (param.field === 'password') {
      flag = ValidatePassword(
        _form.values['password'],
        _form.values['plainPassword']
      )
    } else if (
      param.regex &&
      (param.required === true || _form.values[param.field] !== '') &&
      !validateRegex(_form.values[param.field], param.regex)
    ) {
      flag = i18n.t('error:the entry is not valid')
    }
    if (param.required === true && _form.values[param.field] === '') {
      flag = i18n.t('error:the entry is required')
    }
    if (
      param.required === true &&
      param.type === 'checkbox' &&
      _form.values[param.field] !== true
    ) {
      flag = i18n.t('error:the checkbox must be checked')
    }
    _setForm(f => {
      f.valid[param.field] = flag
      return { ...f }
    })
    return flag === ''
  })
  return !validation.includes(false)
}

const allFormValidation = (ctrlArray, _form, _setForm) => {
  const validation = ctrlArray.map(array => {
    return formValidation(array.data, _form, _setForm)
  })
  return !validation.includes(false)
}

export default function SignUp () {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [form, setForm] = useState({
    values: {
      civility: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      plainPassword: '',
      adress: '',
      postcode: '',
      city: '',
      country: '',
      phone: '',
      allowMailing: true,
      conditions: false
    },
    valid: {
      civility: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      plainPassword: '',
      adress: '',
      postcode: '',
      city: '',
      country: '',
      phone: '',
      allowMailing: '',
      conditions: ''
    }
  })

  const handleNext = useCallback(() => {
    if (formValidation(params[activeStep].data, form, setForm)) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }, [form, activeStep, setActiveStep])

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }, [setActiveStep])

  const handleForm = useCallback(
    (value, valid, field) => {
      setForm(f => {
        f.values[field] = value
        f.valid[field] = valid
        return { ...f }
      })
    },
    [setForm]
  )

  const handleSubmit = useCallback(async () => {
    const getBadResponse = rep => {
      const err = rep?.body?.violations
        ? apiConsumer.getBadResponseMessage(rep)
        : rep?.body[0]

      if (err?.field) {
        const message = i18n.t(`error:${err.name}`, {
          name: i18n.t(`${params.entityName}:field:${err.field}`)
        })
        setForm(f => {
          f.valid[err.field] = message
          return { ...f }
        })
        return message
      } else if (err?.name) {
        return i18n.t(`error:${err.name}`)
      } else if (err?.status === 500) {
        return i18n.t('error:default')
      }
      return i18n.t('error:check the form')
    }

    if (allFormValidation(params, form, setForm)) {
      const rep = await AuthService.signup(form.values)
      if (rep.status === 201) {
        handleNext()
      } else {
        const error = getBadResponse(rep)
        setErrorMsg(error)
      }
    }
  }, [form, setForm, handleNext])

  useMemo(async () => {
    const rep = await apiConsumer.get(`civilities`, false, false)
    if (rep.status === 200) {
      params.forEach((param, index) => {
        param.data.forEach((el, idx) => {
          if (el.field === 'civility' && rep.body.length) {
            params[index].data[idx].options = rep.body
            handleForm(rep.body[0].id || null, '', 'civility')
          }
        })
      })
    }
  }, [handleForm])

  const finalStep = activeStep === params.length - 1

  return (
    <Core title={i18n.t('signup:signup')} links={links}>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {params.map((param, idx) => (
          <Step key={param.label}>
            <StepLabel
              onClick={() => setActiveStep(idx)}
              style={{ cursor: 'pointer' }}
              error={checkValidationError(param.data, form)}
            >
              {param.label}
            </StepLabel>
            <StepContent>
              <Grid container spacing={2}>
                {param.data.map((el, index) => (
                  <FormElement
                    key={`textField-${index}`}
                    param={el}
                    value={form.values[el.field]}
                    valid={form.valid[el.field]}
                    onHandleChange={(value, valid, field) =>
                      handleForm(value, valid, field)
                    }
                  />
                ))}

                <Grid item xs={12}>
                  {finalStep ? (
                    <div className={classes.cguButton}>
                      <Cgu />
                    </div>
                  ) : null}
                  {finalStep && errorMsg ? (
                    <Alert
                      severity='error'
                      variant='outlined'
                      className={classes.error}
                    >
                      {errorMsg}
                    </Alert>
                  ) : null}
                  <Grid container justifyContent='space-between'>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      {i18n.t('main:back')}
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={finalStep ? handleSubmit : handleNext}
                      type='button'
                    >
                      {finalStep
                        ? i18n.t('signup:i signup')
                        : i18n.t('main:next')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      {activeStep === params.length && (
        <Typography className={classes.margin}>
          {i18n.t('signup:finished')}
        </Typography>
      )}
    </Core>
  )
}

const FormElement = ({ param, value, valid, onHandleChange }) => (
  <Grid item xs={12} sm={param.sm || 12}>
    {param.type === 'checkbox' ? (
      <>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={e => onHandleChange(e.target.checked, '', param.field)}
              color='primary'
            />
          }
          label={param.label || ''}
        />
        <FormHelperText error>{valid}</FormHelperText>
      </>
    ) : (
      <AppTextField
        element={param}
        value={value}
        valid={valid}
        setValue={(value, valid, field) => onHandleChange(value, valid, field)}
      />
    )}
  </Grid>
)
