import React, { useCallback, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router'
import Core from './core/Core'

import AuthService from '../../service/api/authService'
import authStore from '../../service/api/authStore'
import { checkAdmin, checkLogin } from '../../service/api/authValidation'
import { Alert } from '@mui/material'
import i18n from './../../i18n'
import TestGuide from '../../components/guide/TestGuide'

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  fail: {
    margin: theme.spacing(3, 0, 1),
    width: '100%'
  },
  checkbox: {
    margin: theme.spacing(1, 0, 2, 0) + '!important'
  }
}))

const links = [
  {
    label: i18n.t('login:forgot password'),
    url: '/forgotpassword'
  },
  {
    label: i18n.t('login:sign up'),
    url: '/signup'
  }
]

const memorisedUser = authStore.getMemorisedUserName()

export default function SignInSide (props) {
  const classes = useStyles()
  const history = useHistory()
  const [username, setUsername] = useState(memorisedUser || 'super.test@ee.ee')
  const [password, setPassword] = useState('')
  const [check, setCheck] = useState(memorisedUser ? true : false)
  const [valid, setValid] = useState(false)
  const [error, setError] = useState(false)

  // check if user is connected
  useMemo(() => {
    if (checkAdmin()) {
      history.push('/app')
    } else if (checkLogin()) {
      history.push('/app')
    }
  }, [history])

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      setError(false)
      setValid(false)
      const result = await AuthService.login(username, password, check)
      if (!result?.status) {
        setError(true)
      } else if (result.status === 401) {
        setValid(true)
        setTimeout(() => setValid(false), 3000)
        // } else if (rightValidator(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) {
        //   history.push('/admin')
      } else {
        const path = props.location.search.replace('?page=', '')
        history.push(path || '/app')
      }
    },
    [history, password, username, check, props.location]
  )

  return (
    <Core title={i18n.t('login:login')} links={links}>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          variant='standard'
          margin='normal'
          required
          fullWidth
          id='email'
          label={i18n.t('login:email')}
          name='email'
          autoComplete='email'
          autoFocus
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <TextField
          variant='standard'
          margin='normal'
          required
          fullWidth
          name='password'
          label={i18n.t('login:password')}
          type='password'
          id='password'
          autoComplete='current-password'
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={check}
              onChange={e => setCheck(e.target.checked)}
              color='primary'
            />
          }
          label={i18n.t('login:remember me')}
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
        >
          {i18n.t('login:sign in')}
        </Button>
        {error ? (
          <Alert variant='outlined' severity='error' className={classes.fail}>
            {i18n.t('login:server failed')}
          </Alert>
        ) : valid ? (
          <Alert variant='outlined' severity='error' className={classes.fail}>
            {i18n.t('login:login failed')}
          </Alert>
        ) : null}
      </form>
      <TestGuide />
    </Core>
  )
}
