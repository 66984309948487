import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { checkAdmin, rightValidator } from '../../service/api/authValidation'
import {
  Dashboard,
  People,
  BarChart,
  Layers,
  WatchLater,
  AvTimer,
  CalendarToday,
  Translate,
  LocalOffer,
  AspectRatio,
  AccountCircle,
  Settings,
  ExitToApp,
  Email,
  // Build,
  Notifications,
  Description,
  Home,
  Explore
} from '@mui/icons-material'
import Drawer from '../../core/Drawer'
import i18n from '../../i18n'
import AuthService from '../../service/api/authService'
import appStyle from '../../assets/jss/globalAppStyle'
import { connect } from 'react-redux'

// service to define admin app left menu and notifications
// each menu element is defined by name, icon and link

const useStyles = makeStyles(theme => appStyle(theme))

const defaultMenus = [
  { icon: <Dashboard />, name: i18n.t(`menu:dashboard`), link: '/admin' },
  {
    name: i18n.t('menu:application'),
    icon: <AspectRatio />,
    link: '/app'
  },
  { icon: <People />, name: i18n.t(`menu:users`), link: '/admin/users' },
  { icon: <BarChart />, name: i18n.t(`menu:songs`), link: '/admin/songs' },
  {
    icon: <Layers />,
    name: i18n.t(`menu:song header`),
    open: false,
    submenus: [
      {
        icon: <Layers />,
        name: i18n.t(`menu:categories`),
        link: '/admin/categories'
      },
      {
        icon: <Translate />,
        name: i18n.t(`menu:languages`),
        link: '/admin/languages'
      },
      {
        name: i18n.t('menu:filenames'),
        icon: <Description />,
        link: `/admin/filenames`
      },
      { icon: <LocalOffer />, name: i18n.t(`menu:tags`), link: '/admin/tags' },
      {
        icon: <WatchLater />,
        name: i18n.t(`menu:liturgicalMoments`),
        link: '/admin/liturgicalMoments'
      },
      {
        icon: <AvTimer />,
        name: i18n.t(`menu:liturgicalTimes`),
        link: '/admin/liturgicalTimes'
      },
      {
        icon: <CalendarToday />,
        name: i18n.t(`menu:solemnities`),
        link: '/admin/solemnities'
      }
    ]
  },
  { divider: true },
  {
    icon: <Explore />,
    name: i18n.t(`menu:trainings`),
    link: '/admin/trainings'
  },
  {
    icon: <Home />,
    name: i18n.t(`menu:celebrations`),
    link: '/admin/Celebrations'
  },
  {
    icon: <Layers />,
    name: i18n.t(`menu:parishes`),
    link: '/admin/parishes'
  },
  {
    name: i18n.t('menu:appNotifications'),
    icon: <Notifications />,
    link: `/admin/appnotifications`
  },
  { divider: true },
  {
    notification: 'adminMessages',
    name: i18n.t('menu:chat'),
    icon: <Email />,
    link: `/admin/chat`
  },
  {
    name: i18n.t('menu:profile'),
    icon: <AccountCircle />,
    link: `/admin/profil`
  },
  {
    name: i18n.t('menu:settings'),
    icon: <Settings />,
    link: `/admin/setting`
  },
  { logButton: null },
  {
    name: i18n.t('menu:log out'),
    icon: <ExitToApp />,
    link: '#',
    func: () => {
      AuthService.logout()
    }
  }
]

// add logs menu for admin
// const superMenu = {
//   name: i18n.t('menu:logs'),
//   icon: <Build />,
//   link: '/admin/logs'
// }

const Admin = ({ children, drawerReduced }) => {
  const classes = useStyles()
  const [menus, setMenus] = useState(defaultMenus)

  useMemo(() => {
    // check if user has right
    checkAdmin(true)
    if (rightValidator(['ROLE_SUPER_ADMIN'])) {
      setMenus(m => {
        // const id = m.findIndex(e => 'logButton' in e)
        // if (id !== -1) m[id] = { ...superMenu }
        return [...m]
      })
    }
  }, [setMenus])

  return (
    <div className={classes.root}>
      <Drawer pageType='admin' menus={menus} />
      <div className={drawerReduced ? classes.fullContent : classes.content}>
        {children}
      </div>
    </div>
  )
}

Admin.propTypes = {
  children: PropTypes.node,
  drawerReduced: PropTypes.bool
}

const AdminStore = connect(state => ({
  drawerReduced: state.setting.drawerReduced
}))(Admin)

export default AdminStore
