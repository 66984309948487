import React, { useMemo } from 'react'
import i18n from '../../../i18n'
import CrudPage from '../../../components/crud/CrudPage'
import apiConsumer from '../../../service/api/apiConsumer'
import { USERS_ROLES } from '../../../service/api/authValidation'
import NavBar from '../../../core/NavBar'
import { regex } from '../../../service/regex'
import { formatDate, sortFrenchDates } from '../../../service/functions'

const params = {
  entityName: 'users',
  targetUrl: 'users',
  mainField: 'email',
  defaultEntity: {
    email: '',
    roles: '',
    firstname: '',
    lastname: '',
    civility: '',
    phone: '',
    password: '',
    plainPassword: '',
    adress: '',
    city: '',
    postcode: '',
    country: '',
    allowMailing: false,
    sendPasswordRequest: false
  },
  datagridOptions: {
    rights: {
      show: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      edit: ['ROLE_SUPER_ADMIN'],
      delete: ['ROLE_SUPER_ADMIN']
    },
    columnsOptions: {
      disableColumnMenu: false
    }
  },
  datatableColumns: [
    {
      field: 'id',
      headerName: i18n.t('users:field:id'),
      width: 60,
      hide: false
    },
    {
      field: 'email',
      headerName: i18n.t('users:field:email'),
      width: 230,
      main: true
    },
    {
      field: 'firstname',
      headerName: i18n.t('users:field:firstname'),
      width: 150
    },
    {
      field: 'lastname',
      headerName: i18n.t('users:field:lastname'),
      width: 150
    },
    {
      field: 'roles',
      headerName: i18n.t('users:field:roles'),
      width: 110
    },
    {
      field: 'createdAt',
      headerName: i18n.t('users:field:sign date'),
      width: 120,
      sortComparator: (v1, v2, p1, p2) => sortFrenchDates(v1, v2)
    },
    {
      field: 'enabled',
      type: 'boolean',
      headerName: i18n.t('users:field:enabled'),
      width: 100
    }
  ],
  formStruct: {
    size: {
      width: 'md',
      fullHeight: true
    },
    type: 'tabs',
    elements: [
      {
        label: i18n.t('users:identity'),
        data: [
          {
            type: 'select',
            field: 'civility',
            required: true,
            label: i18n.t('users:field:civility'),
            md: 6,
            options: []
          },
          {
            type: 'select',
            field: 'roles',
            required: true,
            label: i18n.t('users:field:roles'),
            md: 6,
            options: [
              { id: 'ROLE_USER', name: i18n.t('users:right:ROLE_USER') },
              {
                id: 'ROLE_SUBSCRIBER',
                name: i18n.t('users:right:ROLE_SUBSCRIBER')
              },
              { id: 'ROLE_ADMIN', name: i18n.t('users:right:ROLE_ADMIN') },
              { id: 'ROLE_SUPER_ADMIN', name: i18n.t('users:right:ROLE_ADMIN') }
            ]
          },
          {
            type: 'text',
            field: 'firstname',
            required: true,
            label: i18n.t('users:field:firstname'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            field: 'lastname',
            required: true,
            label: i18n.t('users:field:lastname'),
            md: 6,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'text',
            field: 'email',
            required: true,
            label: i18n.t('users:field:email'),
            md: 6,
            regex: regex.email
          },
          {
            type: 'text',
            field: 'phone',
            required: false,
            label: i18n.t('users:field:phone'),
            md: 6,
            regex: regex.phone
          },
          // {
          //   type: 'password',
          //   field: 'password',
          //   onlyEditor: true,
          //   label: i18n.t('users:field:password'),
          //   md: 6
          // },
          // {
          //   type: 'password',
          //   field: 'plainPassword',
          //   onlyEditor: true,
          //   label: i18n.t('users:field:password_check'),
          //   md: 6
          // },
          {
            type: 'switch',
            field: 'sendPasswordRequest',
            onlyEditor: true,
            label: i18n.t('users:field:send password by email'),
            md: 12
          },
          {
            type: 'switch',
            field: 'allowMailing',
            label: i18n.t('users:field:allowMailing'),
            md: 12
          }
        ]
      },
      {
        label: i18n.t('users:adress'),
        data: [
          {
            type: 'text',
            field: 'adress',
            required: true,
            label: i18n.t('users:field:adress'),
            md: 6,
            regex: regex.adress,
            rules: {
              min: 3,
              max: 100,
              minMessage: i18n.t('error:the text is too short'),
              maxMessage: i18n.t('error:the text is too long')
            }
          },
          {
            type: 'number',
            field: 'postcode',
            required: true,
            label: i18n.t('users:field:postcode'),
            md: 6,
            regex: regex.postcode
          },
          {
            type: 'text',
            field: 'city',
            required: true,
            label: i18n.t('users:field:city'),
            md: 6
          },
          {
            type: 'text',
            field: 'country',
            required: true,
            label: i18n.t('users:field:country'),
            md: 6
          }
        ]
      }
    ]
  }
}

const getRole = roles => {
  for (let r of USERS_ROLES) {
    if (roles.includes(r)) {
      return i18n.t(`users:right:${r}`)
    }
  }
  return ''
}

const formatCRUDEntity = entity => {
  entity.sendPasswordRequest = false
  if ('infos' in entity) {
    entity.adress = entity.infos.adress
    entity.allowMailing = entity.infos.allowMailing
    entity.city = entity.infos.city
    entity.civility =
      entity.infos.civility !== null ? entity.infos.civility.id : ''
    entity.country = entity.infos.country
    entity.phone = entity.infos.phone
    entity.postcode = entity.infos.postcode
    entity.password = ''
    entity.plainPassword = ''
    delete entity.infos
  }
  for (let k of USERS_ROLES) {
    if (entity.roles.includes(k)) {
      entity.roles = k
      return entity
    }
  }

  entity.roles = ''
  return entity
}

const formatEntities = entities => {
  return (
    entities.map(entity => {
      entity.roles = entity.roles ? getRole(entity.roles) : ''
      entity.createdAt = entity.createdAt ? formatDate(entity.createdAt) : ''
      return entity
    }) || null
  )
}

const formatEntity = entity => {
  entity.roles = entity.roles ? getRole(entity.roles) : ''
  entity.createdAt = entity.createdAt ? formatDate(entity.createdAt) : ''
  return entity
}

export default function DataTable () {
  useMemo(async () => {
    const rep = await apiConsumer.get(`civilities`, false)
    if (rep.status === 200) {
      params.formStruct.elements.forEach((param, index) => {
        param.data.forEach((el, idx) => {
          if (el.field === 'civility') {
            params.formStruct.elements[index].data[idx].options = rep.body
          }
        })
      })
    }
  }, [])

  return (
    <>
      <NavBar title={i18n.t(`menu:${params.entityName}`)} />
      <CrudPage
        params={params}
        formatEntities={formatEntities}
        formatEntity={formatEntity}
        formatCRUDEntity={formatCRUDEntity}
      />
    </>
  )
}
