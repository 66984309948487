import React, { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'

import AuthService from '../../service/api/authService'
import i18n from '../../i18n'
import Core from './core/Core'
import { TextField, Alert } from '@mui/material'

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2) + '!important'
  },
  success: {
    margin: theme.spacing(3, 0, 1) + '!important',
    width: '100%'
  }
}))

const ValidateEmail = email => {
  const regex = /^([\w-.]+@([\w-]+.)+[\w-]{2,4})?$/
  return regex.test(email)
}

const links = [
  {
    label: i18n.t('main:back'),
    url: '/login'
  }
]

export default function SetPassword (props) {
  const classes = useStyles()
  const [valid, setValid] = useState('')
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState('')

  const handleForm = useCallback(
    value => {
      setEmail(value)
      if (ValidateEmail(value)) {
        setValid('')
      }
    },
    [setEmail, setValid]
  )

  const handleSubmit = useCallback(async () => {
    let result = ValidateEmail(email)
    if (result.status === 400 || !email.length) {
      setValid(
        i18n.t(
          result.error
            ? `registrationFails:${result.error}`
            : 'registrationFails:default'
        )
      )
    } else {
      result = await AuthService.forgotpassword(email)
      if (result.status === 200) {
        setSuccess('success')
      } else {
        setSuccess(
          i18n.t(
            result.error
              ? `registrationFails:${result.error}`
              : 'registrationFails:default'
          )
        )
      }
    }
  }, [email, setValid])

  return (
    <Core title={i18n.t('forgotpassword:reset password')} links={links}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            variant='standard'
            required
            fullWidth
            label={i18n.t('forgotpassword:email')}
            name='email'
            autoComplete='email'
            autoFocus
            value={email}
            onChange={e => handleForm(e.target.value)}
            error={valid !== ''}
            helperText={valid}
          />
        </Grid>
        {success === 'success' ? (
          <Alert
            variant='outlined'
            severity='success'
            className={classes.success}
          >
            {i18n.t('forgotpassword:success')}
          </Alert>
        ) : null}
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={handleSubmit}
          disabled={success === 'success'}
        >
          {i18n.t('forgotpassword:valid')}
        </Button>
        {success !== '' && success !== 'success' ? (
          <Alert
            variant='outlined'
            severity='error'
            className={classes.success}
          >
            {success}
          </Alert>
        ) : null}
      </Grid>
    </Core>
  )
}
