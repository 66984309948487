import React from 'react'
import Header from './components/Header'
import TripleSection from './components/TripleSection'
import BiSection from './components/BiSection'
import datas from './datas'
import { Container, Fab, Paper, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import Pricing from './components/Pricing'
import Footer from './components/Footer'

const useStyles = makeStyles(theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.6em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '0px'
    },
    a: {
      textDecoration: 'none!important',
      color: theme.palette.text.secondary
    }
  },
  appButton: {
    position: 'fixed!important',
    top: 12,
    right: 8,
    left: 8,
    zIndex: 16
  },
  margin: {
    marginTop: 50,
    marginBottom: 50
  },
  padding: {
    paddingTop: 80,
    paddingBottom: 80
  }
}))

const Showcase = () => {
  const classes = useStyles()
  return (
    <>
      <Stack
        className={classes.appButton}
        direction='row'
        justifyContent='flex-end'
        spacing={2}
      >
        <Link to='/login'>
          <Fab
            variant='extended'
            color='primary'
            aria-label='application access'
          >
            Accéder à l'application
          </Fab>
        </Link>
        <Link to='/signup'>
          <Fab
            variant='extended'
            color='primary'
            aria-label='application access'
          >
            Créer un compte
          </Fab>
        </Link>
      </Stack>

      <Header data={datas.header} />
      <Container maxWidth='lg' component='main'>
        <BiSection data={datas.biSection_1} />
        <BiSection data={datas.biSection_2} />
      </Container>
      <Paper elevation={0} className={classes.margin}>
        <Container maxWidth='lg' component='main'>
          <TripleSection data={datas.tripleSection} />
        </Container>
      </Paper>
      <Container maxWidth='lg' component='main'>
        <BiSection data={datas.biSection_3} />
        <BiSection data={datas.biSection_4} />
        <Stack>
          <Stack alignSelf='center' sx={{ paddingBottom: 14 }}>
            <Link to='/login'>
              <Fab
                variant='extended'
                color='primary'
                aria-label='application access'
              >
                Tester l'application
              </Fab>
            </Link>
          </Stack>
        </Stack>
      </Container>
      <Paper elevation={0} className={classes.padding}>
        <Pricing data={datas.pricing} />
      </Paper>
      <Footer data={datas.footer} />
    </>
  )
}

export default Showcase
