import React from 'react'
import { Avatar, Button, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import CodeIcon from '@mui/icons-material/Code'
import i18n from '../../i18n'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '90vh'
  },
  avatar: {
    height: '60px!important',
    width: '60px!important'
  },
  text: {
    margin: '24px!important',
    whiteSpace: 'pre-wrap'
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit'
  }
}))
const ErrorPage = ({ status }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      className={classes.root}
    >
      <Avatar className={classes.avatar}>
        <CodeIcon fontSize={'large'} />
      </Avatar>
      <Typography variant='h5' className={classes.text}>
        {status}
      </Typography>
      <Link to='/app' className={classes.link}>
        <Button variant='contained' color='primary'>
          {i18n.t('app:error:go back home')}
        </Button>
      </Link>
    </Grid>
  )
}

export default ErrorPage
