import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router";
import DisplayMassSheet from "../../../components/massSheet/DisplayMassSheet";
import NavBar from "../../../core/NavBar";
import apiConsumer from "../../../service/api/apiConsumer";
import { setSettingStoreValueAction } from "../../../store/setting/settingStoreAction";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import NeedSubscribe from "../../error/NeedSubscribe";
import { checkIsSubscriber } from "../../../service/api/authValidation";
import i18n from "../../../i18n";
import MassSheetSetting from "./../../../components/massSheet/MassSheetSetting";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
}));

const Component = ({
  isSubscriber,
  massSheetSettings,
  setmassSheetSettings,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const [massSheet, setMassSheet] = useState({});

  useEffect(() => {
    apiConsumer.getOne("mass_sheets/full", id).then((rep) => {
      if (rep.status === 200) {
        setMassSheet(rep.body);
      }
    });
    return () => setMassSheet();
  }, [id, setMassSheet]);

  return (
    <>
      <NavBar
        title={massSheet.name}
        backArrow
        children={
          <MassSheetSetting
            settings={massSheetSettings}
            setSettings={setmassSheetSettings}
          />
        }
      />
      <Grid container className={classes.root}>
        <DisplayMassSheet
          massSheet={massSheet}
          displayName={false}
          AllowLinks={massSheetSettings.compactMode}
          settings={massSheetSettings}
        />
      </Grid>
      {!isSubscriber && (
        <NeedSubscribe
          message={i18n.t("app:error:bad massSheet show access")}
        />
      )}
    </>
  );
};

export default connect(
  (state) => ({
    massSheetSettings: state.setting?.massSheetSettings,
    isSubscriber: checkIsSubscriber(state.setting?.user.role),
  }),
  (dispach) => ({
    setmassSheetSettings: (value) =>
      dispach(setSettingStoreValueAction("massSheetSettings", value)),
  })
)(Component);
