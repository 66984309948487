import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import FormContent from './FormContent'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  AppBar: { boxShadow: 'none' },
  tabs: {
    margin: theme.spacing(0, 3, 1, 3)
  },
  dialogTabs: {
    position: 'relative',
    top: -16,
    left: -24,
    width: 'calc(100% + 48px)',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%'
    }
  }
}))

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <div>{children}</div>}
  </div>
)

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const FormContentTabs = ({ elements, editable, inDialog }) => {
  const classes = useStyles()
  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = useCallback(
    (event, newValue) => {
      setTabIndex(newValue)
    },
    [setTabIndex]
  )

  return (
    <>
      <AppBar
        position='static'
        color='transparent'
        classes={{ root: classes.AppBar }}
        sx={{ boxShadow: 'none' }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label='simple tabs example'
          indicatorColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          allowScrollButtonsMobile
          className={inDialog ? classes.dialogTabs : classes.tabs}
        >
          {elements?.map((element, index) => (
            <Tab
              label={element.label}
              {...a11yProps(index)}
              key={`tab-content-form-${index}`}
            />
          ))}
        </Tabs>
      </AppBar>

      {elements?.map((subElements, index) => (
        <TabPanel
          value={tabIndex}
          index={index}
          key={`tab-panel-content-form-${index}`}
        >
          <FormContent elements={subElements.data} editable={editable} />
        </TabPanel>
      ))}
    </>
  )
}

FormContent.propTypes = {
  elements: PropTypes.array,
  editable: PropTypes.bool,
  inDialog: PropTypes.bool
}

export default FormContentTabs

export const FormContentTabsStore = connect(state => ({
  elements: state.form.elements,
  editable: state.form.editable
}))(FormContentTabs)
