import React, { useCallback, useMemo, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { TableContainer, IconButton, Tooltip, Badge } from '@mui/material'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
  Edit,
  Visibility,
  Lens,
  ErrorOutline
} from '@mui/icons-material'
import { rightValidator } from '../../../service/api/authValidation'
import frFR from '../../../assets/jss/datagridLocaleFr'
import PropTypes from 'prop-types'
import i18n from '../../../i18n'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  datatable: {
    height: 'calc(100vh - 110px)',
    margin: 0,
    width: '100%',
    minWidth: 'auto!important',
    backgroundColor: 'transparent!important',
    backgroundImage: 'none!important',
    boxShadow: 'none!important',
    overflow: 'hidden',
    '& .MuiDataGrid-root': {
      border: '0'
    },
    '& .MuiDataGrid-viewport': {
      minWidth: 'auto!important'
    },
    '& .MuiDataGrid-root .MuiDataGrid-colCell:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus,.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none!important'
    },
    '& .MuiDataGrid-root .MuiDataGrid-colCellMoving, .MuiDataGrid-columnHeader': {
      backgroundColor: 'transparent!important'
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 132px)'
    }
  },
  errorBadge: {
    top: '1px!important',
    right: '5px!important'
  }
}))

const defaultActionButtons = [
  {
    label: 'show',
    action: 'SHOW',
    icon: <Visibility fontSize='small' />
  },
  { label: 'edit', action: 'EDIT', icon: <Edit fontSize='small' /> },
  {
    label: 'delete',
    action: 'DELETE',
    icon: <Delete fontSize='small' />
  },
  {
    label: 'move_up',
    action: 'MOVE_UP',
    icon: <KeyboardArrowUp />,
    hideSearching: true
  },
  {
    label: 'move_down',
    action: 'MOVE_DOWN',
    icon: <KeyboardArrowDown />,
    hideSearching: true
  }
]

const Dynatable = ({
  columns,
  rows,
  datagridOptions,
  onAction,
  isSearching
}) => {
  const classes = useStyles()
  const [mainWith, setMainWidth] = useState(0)
  const ref = useRef(null)

  const datatableRef = useCallback(
    node => {
      if (node) {
        setMainWidth(node.clientWidth)
      }
      ref.current = node
    },
    [setMainWidth]
  )

  const defaultColumns = useMemo(() => {
    const actionButtons = datagridOptions.rights
      ? defaultActionButtons.filter(
          e =>
            e.label in datagridOptions.rights &&
            ((isSearching && !e?.hideSearching) || !isSearching) &&
            (!datagridOptions.rights[e.label]?.length ||
              rightValidator(datagridOptions.rights[e.label]))
        )
      : []

    const boolFields = columns.filter(c => 'type' in c && c.type === 'boolean')
    const errorFields = columns.filter(c => 'type' in c && c.type === 'error')

    let _headerName = ''
    for (const k of boolFields) {
      _headerName += ` - ${k.headerName}`
    }
    for (const k of errorFields) {
      _headerName += ` - ${k.headerName}`
    }
    _headerName += actionButtons.length ? ' - Actions' : ''
    const _width =
      actionButtons.length * 40 +
      (boolFields.length + errorFields.length) * 35 +
      10 // 10px margin

    return [
      {
        field: '',
        headerName: _headerName.substr(3),
        sortable: false,
        disableColumnMenu: true,
        width: _width,
        renderCell: params => {
          const handleClick = type => {
            onAction(type, params.row)
          }
          const action = actionButtons.map(el => (
            <IconButton
              aria-label={el.label}
              key={el.action}
              onClick={() => handleClick(el.action)}
            >
              {el.icon}
            </IconButton>
          ))

          const bools = boolFields.map((e, i) => (
            <BoolIndicator key={i} value={params.row[e.field]} />
          ))

          const errors = errorFields.map((e, i) => (
            <Tooltip
              key={i}
              title={i18n.t('error:n errors encountered', {
                n: params.row[e.field]
              })}
            >
              <Badge
                badgeContent={
                  Number.isInteger(params.row[e.field]) && params.row[e.field]
                }
                color='error'
                classes={{ badge: classes.errorBadge }}
              >
                <ErrorOutline
                  color='error'
                  style={{
                    visibility: params.row[e.field] ? 'visible' : 'hidden',
                    marginRight: 5
                  }}
                />
              </Badge>
            </Tooltip>
          ))

          return [bools, errors, ...action]
        }
      }
    ]
  }, [onAction, columns, datagridOptions, classes.errorBadge, isSearching])

  columns = useMemo(() => {
    const cols = columns
      .filter(c => c?.type !== 'boolean' && c?.type !== 'error')
      .map(c => ({
        ...c,
        disableColumnMenu: true,
        ...datagridOptions?.columnsOptions
      }))
    return cols.map(c => {
      if (c?.main && mainWith !== 0) {
        const totalW = [...cols, ...defaultColumns].reduce(
          (a, curr) => a + (curr?.main ? 0 : curr.width || 0),
          0
        )
        const newW = mainWith - totalW - 20 // 20 margin
        return { ...c, width: newW > c.width ? newW : c.width }
      }
      return c
    })
  }, [columns, defaultColumns, mainWith, datagridOptions])

  return (
    <TableContainer  aria-label='dynatable content' className={classes.datatable}>
      <DataGrid
        ref={datatableRef}
        pageSize={100}
        rowsPerPageOptions={[20, 50, 100, 500]}
        pagination
        rows={rows === null ? [] : rows}
        columns={[...columns, ...defaultColumns]}
        loading={rows === null}
        style={{
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }}
        localeText={frFR}
        disableMultipleColumnsSorting={true}
        disableMultipleColumnsFiltering={true}
        hideFooterSelectedRowCount={true}
        hideFooterRowCount={true}
      />
    </TableContainer>
  )
}

Dynatable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  rights: PropTypes.object,
  onAction: PropTypes.func
}

export default Dynatable

const BoolIndicator = ({ value }) => (
  <div style={{ marginRight: '15px', marginTop: '12px' }}>
    <Lens
      style={{
        color: value ? 'green' : 'red',
        opacity: '0.5',
        fontSize: 12,
        marginBottom: '4px'
      }}
    />
  </div>
)
