import React, { useCallback, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Button, Grid, Typography } from '@mui/material'
import {
  FormatItalic,
  FormatBold,
  FormatUnderlined,
  Title,
  FormatQuote,
  FormatListNumbered,
  FormatListBulleted,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight
} from '@mui/icons-material'
import { connect } from 'react-redux'
import { setStoreValueAction } from './../../store/form/formStoreAction'
import { Element, Leaf } from '../unclassified/RichTextStyle'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.text.secondary,
    display: 'block',
    paddingBottom: 10
  },
  buttons: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      '& button': {
        padding: '4px 0px',
        minWidth: 30
      }
    }
  },
  editor: {
    padding: '0 2px'
  },
  blockquote: {
    borderLeft: '2px solid',
    borderLeftColor: theme.palette.divider,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 5),
    padding: theme.spacing(0.4, 0, 0.4, 2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1),
      padding: theme.spacing(0.2, 0, 0.2, 1)
    }
  },
  headlineIcon: {
    position: 'relative',
    top: 6,
    left: -2,
    fontSize: 12
  }
}))

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline'
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const RichTextInput = ({ editable, element, value, valid, setValue }) => {
  const classes = useStyles()
  const [val, setVal] = useState()
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  useMemo(() => {
    setVal(
      value?.length && ['object', 'array'].includes((typeof value).toString())
        ? value
        : [
            {
              type: 'paragraph',
              children: [{ text: '' }]
            }
          ]
    )
  }, [setVal, value])

  return (
    <Grid item xs={element.xs || 12} md={element.md}>
      <div className={classes.root}>
        <Typography variant='caption' color='textSecondary'>
          {element.label}
          {element.isRequired && ' *'}
        </Typography>
        <Slate
          editor={editor}
          value={val}
          onChange={value => {
            setVal(value)
            setValue(value, '', element.field)
          }}
        >
          {editable ? (
            <div className={classes.buttons}>
              <MarkButton key='a' format='bold' icon={<FormatBold />} />
              <MarkButton key='b' format='italic' icon={<FormatItalic />} />
              <MarkButton
                key='c'
                format='underline'
                icon={<FormatUnderlined />}
              />
              <BlockButton
                key='d'
                format='heading-one'
                icon={
                  <>
                    <Title />
                    <span className={classes.headlineIcon}>1</span>
                  </>
                }
              />
              <BlockButton
                key='e'
                format='heading-two'
                icon={
                  <>
                    <Title />
                    <span className={classes.headlineIcon}>2</span>
                  </>
                }
              />
              <BlockButton
                key='f'
                format='block-quote'
                icon={<FormatQuote />}
              />
              <BlockButton
                key='g'
                format='numbered-list'
                icon={<FormatListNumbered />}
              />
              <BlockButton
                key='h'
                format='bulleted-list'
                icon={<FormatListBulleted />}
              />
              <BlockButton key='i' format='left' icon={<FormatAlignLeft />} />
              <BlockButton
                key='j'
                format='center'
                icon={<FormatAlignCenter />}
              />
              <BlockButton key='k' format='right' icon={<FormatAlignRight />} />
            </div>
          ) : null}
          <div className={classes.editor}>
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={element.label || ''}
              spellCheck
              readOnly={!editable}
              onKeyDown={event => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault()
                    const mark = HOTKEYS[hotkey]
                    toggleMark(editor, mark)
                  }
                }
              }}
            />
          </div>
        </Slate>
      </div>
      {!!valid && (
        <Typography variant='caption' color='error'>
          {valid}
        </Typography>
      )}
    </Grid>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      color={isMarkActive(editor, format) ? 'primary' : 'inherit'}
      onClick={e => {
        e.preventDefault()
        toggleBlock(editor, format)
      }}
      onMouseDown={e => {
        e.preventDefault()
      }}
    >
      {icon}
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      color={isMarkActive(editor, format) ? 'primary' : 'inherit'}
      onClick={e => {
        e.preventDefault()
        toggleMark(editor, format)
      }}
      onMouseDown={e => {
        e.preventDefault()
      }}
    >
      {icon}
    </Button>
  )
}

export default RichTextInput

export const RichTextInputStore = connect(
  (state, ownProps) => {
    return {
      value:
        'values' in state.form
          ? state.form.values[ownProps.element.field]
          : null,
      valid:
        'values' in state.form ? state.form.valid[ownProps.element.field] : '',
      editable: state.form.editable
    }
  },
  dispach => ({
    setValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(RichTextInput)
