import React, { useCallback } from 'react'
import { MenuItem, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import i18n from '../../i18n'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    marginBottom: theme.spacing(1)
  }
}))

const validForm = (value, newValue, type, rules, required) => {
  if (required !== true && newValue === '') {
    return ''
  } else if (newValue === '') {
    return i18n.t('error:the entry is required')
  }
  if (rules) {
    if (['text', 'textarea'].includes(type)) {
      if (
        newValue?.length > rules.max &&
        (newValue?.length > value?.length || newValue?.length > rules.max)
      ) {
        return rules.maxMessage || 'error'
      } else if (
        newValue?.length < rules.min &&
        newValue?.length < value?.length
      ) {
        return rules.minMessage || 'error'
      } else {
        return ''
      }
    } else if (type === 'number') {
      if (newValue < rules.minVal) {
        return rules.minValMessage || 'error'
      } else if (newValue < rules.maxVal) {
        return rules.maxValMessage || 'error'
      } else {
        return ''
      }
    }
  }
  return ''
}

const AppTextField = ({ editable, element, value, valid, setValue }) => {
  const classes = useStyles()

  const handleChange = useCallback(
    newValue => {
      const validation = validForm(
        value,
        newValue,
        element.type,
        element.rules,
        element.required
      )
      setValue(
        element.type === 'number' ? parseInt(newValue) : newValue,
        validation,
        element.field
      )
    },
    [value, element, setValue]
  )

  let children, id
  if (element.type === 'text') {
    id = 'standard-basic'
  } else if (element.type === 'password') {
    id = 'standard-password-input'
  } else if (element.type === 'textarea') {
    id = 'standard-multiline-flexible'
  } else if (element.type === 'number') {
    id = 'standard-number'
  } else if (element.type === 'select') {
    id = 'standard-select-currency'
    if (element.options) {
      children = element.options.map((option, index) => (
        <MenuItem
          key={`${element.label}-${index}`}
          value={option.value || option.id}
        >
          {option.label || option.name}
        </MenuItem>
      ))
    } else {
      children = <MenuItem></MenuItem>
    }
  }

  return (
    <Grid
      item
      xs={element.xs || 12}
      md={element.md}
      key={`grid-${element.label}`}
    >
      <TextField
        variant='standard'
        className={classes.input}
        id={id}
        type={
          element.type === 'number' || element.type === 'password'
            ? element.type
            : null
        }
        multiline={element.type === 'textarea'}
        maxRows={element.rowsMax}
        select={element.type === 'select'}
        key={element.label}
        label={element.label}
        value={value || ''}
        onChange={e => handleChange(e.target.value)}
        required={element.required || false}
        error={valid !== ''}
        helperText={valid}
        InputProps={{
          readOnly: editable === false || element.readOnly
        }}
      >
        {children || null}
      </TextField>
    </Grid>
  )
}

AppTextField.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.object,
  value: PropTypes.any,
  valid: PropTypes.string,
  setValue: PropTypes.func
}

export default AppTextField

export const AppTextFieldStore = connect(
  (state, ownProps) => {
    return {
      value:
        'values' in state.form ? state.form.values[ownProps.element.field] : '',
      valid:
        'values' in state.form ? state.form.valid[ownProps.element.field] : '',
      editable: state.form.editable
    }
  },
  dispach => ({
    setValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(AppTextField)
