import { grey } from '@mui/material/colors'

const theme = {
  typography: {
    useNextVariants: true
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2'
    },
    secondary: {
      main: '#b80707'
    },
    background: {
      default: '#eee'
    },
    chords: {
      main: grey[700]
    }
  }
}

export default theme
