import React, { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router'

import AuthService from '../../service/api/authService'
import i18n from '../../i18n'
import AppTextField from '../../components/form/FormTextField'
import Core from './core/Core'
import { Alert } from '@mui/material'
import { rightValidator } from '../../service/api/authValidation'

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2) + '!important'
  },
  fail: {
    margin: theme.spacing(3, 0, 1) + '!important',
    width: '100%'
  }
}))

const params = [
  {
    type: 'password',
    field: 'password',
    required: true,
    label: i18n.t('setpassword:password'),
    md: 12
  },
  {
    type: 'password',
    field: 'plainPassword',
    required: true,
    label: i18n.t('setpassword:plainPassword'),
    md: 12
  }
]

const links = [
  {
    label: i18n.t('setpassword:sign up'),
    url: '/signup'
  }
]

const ValidatePassword = (password, bis) => {
  if (password !== bis) {
    return i18n.t('error:passwords.different')
  } else if (!/[a-z]+/.test(password)) {
    return i18n.t('error:should contain at least one lower case')
  } else if (!/[A-Z]+/.test(password)) {
    return i18n.t('error:should contain at least one upper case')
  } else if (!/\d+/.test(password)) {
    return i18n.t('error:should contain at least one digit')
  } else if (password.length < 5) {
    return i18n.t('error:should contain at least 6 characters')
  }
  return ''
}

export default function SetPassword (props) {
  const query = new URLSearchParams(props.location.search)
  const token = query.get('token')

  const classes = useStyles()
  const history = useHistory()
  const [form, setForm] = useState({
    values: {
      token,
      password: '',
      plainPassword: ''
    },
    valid: {
      token,
      password: '',
      plainPassword: ''
    }
  })
  const [fail, setFail] = useState('')

  const handleForm = useCallback(
    (value, valid, field) => {
      setForm(f => {
        f.values[field] = value
        f.valid[field] = valid
        return { ...f }
      })
    },
    [setForm]
  )

  const handleSubmit = useCallback(async () => {
    const error = ValidatePassword(
      form.values.password,
      form.values.plainPassword
    )
    if (error) {
      setForm(f => {
        f.valid.password = error
        return { ...f }
      })
    } else {
      let result = await AuthService.setPassword(form.values)
      if (result.status === 400) {
        setFail(
          i18n.t(
            result.error
              ? `registrationFails:${result.error}`
              : 'registrationFails:default'
          )
        )
      } else if (result.status === 200) {
        result = await AuthService.login(result.username, form.values.password)
        if (result.status === 401) {
          history.push('/login')
        } else if (rightValidator(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) {
          history.push('/admin')
        } else {
          history.push('/app')
        }
      }
    }
  }, [form, setForm, setFail, history])

  return (
    <Core title={i18n.t('setpassword:set password')} links={links}>
      <Grid container>
        {params.map((el, index) => (
          <Grid item key={el.field} xs={12} sm={el.sm || 12}>
            <AppTextField
              element={el}
              value={form.values[el.field]}
              valid={form.valid[el.field]}
              setValue={(value, valid, field) =>
                handleForm(value, valid, field)
              }
            />
          </Grid>
        ))}
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={handleSubmit}
        >
          {i18n.t('setpassword:valid')}
        </Button>
        {fail !== '' ? (
          <Alert variant='outlined' severity='error' className={classes.fail}>
            {fail}
          </Alert>
        ) : null}
      </Grid>
    </Core>
  )
}
