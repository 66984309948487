import React from 'react'
import { TextField, Button, ButtonGroup } from '@mui/material'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

const keysL = [
  'Do',
  'Do#',
  'Ré',
  'Mib',
  'Mi',
  'Fa',
  'Fa#',
  'Sol',
  'Sol#',
  'La',
  'Sib',
  'Si'
]

const ButtonsMenu = ({ onClick }) => (
  <>
    <ButtonGroup variant='text' color='primary'>
      {keysL.map((el, index) => (
        <Button size='small' key={index} onClick={() => onClick(`[${el}]`)}>
          {el}
        </Button>
      ))}
    </ButtonGroup>
    <ButtonGroup variant='text' color='primary'>
      {keysL.map((el, index) => (
        <Button size='small' key={index} onClick={() => onClick(`[${el}m]`)}>
          {`${el}m`}
        </Button>
      ))}
    </ButtonGroup>
  </>
)

class SongTextField extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      position: -1,
      value: this.props.value
    }
    this.insertAtPosition = this.insertAtPosition.bind(this)
  }

  insertAtPosition (chord) {
    if (
      this.state.position !== -1 &&
      this.state.value.length >= this.state.position
    ) {
      const newVal = [
        this.state.value.slice(0, this.state.position),
        chord,
        this.state.value.slice(this.state.position)
      ].join('')
      this.setState(state => {
        state.value = newVal
        return { state }
      })
      this.props.onChange(newVal)
    }
  }

  render () {
    const {
      classes,
      className,
      InputProps,
      label,
      onChange,
      onFocus,
      focus
    } = this.props

    return (
      <>
        {focus ? (
          <Grid item md={12} xs={12} className={classes.songChordMenu}>
            <ButtonsMenu onClick={this.insertAtPosition} />
          </Grid>
        ) : null}

        <TextField
          className={className}
          variant='standard'
          multiline={true}
          key={label}
          label={label}
          value={this.state.value}
          InputProps={InputProps}
          inputRef={el => (this.input = el)}
          onChange={event => {
            if (typeof this.input === 'object' && this.input !== null) {
              this.setState({
                value: event.target.value,
                position: this.input.selectionStart
              })
              onChange(event.target.value)
            }
          }}
          onClick={event => {
            if (typeof this.input === 'object' && this.input !== null) {
              this.setState({
                value: event.target.value,
                position: this.input.selectionStart
              })
              onFocus()
            }
          }}
          onBlur={() => {
            // this.setState(state => {
            //   // state.position = -1
            //   return { state }
            // })
          }}
        />
      </>
    )
  }
}

SongTextField.propTypes = {
  value: PropTypes.string,
  position: PropTypes.number,
  classes: PropTypes.any,
  className: PropTypes.any,
  InputProps: PropTypes.any,
  label: PropTypes.node,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  focus: PropTypes.bool
}

export default SongTextField
