import React, { useCallback, useState } from 'react'
import {
  Grid,
  FormControlLabel,
  TextField,
  Button,
  LinearProgress,
  IconButton
} from '@mui/material'
import { connect } from 'react-redux'
import { setStoreValueAction } from '../../store/form/formStoreAction'
import apiConsumer from '../../service/api/apiConsumer'
import i18n from '../../i18n'
import { Close } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: 10
  },
  input: {
    display: 'none'
  },
  textfield: {
    width: '100%'
  }
}))

const AppFileInput = ({
  editable,
  element,
  value,
  setStoreValue,
  entityId
}) => {
  const classes = useStyles()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState()

  const handleChange = useCallback(
    event => {
      if (entityId) {
        const files = event.target.files
        const formData = new FormData()
        formData.append('file', files[0])

        setError('')
        setLoading(true)

        const uploadLink = element.uploadTarget.replace('{id}', entityId)
        apiConsumer.upload(uploadLink, formData).then(rep => {
          setLoading(false)
          if (rep.status === 201) {
            setStoreValue(rep.body[element.field], null, element.field)
          } else if (rep.status === 422 && rep.body?.violations) {
            // setStoreValue('', null, element.field)
            setError(i18n.t(`error:${rep.body?.violations[0]?.message}`))
          } else {
            setError(i18n.t('error:default'))
          }
        })
      }
    },
    [element, setStoreValue, setError, entityId]
  )

  const removeFile = useCallback(() => {
    setError('')
    setLoading(true)

    apiConsumer.delete(element.deleteTarget, entityId).then(rep => {
      setLoading(false)
      if (rep.status === 200) {
        setStoreValue('', null, element.field)
      } else {
        setError(i18n.t('error:default'))
      }
    })
  }, [element, setStoreValue, setError, entityId])

  return (
    <Grid
      item
      xs={element.xs || 12}
      md={element.md}
      key={`in-${element.label}`}
    >
      <input
        accept={element.mimeTypes || ''}
        className={classes.input}
        type='file'
        id={`input-${element.label}`}
        onChange={editable ? e => handleChange(e) : null}
      />
      <TextField
        variant='standard'
        className={classes.textfield}
        label={element.label}
        value={value ? value.substr(14) : ''}
        InputProps={{
          startAdornment: (
            <FormControlLabel
              htmlFor={entityId ? `input-${element.label}` : ''}
              disabled={!entityId}
              control={
                <Button variant='outlined' className={classes.button}>
                  {i18n.t('main:choose a file')}
                </Button>
              }
              label=''
            />
          ),
          endAdornment:
            editable && value ? (
              <IconButton aria-label='delete' onClick={removeFile}>
                <Close fontSize='small' />
              </IconButton>
            ) : null
        }}
        disabled={!entityId}
        error={error !== ''}
        helperText={
          error
            ? error
            : !entityId
            ? i18n.t('main:item must be created to add files')
            : ''
        }
      />
      {loading ? <LinearProgress /> : null}
    </Grid>
  )
}

AppFileInput.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.object,
  value: PropTypes.object,
  setStoreValue: PropTypes.func,
  entityId: PropTypes.number
}

export default AppFileInput

export const AppFileInputStore = connect(
  (state, ownProps) => ({
    value:
      'values' in state.form ? state.form.values[ownProps.element.field] : '',
    editable: state.form.editable,
    entityId: state.form.values?.id
  }),
  dispach => ({
    setStoreValue: (value, valid, field) =>
      dispach(setStoreValueAction(value, valid, field))
  })
)(AppFileInput)
