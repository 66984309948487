import React, { useCallback, useEffect, useState, useMemo } from 'react'
import i18n from '../../i18n'
import DynatableHeader from '../preview/dynatable/DynatableHeader'
import Dynatable from '../preview/dynatable/Dynatable'
import { FormValidationDialogStore } from './FormValidationDialog'
import { DialogPromptStore } from './DialogPrompt'
import apiConsumer from '../../service/api/apiConsumer'
import { searchInArray } from '../../service/functions'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import { setSnackbarAction } from '../../store/setting/settingStoreAction'
import {
  initStoreStateAction,
  setStoreValidAction,
  setStoreValuesAction
} from '../../store/form/formStoreAction'
import { rightValidator } from '../../service/api/authValidation'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 0, 0, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  }
}))

const _CrudPage = ({
  role,
  params,
  formatEntities,
  formatEntity,
  formatCRUDEntity,
  setSnackbar,
  setStoreValues,
  setStoreValid,
  initStoreState
}) => {
  const classes = useStyles()
  const [formParams, setFormParams] = useState({})
  const [open, setOpen] = useState(null)
  const [entities, setEntities] = useState(null)
  const [entitiesSearch, setEntitiesSearch] = useState([])
  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
    initStoreState({ ...params.formStruct })
  }, [params.formStruct, initStoreState])

  const changeOrdering = useCallback(
    async (elementId, type, n) => {
      const idx = entities.findIndex(e => e.id === elementId)
      if ((n > 0 && idx < entities.length - 1) || (n < 0 && idx > 0)) {
        const rep = await apiConsumer.request('POST', 'ordering', {
          first: elementId,
          second: entities[idx + n].id,
          type
        })
        if (rep.status === 200) {
          setEntities(e => {
            let arr = [...e]
            arr[idx] = e[idx + n]
            arr[idx + n] = e[idx]
            return arr
          })
        } else if (rep.status === 404) {
          setSnackbar(i18n.t('error:default'), 'error')
        } else {
          setSnackbar(i18n.t('error:default'), 'error')
        }
      } else {
        return
      }
    },
    [entities, setEntities, setSnackbar]
  )

  const handleAction = useCallback(
    async (type, element) => {
      let editable
      switch (type) {
        case 'SHOW':
          setFormParams({
            title: i18n.t(`${params.entityName}:entity`),
            button: i18n.t('main:back')
          })
          editable = false
          break
        case 'ADD':
          setFormParams({
            title: i18n.t(`${params.entityName}:new entity`),
            button: i18n.t('main:create')
          })
          editable = true
          setStoreValues({ ...params.defaultEntity }, editable)
          break
        case 'EDIT':
          setFormParams({
            title: i18n.t(`${params.entityName}:edit entity`, {
              entity: element[params?.mainField],
              interpolation: { escapeValue: false }
            }),
            button: i18n.t('main:edit')
          })
          editable = true
          break
        case 'DELETE':
          setFormParams({
            title: i18n.t(`${params.entityName}:delete entity`, {
              entity: element[params?.mainField],
              interpolation: { escapeValue: false }
            }),
            msg: i18n.t(`${params.entityName}:are you sure`),
            yesButton: i18n.t('main:yes'),
            noButton: i18n.t('main:no')
          })
          setStoreValues(element)
          setOpen(type)
          break
        case 'MOVE_UP':
          changeOrdering(element.id, params.entityName, -1)
          break
        case 'MOVE_DOWN':
          changeOrdering(element.id, params.entityName, 1)
          break
        default:
          break
      }

      if (['SHOW', 'EDIT'].includes(type)) {
        const rep = await apiConsumer.getOne(params.targetUrl, element.id)
        if (rep.status === 200) {
          setStoreValues(
            formatCRUDEntity ? formatCRUDEntity(rep.body) : rep.body,
            editable
          )
        } else {
          setSnackbar(i18n.t('error:the data could not be recovered'), 'error')
          return
        }
      }
      if (['SHOW', 'EDIT', 'ADD'].includes(type)) {
        setOpen('EDIT')
      }
    },
    [params, formatCRUDEntity, setStoreValues, setSnackbar, changeOrdering]
  )

  // get api elements
  useEffect(() => {
    if (params?.targetUrlGet !== '' && params.targetUrl) {
      apiConsumer.get(params?.targetUrlGet || params.targetUrl).then(rep => {
        if (rep.status === 200) {
          setEntities(
            formatEntities ? formatEntities(rep.body) : rep.body || []
          )
          setEntitiesSearch(rep.body || [])
        }
      })
    }
    return () => {
      setEntities([])
      setEntitiesSearch([])
    }
  }, [params, formatEntities, setEntities, setEntitiesSearch])

  const onValidEdit = useCallback(
    async element => {
      if (element !== null) {
        const getBadResponse = rep => {
          const err = rep?.body?.violations
            ? apiConsumer.getBadResponseMessage(rep)
            : rep?.body[0]

          if (err?.field) {
            const message = i18n.t(`error:${err.name}`, {
              name: i18n.t(`${params.entityName}:field:${err.field}`)
            })
            setStoreValid(message, err.field)
            return message
          } else if (err?.name) {
            return i18n.t(`error:${err.name}`)
          } else if (err?.status === 500) {
            return i18n.t('error:default')
          }
          return i18n.t('error:check the form')
        }

        if (!element.id) {
          const rep = await apiConsumer.create(params.targetUrl, element)
          if (rep.status === 201) {
            setEntities([
              ...entities,
              formatEntity ? formatEntity(rep.body) : rep.body
            ])
            setOpen(null)
            setSnackbar(i18n.t('main:element added'), 'success')
            return null
          }
          return getBadResponse(rep)
        } else {
          const rep = await apiConsumer.update(params.targetUrl, element)
          if (rep.status === 200 || rep.status === 201) {
            const _entity = formatEntity ? formatEntity(rep.body) : rep.body
            setEntities(
              entities.map(obj => (obj.id === _entity.id ? _entity : obj))
            )
            setOpen(null)
            setSnackbar(i18n.t('main:element updated'), 'success')
            return null
          }
          return getBadResponse(rep)
        }
      } else {
        setOpen(null)
      }
    },
    [entities, params, formatEntity, setStoreValid, setSnackbar]
  )

  const onValidRemove = useCallback(
    async element => {
      if (element && element.id) {
        const rep = await apiConsumer.delete(params.targetUrl, element.id)
        if (rep.status === 204) {
          setEntities(entities.filter(val => val.id !== element.id))
        } else if (rep.status === 403) {
          setSnackbar(i18n.t('error:bad.right'), 'error')
        } else {
          setSnackbar(i18n.t('error:default'), 'error')
        }
        setOpen(null)
      } else {
        setOpen(null)
      }
    },
    [entities, params, setSnackbar]
  )
  const search = useCallback(
    value => {
      setEntities(searchInArray(entitiesSearch, params.mainField, value))
      setIsSearching(value?.length)
    },
    [entitiesSearch, params, setIsSearching]
  )

  const AllowedToEdit = useMemo(
    () =>
      !params.datagridOptions.rights?.edit?.length ||
      params.datagridOptions.rights.edit.includes(role),
    [role, params]
  )

  return (
    <>
      <Grid className={classes.root}>
        <DynatableHeader
          addText={i18n.t(`${params.entityName}:add new`)}
          searchText={i18n.t(`main:search`)}
          onAddAction={AllowedToEdit ? () => handleAction('ADD') : null}
          onSearch={search}
        />
        <Dynatable
          columns={params.datatableColumns}
          rows={entities}
          datagridOptions={params.datagridOptions}
          onAction={handleAction}
          isSearching={isSearching}
        />
      </Grid>
      {open === 'EDIT' ? (
        <FormValidationDialogStore
          canEdit={rightValidator(params?.datagridOptions?.rights?.edit)}
          open={true}
          formParams={formParams}
          size={params.formStruct.size}
          formType={params.formStruct.type || null}
          onValid={onValidEdit}
        />
      ) : null}
      {open === 'DELETE' ? (
        <DialogPromptStore
          open={true}
          params={formParams}
          onValid={onValidRemove}
        />
      ) : null}
    </>
  )
}

_CrudPage.propTypes = {
  role: PropTypes.string,
  params: PropTypes.object,
  formatEntities: PropTypes.func,
  formatEntity: PropTypes.func,
  formatCRUDEntity: PropTypes.func,
  setSnackbar: PropTypes.func,
  setStoreValues: PropTypes.func,
  setStoreValid: PropTypes.func,
  initStoreState: PropTypes.func
}

export default connect(
  state => ({
    role: state.setting?.user.role
  }),
  dispach => ({
    setStoreValues: (values, editable) => {
      const valid = { ...values }
      Object.keys(valid).map(key => (valid[key] = ''))
      dispach(
        setStoreValuesAction({ values, valid, displayError: false, editable })
      )
    },
    setStoreValid: (valid, field) => dispach(setStoreValidAction(valid, field)),
    initStoreState: init => dispach(initStoreStateAction(init)),
    setSnackbar: (message, type, time) =>
      dispach(setSnackbarAction(message, type, time))
  })
)(_CrudPage)
